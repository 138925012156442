import { Button, CircularProgress, Divider, Paper, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import axios from 'axios';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import styles from './CacheAdmin.module.css';

import { AuthContext, AuthContextType } from '../../context/AuthContext';
import { CacheAdminStyles } from './CacheAdminStyles';

const json2HTML = require('json2html');

interface Props {
  
}

export default function CacheAdmin(props: Props): ReactElement {
  
  const history = useHistory();
  
  const { idToken, setIdToken } : AuthContextType = useContext(AuthContext);

  const [ authenticated, setAuthenticated ] = useState(false);

  const [ bookingID, setBookingID ] = useState("");
  const [ bookingIDError, toggleBookingIDError ] = useState(false);

  const [ cache, setCache ] = useState(null);

  const [ getCacheLoadingSpinner, toggleGetCacheLoadingSpinner ] = useState(false);
  const [ deleteCacheLoadingSpinner, toggleDeleteCacheLoadingSpinner ] = useState(false);

  const [ cacheDeleted, toggleCacheDeleted ] = useState(false);

  useEffect(() => {

    //Initial authentication check
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {

      //Redirect to sign-in if no producerID in sessionStorage   
      alert("You do not have privileges to access this page.");
      window.location.replace("/");

    } else {

      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: 'us-east-1_N15Q0NLkm',
        ClientId: '2332rbhi35f5016dglri2mojo'
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession(async (err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {

            axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage

            try {

              let roleResponse = await axios.get("/producer/role", {
                params: {
                  producerID: localStorage.getItem("producerID")
                }
              });

              //Get the role on the producer object
              if (roleResponse.data.role === 0) {

                setAuthenticated(true);

              } else {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              };
            }
            catch {
              alert("You do not have privileges to access this page.");
              window.location.replace("/");
            }

          }
        })
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);

  const handleBookingIDChange = (e) => {
    setBookingID(e.target.value);
  }

  const handleSubmitBookingIDClick = async () => {
    toggleGetCacheLoadingSpinner(true);

    try {
      let response = await axios.get("/cache/admin", {
        params: {
          bookingID
        }
      });

      console.log("Cache get response", response);
      
      toggleGetCacheLoadingSpinner(false);

      //Order cache properties
      let cache = response.data.cache;


      setCache(response.data.cache);
    }
    catch (error) {
      toggleGetCacheLoadingSpinner(false);
      alert("An error occurred getting this cache")
    }

  }

  const handleDeleteCacheClick = async () => {
    //Show confirm popup
    if (window.confirm("Delete this cache?")) {

      //Show loader
      toggleDeleteCacheLoadingSpinner(true);

      //Delete the cache 
      try {

        await axios.delete("/cache/admin", {
          params: {
            bookingID
          }
        });

        toggleDeleteCacheLoadingSpinner(false);
        toggleCacheDeleted(true);
      }
      catch (error) {
        toggleDeleteCacheLoadingSpinner(false);
        alert("An error occurred deleting this cache")
      }

    } else {
      return;
    }
  }

  const classes = CacheAdminStyles();
  
  if (authenticated) {
    return (
      <React.Fragment>
        <Button
          className={classes.adminMenuButton}
          variant="contained"
          color="primary"
          onClick={() => { history.push("/admin-menu") }}
        >
          ADMIN MENU
        </Button>

        <div className={styles.cacheAdminWrapper}>
          <Paper className={classes.cacheAdminHolder}>
            <Typography variant="h1" className={classes.cacheHeader}>
              Cache
            </Typography>

            <Divider className={classes.cacheDivider}></Divider>
            
            {cacheDeleted
            
            ?
              <React.Fragment>
                <Typography variant="body1" className={classes.success}>
                  Success
                </Typography>
              </React.Fragment>
            :

            <React.Fragment>

              <div className={styles.cacheFormHolder}>
                <Typography variant="h2" className={classes.bookingIDHeader}>
                  Booking ID
                </Typography>
              </div>

              <TextField
                variant="outlined"
                className={classes.bookingIDTextField}
                value={bookingID}
                onChange={handleBookingIDChange}
                error={bookingIDError}
              ></TextField>

              <Button
                className={classes.submitButton}
                variant="contained"
                onClick={handleSubmitBookingIDClick}
                color="primary"
                disabled={getCacheLoadingSpinner || !bookingID.trim().length}
              >
                SUBMIT
              </Button>

              {(!getCacheLoadingSpinner && (cache !== null)) && 
                <React.Fragment>
                  <Button
                    className={classes.deleteButton}
                    variant="contained"
                    onClick={handleDeleteCacheClick}
                    color="primary"
                    disabled={deleteCacheLoadingSpinner}
                  >
                    DELETE CACHE
                  </Button>

                  {deleteCacheLoadingSpinner &&
                    <CircularProgress className={classes.deleteCacheLoadingSpinner} />
                  }
                </React.Fragment>
              }
              
              {getCacheLoadingSpinner &&
                <CircularProgress className={classes.cacheLoadingSpinner} />
              }

              <div 
                className={styles.cacheResultsHolder}
                dangerouslySetInnerHTML={{ __html: cache !== null ? json2HTML.render(cache) : ""}}  
              >
              </div>
            </React.Fragment>
            }

          </Paper>

        </div>

      </React.Fragment>
    )
  } else {
    return null;
  }
  
}
