import { makeStyles, createStyles } from '@material-ui/core/styles';

const PopupHeaderDeleteStyles = makeStyles((theme: any) => createStyles({
  closeIconButton: {
    position: "absolute",
    top: 3,
    color: "white",
    padding: 4,
    right: 5,
    zIndex: 10,
  },
  deleteHeader: {
    color: "white",
    fontSize: 20,
    position: "absolute",
    top: 23,
    left: 30,
    fontWeight: 400,
  }
})
)

export { PopupHeaderDeleteStyles };