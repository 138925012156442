import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Paper, Typography, Snackbar, CircularProgress, Button, IconButton } from '@material-ui/core';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/BackupOutlined';
import { orderBy } from 'natural-orderby';
import AddIcon from '@material-ui/icons/Add';

import BookingAttendee from './BookingAttendee/BookingAttendee';
import AddAttendeePopup from './AddAttendeePopup/AddAttendeePopup';
import UploadAttendeesPopup from './UploadAttendeesPopup/UploadAttendeesPopup';
import { AppContext, AppContextType } from '../../../context/AppContext';

import styles from './BookingAttendees.module.css';
import { BookingAttendeesStyles } from './BookingAttendeesStyles';

interface Props {
  
};


export default function BookingAttendees(props: Props): ReactElement {

  const [ bookingAttendees, setBookingAttendees ] = useState([] as SHOWBOAT.Registration[]);
  const [ addAttendee, toggleAddAttendee ] = useState(false);
  const [ downloadAttendees, toggleDownloadAttendees ] = useState(false);
  const [ uploadAttendees, toggleUploadAttendees ] = useState(false);
  const [ isLoading, toggleIsLoading ] = useState(true);
  const [ error, setError ] = useState("");
  const [ noAttendees, toggleNoAttendees ] = useState(false);

  const {
    selectedBookingID,
    selectedRegistration,
    selectedBooking,
    
  } : AppContextType = useContext(AppContext);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {

    //Get all attendees for a booking
    const getAllAttendees = async () => {
      try {
        let response = await axios.get('/attendees/all', {
          params: {
            bookingID: selectedBookingID,
          },
          cancelToken: source.token,
        });

        toggleIsLoading(false);
        setBookingAttendees(response.data);
      }
      catch (response) {
        if (axios.isCancel(error)) {
          return;
        }
  
        toggleIsLoading(false);
      }
    }
    getAllAttendees();

    return function cleanup() {
      source.cancel("Component was unmounted");
    }
  }, []);

  //Re-get attendees on delete/put/post
  useEffect(() => {
    
    axios.get('/attendees/all', {
      params: {
        bookingID: selectedBookingID,
      },
      cancelToken: source.token
    })
    .then(function(response) {
      setBookingAttendees(response.data);
    })
    .catch(function(error) {

      //If the request was canceled due to component unmounting, do nothing
      if (axios.isCancel(error)) return;

      setBookingAttendees([]);

      if (error.response && error.response.status === 404) {
        toggleNoAttendees(true);
      } else {
        setError("Error getting registrations for this booking")
      }
    });

    return function cleanup() {
      source.cancel("Component was unmounted")
    }
    
  }, [selectedRegistration]);

  /* DELETE ATTENDEE */
  const handleAttendeeDelete = (attendee) => {

    let bookingAttendesClone = bookingAttendees;

    //Remove attendee we are deleting from this booking's attendees
    bookingAttendesClone = bookingAttendesClone.filter(a => a.registrationID !== attendee.registrationID);

    setBookingAttendees(bookingAttendesClone);
  }
  /**/

  //Add attendee
  const handleAddAttendeeClick = () => {
    toggleAddAttendee(true);
  }

  const handleAddAttendee = (newAttendee) => {
    toggleNoAttendees(false);
    let bookingAttendesClone = bookingAttendees;
    bookingAttendesClone.push(newAttendee);
    setBookingAttendees(bookingAttendesClone);
    toggleAddAttendee(false);
  }

  const handleAddAttendeeClose = () => {

    toggleAddAttendee(false);
  }
  /**/

  /* DOWNLOAD ATTENDEES */
  const handleDownloadAttendeesClick = () => {

    //restrict download if loading attendeesA
    if (isLoading) return  false;

    //Show download notification
    toggleDownloadAttendees(true);

  }

  const handleDownloadAttendeesClose = () => {
    toggleDownloadAttendees(false);
  }
  /**/

  //Upload attendees
  const handleUploadAttendeesClick = () => {
    toggleUploadAttendees(true);
  }

  const handleUploadAttendeesClose = () => {
    toggleUploadAttendees(false);
  }

  const handleUploadAttendees = (attendees) => {

    if (attendees.length) toggleNoAttendees(false);

    //add uploaded attendees
    setBookingAttendees(bookingAttendees => [...bookingAttendees, ...attendees]);
  }
  
  /**/
  
  //material UI classes
  const classes = BookingAttendeesStyles ();

  //ATTENDEE ITEMS
  let attendeesArray = [];

  for (var key in bookingAttendees) {
    attendeesArray.push(bookingAttendees[key])
  };

  let attendeesArrayOrdered = orderBy(
    attendeesArray,
    [a => a.registrationData.lastName, a => a.registrationData.firstName],
    ['asc', 'asc']
  );

  let attendeesComponentArray = [];

  for (let i = 0; i < attendeesArrayOrdered.length; i++) {
    attendeesComponentArray.push(
      <BookingAttendee
        registration={attendeesArrayOrdered[i]}
        handleAttendeeDelete={handleAttendeeDelete}
        key={attendeesArrayOrdered[i].registrationID}
        index={i}
      />
    )
  } 

  //HEADERS FOR CSV DOWNLOAD

  let csvHeaders;

  if (selectedBooking.intakeFields[5].type === "international") {
    csvHeaders = [
      { label: "First Name", key: "firstName"},
      { label: "Last Name", key: "lastName"},
      { label: "Company Name", key: "company"},
      { label: "Email Address", key: "emailAddress"},
      { label: "Phone Number", key: "phoneNumber"},
      { label: "Address Line 1", key: "addressLine1"},
      { label: "Address Line 2", key: "addressLine2"},
      { label: "City", key: "city"},
      { label: "State/Province/Region", key: "stateProvinceRegion"},
      { label: "Zip/Postal Code", key: "zipPostalCode"},
      { label: "Country", key: "country"},
      { label: "Brand", key: "brand"},
      { label: "Login Link", key: "loginLink"},
      { label: "Registration ID", key: "registrationID"}
    ];
  } else {
    csvHeaders = [
      { label: "First Name", key: "firstName"},
      { label: "Last Name", key: "lastName"},
      { label: "Company Name", key: "company"},
      { label: "Email Address", key: "emailAddress"},
      { label: "Phone Number", key: "phoneNumber"},
      { label: "Address Line 1", key: "addressLine1"},
      { label: "Address Line 2", key: "addressLine2"},
      { label: "City", key: "city"},
      { label: "State", key: "state"},
      { label: "Zip Code", key: "zipCode"},
      { label: "Brand", key: "brand"},
      { label: "Login Link", key: "loginLink"},
      { label: "Registration ID", key:"registrationID"}
    ];
  }

  const csvData = [];

  if (selectedBooking.intakeFields[5].type === "international") {
    bookingAttendees.map(attendee => {

      let brand;

      if (attendee.brand) {
        brand = "true";
      } else {
        brand = "false";
      }

      csvData.push({
        firstName: attendee.registrationData.firstName,
        lastName: attendee.registrationData.lastName,
        company: attendee.registrationData.company,
        emailAddress: attendee.registrationData.emailAddress,
        phoneNumber: attendee.registrationData.phone,
        addressLine1: attendee.registrationData.address.addressLine1,
        addressLine2: attendee.registrationData.address.addressLine2,
        city: attendee.registrationData.address.city,
        stateProvinceRegion: attendee.registrationData.address.stateProvinceRegion,
        zipPostalCode: attendee.registrationData.address.zipPostalCode,
        country: attendee.registrationData.address.country,
        brand: brand,
        loginLink: `go.showboat.live/${attendee.loginCode}`,
        registrationID: attendee.registrationID
      })
    });
  } else {
    bookingAttendees.map(attendee => {

      let brand;

      if (attendee.brand) {
        brand = "true";
      } else {
        brand = "false";
      }

      csvData.push({
        firstName: attendee.registrationData.firstName,
        lastName: attendee.registrationData.lastName,
        company: attendee.registrationData.company,
        emailAddress: attendee.registrationData.emailAddress,
        phoneNumber: attendee.registrationData.phone,
        addressLine1: attendee.registrationData.address.addressLine1,
        addressLine2: attendee.registrationData.address.addressLine2,
        city: attendee.registrationData.address.city,
        state: attendee.registrationData.address.state,
        zipCode: attendee.registrationData.address.zipCode,
        brand: brand,
        loginLink: `go.showboat.live/${attendee.loginCode}`,
        registrationID: attendee.registrationID
      })
    });
  }
  
  

  return (
    <React.Fragment>

      <Button
        className={classes.newButton}
        onClick={handleAddAttendeeClick}
        variant="contained"
      >
        <AddIcon className={classes.addIcon}></AddIcon>
        NEW
      </Button>

      <div className={styles.iconHolder}>
        <div>
          <IconButton
            style={{
              padding: "4px"
            }}
            className={classes.uploadIcon}
            onClick={handleUploadAttendeesClick}
          >
            <UploadIcon 
              style={{
                fontSize: "20px"
              }}
            />
          </IconButton>
        </div>
        
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          className="downloadLink"
          filename={`${selectedBooking.name}-guest-list.csv`}
          onClick={handleDownloadAttendeesClick}
        >
          <IconButton
            style={{
              padding: "4px"
            }}
            className={isLoading ? `${classes.downloadIcon} ${classes.downloadIconDisabled}` : classes.downloadIcon}
            disabled={isLoading}
          >
            <DownloadIcon 
              style={{
                fontSize: "20px"
              }}
            />
          </IconButton>
          
        </CSVLink>
        
      </div>

      <Paper square elevation={3} className={classes.attendeesHeaderHolder}>
        <Typography variant="h1" className={classes.nameHeader}>
          Name
        </Typography>
        <Typography variant="h1" className={classes.companyHeader}>
          Company
        </Typography>
        <Typography variant="h1" className={classes.emailHeader}>
          Email
        </Typography>
        <Typography variant="h1" className={classes.brandHeader}>
          Brand
        </Typography>
        
      </Paper>

      <div 
        className={styles.attendeeHolder}
        style={{
          minHeight: (isLoading || (error !== "") || noAttendees) ? "190px" : "48px"
        }}
      >

        {error !== "" &&
          <Typography variant="body1" className={classes.error}>
            {error}
          </Typography>
        }

        {noAttendees &&
          <Typography variant="body1" className={classes.noAttendeesError}>
            No guests found for this booking
          </Typography>
        }

        {isLoading &&
          <CircularProgress className={classes.loadingSpinner} />
        }
        {
          [attendeesComponentArray].map(item => {
            return (item);
          })
        }
      </div>

      {/*  GUEST LIST DOWNLOAD SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        className={classes.downloadSnackbar}
        open={downloadAttendees}
        onClose={handleDownloadAttendeesClose}
        message="Guest List (.csv) has downloaded"
      />

      {/*  ATTENDEE LIST UPLOAD POPUP */}
      {uploadAttendees &&
        <UploadAttendeesPopup handleUploadAttendees={handleUploadAttendees} handlePopupClose={handleUploadAttendeesClose} open={uploadAttendees} />
      }

      {/* ADD ATTENDEE POPUP */}
      <AddAttendeePopup 
        handleAttendeeAdd={handleAddAttendee} 
        handlePopupClose={handleAddAttendeeClose} 
        open={addAttendee} 
        isPresenter={false}
      />
    </React.Fragment>
  )
}
