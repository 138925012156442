import { makeStyles, createStyles } from '@material-ui/core/styles';

const ManageAvatarColorsPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    height: 609,
    width: 906,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down('sm')]: {
      width: 750,
    }
  },
  popup: {
    height: 609,
    width: "100%",
    position: "absolute",
  },
  header: {
    fontWeight: 400,
    position: "relative",
    fontSize: "1.5rem",
    left: 30,
    top: 54, 
  },
  avatarColorDescription: {
    fontSize: "1.125rem",
    position: "relative",
    left: 30,
    top: 65, 
    width: "95%",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1rem"
    }
  },
  cancelButton: {
    position: "absolute",
    right: 141,
    bottom: 34,
    width: 95,
    height: 44
  },
  saveButton: {
    position: "absolute",
    right: 30,
    bottom: 34,
    width: 95,
    height: 44,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  loadingSpinner: {
    position: "absolute",
    right: 55,
    bottom: 85,
    color: "grey",
    [theme.breakpoints.down('sm')]: {
      bottom: 38,
      right: 255,
    }
  },
  error: {
    position: "relative",
    color: "red",
    left: 30,
    top: 59,
    [theme.breakpoints.down('sm')]: {
    }
  },
  cancelLoadingSpinner: {
    position: "absolute",
    color: "grey",
    bottom: 38,
    right: 255,
  }
})
)

export { ManageAvatarColorsPopupStyles };