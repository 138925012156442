import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { CircularProgress, Divider, IconButton, Switch, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from 'axios';

import EditBookingCapacityPopup from '../../BookingEdit/EditBookingCapacityPopup/EditBookingCapacityPopup';
import EditBookingGraceTimePopup from '../../BookingEdit/EditBookingGraceTimePopup/EditBookingGraceTimePopup';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import styles from './MiscellaneousDisplay.module.css';
import { MiscellaneousDisplayStyles } from './MiscellaneousDisplayStyles';
import moment from 'moment';
import CalendarAssignPopup from '../CalendarAssignPopup/CalendarAssignPopup';

interface Props {
  privateCloud: boolean,
  selectedBookingStartTime: any,
}

export default function MiscellaneousDisplay(props: Props): ReactElement {

  const {
    selectedBooking,
    setSelectedBooking,
    selectedBookingUsage,
    setSelectedBookingUsage,
    isSuperAdmin,
    spaceCalendars,
    toggleBookingHasCalendar
  }: AppContextType = useContext(AppContext);

  const [ editBookingCapacity, toggleEditBookingCapacity ] = useState(false);
  const [ editBookingGracePeriod, toggleEditBookingGracePeriod ] = useState(false);
  const [ usageLoadingSpinner, toggleUsageLoadingSpinner ] = useState(false);

  const [ disableEditCapacity, toggleDisableEditCapacity ] = useState(false);

  const [stateCounter, setStateCounter] = useState(0);

  const [calendarEntry, toggleCalendarEntry] = useState(
    (selectedBooking.calendarID !== undefined && selectedBooking.calendarID !== "")
      ? true
      : false
  );
  const [ calendarAssign, toggleCalendarAssign ] = useState(false);

  const [privateCloud, togglePrivateCloud] = useState(selectedBooking.privateCloud !== undefined
    ? selectedBooking.privateCloud
    : false
  );
  const [ privateCloudLoadingSpinner, togglePrivateCloudLoadingSpinner ] = useState(false);
  const [ calendarEntryLoadingSpinner, toggleCalendarEntryLoadingSpinner ] = useState(false);
  
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    //Get booking usage on mount
    getBookingUsage();

    return function cleanup() {
      source.cancel("Component was unmounted");

      setSelectedBookingUsage(null);
    }
  }, []);

  useEffect(() => {

    //Double check for calendar entry toggle
    if (selectedBooking.calendarID == "" || selectedBooking.calendarID === undefined) {
      toggleCalendarEntry(false);
    };

  }, [selectedBooking]);

  useEffect(() => {
  
    //Check if we need to disable capacity edit (privateCloud and <24hrs before event)
    if (props.privateCloud) {

      let timeDif = moment.utc(props.selectedBookingStartTime).diff(moment.utc(), "h");

      if (timeDif < 24) {
        toggleDisableEditCapacity(true);
      } else {
        toggleDisableEditCapacity(false);
      }

    } else {
      toggleDisableEditCapacity(false);
    }
    
  }, [props.privateCloud, props.selectedBookingStartTime]);
  
  const getBookingUsage = async () => {

    toggleUsageLoadingSpinner(true);

    try {
      let response = await axios.get("/booking/usage", {
        params: {
          bookingID: selectedBooking.bookingID
        },
        cancelToken: source.token,
      });

      toggleUsageLoadingSpinner(false);
      setSelectedBookingUsage(response.data.usageSeconds);
    }
    catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      toggleUsageLoadingSpinner(false);
    }

  }

  /* Refresh booking usage */
  const handleRefreshBookingUsageClick = () => {

    setSelectedBookingUsage(null);
    
    getBookingUsage();    
  }
  /**/

  /* Edit booking capacity popup */
  const handleEditBookingCapacityClick = () => {
    toggleEditBookingCapacity(true);
  }

  const handleEditBookingCapacityClose = () => {
    toggleEditBookingCapacity(false);
  }
  /**/

  /* Edit booking grace period popup */
  const handleEditBookingGracePeriodClick = () => {
    toggleEditBookingGracePeriod(true);
  }

  const handleEditBookingGracePeriodClose = () => {
    toggleEditBookingGracePeriod(false);
  }
  /**/

  //LS Private Cloud toggle handler
  const handlePrivateCloudToggle = async () => {

    //Ensure we are SuperAdmin
    if (isSuperAdmin) {
      try {
        //Show loader
        togglePrivateCloudLoadingSpinner(true);

        let selectedBookingClone = selectedBooking;

        //Assemble object to send to Axios
        selectedBookingClone.privateCloud = !privateCloud;

        let response = await axios.put("/booking", JSON.stringify(selectedBookingClone));

        togglePrivateCloudLoadingSpinner(false);

        //Set selected booking to what is returned in response
        setSelectedBooking(response.data.Attributes);

        togglePrivateCloud(!privateCloud);

      }
      catch (error) {
        togglePrivateCloudLoadingSpinner(false);
        console.log(error);
      }

    }
  }

  /* Calendar Logic*/
  const handleCalendarEntryToggle = async () => {

    //Show assign popup if toggling on calendar entry
    if (!calendarEntry) {
      toggleCalendarAssign(true);
    }
    //Edit booking to empty string for calendar otherwise
    else {

      toggleCalendarEntryLoadingSpinner(true);

      toggleBookingHasCalendar(false);

      let selectedBookingClone = selectedBooking;

      selectedBookingClone.calendarID = "";

      try {
        await axios.put("/booking", JSON.stringify(selectedBookingClone))

        setSelectedBooking(selectedBookingClone);
        toggleCalendarEntryLoadingSpinner(false);
        toggleCalendarEntry(false);
      }
      catch {
        toggleCalendarEntryLoadingSpinner(false);
        alert("An error occurred.")
      }

    }
  }

  const handleCalendarAssignClose = (calendarID: string) => {

    setStateCounter(stateCounter => stateCounter + 1);

    //Check if there is still no calendar ID assigned, if so, toggle calendar entry to false
    if (calendarID === "" || calendarID === undefined) {
      toggleBookingHasCalendar(false);
      toggleCalendarEntry(false);
    } else {
      //Otherwise, toggle calendar entry to true
      toggleBookingHasCalendar(true);
      toggleCalendarEntry(true);
    }

    //Close popup
    toggleCalendarAssign(false);
  }

  //Edit assigned calendar
  const handleEditCalendarNameClick = () => {
    toggleCalendarAssign(true);
  }

  /**/

  const classes= MiscellaneousDisplayStyles();  

  //Find matching calendar name if calendarID !== ""
  let bookingCalendarName;

  if (selectedBooking.calendarID !== undefined && selectedBooking.calendarID !== "") {
    for (let i = 0; i < spaceCalendars.length; i++) {
      if (spaceCalendars[i].calendarID === selectedBooking.calendarID) {
        bookingCalendarName = spaceCalendars[i].calendarName;
        break;
      }
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h2" classes={{ root: classes.miscellaneousHeader }}>
        Miscellaneous
      </Typography>

      <div className={styles.calendarHolder}>
        <Typography variant="body1" classes={{ root: classes.capacityHeader }}>
          Add to Calendar
        </Typography>

        {(calendarEntry && !calendarEntryLoadingSpinner) &&
          <Typography variant="body1" className={classes.calendarName}>

            {bookingCalendarName}

            <IconButton
              className={classes.editButton}
              onClick={handleEditCalendarNameClick}
            >
              <EditIcon
                className={classes.editIcon}
              />
            </IconButton>
          </Typography>
        }

        <Switch
          className={classes.addToCalendarSwitch}
          disabled={calendarEntryLoadingSpinner}
          checked={calendarEntry}
          onChange={handleCalendarEntryToggle}
        />

        {calendarEntryLoadingSpinner &&
          <CircularProgress className={classes.calendarEntryLoadingSpinner} />
        }

        <Divider className={`${classes.divider} ${classes.dividerCalendar}`}></Divider>
        
      </div>

      <div className={styles.capacityHolder}>
        <Typography variant="body1" classes={{ root: classes.capacityHeader}}>
          Event Capacity
        </Typography>

        <Typography variant="body1" classes={{ root: classes.capacity}}>
          {selectedBooking.capacity}
        </Typography>

        
        <IconButton 
          className={`${classes.editButton} ${classes.editButtonCapacity}`} 
          onClick={handleEditBookingCapacityClick}
          disabled={disableEditCapacity}
        >
          <EditIcon 
            className={classes.editIcon}
          />
        </IconButton>

        <Divider className={`${classes.divider} ${classes.dividerEventCapacity}`}></Divider>

      </div>

      <div className={styles.userHoursHolder}>

        {usageLoadingSpinner &&
          <CircularProgress className={classes.usageLoadingSpinner} />        
        }

        <Typography variant="h2" classes={{ root: classes.userHoursHeader}}>
          User Hours
        </Typography>
        <Typography variant="body1" classes={{ root: classes.userHours}}>
          {selectedBookingUsage !== null &&
            `${ (selectedBookingUsage / 3600).toFixed(2) } hours`
          }
        
          <IconButton  
            className={
              usageLoadingSpinner ? `${classes.refreshIcon} ${classes.refreshIconLoading}` : classes.refreshIcon
            } 
            onClick={handleRefreshBookingUsageClick}
          >
            <RefreshIcon className={classes.refreshButtonIcon} />
          </IconButton>

        </Typography>

        <Divider className={`${classes.divider} ${classes.dividerUserHours}`}></Divider>

      </div>
      
      {isSuperAdmin &&
        <div className={styles.privateCloudHolder}>
          <Typography variant="body1" classes={{ root: `${classes.capacityHeader} ${classes.capacityHeaderPrivateCloud}` }}>
            Private Cloud
          </Typography>

          {privateCloudLoadingSpinner &&
            <CircularProgress className={classes.privateCloudLoadingSpinner}></CircularProgress>
          }

          <Switch
            className={`${classes.addToCalendarSwitch} ${classes.privateCloudSwitch}`}
            disabled={privateCloudLoadingSpinner} 
            checked={privateCloud} 
            onChange={handlePrivateCloudToggle} 
          />

          <Divider className={`${classes.divider} ${classes.dividerPrivateCloud}`}></Divider>

        </div>
      }

      {editBookingCapacity &&
        <EditBookingCapacityPopup open={editBookingCapacity} handlePopupClose={handleEditBookingCapacityClose} />
      }

      {editBookingGracePeriod &&
        <EditBookingGraceTimePopup open={editBookingGracePeriod} handlePopupClose={handleEditBookingGracePeriodClose} />
      }

      {calendarAssign &&
        <CalendarAssignPopup
          open={true}
          handlePopupClose={handleCalendarAssignClose}
        />
      }
      
    </React.Fragment>
  )
}
