import { makeStyles, createStyles } from '@material-ui/core/styles';

const SendEmailAttendeeStyles = makeStyles((theme: any) => createStyles({
  attendeeName: {
    fontSize: ".875rem",
    position: "relative",
    left: 20,
    top: 10,
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 210
  },
  checkbox: {
    position: "relative",
    display: "inline-block",
    left: 18,
    top: 4,
  }
})
)

export { SendEmailAttendeeStyles };