import { CircularProgress, Typography } from '@material-ui/core';
import React, { ReactElement, useContext, useEffect } from 'react';
import axios from 'axios';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import RightIcon from '@material-ui/icons/ArrowRight';
import  {testSpace } from '../../utilities/TestSpace';
import { JWTHelper } from '../../utilities/JWTHelper';

import styles from './SpacePicker.module.css';
import { SpacePickerStyles } from './SpacePickerStyles';
import { AppContext, AppContextType } from '../../context/AppContext';
import { AuthContext, AuthContextType } from '../../context/AuthContext';


interface Props {
  
}

export default function SpacePicker(props: Props): ReactElement {

  const history = useHistory();
  
  const {
    setIdToken
  } : AuthContextType = useContext(AuthContext);
  
  const {
    producerProjects,
    setProducerProjects,
    setSelectedSpaceID,
    setSelectedSpace,
    setSelectedProject,
    setProducerSpaces,
    producerSpaces,
    setSelectedBooking,
    producerID
  } : AppContextType = useContext(AppContext);

  const [ showProjectsMenu, toggleShowProjectsMenu ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ selectedProjectLocal, setSelectedProjectLocal ] = useState(null);


  useEffect(() => {
    //apply min-height class to app
    document.getElementById("app").classList.add("appMain");

    //Refresh credentials on page refresh
    var poolData = {
      UserPoolId: 'us-east-1_N15Q0NLkm',
      ClientId: '2332rbhi35f5016dglri2mojo'
    };

    const userPool = new CognitoUserPool(poolData);

    let currentUser = userPool.getCurrentUser();

    if (currentUser) {
      currentUser.getSession(async (err, session) => {
        if (err) {
          alert("Your session has expired. Please sign in again.");
          window.location.replace("/");
        } else {

          setIdToken(session.getIdToken().getJwtToken());

          axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();

          //Set refresh token interval (10 minutes)
          setInterval(function () {
            var poolData = {
              UserPoolId: 'us-east-1_N15Q0NLkm',
              ClientId: '2332rbhi35f5016dglri2mojo'
            };

            const userPool = new CognitoUserPool(poolData);

            let currentUser = userPool.getCurrentUser();

            if (currentUser) {
              currentUser.getSession((err, session) => {
                if (err) {
                  alert("Your session has expired. Please sign in again.");
                  window.location.replace("/");
                } else {
                  setIdToken(session.getIdToken().getJwtToken());
                }
              })
            } else {
              if (err) {
                alert("Your session has expired. Please sign in again.");
                window.location.replace("/");
              }
            }

           
          }, 600000);

          //Check if we have projects. If we do not, get them for this producer
          if (producerProjects.length) {
            return;
          } else {

            if (localStorage.getItem("producerID")) {
              toggleLoadingSpinner(true);

              try {
                let response = await axios.get("/projects-and-spaces", {
                  params: {
                    producerID: localStorage.getItem("producerID")
                  }
                });

                toggleLoadingSpinner(false);

                setProducerProjects(response.data.projects);
              }
              catch (err) {

                alert("Error retrieving projects");

                history.push("/");
              }
            }
            
          }

        

        }
      });
    } else {
      alert("Please log in to see this page");
      history.push("/");
    };

    return function cleanup() {
      /* source.cancel("Component was unmounted") */
    }
  }, [])

  const handleShowProjectsMenu = () => {
    //If we are loading, do not show
    if (loadingSpinner) return; 

    toggleShowProjectsMenu(true);
  }
  const handleHideProjectsMenu = () => {
    //Ensure selected project is set to null
    setSelectedProjectLocal(null);
    
    toggleShowProjectsMenu(false);
  }

  const handleProjectHover = (project) => {
    setSelectedProjectLocal(project)
  }

  const handleSpaceSelect = async (spaceID) => {

    if (loadingSpinner) return;

    //Assemble and store JWT Token with spaceID
    JWTHelper.createJWT(spaceID, "");

    //Hide the menu
    toggleShowProjectsMenu(false);

    //Set selectedSpace using spaceID and producer spaces
    toggleLoadingSpinner(true);

    //Query for space 
    try {
      let response = await axios.get("/space", {
        params: {
          spaceID: spaceID,
          producerID: producerID
        }
      })

      //Check if 

      toggleLoadingSpinner(false);
      setSelectedSpace(response.data.space);
      setSelectedSpaceID(response.data.space.spaceID);
      setSelectedProjectLocal(null);
      setSelectedBooking(null);
      setSelectedProject(selectedProjectLocal);

      history.push(`/space/${response.data.space.spaceID}`)
    }
    catch (error) {
      toggleLoadingSpinner(false);
      alert("Error getting this space");
    }
    
  }

  const handlePickerHolderMouseLeave = (e) => {
    let toElement = (e.toElement || e.relatedTarget);

    //Safety net for somehow hovering over window
    if (toElement == window) {
      return;
    }

    //If we are moving to anything but the project menu, close the menu
    if (toElement) {
      let projectMenu = document.getElementById("projectMenu");
      if (projectMenu) {
        if (!projectMenu.contains(toElement)) toggleShowProjectsMenu(false);
      }
    }

  }
  
  const classes = SpacePickerStyles();
  
  return (

    <React.Fragment>
      <div 
        className={styles.spacePickerHolder}
        onMouseEnter={handleShowProjectsMenu}
        onMouseLeave={handlePickerHolderMouseLeave}
      >

        {loadingSpinner &&
          <CircularProgress
            className={classes.loadingSpinner}></CircularProgress>
        }

        <Typography variant="h2" className={classes.pickerLabel}>
          Select Project / Space
        </Typography>

        <ArrowDown className={classes.arrowDown} />
      </div>

      {showProjectsMenu &&
        <div 
          className={styles.projectsHolder}
          onMouseLeave={handleHideProjectsMenu}
          id="projectMenu"
        >
          {producerProjects.map(project => {
            let spaceArray=[];
            for (var spaceID in project.spaces) {
              spaceArray.push(project.spaces[spaceID])
            }
            return (
              <div
                key={project.projectID}
                className={styles.projectItem}
                onMouseEnter={() => handleProjectHover(project)}
              >
                <Typography variant="h3" className={classes.projectName}>
                  {project.projectName}
                </Typography>

                {((selectedProjectLocal !== null) && 
                (selectedProjectLocal.projectID === project.projectID)) &&
                  <div className={styles.spaceHolder}>
                    {spaceArray.map(space => {
                      return (
                        <div 
                          className={styles.projectItem}
                          key={space.spaceID}
                          onClick={() => handleSpaceSelect(space.spaceID)}
                        >

                          <Typography variant="h3" className={classes.projectName}>
                            {space.spaceName}
                          </Typography>
                        </div>
                      )
                    })}
                  </div>
                }

                <RightIcon className={classes.rightIcon}></RightIcon>
              </div>
            )
          })}
        </div>      
      }
    </React.Fragment>
    
  )
}
