import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';

import { ConfirmLinkRegeneratePopupStyles } from './ConfirmLinkRegeneratePopupStyles';
import styles from './ConfirmLinkRegeneratePopup.module.css';

interface Props {
  open: boolean,
  isPublicCode: boolean,
  handlePopupClose: () => any,
  handlePublicLinkRegenerated: () => any,
  handleSignUpLinkRegenerated: () => any,
}

export default function ConfirmLinkRegeneratePopup(props: Props): ReactElement {

  const { 
    selectedBooking, 
    selectedBookingID, 
    setSelectedBooking 
  } : AppContextType = useContext(AppContext);
  
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ error, toggleError ] = useState(false);
  
  //Regenerate login link click
  const handleRegenerateLoginLink = async (type : string) => {

    toggleLoadingSpinner(true);
    toggleError(false);

    let urlExtension = type === "signup" ? "/booking/signup/regenerate" : "/booking/public/regenerate";

    try {
      let response = await axios.put(urlExtension, {
        bookingID: selectedBookingID,
      });

      toggleLoadingSpinner(false);
      let selectedBookingClone = selectedBooking;

      if (type === "signup") {
        selectedBookingClone.signUpLoginCode = response.data;
      } else {
        selectedBookingClone.publicAppLoginCode = response.data;
      }

      setSelectedBooking(selectedBookingClone);

      type === "signup" ? props.handleSignUpLinkRegenerated() : props.handlePublicLinkRegenerated();

      props.handlePopupClose();
    }
    catch {
      toggleError(true);
      toggleLoadingSpinner(false);
    }

  }

  //Cancel click
  const handleCancelClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();
  }


  const classes = ConfirmLinkRegeneratePopupStyles();
  
  return (
    <Dialog
      open={props.open} 
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <div className={styles.popupHeader}>
          <Typography className={classes.linkRegenerateHeader}>
            Confirm Link Regeneration
          </Typography>
        </div>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred regenerating this link
          </Typography>
        }

        <Typography variant="body1" className={classes.linkRegenerateSubtext}>
          Regenerating this link will cause the current link to stop working.
        </Typography>
        
        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button 
          variant="contained" 
          color="primary" 
          className={classes.saveButton}
          disabled={loadingSpinner}
          onClick={() => handleRegenerateLoginLink(props.isPublicCode ? "public" : "signup")}
        >
          CONFIRM
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          className={classes.cancelButton}
          onClick={handleCancelClick}
        >
          CANCEL
        </Button>
      </div>
    </Dialog>
  )
}
