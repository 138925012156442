import { makeStyles, createStyles } from '@material-ui/core/styles';

const PreviewSingleColorPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    height: 300,
    width: 475,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    textAlign: "center",
  },
  popup: {
    height: 300,
    width: "100%",
    position: "absolute",
    textAlign: "center",
  },
  previewHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
    fontWeight: 400,
    textAlign: "left"
  },
  closeButton: {
    width: 93,
    height: 44,
    position: "absolute",
    bottom: 25,
    right: 25,
  },
  colorHeader: {
    position: "absolute",
    fontSize: 14,
    top: 35,
    left: 81,
  },
  colorLabel: {
    position: "absolute",
    fontSize: 14,
    top: 57,
    left: 81,
  }
})
)

export { PreviewSingleColorPopupStyles };