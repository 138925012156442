import { makeStyles, createStyles } from '@material-ui/core/styles';

const EditBookingGracePeriodPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 730,
    height: 368,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 368,
    position: "absolute",
  },
  graceTimeHeader: {
    fontSize: 24,
    fontWeight: 400,
    position: "absolute",
    left: 63,
    top: 40,
  },
  graceTimeText: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    left: 64,
    top: 82,
  },
  saveButton: {
    width: 95,
    height: 44,
    position: "absolute",
    right: 63,
    bottom: 47,
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  cancelButton: {
    width: 95,
    height: 44,
    right: 174,
    bottom: 47,
    position: "absolute",
  },
  graceTimeInput: {
    width: 256,
    position: "absolute",
    left: 65,
    top: 148,
  },
  error: {
    color: "red",
    position: "absolute",
    left: 65,
    top: 110
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    bottom: 100,
    right: 90,
  }
})
)

export { EditBookingGracePeriodPopupStyles };