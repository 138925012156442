import { makeStyles, createStyles } from '@material-ui/core/styles';

const ForgotPasswordStyles = makeStyles((theme: any) => createStyles({
  signInHeader: {
    fontSize: 20,
    color: "#707070",
    display: "inline-block",
    position: "relative",
    top: 20
  },
  forgotPasswordSubheader: {
    fontSize: 16,
    color: "#707070",
    display: "inline-block",
    position: "relative",
    top: 45
  },
  emailAddressInput: {
    width: "100%",
    position: "relative",
    top: 100,
  },
  verificationCodeInput: {
    width: "100%",
    position: "relative",
    top: 60,
  },
  emailAddressSubmitButton: {
    float: "right",
    top: 145,
    backgroundColor: "green"
  },
  emailAddressBackButton: {
    float: "left",
    top: 145,
  },
  loadingSpinner: {
    position: "absolute",
    color: "grey",
    right: 100,
    bottom: 149
  },
  newPasswordInput: {
    width: "100%",
    position: "relative",
    top: 100,
  },
  newPasswordConfirmInput: {
    width: "100%",
    position: "relative",
    top: 120,
  },
  visibleIcon: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  submitButtonVerify: {
    top: 155,
  },
  confirmPasswordloadingSpinner: {
    position: "relative",
    color: "grey",
    left: 50,
    bottom: -152
  },
  forgotPasswordError: {
    color: "red",
    position: "relative",
    fontSize: 14,
    top: 65,
  },
  confirmPasswordError: {
    color: "red",
    position: "relative",
    fontSize: 14,
    top: 45,
  },
  errorNewPassword: {
    color: "red",
    fontSize: 14,
    display: "block",
    position: "relative",
    top: 60,
  },
  verifyBackButton: {
    float: "left",
    top: 155,
  }
})
)

export { ForgotPasswordStyles };