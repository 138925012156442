import { makeStyles, createStyles } from '@material-ui/core/styles';

const ConfirmLinkRegeneratePopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper:{ 
    width: 361,
    height: 238,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 238,
    position: "absolute",
  },
  errorMessageHeader:{ 
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
    fontWeight: 400,
  },
  errorMessageSubHeader: {
    fontSize: 16,
    position: "relative",
    left: 63,
    top: 40,
  },
  errorMessageTextField: {
    width: 608,
    position: "absolute",
    left: 60,
    top: 135
  },
  errorMessageInput: {
    backgroundColor: "#F7F7F7"
  },
  saveButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 30,
    bottom: 30,
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "green"
    }
  },
  cancelButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 141,
    bottom: 30,
  },
  loadingSpinner:{ 
    color: "grey",
    position: "absolute",
    right: 57,
    bottom: 80
  },
  error: {
    color: "red",
    position: "absolute",
    left: 30,
    top: 129,
  },
  linkRegenerateSubtext: {
    position: "absolute",
    fontSize: 16,
    top: 83,
    left: 30,
    display: "inline-block",
    width: 270,
  },
  linkRegenerateHeader: {
    fontSize: 20,
    position: "absolute",
    color: "white",
    left: 30,
    top: 23,
  },
})
)

export { ConfirmLinkRegeneratePopupStyles };