const DefaultPresenterLink = `
<~~~WELCOME HEADER~~~>Now Presenting...<~~~END WELCOME HEADER~~~>

Hi {~~~FIRSTNAME~~~},

You have been designated as a presenter in the upcoming virtual event. Below is your link to join us, can’t wait to see you!

If you are curious about the Showboat presenter tools, <a href="https://showboat.live/presenter-tools" target="_blank">click here</a> to find out more.

<~~~ADD TO CALENDAR LINK~~~>

<~~~EVENT DETAILS~~~>
`

export default DefaultPresenterLink;