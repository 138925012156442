import { Button, CircularProgress, Dialog, Paper, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { orderBy } from 'natural-orderby';

import { AppContext, AppContextType } from '../../../../context/AppContext';
import NameWarningPopup from '../NameWarningPopup/NameWarningPopup';

import styles from './CalendarAssignPopup.module.css';
import { CalendarAssignPopupStyles } from './CalendarAssignPopupStyles';
import PopupHeader from '../../../PopupHeader/PopupHeader';


interface Props {
  open: boolean,
  handlePopupClose: (calendarID : string) => any,
}

export default function CalendarAssignPopup(props: Props): ReactElement {

  const { 
    selectedSpace, 
    selectedSpaceID, 
    selectedBooking,
    setSelectedBooking 
  } : AppContextType = useContext(AppContext);

  const [ selectedCalendarID, setSelectedCalendarID ] = useState(selectedBooking.calendarID !== undefined ? selectedBooking.calendarID : "");
  
  const [ spaceCalendars, setSpaceCalendars ] = useState([]);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ nameWarning, toggleNameWarning ] = useState(false);
  const [ calendarName, setCalendarName ] = useState("");

  const [ saveLoadingSpinner, toggleSaveLoadingSpinner ] = useState(false);

  const [ error, toggleError ] = useState(false);
  
  //Get space calendars on mount
  useEffect(() => {

    toggleLoadingSpinner(true);
    
    const getSpaceCalendars = async () => {
      try {
        let response = await axios.get("/calendars", {
          params: {
            spaceID: selectedSpaceID
          }
        });

        toggleLoadingSpinner(false);

        let spaceCalendarsResponse = response.data;
  
        let spaceCalendarsOrdered = orderBy(
          spaceCalendarsResponse,
          [c => (c as any).calendarName],
          ["asc"]
        );

        setSpaceCalendars(spaceCalendarsOrdered);

      }
      catch {
        toggleLoadingSpinner(false);
      }
    }
    getSpaceCalendars();
    
  }, []);

  const handleCalendarSelect = (calendarID : string) => {
    setSelectedCalendarID(calendarID);
  }
  
  const handleCancelClick = () => {

    if (saveLoadingSpinner) {
      return;
    }

    props.handlePopupClose(selectedCalendarID);
  }

  const handleSaveClick = async () => {
    toggleError(false);

    let calendarName;

    //Get calendar name
    for (let i = 0; i < spaceCalendars.length; i++) {
      if (spaceCalendars[i].calendarID === selectedCalendarID) {
        calendarName = spaceCalendars[i].calendarName;
        break;
      }
    };
    
    //Check if booking name is same as calendar name
    if (selectedBooking.name !== calendarName) {
      
      //Update state with calendar name
      setCalendarName(calendarName);
      
      //Show name warning popup
      toggleNameWarning(true);
    } else {
      toggleSaveLoadingSpinner(true);
      
      let selectedBookingClone = selectedBooking;

      selectedBookingClone.calendarID = selectedCalendarID;

      try {
        await axios.put("/booking", JSON.stringify(selectedBookingClone));

        toggleSaveLoadingSpinner(false);
        setSelectedBooking(selectedBookingClone);
        props.handlePopupClose(selectedCalendarID);
      }
      catch {
        toggleSaveLoadingSpinner(false);
        toggleError(true);
      }
      
    }
    
  }

  //Name warning
  const handleNameWarningPopupClose = () => {
    toggleNameWarning(false);
    props.handlePopupClose(selectedCalendarID);
  }
  
  const classes = CalendarAssignPopupStyles();
  
  return (

    <React.Fragment>
      <Dialog
        open={props.open}
        classes={{ paper: classes.dialogPaper }}
      >
        <div className={classes.popup}>

          <PopupHeader
            closeFunction={props.handlePopupClose}
            disableBoolean={loadingSpinner}
          />

          <Typography variant="h1" className={classes.chooseCalendarHeader}>
            Choose Calendar
          </Typography>

          {error && 
            <Typography variant="body1" className={classes.error}>
              An error occurred assigning a calendar to your booking.
            </Typography>
          }

          <Paper className={classes.calendarHolder}>
            {spaceCalendars.map(calendar => {
              if (calendar.calendarID === selectedCalendarID) {
                return (
                  <div key={calendar.calendarID} className={`${styles.calendarItemHolder} ${styles.calendarItemHolderSelected}`}>
                    <Typography variant="body1" className={classes.calendarName}>
                      {calendar.calendarName}
                    </Typography>

                    <CheckIcon className={classes.checkIcon}></CheckIcon>
                  </div>
                )
              } else {
                return (
                  <div key={calendar.calendarID} onClick={() => handleCalendarSelect(calendar.calendarID)} className={`${styles.calendarItemHolder}`}>
                    <Typography variant="body1" className={classes.calendarName}>
                      {calendar.calendarName}
                    </Typography>
                  </div>
                )
              }
            })}

            {loadingSpinner &&
              <CircularProgress className={classes.loadingSpinner} />
            }

          </Paper>

          {saveLoadingSpinner &&
            <CircularProgress className={classes.saveLoadingSpinner} />
          }
          <Button onClick={handleCancelClick} className={classes.cancelButton} variant="contained" color="primary">
            CANCEL
          </Button>
          <Button disabled={saveLoadingSpinner || selectedCalendarID == ""} onClick={handleSaveClick} className={classes.saveButton} variant="contained" color="primary">
            SAVE
          </Button>
          
        </div>
      </Dialog>

      {nameWarning &&
        <NameWarningPopup 
          open={true} 
          calendarID={selectedCalendarID} 
          calendarName={calendarName} 
          handlePopupClose={handleNameWarningPopupClose} 
        />
      }
    </React.Fragment>

  )
}
