import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const ProjectsAdminEditStyles = makeStyles((theme: any) => createStyles({
  projectAdminWrapper: {
    position: "absolute",
    display: "inline-block",
    width: 714,
    height: 875,
    top: 30,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  editProjectHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
})
)

export { ProjectsAdminEditStyles };