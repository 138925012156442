import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeFormStyles = makeStyles((theme: any) => createStyles({
  attendeeEditInput: {
    width: 460,
    height: 74,
    position: "relative",
    marginBottom: 15,
    top: 20,
    [theme.breakpoints.down('sm')]: {
      width: 300
    }
  },
  attendeeEditInputNotRequired: {
    width: 460,
    height: 74,
    marginBottom: 15,
    position: "relative",
    top: 20,
    [theme.breakpoints.down('sm')]: {
      width: 300,
    }
  },
  cancelButton: {
    width: 95,
    height: 44,
    position: "absolute",
    bottom: -129,
    right: 115,
    [theme.breakpoints.down('sm')]: {
      bottom: -143
    }
  },
  cancelButtonInternational: {
    bottom: -250,
    [theme.breakpoints.down('sm')]: {
      bottom: -145
    }
  },
  saveButton: {
    width: 95,
    height: 44,
    position: "absolute",
    bottom: -129,
    right: -10,
    backgroundColor: "#009617",
    [theme.breakpoints.down('sm')]: {
      bottom: -143
    },
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  saveButtonInternational: {
    bottom: -250,
    [theme.breakpoints.down('sm')]: {
      bottom: -145,
    }
  },
  saveButtonAddAttendee: {
    bottom: -43,
  },
  saveButtonAddInternational: {
    bottom: -168
  },
  cancelButtonAddAttendee: {
    bottom: -43
  },
  cancelButtonAddInternational: {
    bottom: -168,
  }
})
)

export { AttendeeFormStyles };