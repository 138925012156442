import React, { ReactElement, useState, useContext } from 'react';
import axios from 'axios';
import { EditBookingNameStyles } from './EditBookingNamePopupStyles';
import { Typography, Button, TextField, Dialog, CircularProgress } from '@material-ui/core';
import { AppContext, AppContextType } from '../../../../context/AppContext';
import PopupHeader from '../../../PopupHeader/PopupHeader';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
  bookingName: string,
}

export default function EditBookingNamePopup(props: Props): ReactElement {
  
  const { 
    selectedSpaceID, 
    selectedBooking, 
    setSelectedBooking, 
    selectedBookingID
  } : AppContextType = useContext(AppContext);

  const [ bookingName, setBookingName ] = useState(selectedBooking.name);

  const [ isLoading, toggleIsLoading ] = useState(false);

  const [ bookingNameError, toggleBookingNameError ] = useState(false);
  const [ error, toggleError ] = useState(false);
  
  const handleBookingNameChange = (e) => {
    toggleBookingNameError(false);
    
    setBookingName(e.target.value);
  }

  const handleSaveButtonClick = async () => {

    toggleBookingNameError(false);

    if (!bookingName.trim().length) {
      toggleBookingNameError(true);
      return;
    }

    toggleIsLoading(true);

    let selectedBookingClone = selectedBooking;

    selectedBookingClone.name = bookingName;

    try {
      await axios.put('/booking', JSON.stringify(selectedBookingClone))
      
      setSelectedBooking(selectedBookingClone);
      toggleIsLoading(false);
      props.handlePopupClose();
    }
    catch (error) {
      toggleIsLoading(false);
      toggleError(true);
    }
 
  }

  const handleCancelButtonClick = () => {

    if (isLoading) {
      return;
    }

    props.handlePopupClose();
  }

  //material ui classes
  const classes = EditBookingNameStyles();

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    > 
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={isLoading}
        />

        <Typography className={classes.editNameHeader}>
          Edit Name
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred updating the name of this booking
          </Typography>
        }

        <TextField
          className={classes.eventNameTextField}
          label=""
          helperText="Required"
          variant="outlined"
          value={bookingName}
          onChange={handleBookingNameChange}
          defaultValue={selectedBooking.name}
          InputProps={{
            className: classes.eventNameInput
          }}
          error={bookingNameError}
        />

        {isLoading &&
          <CircularProgress className={classes.loadingSpinner } />
        }
        
        <Button onClick={handleCancelButtonClick} className={classes.cancelButton} variant="contained" color="primary">
          CANCEL
        </Button>
        <Button disabled={isLoading} onClick={handleSaveButtonClick} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}

