import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const CacheAdminStyles = makeStyles((theme: any) => createStyles({
  adminMenuButton: {
    position: "absolute",
    top: 15,
    left: 15,
  },
  cacheAdminHolder: {
    position: "absolute",
    display: "inline-block",
    width: 714,
    height: 975,
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  cacheHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
  cacheDivider: {
    width: "90%",
    position: "relative",
    top: 25,
    display: "inline-block"
  },
  bookingIDHeader: {
    position: "relative",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
  },
  bookingIDTextField: {
    position: "absolute",
    left: 35,
    top: 130,
    width: 300,
  },
  submitButton: {
    position: "absolute",
    left: 35,
    top: 200
  },
  deleteButton: {
    position: "absolute",
    left: 35,
    top: 245
  },
  cacheLoadingSpinner: {
    color: "grey",
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    top: 207,
    left: 131,
  },
  cacheProperty: {
    fontSize: 17,
    fontWeight: "bold"
  },
  cacheProperty2: {
    fontSize: 16,

  },
  cacheProperty3: {
    fontSize: 14
  },
  cacheValue: {
    fontSize: 12,
  },
  success: {
    fontSize: 18,
    fontWeight: 400,
    display: "inline-block",
    position: "absolute",
    top: 150,
    left: 0,
    right: 0,
    center: 0,
    margin: "auto"
  },
  deleteCacheLoadingSpinner: {
    color: "grey",
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    top: 253,
    left: 183,
  }
})
)

export { CacheAdminStyles };