import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useContext, useState } from 'react';
import { AppContext, AppContextType } from '../../../../context/AppContext';
import PopupHeader from '../../../PopupHeader/PopupHeader';

import styles from './NameWarningPopup.module.css';
import { NameWarningPopupStyles } from './NameWarningPopupStyles';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
  calendarName: string,
  calendarID: string
}

export default function NameWarningPopup(props: Props): ReactElement {

  const { 
    selectedBooking, 
    setSelectedBooking 
  } : AppContextType = useContext(AppContext);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ error, toggleError ] = useState(false);
  
  //Execute PUT with changed name
  const handleYesClick = async () => {

    if (loadingSpinner) {
      return;
    }

    toggleLoadingSpinner(true);

    let selectedBookingClone = {...selectedBooking};

    selectedBookingClone.name = props.calendarName;
    selectedBookingClone.calendarID = props.calendarID;

    try {
      await axios.put("/booking", JSON.stringify(selectedBookingClone));
      toggleLoadingSpinner(false);
      setSelectedBooking(selectedBookingClone);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
    
  };

  //Execute PUT without changed name
  const handleNoClick = async () => {

    if (loadingSpinner) {
      return;
    }

    toggleLoadingSpinner(true);

    let selectedBookingClone = {...selectedBooking};

    selectedBookingClone.calendarID = props.calendarID;

    try {
      await axios.put("/booking", JSON.stringify(selectedBookingClone));
      toggleLoadingSpinner(false);
      setSelectedBooking(selectedBookingClone);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
    
  }
  
  const classes = NameWarningPopupStyles();  
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="body1" className={classes.warningText}>
          Currently the name of your booking does not match the calendar name. Would you like to update your booking name?
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred. Please try again.
          </Typography>
        }

        <Button className={classes.noButton} onClick={handleNoClick} variant="text" color="primary">
          NO
        </Button>
        <Button disabled={loadingSpinner} className={classes.yesButton} onClick={handleYesClick} variant="contained" color="primary">
          YES
        </Button>

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }
      </div>
    </Dialog>
  )
}
