import { makeStyles, createStyles } from '@material-ui/core/styles';

const MiscellaneousDisplayStyles = makeStyles((theme: any) => createStyles({
  miscellaneousHeader: {
    left: 30,
    position: "absolute",
    top: 0,
    fontWeight: 400,
    fontSize: 16,
  },
  capacityHeader: {
    position: "absolute",
    fontSize: 14,
    left: 30,
    fontWeight: "bold",
  },
  capacityHeaderPrivateCloud: {
    top: 5,
  },
  graceTimeHeader: {
    fontSize: 16,
    left: 30,
    fontWeight: 400,
    position: "absolute",
  },
  userHoursHeader: {
    fontSize: 14,
    left: 30,
    fontWeight: "bold",
    position: "absolute",
    bottom: 4,
  },
  capacity: {
    fontSize: 14,
    position: "absolute",
    left: 191,
  },
  graceTime: {
    fontSize: 14,
    position: "absolute",
    left: 191,
  },
  userHours: {
    fontSize: 14,
    position: "relative",
    left: 191,
    bottom: 9,
    display: "inline-block"
  },
  editButton: {
    position: "relative",
    left: 28,
    top: -2,
    padding: 4,
    color: "black",
    "&:hover": {
      cursor: "pointer" 
    }
  },
  editIcon: {
    fontSize: 20
  },
  editButtonGraceTime: {
    position: "absolute",
    left: 290,
    top: -9,
    padding: 4,
    color: "black"
  },
  editButtonCapacity: {
    left: "92.4%",
    top: -4,
    padding: 4,
    position: "absolute"
  },
  editIconGraceTime: {
    fontSize: 20,
  },
  usageLoadingSpinner: {
    color: "grey",
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    bottom: 2,
    left: 190,
  },
  refreshIcon: {
    color: "grey",
    fontSize: 22,
    padding: 4,
    position: "relative",
    "&:hover": {
      cursor: "pointer"
    },
    top: -1,
    left: 15,
  },
  refreshButtonIcon: {
    fontSize: 20,
    padding: 4,
  },
  refreshIconLoading: {
    left: 30
  },
  addToCalendarSwitch: {
    right: 17,
    position: "absolute",
    bottom: -8,
  },
  divider: {
    position: "absolute",
    left: 31,
    width: "92%",
  },
  dividerCalendar: {
    top: 33,
  },
  dividerEventCapacity: {
    top: 33,
  },
  dividerUserHours: {
    top: 31,
  },
  dividerPrivateCloud: {
    top: 39,
  },
  privateCloudLoadingSpinner: {
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    right: 87,
    top: 6,
  },
  privateCloudSwitch: {
    color: "#FF0092",
    bottom: -15,
  },
  calendarEntryLoadingSpinner: {
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    right: 90,
    top: 0,
  },
  calendarName: {
    fontSize: 14,
    position: "relative",
    left: 191,
    bottom: 4,
  }
})
)

export { MiscellaneousDisplayStyles };