import React, { ReactElement, useContext, useState } from 'react';
import { Typography, Button, IconButton, Snackbar } from '@material-ui/core';
import OpenIcon from '@material-ui/icons/OpenInNewOutlined';
import LinkIcon from '@material-ui/icons/LinkOutlined';

import AttendeeEditPopup from '../AttendeeEditPopup/AttendeeEditPopup';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import styles from './BookingAttendee.module.css';
import { BookingAttendeeStyles } from './BookingAttendeeStyles';

interface Props {
  registration: SHOWBOAT.Registration,
  handleAttendeeDelete: (attendee: any) => any,
  index: number,
}

export default function BookingAttendee(props: Props): ReactElement {

  const [ showEditPopup, toggleShowEditPopup ] = useState(false);
  const [ initialEmailAddress, setInitialEmailAddress ] = useState(props.registration.registrationData.emailAddress);
  const [ copyToClipboard, toggleCopyToClipboard ] = useState(false);
  
  const {
    selectedRegistration,
    setSelectedRegistration,
  } : AppContextType = useContext(AppContext);
  
  const handleAttendeeEditClick = () => {

    setSelectedRegistration(props.registration);

    toggleShowEditPopup(true);
  }

  const handlePopupClose = (cancelled: boolean) => {

    if (cancelled) {

      //Reset email address
      let selectedRegistrationClone : SHOWBOAT.Registration = selectedRegistration;

      selectedRegistrationClone.registrationData.emailAddress = initialEmailAddress;
  
      setSelectedRegistration(selectedRegistrationClone);
    }

    setSelectedRegistration(null);
    toggleShowEditPopup(false);
  }

  const handleAttendeeLinkCopy = () => {
    let dummy = document.createElement('textarea');

    document.body.appendChild(dummy);

    dummy.value = `https://go.showboat.live/${props.registration.loginCode}`;
    dummy.select();

    document.execCommand('copy');
    document.body.removeChild(dummy);

    toggleCopyToClipboard(true);
  }

  const handleCopyToClipboardClose = () => {
    toggleCopyToClipboard(false);
  }

  //Material ui classes
  const classes = BookingAttendeeStyles();
  
  return (
    <React.Fragment>
      <div 
        className={styles.attendeeHolder}
        style={{
          backgroundColor: (props.index + 1) % 2 === 0
            ? "#FBFBFB"
            : "white"
        }}
      >
       
        <Typography variant="body1" className={classes.attendeeName}>
          {props.registration.registrationData.lastName.slice().length 
            ? `${props.registration.registrationData.lastName}, ${props.registration.registrationData.firstName}`
            : `${props.registration.registrationData.firstName}`
          }
        </Typography>

        <Typography variant="body1" className={classes.attendeeCompany}>
          {props.registration.registrationData.company}
        </Typography>

        <Typography variant="body1" className={classes.attendeeEmail}>
          {props.registration.registrationData.emailAddress}
        </Typography>

        {props.registration.brand &&
          <div className={styles.brandDisplay}></div>
        }

        <div className={styles.iconHolder}>

          <div>
            <IconButton
              className={classes.linkIconButton}
              onClick={handleAttendeeLinkCopy}
            >
              <LinkIcon 
                style={{
                  fontSize: "20px"
                }}
              />
            </IconButton>
          </div>
          
          <div>
            <IconButton
              className={classes.editIconButton}
              onClick={handleAttendeeEditClick}
            >
              <OpenIcon 
                style={{
                  fontSize: "20px"
                }}
              />
            </IconButton>
          </div>

        </div>
      </div>

      {selectedRegistration !== null &&
        <AttendeeEditPopup 
          handlePopupClose={handlePopupClose} 
          open={showEditPopup} 
          presenter={false}
        />
      }

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={classes.copySnackbar}
        autoHideDuration={1500}
        open={copyToClipboard}
        onClose={handleCopyToClipboardClose}
        message="Attendee Link Copied to Clipboard"
      />


    </React.Fragment>
    
  )
}
