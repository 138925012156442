import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import axios from 'axios';
import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { AuthContext, AuthContextType } from '../../context/AuthContext';
import ProjectsAdminCreate from './ProjectsAdminCreate/ProjectsAdminCreate';
import ProjectsAdminEdit from './ProjectsAdminEdit/ProjectsAdminEdit';
import { ProjectsAdminStyles } from './ProjectsAdminStyles';

interface Props {
  
}

export default function ProjectsAdmin(props: Props): ReactElement {

  const history = useHistory();
  
  const { idToken, setIdToken } : AuthContextType = useContext(AuthContext);
  
  const [ authenticated, toggleAuthenticated ] = useState(false);
  
  const [ currentView, setCurrentView ] = useState("create");

  useEffect(() => {
//Initial authentication check
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {

      //Redirect to sign-in if no producerID in sessionStorage   
      alert("You do not have privileges to access this page.");
      window.location.replace("/");

    } else {

      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: 'us-east-1_N15Q0NLkm',
        ClientId: '2332rbhi35f5016dglri2mojo'
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {

            axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage
            axios.get("/producer/role", {
              params: {
                producerID: localStorage.getItem("producerID")
              }
            })
            .then(function(response) {

              //Get the role on the producer object
              if (response.data.role === 0) {

                toggleAuthenticated(true);

              } else {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              };

            })
            .catch(function(error) {
              alert("You do not have privileges to access this page.");
              window.location.replace("/");
            })
          }
        })
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);
  
  const classes = ProjectsAdminStyles();

  if (authenticated) {
    return (
      <React.Fragment>
  
        <Button 
          className={classes.adminMenuButton} 
          variant="contained" 
          color="primary"
          onClick={() => {history.push("/admin-menu")}}
          >
          ADMIN MENU
        </Button>
        
       {/*  <Button onClick={() => setCurrentView("create")} variant="contained" color="primary" className={classes.createButton}>
          CREATE
        </Button>
        <Button onClick={() => setCurrentView("edit")} variant="contained" color="primary" className={classes.editButton}>
          EDIT
        </Button> */}
  
        
        {currentView === "create"
        ? (
          <ProjectsAdminCreate />
        )
        : (
          <ProjectsAdminEdit />
        )}
      </React.Fragment>
    )
  } else {
    return <div></div>
  }
  
}
