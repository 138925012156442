import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const SystemCheckAdminStyles = makeStyles((theme: any) => createStyles({
  adminMenuButton: {
    position: "absolute",
    top: 15,
    left: 15,
  },
  systemCheckAdminWrapper: {
    position: "absolute",
    display: "inline-block",
    width: 1050,
    height: 700,
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  systemCheckAdminHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
  systemCheckAdminDivider: {
    width: "90%",
    position: "relative",
    top: 25,
    display: "inline-block"
  },
  calendarHolder: {
    width: "100%",
    position: "absolute",
    bottom: -70,
    height: 430
  },
  calendar: {
    width: 450,
  },
  leftArrow: {
    left: 10,
  },
  rightArrow: {
    right: 5
  },
  systemCheckHeaderHolder: {
    position: "absolute",
    width: "100%",
    height: 50,
    backgroundColor: "#E5E5E5"
  },
  systemCheckLoadingSpinner: {
    color: "grey",
    width: "40px !important",
    height: "40px !important",
    position: "relative",
    display: "inline-block",
    top: 100,
  },
  nameHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    bottom: 5,
    left: 20,
  },
  dateHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    bottom: 5,
    left: 185,
  },
  systemIDHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    bottom: 5,
    left: 325,
  },
  error: {
    color: "red",
    fontSize: 14,
    position: "absolute",
    top: 15,
    left: 0,
  },
  csvIconButton: {
    position: "absolute",
    top: 10,
    padding: 3,
    right: 10,
  }
})
)

export { SystemCheckAdminStyles };