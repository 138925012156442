import { Button } from '@material-ui/core';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import { AuthContext, AuthContextType } from '../../context/AuthContext';

import styles from './AdminMenu.module.css';
import { AdminMenuStyles } from './AdminMenuStyles';

interface Props {
  
}

export default function AdminMenu(props: Props): ReactElement {

  const history = useHistory();
  
  const { idToken, setIdToken } : AuthContextType = useContext(AuthContext);

  const [ isAuthenticated, toggleIsAuthenticated ] = useState(false);
  
  useEffect(() => {
    //Initial authentication check
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {

      //Redirect to sign-in if no producerID in sessionStorage   
      alert("You do not have privileges to access this page.");
      history.push("/");

    } else {

      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: 'us-east-1_N15Q0NLkm',
        ClientId: '2332rbhi35f5016dglri2mojo'
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            history.push("/");
          } else {

            axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            axios.get("/producer/role", {
              params: {
                producerID: localStorage.getItem("producerID")
              }
            })
            .then(function(response) {

              //Get the role on the producer object
              if (response.data.role === 0) {

                toggleIsAuthenticated(true);

              } else {
                alert("You do not have privileges to access this page.");
                history.push("/");
              }
            })
            .catch(function(error) {
              alert("You do not have privileges to access this page.");
              history.push("/");
            })
          }
        })
      }
    }
  }, []);
  
  const classes = AdminMenuStyles();
  
  if (isAuthenticated) {
    return (
      <div className={styles.adminMenuWrapper}>
        
        <Button 
          className={classes.spacePickerButton} 
          variant="contained" 
          color="primary"
          onClick={() => { history.push("/home")}}
        >
          HOME
        </Button>
        <div className={styles.adminMenuHolder}>
  
          <Button 
            className={classes.menuButton} 
            variant="contained" 
            color="primary"
            onClick={() => { history.push("/space-admin")}}
          >
            SPACE
          </Button>
  
          <Button 
            className={classes.menuButton} 
            variant="contained" 
            color="primary"
            onClick={() => { history.push("/producer-admin")}}
          >
            PRODUCER
          </Button>
  
          <Button 
            className={classes.menuButton} 
            variant="contained" 
            color="primary"
            onClick={() => { history.push("/projects-admin")}}
          >
            PROJECT
          </Button>
          
          <Button 
            className={classes.menuButton} 
            variant="contained" 
            color="primary"
            onClick={() => { history.push("/bookings-admin")}}
          >
            BOOKINGS
          </Button>

          <Button
            className={classes.menuButton}
            variant="contained"
            color="primary"
            onClick={() => { history.push("/cache-admin") }}
          >
            CACHE
          </Button>

          <Button 
            className={`${classes.menuButton} ${classes.menuButtonUsage}`} 
            variant="contained" 
            color="primary"
            onClick={() => { history.push("/usage-admin")}}
          >
            USAGE REPAIR
          </Button>

          <Button 
            className={`${classes.menuButton} ${classes.menuButtonUsage}`} 
            variant="contained" 
            color="primary"
            onClick={() => { history.push("/system-check-admin")}}
          >
            SYSTEM CHECK
          </Button>
        </div>
        
      </div>
    )
  } else {
    return <div></div>
  }
}
