import React, { ReactElement, useContext, useState } from 'react';
import { Button, CircularProgress, Dialog, TextField, Typography } from '@material-ui/core';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../context/AppContext';

import styles from './AddCalendarPopup.module.css';
import { AddCalendarPopupStyles } from './AddCalendarPopupStyles';
import PopupHeader from '../../PopupHeader/PopupHeader';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
  handleCancelClick: () => any,
}

export default function AddCalendarPopup(props: Props): ReactElement {

  const { 
    selectedSpaceID, 
    selectedCalendar, 
    setSelectedCalendar 
  } : AppContextType = useContext(AppContext);

  const [ calendarName, setCalendarName ] = useState("");
  const [ error, toggleError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ formHasBeenSubmitted, toggleFormHasBeenSubmitted] = useState(false);
  const [ nameRequiredError, toggleNameRequiredError ] = useState(false);
  
  const handleCalendarNameChange = (e) => {
    setCalendarName(e.target.value);
  }
  
  const handleSaveButtonClick = async () => {

    toggleLoadingSpinner(true);
    toggleError(false);

    try {
      let response = await axios.post("/calendar", {
        calendarName: calendarName,
        spaceID: selectedSpaceID
      });

      toggleLoadingSpinner(false);
      setSelectedCalendar(response.data);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }

  }

  const handleCancelButtonClick = () => {
    props.handleCancelClick();
  }
  
  const classes = AddCalendarPopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper}}  
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.createCalendarHeader}>
          Name Calendar
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred creating this calendar
          </Typography>
        }

        <TextField
          variant="outlined"
          value={calendarName}
          onChange={handleCalendarNameChange}
          helperText="Required"
          className={classes.calendarNameInput}
          placeholder="Calendar Name"
          error={formHasBeenSubmitted && nameRequiredError}
        />

        {loadingSpinner && 
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button onClick={handleCancelButtonClick} variant="text" className={classes.cancelButton}>
          CANCEL
        </Button>
        <Button disabled={loadingSpinner} onClick={handleSaveButtonClick} variant="contained" color="primary" className={classes.saveButton}>
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
