import React, { ReactElement, useContext, useEffect, useState } from 'react'
import axios from 'axios';

export interface AuthContextType {
  idToken : string,
  setIdToken : (idToken: string) => void,
  refreshToken : string,
  setRefreshToken : (refreshToken : string) => void
}

export const AuthContext = React.createContext(null);

export default function AuthContextProvider(props): ReactElement {
  const [ idToken, setIdToken ] = useState("");
  const [ refreshToken, setRefreshToken ] = useState("");

  useEffect(() => {

    //SET GLOBAL AUTHORIZATION HEADER
    axios.defaults.headers.common["Authorization"] = idToken;
    
  }, [idToken])
  
  const AuthContextValue : AuthContextType = {
    idToken,
    setIdToken,
    refreshToken,
    setRefreshToken
  }

  return (
    <AuthContext.Provider
      value={AuthContextValue}
    >
      {props.children}
    </AuthContext.Provider>
  )

}