import { makeStyles, createStyles } from '@material-ui/core/styles';

const EditSupportMessagePopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 730,
    height: 502,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 502,
    position: "absolute",
  },
  editErrorMessageHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 55,
    display: "inline-block"
  },
  supportMessageTextField: {
    width: 670,
    position: "absolute",
    left: 30,
    top: 109,
  },
  errorMessageInput: {
    backgroundColor: "white"
  },
  error: {
    color: "red",
    position: "absolute",
    left: 30,
    top: 85,
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 65,
    bottom: 85,
    width: "25px !important",
    height: "25px !important"
  },
  saveButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 30,
    bottom: 34,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  cancelButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 141,
    bottom: 34,
  },
  enableSupportToggle: {
    position: "absolute",
    top: 55,
    right: 75,
  },
  enableSupportSwitch: {
    position: "relative",
    left: 180
  },
  enableSupportLabel: {
    display: "inline-block"
  }
})
)

export { EditSupportMessagePopupStyles };