import { makeStyles, createStyles } from '@material-ui/core/styles';

const ManageTemplatePopupStyles = makeStyles((theme: any) => createStyles({
  popup: {
    width: 1200,
    height: 865,
    backgroundColor: "white",
    border: "none",
    outline: "none",
    position: "absolute",
    [theme.breakpoints.down('md')]: {
      width: 940,
    },
    [theme.breakpoints.down('sm')]: { 
      width: 775,
      height: 940
    },
  },
  dialogPaper: {
    height: 865,
    width: 1200,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar-track": {
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.425)",
      borderRadius: 15,
    },
    "&::-webkit-scrollbar": {
      width: "0.4em"
    },
    [theme.breakpoints.down('md')]: {
      width: 940,
    },
    [theme.breakpoints.down('sm')]: { 
      width: 775,
      height: 940
    },
  },
  manageTemplateHeader: {
    fontSize: "1rem",
    position: "absolute",
    left: 30,
    top: 71,
    color: "#A3A3A3"
  },
  manageTemplateSubheader: {
    position: "absolute",
    left: 30,
    top: 94,
    fontSize: "1.25rem",
    fontWeight: 400,
  },
  cancelButton: {
    position: "absolute",
    height: 44,
    width: 95,
    right: 141,
    bottom: 49,
    [theme.breakpoints.down('sm')]: {
      bottom: 25,
      right: 200
    }
  },
  saveButton: {
    position: "absolute",
    height: 44,
    width: 95,
    right: 30,
    bottom: 49,
    backgroundColor: "#009617",
    [theme.breakpoints.down('sm')]: {
      bottom: 25,
      right: 85
    },
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  templateTextField: {
    width: 608,
    position: "absolute",
    top: 158,
    right: 30,
    height: 475,
    [theme.breakpoints.down('md')]: {
      right: 20
    },
    [theme.breakpoints.down('sm')]: {
      left: 80,
      top: 246
    },
  },
  templateInput: {
    height: 575,
    padding: 33,
    margin: "auto"
  },
  htmlToggle: {
    position: "absolute",
    top: 75,
    right: 365,
  },
  loadingSpinner: {
    bottom: 100,
    right: 65,
    color: "grey",
    position: "absolute",
    width: "25px !important",
    height: "25px !important",
    [theme.breakpoints.down('sm')]: {
      right: 30,
      bottom: 35,
      width: "30px !important",
      height: "30px !important"
    }
  },
  error: {
    color: "red",
    position: "absolute",
    bottom: 135,
    left: 60
  },
  dynamicDataForm: {
    position: "absolute",
    minWidth: 260,
    maxWidth: 260,
    right: 30,
    top: 65
  },
  emailOptionsInput: {
    width: 460,
    position: "relative",
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
      width: 250
    },
    [theme.breakpoints.down('sm')]: {
      width: 210
    }
  },
  selectLabel: {
    backgroundColor: "white",
    marginRight: 10,
    right: 70,
  },
  deleteIconButton: {
    position: "relative",
    left: 20,
    top: -3,
    padding: 4,
    color: "red",
  }
})
)

export { ManageTemplatePopupStyles };