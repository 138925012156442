import { makeStyles, createStyles } from '@material-ui/core/styles';

const BrandingWindowStyles = makeStyles((theme: any) => createStyles({
  bookingNavOption: {
    fontSize: 14,
    position: "relative",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer"
    }
  },
  bookingNavOptionSelected: {
  },
  bookingNavOptionSafari: {

  },
  brandingHeader: {
    fontSize: 20,
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 75,
  }
})
)

export { BrandingWindowStyles };