import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import React, { ReactElement, useState, useContext } from 'react';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';

import { DeletePresenterPopupStyles } from './DeletePresenterPopupStyles';
import PopupHeaderDelete from '../../../PopupHeaderDelete/PopupHeaderDelete';

interface Props {
  handlePopupClose: () => any,
  handleDeleteCancel: () => any,
}

export default function DeletePresenterPopup(props: Props): ReactElement {
  
  const { 
    selectedPresenter, 
    setSelectedPresenter,
    selectedBooking 
  } : AppContextType = useContext(AppContext);

  const [ error, toggleError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  
  const handleNoClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handleDeleteCancel();
  }

  const handleYesClick = async () => {
    toggleLoadingSpinner(true);
    toggleError(false);

    try {
      await axios.delete("/presenter", {
        params: {
          bookingID: selectedBooking.bookingID,
          registrationID: selectedPresenter.registrationID
        }
      });

      toggleLoadingSpinner(false);
      props.handlePopupClose();
      setSelectedPresenter(null);
    }
    catch {
      toggleLoadingSpinner(false);
    }
    
  }
  
  const classes = DeletePresenterPopupStyles();
  
  return (
    <Dialog
      open={true}
      classes={{ paper: classes.dialogPaper}}
    >
      <div className={classes.popup}>

        <PopupHeaderDelete
          headerText="Delete Presenter?"
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="body1" className={classes.deleteText}>
          Delete {selectedPresenter.registrationData.firstName} {selectedPresenter.registrationData.lastName} from Presenter List?
        </Typography>

        <Typography variant="body1" className={classes.deleteTextConfirm}>
          There is no undo for Presenter delete.
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred deleting this presenter
          </Typography>
        }

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }

        <Button 
          variant="contained" 
          color="primary" 
          className={classes.noButton}
          onClick={handleNoClick}
        >
          CANCEL
        </Button>

        <Button 
          variant="contained" 
          className={classes.yesButton}
          onClick={handleYesClick}
          disabled={loadingSpinner}
        >
          CONFIRM
        </Button>
      </div>
    </Dialog>
  )
}
