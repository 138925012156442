import React, { ReactElement, useState } from 'react';
import { Typography } from '@material-ui/core';
import { isSafari } from "react-device-detect";

import BrandingInRoom from './BrandingInRoom/BrandingInRoom';
import BrandingWebTemplate from './BrandingWebTemplate/BrandingWebTemplate';
import BrandingEmail from './BrandingEmail/BrandingEmail';

import styles from './BrandingWindow.module.css';
import { BrandingWindowStyles } from './BrandingWindowStyles';

interface Props {
  
}

export default function BrandingWindow(props: Props): ReactElement {
  const [ selectedTab, setSelectedTab ] = useState('in-room');

  //Change tab
  const handleTabSelect = (e) => {
    setSelectedTab(e.target.id);
  }
  
  const classes = BrandingWindowStyles();

  return (
    <div>

      <Typography variant="h1" classes={{ root: classes.brandingHeader }}>
        Branding
      </Typography>

      <div className={styles.brandingNav}>

        <div className={styles.optionWrapper}>

          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedInRoom}`}
            style={{
              backgroundColor: selectedTab === "in-room"
                ? "#FF0092"
                : "#DCDCDC",
            }}
          >
            <Typography
              id="in-room"
              onClick={handleTabSelect}
              variant="body1"
              classes={
                {
                  root: isSafari ? `${classes.bookingNavOption} ${classes.bookingNavOptionSafari}` : classes.bookingNavOption
                }
              }
              style={{
                color: selectedTab === "in-room" ? "white" : "black"
              }}
            >
              In-Room
          </Typography>
          </span>
          
        </div>
        
        <div className={styles.optionWrapper}>

          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedWebTemplate}`}
            style={{
              backgroundColor: selectedTab === "web-template"
                ? "#FF0092"
                : "#DCDCDC"
            }}
          >
            <Typography
              id="web-template"
              onClick={handleTabSelect}
              variant="body1"
              classes={
                {
                  root: isSafari ? `${classes.bookingNavOption} ${classes.bookingNavOptionSafari}` : classes.bookingNavOption
                }
              }
              style={{
                color: selectedTab === "web-template" ? "white" : "black"
              }}
            >
              Web
          </Typography>
          </span>

          
        </div>

        <div className={styles.optionWrapper}>

          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedEmail}`}
            style={{
              backgroundColor: selectedTab === "email"
                ? "#FF0092"
                : "#DCDCDC"
            }}
          >
            <Typography
              id="email"
              onClick={handleTabSelect}
              variant="body1"
              classes={
                {
                  root: isSafari ? `${classes.bookingNavOption} ${classes.bookingNavOptionSafari}` : classes.bookingNavOption
                }
              }
              style={{
                color: selectedTab === "email" ? "white" : "black"
              }}
            >
              Email
          </Typography>
          </span>

          
        </div>
        
      </div>

      <div className={styles.brandingViewHolder}>
        {
          {
            'in-room': <BrandingInRoom />,
            'web-template': <BrandingWebTemplate />,
            'email': <BrandingEmail />,
          }[selectedTab]
        }
      </div>
    </div>
  )
}
