import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingItemStyles = makeStyles((theme: any) => createStyles({
  bookingItem: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    height: 47,
    boxShadow: "none",
    borderBottom: "1px solid #EBEBEB"
  },
  bookingName: {
    position: "absolute",
    left: 29,
    top: 15,
    fontSize: 14,
    width: 195,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  bookingStartDate: {
    marginBottom: -5,
    fontSize: 14,
  },
  bookingStartTime: {
    fontSize: 14
  },
  bookingEndDate: {
    marginBottom: -5,
    fontSize: 14
  },
  bookingEndTime: {
    fontSize: 14
  },
  bookingEditButton: {
    position: "absolute",
    right: 30,
    top: 7.5,
    color: "black",
    padding: 6,
  },
  editIcon: {
    width: 20,
    height: 20
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 110,
    bottom: 12,
    width: "25px !important",
    height: "25px !important"
  }
})
)

export { BookingItemStyles };