import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Typography, Button } from '@material-ui/core';
import AddPresentationPopup from './AddPresentationPopup/AddPresentationPopup';
import PresentationItem from './PresentationItem/PresentationItem';
import AddIcon from '@material-ui/icons/Add';

import { PresentationsWindowStyles } from './PresentationsWindowStyles'

import { AppContext, AppContextType } from '../../context/AppContext';
import styles from './PresentationsWindow.module.css';
import { orderBy } from 'natural-orderby';


interface Props {
  
}

export default function PresentationsWindow(props: Props): ReactElement {

  const { 
    selectedSpace, 
    setSelectedPresentation 
  } : AppContextType = useContext(AppContext);

  const [ spacePresentations, setSpacePresentations ] = useState(selectedSpace.presentations);
  const [ addPresentation, toggleAddPresentation ] = useState(false);

  /** Add presentation */
  const handleAddPresentationClick = () => {
    toggleAddPresentation(true);
  }

  const handleAddPresentationClose = () => {
    toggleAddPresentation(false);
  }
  /**/

  /** Delete presentation */
  const handleDeletePresentation = (deletedPresentation) => {
    delete spacePresentations[deletedPresentation.presentationID];

    setSelectedPresentation(null);
  }
  /**/

  const handlePresentationAdd = (newPresentation) => {
    let newPresentationObj = {
      presentationName: newPresentation.name,
      presentationID: newPresentation.presentationID,
      slides: []
    }
    
    let spacePresentationsClone = spacePresentations;

    spacePresentationsClone[newPresentation.presentationID] = newPresentationObj;

    setSpacePresentations(spacePresentationsClone);

    toggleAddPresentation(false);
  }

  /**/
  
  const classes = PresentationsWindowStyles();

  //Assemble presentation array and order it
  let presentationArray = [];
  
  for (var key in spacePresentations) {
    presentationArray.push(spacePresentations[key]);
  }
  let presentationArrayOrdered = orderBy(
    presentationArray,
    [p => (p as any).presentationName],
    ['asc']
  );
  let presentationItemsArray = [];
  presentationArrayOrdered.forEach((presentation, i) => {
    presentationItemsArray.push(
      <PresentationItem
        presentation={presentation}
        handleDeletePresentation={handleDeletePresentation}
        key={presentation.presentationID}
        index={i}
      />
    )
  })
  
  return (
    <React.Fragment>

        <Typography variant="h1" classes={{ root: classes.presentationsHeader }}>
          Presentations
        </Typography>

        <Button
          color="primary"
          variant="contained"
          classes={{ root: classes.addAndEditButton }}
          onClick={handleAddPresentationClick}
        >
          <AddIcon className={classes.addIcon}></AddIcon>
          NEW
        </Button>

        <div 
          className={styles.presentationsHolder}
        >

          {
            [presentationItemsArray].map(item => {
              return (item);
            })
          }

        </div>
      
      {addPresentation &&
        <AddPresentationPopup open={addPresentation} handlePresentationAdd={handlePresentationAdd} handlePopupClose={handleAddPresentationClose} />
      }
    </React.Fragment>
    
      
  )
}
