import { makeStyles, createStyles } from '@material-ui/core/styles';

const InRoomSkinnableItemStyles = makeStyles((theme: any) => createStyles({
  itemHeader: {
    position: "relative",
    fontSize: 14,
    left: 30,
    top: 14,
  },
  viewIcon: {
    position: "absolute",
    left: 311,
    top: 16,
    color: "black",
    fontSize: 20,
    "&:hover": {
      cursor: "pointer"
    }
  },
  button: {
    position: "absolute",
    right: 30,
    top: 10,
    padding: 4,
    color: "black",
  },
  editButton: {
    top: 9,
    right: 29,
  },
  webAssetIcon: {
    fontSize: 30,
  },
  webAssetButton: {
    position: "absolute",
    zIndex: 999,
    left: 605,
    top: 6,
    color: "black",
    padding: 5,
  }
})
)

export { InRoomSkinnableItemStyles };