import React, { ReactElement, useState, useContext } from 'react';
import axios from 'axios';
import { Typography, Button, CircularProgress, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/SendOutlined';
import EditIcon from '@material-ui/icons/OpenInNew';

import ManageTemplatePopup from '../ManageTemplatePopup/ManageTemplatePopup';
import SendEmailPopup from '../SendEmailPopup/SendEmailPopup';
import { EmailContext, EmailContextType } from '../../../../context/EmailContext';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import styles from './EmailItem.module.css';
import { EmailItemStyles} from './EmailItemStyles';

interface Props {
  name: string,
  id: string,
  HTML: boolean,
  system: boolean,
  from: string,
  subject: string,
  replyTo: string,
  handleEmailTemplateDelete: (emailTemplateID : string) => any,
  index: number,
}

export default function EmailItem(props: Props): ReactElement {
  
  const [ isManagePopupOpen, toggleIsManagePopupOpen ] = useState(false);
  const [ isSendPopupOpen, toggleIsSendPopupOpen ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ disableManage, toggleDisableManage ] = useState(false);
  const [ disableSend, toggleDisableSend ] = useState(false);

  const { setSelectedEmailTemplate } : EmailContextType = useContext(EmailContext);
  
  const { 
    selectedBooking 
  } : AppContextType = useContext(AppContext);

  const handleManageEmailClick = () => {

    if (disableManage) {
      return;
    }

    toggleDisableSend(true);
    toggleLoadingSpinner(true);
    axios.get("/email-template", {
      params: {
        emailTemplateID: props.id
      }
    })
    .then(function(response) {
      setSelectedEmailTemplate(response.data);
      toggleLoadingSpinner(false);
      toggleDisableSend(false);
      toggleIsManagePopupOpen(true);
    })
    .catch(function(error) {
      toggleLoadingSpinner(false);
      toggleDisableSend(false);
      console.log(error);
    });

  }

  const handleManageEmailPopupClose = () => {
    setSelectedEmailTemplate({});
    toggleIsManagePopupOpen(false);
  }

  const handleSendEmailClick = async () => {

    if (disableSend) {
      return;
    }

    toggleDisableManage(true);
    toggleLoadingSpinner(true);

    try {

      let response = await axios.get("/email-template", {
        params: {
          emailTemplateID: props.id
        }
      });

      setSelectedEmailTemplate(response.data);
      toggleLoadingSpinner(false);
      toggleDisableManage(false);
      toggleIsSendPopupOpen(true);

    }
    catch (error) {
      toggleLoadingSpinner(false);
      toggleDisableManage(false);
      console.log(error);
    }
   
  }
  
  const handleSendEmailPopupClose = () => {
    setSelectedEmailTemplate({});
    toggleIsSendPopupOpen(false);
  }

  const handleEmailTemplateDelete = (emailTemplateID : string) => {
    toggleIsManagePopupOpen(false);

    props.handleEmailTemplateDelete(emailTemplateID);
  }

  const classes = EmailItemStyles();
  
  return (

    <React.Fragment>
      <div 
        className={styles.emailItemHolder}
        style={{ backgroundColor: (props.index + 1) % 2 === 0 
          ? "#FBFBFB"
          : "white"
        }}
      >
        <Typography variant="body1" className={classes.emailName}>
          {props.name}
        </Typography>

        {selectedBooking.emailTemplates[props.id].system &&
          <div className={styles.systemDisplay}></div>
        }

        {!selectedBooking.emailTemplates[props.id].HTML &&
          <div className={styles.htmlDisplay}></div>
        }

        {loadingSpinner && 
          <CircularProgress className={classes.loadingSpinner} />
        }
        
        <div className={styles.iconHolder}>


          <div>
            <IconButton
              disabled={disableManage}
              className={classes.sendButton}
              onClick={handleSendEmailClick}
            >
              <SendIcon 
                style={{
                  fontSize: "20px"
                }}
              />
            </IconButton>
          </div>
          
          <div>
            <IconButton
              disabled={disableSend}
              className={classes.manageButton}
              onClick={handleManageEmailClick}
            >
              <EditIcon 
                style={{
                  fontSize: "20px"
                }}
              />
            </IconButton>
          </div>
          
        </div>

      </div>
      
      {isManagePopupOpen &&
        <ManageTemplatePopup 
          handlePopupClose={handleManageEmailPopupClose}
          templateName={props.name}
          templateID={props.id}
          HTML={props.HTML}
          system={props.system}
          handleEmailTemplateDelete={handleEmailTemplateDelete}
        />
      }
      
      {isSendPopupOpen &&
        <SendEmailPopup
          handlePopupClose={handleSendEmailPopupClose}
          templateName={props.name}
          templateID={props.id}
      />
      }
      
      
    </React.Fragment>
    
  )
}
