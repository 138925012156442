import React, { ReactElement, useContext, useState } from 'react'
import { Typography, Button, Dialog, CircularProgress, IconButton } from '@material-ui/core'
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';

import { AppContext, AppContextType } from '../../../../context/AppContext';
import { AuthContext, AuthContextType } from '../../../../context/AuthContext';

import styles from './AttendeeResendInvitePopup.module.css';
import { AttendeeResendInvitePopupStyles } from './AttendeeResendInvitePopupStyles';


interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function AttendeeResendInvitePopup(props: Props): ReactElement {

  const { idToken } : AuthContextType = useContext(AuthContext);

  const {
    selectedBooking,
    selectedRegistration,
    selectedSpace
  } : AppContextType = useContext(AppContext);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  
  //Resend invite handler
  const handleResendInvite = async () => {

    toggleLoadingSpinner(true);

    //Get invite email template
    let inviteEmailTemplate : SHOWBOAT.EmailTemplateMiniObject;

    for (var key in selectedBooking.emailTemplates) {
      if (selectedBooking.emailTemplates[key].name === "Attendee Invite Link") {
        inviteEmailTemplate = selectedBooking.emailTemplates[key];
      };
    };
    
    let toSendArr = [];
    toSendArr.push(selectedRegistration);

    try {
      let response = await  axios.post("/email-parse-request", {
        bookingID: selectedBooking.bookingID,
        spaceID: selectedSpace.spaceID,
        emailTemplateID: inviteEmailTemplate.id,
        toSend: toSendArr
      });

      toggleLoadingSpinner(false);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
    }
    
  }

  //Cancel handler
  const handleCancelResendInvite = () => {

    if (loadingSpinner) return;
    
    props.handlePopupClose();
  }

  //Material UI classes
  const classes = AttendeeResendInvitePopupStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper}}
      open={props.open}
    >
      <div className={classes.popup}>

        <div className={styles.popupHeader}>
          <IconButton 
            onClick={handleCancelResendInvite}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Typography variant="body1" className={classes.resendInviteHeader}>
          Send Invite to:
        </Typography>
        <Typography variant="body1" className={classes.resendInviteName}>
          {selectedRegistration.registrationData.firstName} {selectedRegistration.registrationData.lastName}
        </Typography>

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button 
          className={classes.cancelButton} 
          variant="contained"
          onClick={handleCancelResendInvite}
          color="primary"
        >
          CANCEL
        </Button>
        <Button 
          className={classes.sendButton} 
          variant="contained" 
          color="primary"
          onClick={handleResendInvite}
          disabled={loadingSpinner}
        >
          SEND
        </Button>
      </div>
    </Dialog>
    
  )
}
