import React, { ReactElement, useContext, useState, useEffect } from 'react'
import { Typography, Button, Switch, FormControlLabel, Snackbar, Dialog, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import isEmail from 'validator/lib/isEmail';
import LinkIcon from '@material-ui/icons/LinkOutlined';
import SendIcon from '@material-ui/icons/SendOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';

import AttendeeResendInvitePopup from '../AttendeeResendInvitePopup/AttendeeResendInvitePopup';
import AttendeeForm from '../AttendeeForm/AttendeeForm';
import AttendeeDeletePopup from '../AttendeeDeletePopup/AttendeeDeletePopup';
import { AppContext, AppContextType } from '../../../../context/AppContext';
import ConfirmAttendeeLinkRegenerate from '../ConfirmAttendeeLinkRegenerate/ConfirmAttendeeLinkRegenerate';

import styles from './AttendeeEditPopup.module.css';
import { AttendeeEditPopupStyles } from './AttendeeEditPopupStyles'
import DeletePresenterPopup from '../../BookingPresenters/DeletePresenterPopup/DeletePresenterPopup';

interface Props {
  open: boolean,
  handlePopupClose: (cancelled: boolean) => any,
  presenter: boolean,
}

export default function AttendeeEditPopup(props: Props): ReactElement {

  const {
    selectedRegistration,
    setSelectedRegistration,
    selectedBooking,
  } : AppContextType = useContext(AppContext);

  const [ copyToClipboard, toggleCopyToClipboard ] = useState(false);
  const [ linkRegenerated, toggleLinkRegenerated ] = useState(false);
  const [ resendInvite, toggleResendInvite ] = useState(false);
  const [ deleteAttendee, toggleDeleteAttendee ] = useState(false);
  const [ regenerateAttendeeLinkPopup, toggleRegenerateAttendeeLinkPopup ] = useState(false);
  const [ emailError, toggleEmailError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ error, toggleError ] = useState(false);
  const [ brand, toggleBrand ] = useState(false);
  const [ allowEarlyAccess, toggleALlowEarlyAccess ] = useState(false);

  useEffect(() => {

    if (selectedRegistration.brand) {
      toggleBrand(true);
    }

    if (selectedRegistration.allowEarlyAccess) {
      toggleALlowEarlyAccess(true);
    }

  }, [])

  //Copy to clipboard
  const handleCopyLinkClick = () => {

    let dummy = document.createElement('textarea');

    document.getElementById("attendeePopup").appendChild(dummy);
    
    dummy.value = "https://" + document.getElementById("toCopy").innerText;

    dummy.select();
    dummy.setSelectionRange(0, 99999);


    let copy = document.execCommand('copy');
    document.getElementById("attendeePopup").removeChild(dummy);

    toggleCopyToClipboard(true);
  }

  const handleCopyToClipboardClose = () => {
    toggleCopyToClipboard(false);
  }

  //Regenerate link
  const handleRegenerateLinkClick = () => {

    toggleRegenerateAttendeeLinkPopup(true);
  }

  const handleRegenerateLinkPopupClose = (wasRegenerated: boolean) => {

    //Check if we need to show toast notification
    if (wasRegenerated) toggleLinkRegenerated(true);

    toggleRegenerateAttendeeLinkPopup(false);
  }

  const handleRegenerateLinkClose = () => {
    toggleLinkRegenerated(false);
  }

  //Resend invite
  const handleResendInviteClick = () => {

    //Show error if we do not have a valid email address to send an email to
    if ((!selectedRegistration.registrationData.emailAddress.trim().length 
    || !isEmail(selectedRegistration.registrationData.emailAddress))) {
      toggleEmailError(true);
      return;
    }

    toggleEmailError(false);

    toggleResendInvite(true);

  }

  const handleResendInvitePopupClose = () => {
    toggleResendInvite(false);
  }

  //Delete attendee
  const handleAttendeeDelete = () => {
    toggleDeleteAttendee(true);
  }

  const handleDeleteCancel = () => {
    toggleDeleteAttendee(false);
  }

  const handleDeleteAttendeePopupClose = () => {
    toggleDeleteAttendee(false);
    setSelectedRegistration(null);
    props.handlePopupClose(false);
  }

  //Handle attendee edit submit
  const handleAttendeeSubmit = async (newAttendeeObject : SHOWBOAT.Registration) => {
    toggleLoadingSpinner(true);

    try {
      let response = await axios.put(props.presenter ? "/presenter" : "/registration", {
        bookingID: selectedRegistration.bookingID,
        registrationID: selectedRegistration.registrationID,
        first: newAttendeeObject.registrationData.firstName,
        last: newAttendeeObject.registrationData.lastName,
        email: newAttendeeObject.registrationData.emailAddress,
        company: newAttendeeObject.registrationData.company,
        phone: newAttendeeObject.registrationData.phone,
        address: {
          addressLine1: newAttendeeObject.registrationData.address.addressLine1,
          addressLine2: newAttendeeObject.registrationData.address.addressLine2,
          city: newAttendeeObject.registrationData.address.city,
          state: newAttendeeObject.registrationData.address.state,
          zipCode: newAttendeeObject.registrationData.address.zipCode
        },
        brand: brand,
        allowEarlyAccess: allowEarlyAccess,
      })

      toggleLoadingSpinner(false);
      toggleEmailError(false);
      setSelectedRegistration(null);
      props.handlePopupClose(false);
      
    }
    catch (error) {
      toggleError(true);
      toggleLoadingSpinner(false);
    }
    
  }

  //Change brand
  const handleBrandChange = () => {
    if (brand) {
      toggleBrand(false);
    } else {
      toggleBrand(true);
    }
  }

  //Change allow early access
  const handleAllowEarlyAccessChange = () => {
    if (allowEarlyAccess) {
      toggleALlowEarlyAccess(false);
    } else {
      toggleALlowEarlyAccess(true);
    }
  }

  const handleCloseIconClick = () => {
    props.handlePopupClose(true);
  }
  
  //Material ui classes
  const classes = AttendeeEditPopupStyles();

  return (
    <Dialog
      open={props.open}
      classes={{ paper: selectedBooking.intakeFields[5].type === "international" ? `${classes.dialogPaper} ${classes.dialogPaperInternational} ` : classes.dialogPaper}}
    >
      <div id="attendeePopup" className={selectedBooking.intakeFields[5].type === "international" ? `${classes.popup} ${classes.popupInternational}` : classes.popup}>
        <div className={styles.popupHeader}>
          <IconButton
            className={classes.closeIconButton}
            onClick={handleCloseIconClick}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Typography className={classes.editAttendeeHeader} variant="h1">
          Edit {props.presenter ? "Presenter" : "Attendee"}
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred editing this attendee
          </Typography>
        }

        <div  className={styles.buttonTopHolder}>
          <Typography className={classes.editAttendeeLink} id="attendeeLink" variant="h1">
            URL: <span id="toCopy">go.showboat.live/{selectedRegistration.loginCode}</span>
          </Typography>

          <div>
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper
              }}
              placement="bottom"
              title="Copy Link"
            >
              <IconButton
                color="primary"
                className={classes.copyLinkButton}
                onClick={handleCopyLinkClick}
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </div>
          
          <div>
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper
              }}
              placement="bottom"
              title="Resend Invite"
            >
              <IconButton
                color="primary"
                className={classes.resendInviteButton}
                onClick={handleResendInviteClick}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          </div>
          
          <div>
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper
              }}
              placement="bottom"
              title="Regenerate Link"
            >
              <IconButton
                color="primary"
                className={classes.regenerateButton}
                onClick={handleRegenerateLinkClick}
                style={{
                  color: "red"
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>

          <div>
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper
              }}
              placement="bottom"
              title="Delete Record"
            >
              <IconButton
                onClick={handleAttendeeDelete}
                className={classes.trashIconButton}
                style={{
                  color: "red"
                }}
              >
                <TrashIcon
                  className={classes.trashIcon}
                />
              </IconButton>
            </Tooltip>
          </div>
          
         </div>

        <div className={styles.attendeeFormHolder}>
          <AttendeeForm 
            handlePopupClose={props.handlePopupClose} 
            handleAttendeeSubmit={handleAttendeeSubmit} 
            isAddingAttendee={false}
            isLoading={loadingSpinner}
            emailError={emailError}
          />
        </div>
        
        {!props.presenter &&
          <div className={styles.brandToggleHolder}>

            <FormControlLabel
              control={<Switch checked={brand} onChange={handleBrandChange} color="secondary" className={classes.brandToggle} />}
              label="Brand"
              classes={{
                label: classes.toggleLabel
              }}
            />

          </div>
        }
        

        <div className={styles.allowEarlyAccessToggleHolder}>

          <FormControlLabel
            control={<Switch checked={allowEarlyAccess} onChange={handleAllowEarlyAccessChange} color="secondary" className={classes.allowEarlyAccessToggle} />}
            label="Allow Early Access"
            classes={{
              label: classes.toggleLabel
            }}
          />

        </div>

        {regenerateAttendeeLinkPopup &&
          <ConfirmAttendeeLinkRegenerate
            isPresenter={props.presenter}
            handlePopupClose={handleRegenerateLinkPopupClose}
            open={regenerateAttendeeLinkPopup}
          />
        }

        {/*  COPY TO CLIPBOARD SNACKBAR */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }} 
          className={classes.copySnackbar}
          autoHideDuration={1500}
          open={copyToClipboard}
          onClose={handleCopyToClipboardClose}
          message={`${props.presenter ? "Presenter" : "Attendee"} Link Copied to Clipboard`}
        />

        {/*  REGENERATE LINK SNACKBAR */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }} 
          autoHideDuration={1500}
          className={classes.regenerateSnackbar}
          open={linkRegenerated}
          onClose={handleRegenerateLinkClose}
          message={`${props.presenter ? "Presenter" : "Attendee"} Event URL Regenerated`}
        />

        {/*  Resend invite POPUP */} 
        <AttendeeResendInvitePopup 
          handlePopupClose={handleResendInvitePopupClose} 
          open={resendInvite} 
        />

        {deleteAttendee &&
          <React.Fragment>
            {props.presenter
            
            ? 
            <DeletePresenterPopup
              handlePopupClose={handleDeleteAttendeePopupClose}
              handleDeleteCancel={handleDeleteCancel}
            />
            :
            <AttendeeDeletePopup
              handlePopupClose={handleDeleteAttendeePopupClose}
              handleDeleteCancel={handleDeleteCancel}
              open={deleteAttendee}
            />
            }
          </React.Fragment>
          
        }
        
        {loadingSpinner &&
          <CircularProgress className={selectedBooking.intakeFields[5].type === "international" ? `${classes.loadingSpinner} ${classes.loadingSpinnerInternational}` : classes.loadingSpinner} />
        }
      </div>
    </Dialog>
    
  )
}
