import { IconButton } from '@material-ui/core';
import React, { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import styles from './PopupHeader.module.css';
import { PopupHeaderStyles } from './PopupHeaderStyles';

interface Props {
  closeFunction: any,
  disableBoolean: boolean,
}

export default function PopupHeader(props: Props): ReactElement {
  
  const handleCloseIconClick = () => {
    props.closeFunction();
  }
  
  const classes = PopupHeaderStyles();
  
  return (
    <div
      className={styles.popupHeader}    
    >
      <IconButton
        className={classes.closeIconButton}
        onClick={handleCloseIconClick}
        disabled={props.disableBoolean}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}
