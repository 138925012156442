import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeItemStyles = makeStyles((theme: any) => createStyles({
  attendeeItemText: {
    fontSize: 14,
    position: "absolute",
    top: 15,
    textAlign: "left",
  }
})
)

export { AttendeeItemStyles };