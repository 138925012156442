import React, { ReactElement, useState } from 'react';

import EditBookingStartTimePopup from '../EditBookingStartTimePopup/EditBookingStartTimePopup';
import EditBookingEndTimePopup from '../EditBookingEndTimePopup/EditBookingEndTimePopup';

import styles from './EditDuplicateBookingTimePopup.module.css';
import { EditDuplicateBookingTimePopupStyles } from './EditDuplicateBookingTimePopupStyles';

interface Props {
  open: boolean,
  handlePopupClose?: () => any,
}

export default function EditDuplicateBookingTimePopup(props: Props): ReactElement {
  
  const [ currentStep, setCurrentStep ] = useState(1);
  const [ selectedStartTime, setSelectedStartTime ] = useState(""); 

  //Cancel
  const handleCancelClick = () => {
    props.handlePopupClose();
  }

  //Step advance
  const handleStepAdvance = (selectedStartTime) => {

    setSelectedStartTime(selectedStartTime);
    setCurrentStep(currentStep + 1);
  }
  
  const classes = EditDuplicateBookingTimePopupStyles();

  let dialogContent;

  if (currentStep === 1) {
    dialogContent = (
      <EditBookingStartTimePopup 
        open={true}
        handlePopupClose={handleCancelClick}
        duplicateBooking={true}
        handleNextButtonClick={handleStepAdvance}
      />
    ) 
  } else {
      dialogContent = (
        <EditBookingEndTimePopup 
          open={true}
          handlePopupClose={handleCancelClick}
          duplicateBooking={true}
          selectedStartTime={selectedStartTime}
        />
      )
    }

  return (dialogContent)
}
