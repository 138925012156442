import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingCurrentAttendeeStyles = makeStyles((theme: any) => createStyles({
  attendeeName: {
    display: "inline-block",
    fontSize: 14,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 190,
  },
  detailsButton: {
    display: "inline-block",
    position: "absolute",
    top: 8,
    right: 22,
    padding: 6,
    paddingTop: 10,
    color: "black",
  },
  personButton: {
    display: "inline-block",
    position: "absolute",
    top: 8,
    right: 64,
    padding: 6,
    paddingTop: 10,
    color: "black",
  },
  detailsButtonLabel: {
    position: "relative",
    bottom: 4,
  },
  currentAttendeeTime: {
    position: "absolute",
    top: 15,
    right: 140,
    fontSize: 14
  }
})
)

export { BookingCurrentAttendeeStyles };