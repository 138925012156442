import React, { ReactElement, useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Card, ClickAwayListener, Typography } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import { AvatarColorItemStyles } from './AvatarColorItemStyles';
import styles from './AvatarColorItem.module.css';

interface Props {
  primary: string,
  secondary: string,
  index: number,
  error: boolean,
  avatarColorSelections: any,
  handleBodyColorChange: (color: string, index: number) => any,
  handleFaceColorChange: (color: string, index: number) => any,
  handleDisableClick: (index: number) => any,
  handleEnableClick: (index: number) => any
}

export default function AvatarColorItem(props: Props): ReactElement {

  const [ bodyColor, setBodyColor ] = useState(props.primary);
  const [ selectBodyColor, toggleSelectBodyColor ] = useState(false);
  const [ persistedBodyColor, setPersistedBodyColor ] = useState(props.primary);

  const [ faceColor, setFaceColor ] = useState(props.secondary);
  const [ selectFaceColor, toggleSelectFaceColor ] = useState(false);
  const [ persistedFaceColor, setPersistedFaceColor ] = useState(props.secondary);

  const [ isDisabled, toggleIsDisabled ] = useState(false);

  const [ colorSelectionError, toggleColorSelectionError ] = useState(false);

  useEffect(() => {
    if (props.secondary === "" && props.primary === "") {
      toggleIsDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (props.error){
      toggleColorSelectionError(true);
    }
  }, [props.error])

  //BODY COLOR CHANGE
  const handleEditBodyColorClick = () => {
    toggleSelectFaceColor(false);
    toggleSelectBodyColor(!selectBodyColor);
  }

  const handleBodyColorChange = (e) => {
    setBodyColor(e.hex);
  }

  const handleBodyColorSave = () => {
    props.handleBodyColorChange(bodyColor, props.index);
    setPersistedBodyColor(bodyColor);
    toggleSelectBodyColor(false);
  }
  
  //FACE COLOR CHANGE
  const handleEditFaceColorClick = () => {
    toggleSelectBodyColor(false);
    toggleSelectFaceColor(!selectFaceColor);
  }

  const handleFaceColorChange = (e) => {
    setFaceColor(e.hex);
  }

  const handleFaceColorSave = () => {

    props.handleFaceColorChange(faceColor, props.index);
    setPersistedFaceColor(faceColor);
    toggleSelectFaceColor(false);
  }

  //DISABLE/ENABLE
  const handleDisableClick = () => {

    setPersistedBodyColor("");
    setPersistedFaceColor("");
    setBodyColor("");
    setFaceColor("");
    toggleIsDisabled(true);

    props.handleDisableClick(props.index);

  }

  const handleEnableClick = () => {

    toggleIsDisabled(false);
    props.handleEnableClick(props.index);
  }

  const classes = AvatarColorItemStyles();

  //color display
  let avatarColorSelector;
  let faceErrorClasses;
  let bodyErrorClasses;

  if (persistedFaceColor === "") {
    faceErrorClasses = `${styles.faceColor} ${styles.error}`;
  } else {
    faceErrorClasses = `${styles.faceColor}`;
  }

  if (persistedBodyColor === "") {
    bodyErrorClasses = `${styles.bodyColor} ${styles.error}`;
  } else {
    bodyErrorClasses = `${styles.bodyColor}`;
  }

  if (colorSelectionError) {
    avatarColorSelector = (
      <React.Fragment>
        <div className={bodyErrorClasses} style={{ backgroundColor: persistedBodyColor }}>
          <div className={styles.editIconHolder}>
            <EditIcon onClick={handleEditBodyColorClick} className={classes.editIcon} />
          </div>
        </div>

        <div className={faceErrorClasses} style={{ backgroundColor: persistedFaceColor }}>
          <div className={styles.editIconHolder}>
            <EditIcon onClick={handleEditFaceColorClick} className={classes.editIcon} />
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    avatarColorSelector = (
      <React.Fragment>
        <div className={styles.bodyColor} style={{ backgroundColor: persistedBodyColor }}>
          <div className={styles.editIconHolder}>
            <EditIcon onClick={handleEditBodyColorClick} className={classes.editIcon} />
          </div>
        </div>

        <div className={styles.faceColor} style={{ backgroundColor: persistedFaceColor }}>
          <div className={styles.editIconHolder}>
            <EditIcon onClick={handleEditFaceColorClick} className={classes.editIcon} />
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (

    <React.Fragment>
      <Card className={classes.avatarColorHolder}>
        { isDisabled 
        ?
          <AddIcon className={classes.addIcon} onClick={handleEnableClick} />
        :
          <CloseIcon className={classes.closeIcon} onClick={handleDisableClick} />
        }

        { isDisabled
        ?
          <React.Fragment>
            <div className={`${styles.bodyColor} ${styles.disabled}`}>
              
            </div>

            <div className={`${styles.faceColor} ${styles.disabled}`} >
              
            </div>
          </React.Fragment>
        :
          avatarColorSelector      
        }
        

        <Typography variant="body1" className={classes.bodyColorHeader}>Body</Typography>
        {!isDisabled &&
          <Typography variant="body1" className={classes.bodyColor}>{persistedBodyColor}</Typography>
        }

        <Typography variant="body1" className={classes.faceColorHeader}>Face</Typography>
        {!isDisabled &&
        <Typography variant="body1" className={classes.faceColor}>{persistedFaceColor}</Typography>
        }
        
        {selectBodyColor &&
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleBodyColorSave}>
          <ChromePicker 
            className={(props.index === 3 || props.index === 7) ? styles.colorPickerEnd : styles.colorPicker} 
            color={bodyColor}
            onChange={handleBodyColorChange}
            value={bodyColor}
          />
        </ClickAwayListener>
        }

        {selectFaceColor &&
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleFaceColorSave}>
          <ChromePicker 
            className={(props.index === 3 || props.index === 7) ? styles.colorPickerEnd : styles.colorPicker} 
            color={faceColor}
            onChange={handleFaceColorChange}
            value={faceColor}
          />
        </ClickAwayListener>
        }
    </Card>

    
    </React.Fragment>
    
  )
}
