import React, { ReactElement, useContext, useState, useEffect } from 'react';
import UploadIcon from '@material-ui/icons/CloudUpload';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import { Typography, Button, CircularProgress, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { isSafari } from 'react-device-detect';

import DeletePresentationPopup from '../DeletePresentationPopup/DeletePresentationPopup';
import UploadSlidesPopup from '../UploadSlidesPopup/UploadSlidesPopup';
import EditPresentationPopup from '../EditPresentationPopup/EditPresentationPopup';
import { AppContext, AppContextType } from '../../../context/AppContext';

import styles from './PresentationItem.module.css';
import { PresentationItemStyles } from './PresentationItemStyles';

interface Props {
  presentation: any,
  handleDeletePresentation: (deletedPresentation) => any,
  index: number,
}

export default function PresentationItem(props: Props): ReactElement {

  const [ editPresentation, toggleEditPresentation ] = useState(false);
  const [ uploadSlides, toggleUploadSlides ] = useState(false);
  const [ deletePresentation, toggleDeletePresentation ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const {
    setSelectedPresentation,
    selectedPresentation
  } : AppContextType = useContext(AppContext);

  //Edit presentation
  const handleEditPresentationClick = async () => {
    toggleLoadingSpinner(true);

    try {
      let response = await axios.get("/presentation", {
        params: {
          presentationID: props.presentation.presentationID
        }
      });

      toggleLoadingSpinner(false);
      setSelectedPresentation(response.data);
      toggleEditPresentation(true);

    }
    catch {
      toggleLoadingSpinner(false);
      alert("An error occurred getting info about this presentation")
    }

  }

  const handleEditPresentationClose = () => {
    setSelectedPresentation(null);
    toggleEditPresentation(false);
  }
  
  //Upload slides
  const handleUploadSlidesClick = async () => {

    toggleLoadingSpinner(true);

    try {

      let response = await axios.get("/presentation", {
        params: {
          presentationID: props.presentation.presentationID
        }
      });

      toggleLoadingSpinner(false);
      setSelectedPresentation(response.data);
      toggleUploadSlides(true);
    }
    catch {
      toggleLoadingSpinner(false);
      alert("An error occurred getting info about this presentation");
    }
    
  }

  const handleUploadSlidesClose = () => {
    toggleUploadSlides(false);
  }

  //Delete presentation
  const handleDeletePresentationClick = () => {
    setSelectedPresentation(props.presentation);
    toggleDeletePresentation(true);
  }

  const handleDeletePresentation = (deletedPresentation) => {
    toggleDeletePresentation(false);
    props.handleDeletePresentation(deletedPresentation);
  }

  const handleDeletePresentationClose = () => {
    setSelectedPresentation(null);
    toggleDeletePresentation(false);
  }

  const classes = PresentationItemStyles();
  
  return (
    <div 
      className={isSafari ? `${styles.presentationItemHolder} ${styles.presentationItemHolderSafari}` : styles.presentationItemHolder}
      style={{
        backgroundColor: (props.index + 1) % 2 === 0
          ? "#FBFBFB"
          : "white"
      }}
    >

      <Typography variant="h1" className={classes.presentationName}>
        {props.presentation.presentationName}
      </Typography>

      

      {loadingSpinner &&
        <CircularProgress className={classes.loadingSpinner} />
      }

      <IconButton 
        className={classes.deleteIconButton} 
        onClick={handleDeletePresentationClick}
      >
        <TrashIcon className={classes.deleteIcon} />
      </IconButton>

      <IconButton 
        className={classes.uploadIconButton} 
        onClick={handleUploadSlidesClick}
        style={{
          color: "red"
        }}
      >
        <img 
          src="assets/images/UploadIcon.svg" 
          alt="Upload icon" 
          style={{
            width: "18px",
            height: "18px"
          }}
        />
      </IconButton>

      <IconButton
        disabled={loadingSpinner} 
        onClick={handleEditPresentationClick} 
        className={classes.editButton} 
      >
        <EditIcon 
          style={{
            fontSize: "20px"
          }}
        />
      </IconButton>
      
      {uploadSlides &&
        <UploadSlidesPopup presentationID={props.presentation.presentationID} handlePopupClose={handleUploadSlidesClose} />
      }

      {selectedPresentation !== null &&
        <EditPresentationPopup open={editPresentation} handlePopupClose={handleEditPresentationClose} />
      }

      {selectedPresentation !== null &&
        <DeletePresentationPopup presentationName={props.presentation.presentationName} handleDeletePresentation={handleDeletePresentation} open={deletePresentation} handlePopupClose={handleDeletePresentationClose} />
      }

    </div>
  )
}
