import moment from 'moment';

export class AttendeeUsageHelper {

  public static calculateUsage(startTime : string, endTime : string) : [string, string] {
    let difference = new Date(endTime).getTime() - new Date(startTime).getTime();

    //Convert milliseconds difference to seconds
    let differenceSeconds = Math.round(difference/1000);

    //Calculate number of days in difference between startTime and endTime
    let momentStart = moment(startTime);
    let momentEnd = moment(endTime);
    let days = momentEnd.diff(momentStart, "days");

    let timeFormatted = new Date(differenceSeconds * 1000).toISOString().substr(11, 8);

    let hours = timeFormatted.substring(0, 2);

    let hoursFormatted = parseInt(hours, 10);

    //If days have elapsed, multiply # of days by 24 to get hours to add on
    if (days > 0) {
      hoursFormatted = hoursFormatted + (days * 24);
    }

    let minutes = timeFormatted.substring(3, 5);

    return [hours, minutes];
  }

  public static calculateTotalMatchingRecordUsage(matchingRecords : [any]) : string {
    let totalSeconds = 0;

    //For each matching record, check if there is a totalSeconds property on there
      //If so, add that to totalSeconds
      //If not, calculate difference between currentTime and startTime for that record and add to totalSeconds
    matchingRecords.forEach(record => {
      if (record.totalSeconds !== 0) {
        totalSeconds += record.totalSeconds;
      } else if (record.totalSeconds === 0 && record.endTime !== "") {
        totalSeconds += record.totalSeconds;
      } else {
        let currentTime = new Date().toUTCString();

        let difference = new Date(currentTime).getTime() - new Date(record.startTime).getTime();

        let differenceSeconds = Math.round(difference/1000);

        totalSeconds += differenceSeconds;
      }
    });

    //Use toHHMMSS helper to format return value
    let timeStringFormatted = AttendeeUsageHelper.toHHMMSS(totalSeconds);

    return timeStringFormatted;
  }

  private static toHHMMSS(secondsToParse) {
    var sec_num = parseInt(secondsToParse, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {hours = parseInt("0"+hours);}
    if (minutes < 10) {minutes = parseInt("0"+minutes);}
    if (seconds < 10) {seconds = parseInt("0"+seconds);}
    return hours+'h:'+minutes+'m'
  }
}