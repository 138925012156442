import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingsWindowStyles = makeStyles((theme: any) => createStyles({
  bookingsHeader: {
    fontSize: 20,
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 75,
  },
  addAndEditButton: {
    left: 139,
    padding: 0,
    top: 26,
    backgroundColor: "white",
    border: "1px solid #FF0092",
    borderRadius: 4,
    color: "black",
    width: 67,
    height: 26,
    fontSize: 14,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FF0092",
      boxShadow: "none",
      color: "white",
    }
  },
  loadingSpinner: {
    color: "grey",
    top: 67,
    right: 220,
    zIndex: 9999,
    position: "absolute"
  },
  bookingID: {
    fontSize: 14,
    position: "absolute",
  },
  addIcon: {
    position: "relative",
    bottom: 1,
    right: 2,
    marginRight: 4,
    width: 16,
    height: 16,
  }
})
)

export { BookingsWindowStyles };