import { Button, CircularProgress, Dialog, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { AppContext, AppContextType } from '../../../../context/AppContext';
import PopupHeader from '../../../PopupHeader/PopupHeader';
import { HangUpURLEditPopupStyles } from './HangUpURLEditPopupStyles';

interface Props {
  handlePopupClose: () => any,
  open: boolean,
}

export default function HangUpURLEditPopup(props: Props): ReactElement {

  const { 
    selectedBooking, 
    setSelectedBooking 
  } : AppContextType = useContext(AppContext);

  const [ hangUpURL, setHangUpURL ] = useState(selectedBooking.hangUpURL !== undefined 
    ? selectedBooking.hangUpURL
    : ""
  );

  const [ formHasBeenSubmitted, toggleFormHasBeenSubmitted ] = useState(false);
  const [ error, toggleError ] = useState(false);
  const [ httpError, toggleHttpError ] = useState(false); 
  const [ requiredError, toggleRequiredError ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const handleHangUpURLChange = (e) => {
    
    toggleRequiredError(false);
    
    setHangUpURL(e.target.value);
  };

  const handleSaveButtonClick = async () => {

    toggleFormHasBeenSubmitted(true);
    
    //Detect if hangUpURL is present
    if (!hangUpURL.trim().length) {
      toggleRequiredError(true);
      return;
    }

    toggleRequiredError(false);

    //Make sure hangUpURL starts with http
    if (!hangUpURL.trim().startsWith("http")) {
      toggleHttpError(true);
      return;
    }

    toggleHttpError(false);

    toggleLoadingSpinner(true);
    
    let selectedBookingClone = selectedBooking;

    selectedBookingClone.hangUpURL = hangUpURL;

    try {
      await axios.put("/booking", JSON.stringify(selectedBookingClone));

      toggleLoadingSpinner(false);
      setSelectedBooking(selectedBookingClone);
      props.handlePopupClose();

    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
    
  }

  const handleCancelClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();
  }

  const classes = HangUpURLEditPopupStyles();

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography className={classes.editErrorMessageHeader}>
          Leave Meeting Destination URL
        </Typography>

        <Typography className={`${classes.editErrorMessageHeader} ${classes.editErrorMessageSubheader}`}>
          This is where attendees will be sent when they leave the event using the "hang up" button in Showboat.
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred updating the leave meeting destination URL for this event.
          </Typography>
        }

        {httpError &&
          <Typography variant="body1" className={classes.error}>
            Please ensure that your URL begins with HTTP or HTTPS. 
          </Typography>
        }

        <TextField
          className={classes.errorMessageTextField}
          label=""
          helperText="Required"
          variant="outlined"
          error={formHasBeenSubmitted && requiredError}
          value={hangUpURL}
          onChange={handleHangUpURLChange}
          InputProps={{
            className: classes.errorMessageInput
          }}
        />

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }
        
        <Button onClick={handleCancelClick} className={classes.cancelButton} variant="contained" color="primary">
          CANCEL
        </Button>
        <Button disabled={loadingSpinner} onClick={handleSaveButtonClick} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
