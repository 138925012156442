import React, { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import styles from './PopupHeaderDelete.module.css';
import { PopupHeaderDeleteStyles } from './PopupHeaderDeleteStyles';
import { IconButton, Typography } from '@material-ui/core';

interface Props {
  closeFunction: any,
  headerText: string,
  disableBoolean: boolean
}

export default function PopupHeaderDelete(props: Props): ReactElement {

  const handleCloseIconClick = () => {
    props.closeFunction();
  }
  
  const classes = PopupHeaderDeleteStyles();
  
  return (
    <div className={styles.deleteHeader}>
      <IconButton
        className={classes.closeIconButton}
        onClick={handleCloseIconClick}
        disabled={props.disableBoolean}
      >
        <CloseIcon />
      </IconButton>

      <Typography variant="h2" className={classes.deleteHeader}>
        {props.headerText}
      </Typography>
    </div>
  )
}
