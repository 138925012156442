import { makeStyles, createStyles } from '@material-ui/core/styles';

const SendEmailPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    minHeight: 373,
    width: 730,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarWidth: "thin",
    height: "auto",
    "&::-webkit-scrollbar-track": {
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.425)",
      borderRadius: 15,
    },
    "&::-webkit-scrollbar": {
      width: "0.4em"
    },
  },
  popup: {
    height: "auto",
    minHeight: 373,
    width: "100%",
  },
  sendEmailHeaderHolder: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 165,
    width: "100%",
    backgroundColor: "#EFEFEF"
  },
  sendEmailHeader: {
    position: "relative",
    fontSize: "1rem",
    marginLeft: 30,
    top: 50,
  },
  sendEmailName: {
    position: "relative",
    fontSize: "1.5rem",
    marginLeft: 30,
    top: 57,
    fontWeight: 400,
  },
  sendEmailTabs: {
    position: "absolute",
    bottom: 0,
    left: 21,
  },
  cancelButton: {
    width: 95,
    height: 44,
    position: "absolute",
    right: 145,
    top: 67,
  },
  sendButton: {
    width: 95,
    height: 44,
    position: "absolute",
    right: 34,
    top: 67,
    backgroundColor: "green",
  },
  checkbox: {
    position: "relative",
    left: 57,
  },
  attendeeSearch: {
    position: "absolute",
    width: 262,
    left: 59,
    top: 52,
    height: 39,
    zIndex: 9999
  },
 
})
)

export { SendEmailPopupStyles };