import { Dialog, Typography, ClickAwayListener, Button } from '@material-ui/core';
import React, { ReactElement, useContext } from 'react';
import { ChromePicker } from 'react-color';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { useState } from 'react';

import styles from './EditColorPopup.module.css';
import { EditColorPopupStyles } from './EditColorPopupStyles';
import { CircularProgress } from '@material-ui/core';
import { AppContext } from '../../../../context/AppContext';
import PopupHeader from '../../../PopupHeader/PopupHeader';

interface Props {
  uploadTitle: string,
  uploadProperty: string,
  uploadDivision: string,
  uploadType: string,
  handlePopupClose: () => any,
}

export default function EditColorPopup(props: Props): ReactElement {

  const {
    selectedSpace,
    setSelectedSpace
  } = useContext(AppContext);

  const [ editColor, toggleEditColor ] = useState(false);
  
  const [color, setColor] = useState(
    selectedSpace[props.uploadDivision][props.uploadProperty].value !== undefined
      ? selectedSpace[props.uploadDivision][props.uploadProperty].value
      : "#000000"
    );

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ error, toggleError ] = useState(false);

  const handleEditColorToggle = () => {
    toggleEditColor(!editColor);
  }

  const handlePickerClose = () => {
    toggleEditColor(false);
  }

  const handleColorChange = (e) => {
    setColor(e.hex);
  }

  const handleCancelClick = () => {
    if (loadingSpinner) {
      return;
    }

    toggleError(false);
    props.handlePopupClose();
  }

  const handleSaveClick = () => {
    toggleLoadingSpinner(true);

    let selectedSpaceClone = selectedSpace;

    selectedSpaceClone[props.uploadDivision][props.uploadProperty].value = color;

    //Assemble correct axios endpoint based on which skin we are editing
    let axiosBaseURL;
    switch(props.uploadDivision) {
      case "worldSkin":
        axiosBaseURL = "/skin/world";
        break;
      case "avatarSkin":
        axiosBaseURL = "/skin/avatar";
        break;
      case "emailSkin":
        axiosBaseURL = "/skin/email";
        break;
      case "uiSkin":
        axiosBaseURL = "/skin/ui";
        break;
    }

    axios.put(axiosBaseURL, {
      spaceID: selectedSpace.spaceID,
      property: props.uploadProperty,
      value: color
    })
    .then(response => {
      toggleLoadingSpinner(false);
      setSelectedSpace(selectedSpaceClone);
      props.handlePopupClose();
    })
    .catch(err => {
      toggleLoadingSpinner(false);
      console.log(err);
    })
  }

  const classes = EditColorPopupStyles();
  
  return (
    <Dialog
      open={true}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.editHeader}>
          Edit {props.uploadTitle}
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred editing this color
          </Typography>
        }

        <div className={styles.editHolder}>

          <Typography variant="body1" className={classes.colorHeader}>
            {props.uploadTitle}
          </Typography>

          <Typography variant="body1" className={classes.colorLabel}>
            {color}
          </Typography>

          <div style={{ backgroundColor: color }} className={styles.colorHolder}>
            <div className={styles.editIconHolder}>
              <EditIcon onClick={handleEditColorToggle} className={classes.editIcon} />
            </div>

            {editColor &&
              <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handlePickerClose}>
                <ChromePicker
                  className={styles.colorPicker}
                  color={color}
                  onChange={handleColorChange}
                  value={color}
                />
              </ClickAwayListener>
            }

          </div>

        </div>

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button onClick={handleCancelClick} className={classes.cancelButton} variant="text">
          CANCEL
        </Button>
        <Button disabled={loadingSpinner} onClick={handleSaveClick} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>
        
      </div>

    </Dialog>
  )
}
