import React, { ReactElement, useContext, useState } from 'react';
import { Button, ClickAwayListener, FormControl, FormControlLabel, RadioGroup, Radio, Typography, CircularProgress, IconButton } from '@material-ui/core';
import axios from 'axios';
import { ChromePicker } from 'react-color';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import WebAssetIcon from '@material-ui/icons/WebAsset';

import { AppContext, AppContextType } from '../../../context/AppContext';

import styles from './WebTemplateSkinnableItem.module.css';
import { WebTemplateSkinnableItemStyles } from './WebTemplateSkinnableItemStyles';

interface Props {
  type: string,
  value: string,
  label: string,
  property: string,
  division: string,
  dimensions: any,
  assetFamily: string,
  handleShowUploadPopup: (label: string, type: string, property: string, assetFamily: any, dimensions: {}) => any,
  handleShowPreviewPopup: (previewObj : PreviewObject) => any,
  index: number,
}

interface PreviewObject {
  title: string,
  property: string,
  value: string,
  type: string,
  division: string,
}

export default function WebTemplateSkinnableItem(props: Props): ReactElement {

  const { 
    selectedSpace, 
    setSelectedSpace, 
    selectedSpaceID 
  } : AppContextType = useContext(AppContext);

  const [ webTheme, setWebTheme ] = useState(selectedSpace.uiSkin.theme.value);

  const [ highlightColor, setHighlightColor ] = useState(selectedSpace.uiSkin.primaryColor.value);
  const [ persistedHighlightColor, setPersistedHighlightColor ] = useState(selectedSpace.uiSkin.primaryColor.value);
  const [ editHighlightColor, toggleEditHighlightColor ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ highlightColorLoadingSpinner, toggleHighlightColorLoadingSpinner ] = useState(false);

  /* Web theme */
  const handleWebThemeChange = (e) => {

    toggleLoadingSpinner(true);

    let newThemeSelection = e.target.value;

    let selectedSpaceClone = selectedSpace;

    selectedSpaceClone.uiSkin.theme.value = newThemeSelection;

    axios.put("/skin/ui", {
      spaceID: selectedSpaceID,
      property: "theme",
      value: newThemeSelection
    })
    .then(function(response) {
      toggleLoadingSpinner(false);
      setWebTheme(newThemeSelection);
      setSelectedSpace(selectedSpaceClone);
    })
    .catch(function(error) {
      toggleLoadingSpinner(false);
      alert("An error occurred changing the theme for this space")
    })
  }
  /**/

  /* Highlight color */
  const handleEditHighlightColorToggle = () => {
    toggleEditHighlightColor(!editHighlightColor);
  }

  const handleSketchPickerClose = () => {

    if (highlightColor === persistedHighlightColor) {
      toggleEditHighlightColor(false);
      return;
    }

    let selectedSpaceClone = selectedSpace;

    selectedSpaceClone.uiSkin.primaryColor.value = highlightColor;

    toggleHighlightColorLoadingSpinner(true);

    axios.put("/skin/ui", {
      spaceID: selectedSpaceID,
      property: "primaryColor",
      value: highlightColor
    })
    .then(function(response) {
      toggleHighlightColorLoadingSpinner(false);
      setPersistedHighlightColor(highlightColor);
      setSelectedSpace(selectedSpaceClone);
    })
    .catch(function(error) {
      toggleHighlightColorLoadingSpinner(false);
      alert("An error occured changing the primary color for this space");
    })

    toggleEditHighlightColor(false);
  }

  const handleHighlightColorChange = (e) => {

    setHighlightColor(e.hex);
  }
  /**/

  /* Upload asset */
  const handleUploadClick = () => {
    props.handleShowUploadPopup(props.label, props.type, props.property, props.assetFamily, props.dimensions)
  }
  /**/

  /* Preview asset */
  const handlePreviewClick = () => {

    //Assemble preview object
    let previewObj : PreviewObject = {
      title: props.label,
      property: props.property,
      value: props.value,
      division: props.division,
      type: props.type
    }

    props.handleShowPreviewPopup(previewObj)
  }
  /**/

  const classes = WebTemplateSkinnableItemStyles();
  
  if (props.property === "theme") {
    return (
      <div 
        className={styles.webThemeHolder}
        style={{ backgroundColor: (props.index + 1) % 2 === 0
          ? "#FBFBFB"
          : "white"
        }}
      >

        <Typography variant="body1" className={classes.webThemeHeader}>
          Web Theme
        </Typography>

        <FormControl className={classes.themeFormHolder} component="fieldset">
          <RadioGroup value={webTheme} aria-label="web theme" onChange={handleWebThemeChange}name="web theme">
            <FormControlLabel classes={{label: classes.radioLabel}} value="dark" control={<Radio color="secondary" />} label="Dark Theme" />
            <FormControlLabel classes={{label: `${classes.radioLabel} ${classes.radioLabelLight}`}} value="light" control={<Radio color="secondary" style={{ position: "relative", bottom: "5px" }}/>} label="Light Theme" />
          </RadioGroup>
        </FormControl>

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }
        
      </div>
    )
  } else if (props.property === "primaryColor") {
    return (
      <div 
        className={styles.highlightColorHolder}
        style={{
          backgroundColor: (props.index + 1) % 2 === 0
            ? "#FBFBFB"
            : "white"
        }}
      >
        <Typography variant="body1" className={classes.highlightColorHeader}>
          Hightlight Color
        </Typography>

        <Typography variant="body1" className={classes.highlightColor}>
          {persistedHighlightColor}
        </Typography>

        <div style={{ backgroundColor: persistedHighlightColor }} className={styles.colorHolder}>
          <div className={styles.editIconHolder}>
            <EditIcon onClick={handleEditHighlightColorToggle} className={classes.editIcon} />
          </div>

          {editHighlightColor &&
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleSketchPickerClose}>
              <ChromePicker 
                className={styles.colorPicker} 
                color={highlightColor}
                onChange={handleHighlightColorChange}
                value={highlightColor}
              />
            </ClickAwayListener>
          }

        </div>

        {highlightColorLoadingSpinner &&
          <CircularProgress className={classes.highlightColorLoadingSpinner} />
        }
      </div>
    )
  } else if (props.property === "landingPageAsset" || props.property === "thumbnailLogo") {
    return (
      <div 
        className={styles.itemHolder}
        style={{
          backgroundColor: (props.index + 1) % 2 === 0
            ? "#FBFBFB"
            : "white"
        }}
      >
        <Typography variant="body1" className={classes.itemHeader}>
          {props.label}
        </Typography>

        <IconButton 
          onClick={handlePreviewClick} 
          className={classes.webAssetIconButton}
        >
          <img src="assets/images/PreviewIcon.svg" alt="Preview icon" />
        </IconButton>


        <IconButton 
          onClick={handleUploadClick} 
          className={classes.uploadButton} 
        >
          <img className={styles.uploadIcon} src="assets/images/UploadIcon.svg" alt="upload icon" />
        </IconButton>
      </div>
    )
  } 
}
