import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { CircularProgress, Typography } from '@material-ui/core';

import { AppContext, AppContextType } from '../../../context/AppContext';
import BookingItem from '../BookingItem/BookingItem';

import { BookingItemsDisplayStyles } from './BookingItemsDisplayStyles';
import styles from './BookingItemsDisplay.module.css';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { orderBy } from 'natural-orderby';
import { JWTHelper } from '../../../utilities/JWTHelper';

interface Props {
  
}

export default function BookingItemsDisplay(props: Props): ReactElement {

  const {
    selectedSpaceID,
    setSelectedBooking,
    selectedSpace,
    selectedProject,
    selectedBooking
  } : AppContextType = useContext(AppContext);

  const [ spaceBookings, setSpaceBookings ] = useState([]);
  const [ isLoading, toggleIsLoading ] = useState(true);

  const [ noBookings, toggleNoBookings ] = useState(false);
  const [ error, setError ] = useState("");

  const [ isLoadingEdit, toggleIsLoadingEdit ] = useState(false);
  
  const classes = BookingItemsDisplayStyles();

  const firstRender = useRef(true);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();


  const getAllBookings = async () => {

    //Don't get bookings initially if we are coming from a booking link
    if (sessionStorage.getItem("bookingLink") === "true") {
      toggleIsLoading(false);
      sessionStorage.removeItem("bookingLink");
      sessionStorage.removeItem("linkBookingID");
      firstRender.current = false;
      return;
    }
 
    if (selectedSpaceID === undefined) return

    if (selectedSpaceID === "") return;

    setSpaceBookings([]);
    setError("");
    toggleIsLoading(true);
    try {
      //Get bookings for a space
      let response = await axios.get('/space/bookings/all', {
        params: {
          spaceID: selectedSpaceID,
        },
        cancelToken: source.token,
      })

      toggleIsLoading(false);
      setSpaceBookings(response.data);
      toggleNoBookings(false);
    }
    catch (error) {
      console.log("ERROR", error);

      if (axios.isCancel(error)) {
        return;
      }

      toggleIsLoading(false);


      if (error.response && error.response.status === 404) {
        toggleNoBookings(true);
      } else {
        setError("Error getting bookings for this space")
      }
    }
  }

  useEffect(() => {

    var poolData = {
      UserPoolId: 'us-east-1_N15Q0NLkm',
      ClientId: '2332rbhi35f5016dglri2mojo'
    };

    const userPool = new CognitoUserPool(poolData);

    let currentUser = userPool.getCurrentUser();

    if (currentUser) {
      currentUser.getSession((err, session) => {
        if (err) {
          console.log("ERROR", err);
        } else {
  
          axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();
          
          //Get bookings for this space
          getAllBookings();
         
        }
      });
    } else {
      return;
    }

    return function cleanup() {
      source.cancel("Component was unmounted")
    }
    
  }, []);

  useEffect(() => {

    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    
    //Re-get bookings when selected space changes
    getAllBookings();
  }, [selectedSpaceID, selectedBooking])

  //handle edit button click
  const onEditButtonClick = (booking : SHOWBOAT.Booking) => {

    //Create JWT for selected booking and space
    JWTHelper.createJWT(selectedSpaceID, booking.bookingID);
    
    //Set booking to edit as selected booking
    setSelectedBooking(booking);

  }

  //Add each item in spaceBookings to a new array
  let bookingItemsArray = [];

  for (let i = 0; i < spaceBookings.length; i++) {
    bookingItemsArray.push(spaceBookings[i]);
  }

  //Sort space bookings so that most recent booking is at the top
  let bookingItemsArraySorted = orderBy(
    bookingItemsArray,
    [b => new Date(b.start.time).getTime()],
    ['desc']
  );

  let bookingItemsComponentArray = [];

  //Push component from each booking object on space into components array
  for (let i = 0; i < bookingItemsArraySorted.length; i++) {
    
    bookingItemsComponentArray.push(
      <BookingItem
        isLoading={isLoadingEdit}
        name={bookingItemsArraySorted[i].name}
        start={bookingItemsArraySorted[i].start}
        end={bookingItemsArraySorted[i].end}
        registrationCount={
          bookingItemsArraySorted[i].registrationCount !== undefined 
          ? bookingItemsArraySorted[i].registrationCount
          : 0
        }
        bookingID={bookingItemsArraySorted[i].bookingID}
        onEditClick={onEditButtonClick}
        key={bookingItemsArraySorted[i].bookingID}
        booking={bookingItemsArraySorted[i]}
        index={i}
      />
    )
    
  }
  
  return (
    <React.Fragment>
      <div className={styles.bookingsHeaderHolder}>
      <Typography variant="h2" classes={{ root: classes.nameHeader}}>
        Name
      </Typography>
      <Typography variant="h2" classes={{ root: classes.startHeader}}>
        Start
      </Typography>
      <Typography variant="h2" classes={{ root: classes.endHeader}}>
        End
      </Typography>
      <Typography variant="h2" classes={{ root: classes.registrationCountHeader}}>
        Reg Count
      </Typography>
      </div>
      <div 
        className={styles.bookingsHolder}
        style={{
          minHeight: (isLoading || noBookings || (error !== "")) ? "200px" : "0px"
        }}
      >

        {error !== "" &&
          <Typography variant="body1" className={classes.error}>
            {error}
          </Typography>
        }

        {(!isLoading && noBookings) &&
          <Typography variant="body1" className={classes.noBookingsError}>
            No bookings found for this space
          </Typography>
        }

        {isLoading &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        {!isLoading &&
          <React.Fragment>
            {
              [bookingItemsComponentArray].map(item => {
                return (item);
              })
            }
          </React.Fragment>
        }
              
      </div>  
    </React.Fragment>
    
  )
}
