import { Divider, FormControl, InputLabel, Paper, Select, Typography, MenuItem, Button, List, ListItem, ListItemIcon, Checkbox, ListItemText, CircularProgress } from '@material-ui/core';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import axios from 'axios';
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import { AuthContext, AuthContextType } from '../../../context/AuthContext';

import styles from './ProducerAdminEdit.module.css';
import { ProducerAdminEditStyles } from './ProducerAdminEditStyles';

interface Props {
}

function not(a: any[], b: any[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: any[], b: any[]) {
  return a.filter((value) => b.indexOf(value.index) !== -1);
}

export default function ProducerAdminEdit(props: Props): ReactElement {

  const { idToken, setIdToken } : AuthContextType = useContext(AuthContext);

  const [ producersLoadingSpinner, toggleProducersLoadingSpinner ] = useState(false);

  const [ producers, setProducers ] = useState([]);

  const [ projects, setProjects ] = useState([]);
  
  const [ selectedProducerID, setSelectedProducerID ] = useState("");
  
  const [ isAuthenticated, toggleIsAuthenticated ] = useState(false);

  const [ producerError, toggleProducerError ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ producerPutComplete, toggleProducerPutComplete ] = useState(false);

  //Transfer list state
  const [ checked, setChecked ] = useState([]);
  const [ left, setLeft ] = useState([]);
  const [ right, setRight ] = useState([]);

  const [ error, toggleError ] = useState(false);

  let leftIndexes = left.map(item => {
    return item.index;
  });

  let rightIndexes = right.map(item => {
    return item.index;
  });

  const leftChecked = intersection(checked, leftIndexes);
  const rightChecked = intersection(checked, rightIndexes);
  
  useEffect(() => {
    //Initial authentication check
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {

      //Redirect to sign-in if no producerID in sessionStorage   
      alert("You do not have privileges to access this page.");
      window.location.replace("/");

    } else {

      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: 'us-east-1_N15Q0NLkm',
        ClientId: '2332rbhi35f5016dglri2mojo'
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {

            axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage
            axios.get("/producer/role", {
              params: {
                producerID: localStorage.getItem("producerID")
              }
            })
            .then(function(response) {

              //Get the role on the producer object
              if (response.data.role === 0) {

                toggleIsAuthenticated(true);

                //Show producers loading spinner
                toggleProducersLoadingSpinner(true);

                //Get all producers 
                axios.get("/producers/all/admin", {
                  headers: {
                    Authorization: session.getIdToken().getJwtToken()
                  }
                })
                .then(function(response) {

                  //Set state
                  setProducers(response.data);

                  //Get all projects
                  axios.get("/projects/all/admin", {
                    headers: {
                      Authorization: session.getIdToken().getJwtToken()
                    }
                  })
                  .then(function(response) {

                    //Add index to each project
                    let projectsResponseArray = response.data;
                    let projectsWithIndex = [];

                    for (let i = 0; i < projectsResponseArray.length; i++) {
                      let project = projectsResponseArray[i];
                      project.index = i;
                      projectsWithIndex.push(project);
                    }

                    setProjects(projectsWithIndex);

                    toggleProducersLoadingSpinner(false);

                  })
                  .catch(function(error) {
                    toggleProducersLoadingSpinner(false);

                  })
                })
                .catch(function(error) {
                  toggleLoadingSpinner(false);
                });

              } else {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              };

            })
            .catch(function(error) {
              alert("You do not have privileges to access this page.");
              window.location.replace("/");
            })
          }
        })
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, [])

  //Select producer
  const handleProducerSelect = (e) => {
    //TODO: TODO: TODO:
    // disable until async completed
    // Cannot allow producer select until projects are fetched
    
    toggleProducerError(false)

    setSelectedProducerID(e.target.value);

    let selectedProducerObj = producers.find(producer => producer.producerID === e.target.value);

    let selectedProducerProjects = selectedProducerObj.projects;
    let selectedProducerProjectsIDArray = [];

    for (var item in selectedProducerObj.projects) {
      selectedProducerProjectsIDArray.push(selectedProducerObj.projects[item].projectID)
    };

    //Populate project transfer list
    let projectsArray = projects;

    //Put assigned projects on right list, non-assigned projects on left list
    let leftListArray = [];
    let rightListArray = [];
    
    for (let i = 0; i < projectsArray.length; i++) {

      //If projectID is in selectedProducerProjectsIDArray, put in right list
      if (selectedProducerProjectsIDArray.includes(projectsArray[i].projectID)) {
        rightListArray.push(projectsArray[i]);
      } else {

        //Otherwise, add to left list
        leftListArray.push(projectsArray[i]);
      };

    }

    setLeft(leftListArray);
    setRight(rightListArray);

  }

  /* Transfer list functions */
  const handleToggle = (value: any) => {
    let checkedIndexes = [];
    for (let i = 0; i < checked.length; i++) {
      checkedIndexes.push(checked[i].index);
    }

    const currentIndex = checkedIndexes.indexOf(value.index);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: any[]) => (
    <Paper className={classes.listHolder}>
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `transfer-list-item-${value.index}-label`;

          return (
            <ListItem key={value.index} onClick={() => handleToggle(value)} role="listitem" button>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.projectName} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  )
  /**/
  
  //Save
  const handleSaveClick = () => {

    toggleError(false);
    
    let formHasError = false;
    
    if (selectedProducerID === "") {
      toggleProducerError(true);
      formHasError = true;
    } else {
      toggleProducerError(false);
    }

    if (formHasError) {
      return;
    }

    toggleLoadingSpinner(true);
    
    //Assemble projects obj via each item in right list
    let selectedProjectsObj = {};

    for (let i = 0; i < right.length; i++) {
      selectedProjectsObj[`${right[i].projectID}`] = {
        projectName: right[i].projectName,
        projectID: right[i].projectID
      }
    }

    //Execute put operation with projects
    let putObj = {
      producerID: selectedProducerID,
      projects: selectedProjectsObj
    };

    axios.put("/producer", JSON.stringify(putObj), {
      headers: {
        Authorization: idToken
      }
    })
    .then(function(response) {
      toggleLoadingSpinner(false);
      toggleProducerPutComplete(true);
    })
    .catch(function(error) {
      toggleLoadingSpinner(false);
      toggleError(true);
    })
  }

  //Back
  const handleBackClick = () => {
    window.location.reload();
  }
  
  const classes = ProducerAdminEditStyles();
  
  if (isAuthenticated) {
    return (
      <div className={styles.producerAdminWrapper}>
        <Paper className={classes.producerAdminWrapper}>

          <Typography variant="h1" className={classes.createProducerHeader}>
            Edit a Producer
          </Typography>

          <Divider className={classes.producerDivider}></Divider>

          {producerPutComplete 
          ? (
            <Typography variant="body1" className={classes.success}>
              Success
            </Typography>
          )
          : (
            <React.Fragment>
              
              <div className={styles.editProducerFormHolder}>

                {error &&
                  <Typography variant="body1" className={classes.errorMessage}>
                    An error occurred editing this producer.
                  </Typography>
                }
                
                <Typography variant="h2" className={classes.producerNameHeader}>
                  Choose Producer
                </Typography>

                <FormControl 
                  classes={{ root: classes.producerSelectHolder}}
                  variant="outlined"
                  error={producerError}
                  disabled={producersLoadingSpinner}
                >
                  <InputLabel>Choose Producer</InputLabel>
                  <Select
                    label="Choose producer"
                    classes={{ selectMenu: classes.producerMenu }}
                    value={selectedProducerID}
                    onChange={handleProducerSelect}
                    MenuProps={{ classes: { paper: classes.producerMenuPaper }}}
                  >
                    {
                    producers.map(producer => {
                      return (
                        <MenuItem 
                          key={producer.producerID} 
                          value={producer.producerID}
                          classes={{ 
                            root: classes.menuItem,
                            selected: classes.menuItemSelected
                          }}
                        >
                          {producer.name}
                        </MenuItem>
                      )
                    })
                  }
                  </Select>
                </FormControl>

                {producersLoadingSpinner &&
                  <CircularProgress className={classes.producerLoadingSpinner} />
                }

                <Typography variant="h2" className={classes.assignProjectsHeader}>
                  Assign Projects
                </Typography>

                <div className={styles.projectTransferList}>
                  <div className={styles.leftListHolder}>
                    {customList(left)}
                  </div>
                  <div className={styles.rightListHolder}>
                    {customList(right)}
                  </div>
                  <div className={styles.listButtonsHolder}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ≫
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ≪
                    </Button>
                  </div>


                </div>


              </div>

              {loadingSpinner &&
                <CircularProgress className={classes.loadingSpinner}></CircularProgress>
              }

            </React.Fragment>
          )}
          
          {producerPutComplete 
          ? (
            <Button 
              disabled={loadingSpinner} 
              className={classes.saveButton} 
              variant="contained" 
              color="primary" 
              onClick={handleBackClick}
            >
                BACK
            </Button>
          )
          : (
            <Button 
              disabled={loadingSpinner} 
              className={classes.saveButton} 
              variant="contained" 
              color="primary" 
              onClick={handleSaveClick}
              >
                SAVE
            </Button>
          )}

        </Paper>
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}
