const DefaultRegistrationConfirmation = `
<~~~WELCOME HEADER~~~>You're Registered!<~~~END WELCOME HEADER~~~>

Hi {~~~FIRSTNAME~~~},

Thanks for registering for our virtual event! Click the blue button to add this event to your calendar! See you there!

<~~~ADD TO CALENDAR LINK~~~>

<~~~EVENT DETAILS~~~>
`

export default DefaultRegistrationConfirmation;