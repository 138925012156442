import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeResendInvitePopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 730,
    height: 218,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 218,
    position: "absolute"
  },
  resendInviteHeader: {
    fontSize: 18,
    position: "absolute",
    top: 61,
    left: 30,
  },
  resendInviteName: {
    fontSize: 24,
    position: "absolute",
    top: 92,
    left: 30
  },
  cancelButton: {
    width: 95,
    height: 44,
    position: "absolute",
    right: 141,
    bottom: 30,
  },
  sendButton: {
    width: 95,
    height: 44,
    position: "absolute",
    right: 30,
    bottom: 30,
    backgroundColor: "#009617"
  },
  loadingSpinner: { 
    color: "grey",
    position: "absolute",
    bottom: 84,
    right: 57,
  },
  closeButton: {
    position: "absolute",
    top: 3,
    color: "white",
    padding: 4,
    right: 5,
  }
})
)

export { AttendeeResendInvitePopupStyles }; 