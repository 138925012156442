import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { CircularProgress, Button, Paper, Typography } from '@material-ui/core';
import axios from 'axios';
import { orderBy } from 'natural-orderby';
import AddIcon from '@material-ui/icons/Add';

import BookingPresenter from './BookingPresenter/BookingPresenter';
import AddPresenterPopup from './AddPresenterPopup/AddPresenterPopup';
import { AppContext, AppContextType } from '../../../context/AppContext';

import styles from './BookingPresenters.module.css';
import {BookingPresentersStyles} from './BookingPresentersStyles';
import AddAttendeePopup from '../BookingAttendees/AddAttendeePopup/AddAttendeePopup';

interface Props {
  
}

export default function BookingPresenters(props: Props): ReactElement {

  const [ addPresenter, toggleAddPresenter ] = useState(false);
  const [ bookingPresenters, setBookingPresenters ] = useState([]);
  const [ isLoading, toggleIsLoading ] = useState(true);
  const [ error, setError ] = useState("");
  const [ noPresenters, toggleNoPresenters ] = useState(false);

  const { 
    selectedBookingID, 
    selectedPresenter 
  } : AppContextType = useContext(AppContext);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {

    //Get presenters for a booking
    getAllPresenters();

    return function cleanup() {
      source.cancel("Component was unmounted");
    }
  }, []);

  const getAllPresenters = async () => {
    try {
      let response = await axios.get('/presenters/all', {
        params: {
          bookingID: selectedBookingID,
        },
        cancelToken: source.token,
      });

      setBookingPresenters(response.data);
      toggleIsLoading(false);

    }
    catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      toggleIsLoading(false);

      if (error.response && error.response.status === 404) {
        setBookingPresenters([]);
        toggleNoPresenters(true);
      } else {
        setError("Error getting presenters for this booking");
      }
    }
  }
  
  //Re-get presenters on delete
  useEffect(() => {
    
    getAllPresenters();
   
  }, [selectedPresenter])

  /*Add presenter popup */
  const handleAddPresenterClick = () => {
    toggleAddPresenter(true);
  }

  const handleAddPresenterClose = () => {
    toggleAddPresenter(false);
  }

  const handleAddPresenter = (newPresenter) => {
    toggleNoPresenters(false);
    
    let bookingPresentersClone = [...bookingPresenters];

    bookingPresentersClone.push(newPresenter);

    setBookingPresenters(bookingPresentersClone);

    toggleAddPresenter(false);
  }
  /**/

  /*Edit presenter */
  const handleEditPresenter = (newPresenter) => {

    let bookingPresentersClone = [...bookingPresenters];

    let updatedElementIndex;

    bookingPresenters.forEach((presenter, i) => {
      if (bookingPresenters[i].registrationID === newPresenter.registrationID) {
        updatedElementIndex = i;
      }
    });

    bookingPresentersClone[updatedElementIndex] = newPresenter;

    setBookingPresenters(bookingPresentersClone);
   
  }
  /**/

  //Order booking items by name
  let bookingPresentersOrdered = orderBy(
    bookingPresenters,
    [p => p.registrationData.lastName, p => p.registrationData.firstName],
    ['asc', 'asc']
  );

  let presentersArray = [];

  bookingPresentersOrdered.forEach(presenter => {
    presentersArray.push(
      <BookingPresenter
        presenter={presenter}
        handleEditPresenter={handleEditPresenter}
        key={presenter.registrationID}
      />
    )
  });

  //Material ui classes
  const classes = BookingPresentersStyles();
  
  return (
    <React.Fragment>

      <Button
        className={classes.newButton}
        onClick={handleAddPresenterClick}
        variant="contained"
      >
        <AddIcon className={classes.addIcon}></AddIcon>
        NEW
      </Button>

      <Paper square elevation={3} className={classes.presentersHeaderHolder}>
        <Typography variant="h1" className={classes.nameHeader}>
          Name
        </Typography>
        <Typography variant="h1" className={classes.companyHeader}>
          Company
        </Typography>
        <Typography variant="h1" className={classes.emailHeader}>
          Email
        </Typography>
        <Typography variant="h1" className={classes.brandHeader}>
          Brand
        </Typography>
      </Paper>

      <div 
        className={styles.presenterHolder}
        style={{
          minHeight: (isLoading || (error !== "") || noPresenters) ? "190px" : "48px"
        }}
      >

        {error !== "" &&
          <Typography variant="body1" className={classes.error}>
            {error}
          </Typography>
        }

        {noPresenters &&
          <Typography variant="body1" className={classes.noPresentersError}>
            No presenters found for this booking
          </Typography>
        }

        {isLoading &&
          <CircularProgress className={classes.loadingSpinner} />
        }
        {
          [presentersArray].map(item => {
            return (item);
          })
        }
      </div>
      
      {addPresenter &&
        <AddAttendeePopup 
          handleAttendeeAdd={handleAddPresenter} 
          open={addPresenter} 
          isPresenter={true}
          handlePopupClose={handleAddPresenterClose} 
        />
      }

    </React.Fragment>
  )
}
