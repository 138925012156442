import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const UsageAdminStyles = makeStyles((theme: any) => createStyles({
  usageAdminWrapper: {
    position: "absolute",
    display: "inline-block",
    width: 1050,
    height: 900,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  adminMenuButton: {
    position: "absolute",
    left: 15,
    top: 15,
  },
  usageRepairHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
  usageDivider: {
    width: "90%",
    position: "relative",
    top: 25,
    display: "inline-block"
  },
  bookingHeader: {
    position: "relative",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
  },
  bookingIDTextField: {
    position: "absolute",
    left: 0,
    top: 40,
    width: 300,
  },
  submitButton: {
    position: "absolute",
    top: 50,
    left: 320,
  },
  loadingSpinner: {
    width: "30px !important",
    height: "30px !important",
    position: "absolute",
    top: 53,
    left: 430,
  },
  error: {
    fontSize: 14,
    color: "red",
    position: "relative",
    bottom: 10,
  },
  success: {
    fontSize: 18,
    fontWeight: 400,
    display: "inline-block",
    position: "absolute",
    top: 150,
    left: 0,
    right: 0,
    center: 0,
    margin: "auto"
  },
  repairedCount: {
    fontSize: 18,
    fontWeight: 400,
    display: "inline-block",
    position: "absolute",
    top: 180,
    left: 0,
    right: 0,
    center: 0,
    margin: "auto"
  },
  attendeeHeader: {
    top: 150,
    position: "absolute",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
  },
  attendeeSelectHeaderHolder: {
    position: "absolute",
    width: 500,
    top: 190,
    height: 50,
    backgroundColor: "#E5E5E5"
  },
  attendeeNameHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    top: 15,
    left: 15,
  },
  attendeeEnterHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    top: 15,
    left: 200,
  },
  attendeeExitHeader: {
    fontSize: 14,
    fontWeight: "bold",
    top: 15,
    position: "absolute",
    left: 350
  },
  dateHeader: {
    top: 150,
    left: 550,
    position: "absolute",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
  },
  dateSubheader: {
    top: 180,
    left: 550,
    position: "absolute",
    fontSize: 15,
    float: "left",
    fontWeight: 400,
  },
  dateTimePicker: {
    position: "absolute",
    top: 210,
    left: 550,
  },
  overallSubmitButton: {
    position: "absolute",
    bottom: 150,
    right: 50,
  },
  overallLoadingSpinner: {
    position: "absolute",
    bottom: 155,
    right: 150,
    width: "25px !important",
    height: "25px !important"
  },
  backButton: {
    position: "absolute",
    bottom: 150,
    right: 50,
  }
})
)

export { UsageAdminStyles };