import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingEditStyles = makeStyles((theme: any) => createStyles({
  doneButton: {
    width: 95,
    height: 44,
    position: "absolute",
    right: 30,
    top: -54,
    color: "black",
    fontSize: 20,
    fontWeight: "lighter",
  },
  doneButtonLabel: {
    fontSize: "20px !important",
  },
  bookingNameInput: {
    position: "absolute",
    left: 30,
  },
  eventNameInput: {
    fontSize: 20,
    border: "none",
  },
  bookingNavOption: {
    fontSize: 14,
    position: "relative",
    top: 7,
    "&:hover": {
      cursor: "pointer"
    }
  },
  bookingNavOptionSelected: {
    
  },
  editIcon: {
    fontSize: 20,
    "&:hover": {
      cursor: "pointer"
    }
  },
  trashIcon: {
    fontSize: 20,
    position: "relative",
    left: 25,
    "&:hover": {
      cursor: "pointer"
    }
  },
  liveHeader: {
    position: "relative",
    top: 2,
  },
  eventNameHeader: {
    fontSize: 10,
    color: "#888888",
    position: "absolute",
    left: 30,
    top: 5,
  },
  eventName: {
    fontSize: 20,
    position: "relative",
    display: "inline-block",
    left: 30,
    top: 20,
    maxWidth: 350,
    minWidth: 100,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  bookingID: {
    fontSize: 14,
    position: "absolute",
    top: 14,
    right: 67,
    display: "inline-block",
    width: 215,
  },
  copyBookingIDButton: {
    position: "absolute",
    top: 20,
    right: 24,
    color: "black",
    padding: 4,
    fontSize: 20,
  },
  arrowBackIcon: {
    marginRight: 10,
    color: "#FF0092"
  }
})
)

export { BookingEditStyles };