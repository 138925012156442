import { Button, CircularProgress, Dialog, TextField, Typography } from '@material-ui/core';
import React, { ReactElement, useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';

import { ErrorMessageEditPopupStyles } from './ErrorMessageEditPopupStyles';
import PopupHeader from '../../../PopupHeader/PopupHeader';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
  errorMessageType: string,
}

export default function ErrorMessageEditPopup(props: Props): ReactElement {

  const { selectedBooking, setSelectedBooking } : AppContextType = useContext(AppContext);

  const [ errorMessage, setErrorMessage ] = useState("");

  const [ formHasBeenSubmitted, toggleFormHasBeenSubmitted ] = useState(false);
  const [ error, toggleError ] = useState(false);
  const [ requiredError, toggleRequiredError ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  useEffect(() => {

    //TODO: TODO:
    // -set default values
    if (props.errorMessageType === "Event") {
      setErrorMessage(selectedBooking.errorMessages.public);
    } else if (props.errorMessageType === "Sign Up") {
      setErrorMessage(selectedBooking.errorMessages.signUp);
    } else if (props.errorMessageType === "Attendee") {
      setErrorMessage(selectedBooking.errorMessages.attendee);
    } else {
      setErrorMessage(selectedBooking.errorMessages.presenter);
    }
  }, []);

  const handleErrorMessageChange = (e) => {
    
    toggleRequiredError(false);
    
    setErrorMessage(e.target.value);
  };

  const handleSaveButtonClick =  async () => {

    toggleFormHasBeenSubmitted(true);
    
    if (!errorMessage.trim().length) {
      toggleRequiredError(true);
      return;
    }

    toggleLoadingSpinner(true);

    //get correct property to edit
    let propertyToEdit;
    if (props.errorMessageType === "Event") {
      propertyToEdit = "public"
    } else if (props.errorMessageType === "Sign Up") {
      propertyToEdit = "signUp"
    } else if (props.errorMessageType === "Attendee") {
      propertyToEdit = "attendee"
    } else {
      propertyToEdit = "presenter"
    }

    let newErrorMessageObject = selectedBooking.errorMessages;
    newErrorMessageObject[propertyToEdit] = errorMessage
    
    let selectedBookingClone = selectedBooking;

    selectedBookingClone.errorMessages = newErrorMessageObject;

    try {
      await axios.put("/booking", selectedBookingClone);

      toggleLoadingSpinner(false);
      setSelectedBooking(selectedBookingClone);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
    
  }

  const handleCancelClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();
  }

  const classes = ErrorMessageEditPopupStyles();

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography className={classes.editErrorMessageHeader}>
          Edit {props.errorMessageType} Link Error Message
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred updating the error message for this link
          </Typography>
        }

        <TextField
          className={classes.errorMessageTextField}
          label=""
          helperText="Required"
          variant="outlined"
          error={formHasBeenSubmitted && requiredError}
          value={errorMessage}
          onChange={handleErrorMessageChange}
          InputProps={{
            className: classes.errorMessageInput
          }}
        />

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }
        
        <Button onClick={handleCancelClick} className={classes.cancelButton} variant="contained" color="primary">
          CANCEL
        </Button>
        <Button disabled={loadingSpinner} onClick={handleSaveButtonClick} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
