import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Typography, Checkbox } from '@material-ui/core';

import { EmailContext, EmailContextType } from '../../../../../context/EmailContext';

import styles from './SendEmailPresenter.module.css';
import { SendEmailPresenterStyles } from './SendEmailPresenterStyles';

interface Props {
  presenters: boolean,
  registration: any,
  selectAll: boolean,
  selectAllBrand: boolean,
  selectAllSentPrevious: boolean,
}

export default function SendEmailPresenter(props: Props): ReactElement {

  const { 
    selectedEmailTemplate,
    selectedPresenters, 
    setSelectedPresenters 
  } : EmailContextType = useContext(EmailContext);
  
  const [ selected, toggleSelected ] = useState(false);

  const [ sentPrevious, toggleSentPrevious ] = useState(false);

  //See if presenter has been sent this email previously
  useEffect(() => {
    if (props.registration.emails.includes(selectedEmailTemplate.emailTemplateID)) {
      toggleSentPrevious(true);
    };
  }, [])

  const handlePresenterSelect = () => {

    if (!selectedPresenters.includes(props.registration)) {
        
      setSelectedPresenters(selectedPresenters => [...selectedPresenters, props.registration]);

    } else {

      setSelectedPresenters(selectedPresenters.filter(attendee => 
        attendee.registrationID !== props.registration.registrationID
      ))

    }
  };

  useEffect(() => {

    if (selectedPresenters.includes(props.registration)) {
      toggleSelected(true);
    } else {
      toggleSelected(false);
    }
  }, [selectedPresenters]);
  
  const classes = SendEmailPresenterStyles();
  
  return (
    <div className={styles.personHolder}>

      <Checkbox
        checked={selected}
        onChange={handlePresenterSelect}
        name="select to send"
        color="primary"
        className={classes.checkbox}
      />

      <Typography variant="body1" className={classes.attendeeName}>
        {props.registration.registrationData.lastName === "" 
        ? `${props.registration.registrationData.firstName}`
        : `${props.registration.registrationData.lastName}, ${props.registration.registrationData.firstName}`
        }
      </Typography>

      {props.registration.brand &&
        <div className={styles.brandDisplay}>

        </div>
      }

      {sentPrevious &&
        <div className={styles.sentPreviousDisplay}>

        </div>
      }
      
    </div>
  )
}
