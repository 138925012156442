import { makeStyles, createStyles } from '@material-ui/core/styles';

const ErrorPageStyles = makeStyles((theme: any) => createStyles({
  errorMessageHolder: {
    display: "inline-block",
    width: 700,
    height: 200,
    textAlign: "center",
    position: "relative",
    backgroundColor: "#EFEFEF",
    top: 150,
  },
  errorMessage: {
    fontWeight: 400,
    fontSize: 16,
    color: "black",
    position: "relative",
    top: 85,
    right: 30,
    display: "inline-block",
    width: 360,
    textAlign: "left",
  },
  link: {
    fontSize: 16,
    color: "blue",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer"
    }
  }
})
)

export { ErrorPageStyles };