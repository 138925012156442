import { Typography } from '@material-ui/core';
import moment from 'moment';
import React, { ReactElement } from 'react';

import styles from './BookingsAdminItem.module.css';
import { BookingsAdminItemStyles } from './BookingsAdminItemStyles';

interface Props {
  name: string,
  start: any,
  end: any,
  spaceName: string,
}

export default function BookingsAdminItem(props: Props): ReactElement {

  const classes = BookingsAdminItemStyles();
  
  return (
    <div className={styles.bookingsItemHolder}>
      <Typography variant="body1" className={classes.bookingName}>
        {props.name}
      </Typography>

      <Typography variant="body1" className={classes.spaceName}>
        {props.spaceName}
      </Typography>

      <Typography variant="body1" className={classes.bookingStart}>
        {moment(props.start.time).format("L")}
      </Typography>

      <Typography variant="body1" className={classes.bookingStartTime}>
        {moment.tz(props.start.time, props.start.timezone).format("LT z")}
      </Typography>

      <Typography variant="body1" className={classes.bookingEnd}>
        {moment(props.end.time).format("L")}
      </Typography>
      <Typography variant="body1" className={classes.bookingEndTime}>
        {moment.tz(props.end.time, props.end.timezone).format("LT z")}
      </Typography>

    </div>
  )
}
