import { Button, CircularProgress, Divider, IconButton, Paper, Typography } from '@material-ui/core';
import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import axios from 'axios';
import DownloadIcon from '@material-ui/icons/GetApp';

import { MuiPickersUtilsProvider, Calendar, DatePicker } from "@material-ui/pickers";
import { AuthContext, AuthContextType } from '../../context/AuthContext';

import styles from './SystemCheckAdmin.module.css';
import { SystemCheckAdminStyles } from './SystemCheckAdminStyles';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { SystemCheckAdminItemStyles } from './SystemCheckAdminItem/SystemCheckAdminItemStyles';
import SystemCheckAdminItem from './SystemCheckAdminItem/SystemCheckAdminItem';

interface Props {
  
}

export default function SystemCheckAdmin(props: Props): ReactElement {

  const history = useHistory();
  
  const { idToken, setIdToken } : AuthContextType = useContext(AuthContext);
  
  const [ selectedDate, setSelectedDate ] = useState(null);
  
  const [ systemCheckLoadingSpinner, toggleSystemCheckLoadingSpinner ] = useState(false);

  const [ systemChecksForDay, setSystemChecksForDay ] = useState([]);

  const [ getSystemChecksError, toggleGetSystemChecksError ] = useState(false);

  const [ isAuthenticated, toggleIsAuthenticated ] = useState(false);

  useEffect(() => {

    toggleSystemCheckLoadingSpinner(true);

    //Refresh credentials, as we are in a new route
    if (localStorage.getItem("producerID") == undefined) {

      //Redirect to sign-in if no producerID in sessionStorage   
      alert("You do not have privileges to access this page.");
      window.location.replace("/");

    } else {

      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: 'us-east-1_N15Q0NLkm',
        ClientId: '2332rbhi35f5016dglri2mojo'
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession(async (err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {

            axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Get System Checks for current day
            getSystemChecksForDay(new Date());

            toggleIsAuthenticated(true);
           
          }
        })
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, [])
  
  //Get system checks for selected day
  const getSystemChecksForDay = async (date) => {
    
    //Assemble date string
    let dateString;

    let selectedDateCentralTime = moment.tz(date, "America/Chicago").format();
    let selectedDateSplitAtT = selectedDateCentralTime.split('T');

    dateString = selectedDateSplitAtT[0];

    toggleGetSystemChecksError(false);

    try {

      toggleSystemCheckLoadingSpinner(true);

      let response = await axios.get("/system-check", {
        params: {
          dateString: dateString,
        },
      });

      toggleSystemCheckLoadingSpinner(false);

      setSystemChecksForDay(response.data);

    }
    catch (error) {

      toggleSystemCheckLoadingSpinner(false);

      if (error.response && error.response.status === 404) {
        setSystemChecksForDay([]);
        return;
      } else {
        toggleGetSystemChecksError(true);
      }
    }
  }
  
  //Handle date change
  const handleDateChange = (date : any) => {

    getSystemChecksForDay(date);
    
    setSelectedDate(date);
  }

  //CSV Download 
  const handleCSVDownloadClick = () => {

  }
  
  const classes = SystemCheckAdminStyles();

  //CSV headers
  let csvHeaders;

  csvHeaders = [
    { label: "SystemCheck ID", key: "systemCheckID"},
    { label: "DateTime", key: "dateTime"},
    { label: "Websockets Blocked", key: "areWebsocketsBlocked"},
    { label: "WebWorkers Supported", key: "areWebWorkersSupported" },
    { label: "Browser Name", key: "browserName" },
    { label: "Browser Version", key: "browserVersion" },
    { label: "Can Reach AWS", key: "canReachAWS" },
    { label: "Can Reach Game Server", key: "canReachGameServer" },
    { label: "Can Reach Liveswitch", key: "canReachLiveswitch" },
    { label: "Can Screenshare", key: "canScreenshare" },
    { label: "Can Use Websockets", key: "canUseWebsockets" },
    { label: "Can Video Stream", key: "canVideoStream" },
    { label: "Has Camera", key: "hasCamera" },
    { label: "Has Camera Permissions", key: "hasCameraPermissions" },
    { label: "Has Mic", key: "hasMic" },
    { label: "Has Mic Permissions", key: "hasMicPermissions" },
    { label: "WebRTC Supported", key: "isWebRTCSupported" },
    { label: "Operating System", key: "operatingSystem" },
    { label: "Operating System Version", key: "operatingSystemVersion" },
  ]

  const csvData = [];

  systemChecksForDay.map(check => {
    csvData.push({
      systemCheckID: check.systemCheckID,
      dateTime: moment(check.timestamp).format(),
      areWebsocketsBlocked: check.data.areWebsocketsBlocked ? "TRUE" : "FALSE",
      areWebWorkersSupported: check.data.areWebWorkersSupported ? "TRUE" : "FALSE",
      browserName: check.data.browserName,
      browserVersion: check.data.browserVersion,
      canReachAWS: check.data.canReachAWS ? "TRUE" : "FALSE",
      canReachGameServer: check.data.canReachGameServer ? "TRUE" : "FALSE",
      canReachLiveswitch: check.data.canReachLiveswitch ? "TRUE" : "FALSE",
      canScreenshare: check.data.canScreenshare ? "TRUE" : "FALSE",
      canUseWebsockets: check.data.canUseWebsockets ? "TRUE" : "FALSE",
      canVideoStream: check.data.canVideoStream ?"TRUE": "FALSE",
      hasCamera: check.data.hasCamera ? "TRUE" : "FALSE",
      hasCameraPermissions: check.data.hasCameraPermissions ? "TRUE" : "FALSE",
      hasMic: check.data.hasMic ? "TRUE" : "FALSE",
      hasMicPermissions: check.data.hasMicPermissions ? "TRUE" : "FALSE",
      isWebRTCSupported: check.data.isWebRTCSupported ? "TRUE" : "FALSE",
      operatingSystem: check.data.operatingSystem,
      operatingSystemVersion: check.data.operatingSystemVersion
    })
  })

  if (isAuthenticated) {
    return (
      <React.Fragment>
        <Button 
          className={classes.adminMenuButton} 
          variant="contained" 
          color="primary"
          onClick={() => {history.push("/admin-menu")}}
          >
          ADMIN MENU
        </Button>
  
        <div className={styles.systemCheckAdminWrapper}>
          <Paper className={classes.systemCheckAdminWrapper}>
  
            <Typography variant="h1" className={classes.systemCheckAdminHeader}>
              System Check Admin
            </Typography>
  
            <Divider className={classes.systemCheckAdminDivider}></Divider>
  
            <div className={styles.systemCheckAdminHolder}>
  
              {getSystemChecksError &&
                <Typography variant="body1" className={classes.error}>
                  An error occurred getting system checks for this date.
                </Typography>
              }
  
              <div className={styles.calendarHolder}>
                <Paper className={classes.calendarHolder}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disabled={systemCheckLoadingSpinner}
                        defaultValue={null}
                        value={selectedDate}
                        className={classes.calendar}
                        onChange={handleDateChange}
                        disablePast={systemCheckLoadingSpinner}
                        disableFuture={systemCheckLoadingSpinner}
                        TextFieldComponent={() => null}
                        open={true}
                        variant="static"
                        leftArrowButtonProps={{
                          classes: {
                            root: classes.leftArrow
                          }
                        }}
                        rightArrowButtonProps={{
                          classes: {
                            root: classes.rightArrow
                          }
                        }}
                      >
                      </DatePicker>
                    </MuiPickersUtilsProvider>
                </Paper>
  
              </div>
              
              <div className={styles.systemCheckHolder}>
                <Paper square className={classes.systemCheckHeaderHolder}>
                  <Typography variant="body1" className={classes.nameHeader}>
                    Name
                  </Typography>
  
                  <Typography variant="body1" className={classes.dateHeader}>
                    Date/Time
                  </Typography>
  
                  <Typography variant="body1" className={classes.systemIDHeader}>
                    SystemCheck ID
                  </Typography>
                  
                  <CSVLink
                    data={csvData}
                    headers={csvHeaders}
                    filename={`${selectedDate !== null ? new Date(selectedDate) : new Date()}-systemchecks.csv`}
                    onClick={handleCSVDownloadClick}
                  >
                    <IconButton
                      className={classes.csvIconButton}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </CSVLink>
                
                </Paper>
  
                <div className={styles.systemCheckItemHolder}>
                  {systemCheckLoadingSpinner
                    ? <CircularProgress className={classes.systemCheckLoadingSpinner} />
                    : systemChecksForDay.map((systemCheck, i) => {
                      return (
                        <SystemCheckAdminItem
                          firstName={systemCheck.firstName}
                          lastName={systemCheck.lastName}
                          data={systemCheck.data}
                          timestamp={systemCheck.timestamp}
                          comment={systemCheck.comment}
                          systemCheckID={systemCheck.systemCheckID}
                          key={i}
                        />
                      )
                    })
                  }
                </div>
                
              </div>
              
  
            </div>
  
          </Paper>
  
        </div>
  
      </React.Fragment>
    )
  } else {
    return <div></div>
  }
  
}
