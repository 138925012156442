import { makeStyles, createStyles } from '@material-ui/core/styles';

const UploadAudioPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 660,
    minHeight: 365,
    height: "auto",
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
  },
  popup: {
    minHeight: 365,
    height: "auto",
    width: "100%",
  },
  uploadHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
    fontWeight: 400,
  },
  uploadSizeHeader: {
    fontSize: "1rem",
    position: "relative",
    top: 60,
    left: 30,
    fontWeight: 400,
  },
  dropzoneText: {
    fontSize: 16,
    color: "#AAA6A6",
    position: "relative",
    top: 36,
  },
  cancelButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 141,
    bottom: 30,
  },
  saveButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 30,
    bottom: 30,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 57,
    bottom: 77
  },
  dimensionsError: {
    top: 5,
    color: "red",
    fontSize: 16,
  },
  error: {
    color: "red",
    position: "absolute",
    top: 95,
    left: 61
  },
  fileName: {
    fontSize: 14,
    position: 'absolute',
    left: 20,
    top: 14,
  },
  fileError: {
    fontSize: 14,
    color: "red",
    left: 20,
    bottom: 12,
    position: 'absolute',
  }
})
)

export { UploadAudioPopupStyles };