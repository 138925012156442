import React, { ReactElement, useContext, useState } from 'react'
import { AppContext } from '../../../../context/AppContext';
import axios from 'axios';
import { Button, CircularProgress, Dialog, TextField, Typography } from '@material-ui/core';
import PopupHeader from '../../../PopupHeader/PopupHeader';

import styles from './EditNumberPopup.module.css';
import { EditNumberPopupStyles } from './EditNumberPopupStyles';

interface Props {
  handlePopupClose: () => void,
  uploadTitle: string,
  uploadProperty: string,
  uploadDivision: string,
  uploadType: string,
}

export default function EditNumberPopup(props: Props): ReactElement {

  const {
    selectedSpace,
    setSelectedSpace
  } = useContext(AppContext);

  const [number, setNumber] = useState(
    selectedSpace[props.uploadDivision][props.uploadProperty].value !== undefined
      ? selectedSpace[props.uploadDivision][props.uploadProperty].value
      : 0
  );

  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  const [ requiredError, toggleRequiredError ] = useState(false);
  
  const [error, toggleError] = useState(false);

  const handleNumberChange = (e) => {
    let t = e.target.value;
    e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;

    setNumber(e.target.value);
  }

  const handleCancelClick = () => {
    if (loadingSpinner) {
      return;
    }

    toggleError(false);
    props.handlePopupClose();
  }

  const handleSaveClick = () => {

    if (!number.toString().length) {
      toggleRequiredError(true);
      return;
    }

    toggleRequiredError(false);
    toggleLoadingSpinner(true);

    let selectedSpaceClone = selectedSpace;

    //Ensure number has 2 decimal places
    let numberValue = Math.round(number * 100) / 100;

    selectedSpaceClone[props.uploadDivision][props.uploadProperty].value = numberValue;

    //Assemble correct axios endpoint based on which skin we are editing
    let axiosBaseURL;
    switch (props.uploadDivision) {
      case "worldSkin":
        axiosBaseURL = "/skin/world";
        break;
      case "avatarSkin":
        axiosBaseURL = "/skin/avatar";
        break;
      case "emailSkin":
        axiosBaseURL = "/skin/email";
        break;
      case "uiSkin":
        axiosBaseURL = "/skin/ui";
        break;
    }

    axios.put(axiosBaseURL, {
      spaceID: selectedSpace.spaceID,
      property: props.uploadProperty,
      value: number,
      type: props.uploadType
    })
      .then(response => {
        toggleLoadingSpinner(false);
        setSelectedSpace(selectedSpaceClone);
        props.handlePopupClose();
      })
      .catch(err => {
        toggleLoadingSpinner(false);
        console.log(err);
      })
  }

  const classes = EditNumberPopupStyles();
  
  return (
    <Dialog
      open={true}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.editHeader}>
          Edit {props.uploadTitle}
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred editing this property
          </Typography>
        }

        <TextField
          className={classes.numberInput}
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: {
              min: 0,
              step: 0.01
            }
          }}
          onChange={handleNumberChange}
          value={number}
          defaultValue={number}
          error={requiredError}
        />


        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button onClick={handleCancelClick} className={classes.cancelButton} variant="text">
          CANCEL
        </Button>
        <Button disabled={loadingSpinner} onClick={handleSaveClick} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>

      </div>

    </Dialog>
  )
}
