import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingAttendeesStyles = makeStyles((theme: any) => createStyles({
  attendeesHeaderHolder: {
    position: "absolute",
    width: "100%",
    top: 48,
    height: 50,
    backgroundColor: "#E5E5E5"
  },
  nameHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 30,
    top: 26,
    fontFamily: "Helvetica"
  },
  brandHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 586,
    top: 26,
    fontFamily: "Helvetica"
  },
  companyHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 219,
    top: 26,
    fontFamily: "Helvetica"
  },
  emailHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 367,
    top: 26,
    fontFamily: "Helvetica"
  },
  downloadIcon: {
    fontSize: 25,
    color: "black",
    "&:hover": {
      cursor: "pointer",
    }
  },
  downloadIconDisabled: {
    color: "grey",
    opacity: 0.5,
    "&:hover": {
      cursor: "auto"
    }
  },
  uploadIcon: {
    fontSize: 25,
    color: "black",
    "&:hover": {
      cursor: "pointer"
    }
  },
  loadingSpinner: {
    position: "absolute",
    color: "grey",
    zIndex: 9999,
    left: "calc(50% - 20px)",
    top: 60
  },
  downloadSnackbar: {
    width: 360
  },
  error: {
    fontSize: 14,
    color: "red",
    position: "relative",
    top: 50,
    textAlign: "center"
  },
  noAttendeesError: {
    fontSize: 14,
    position: "relative",
    top: 50,
    textAlign: "center"
  },
  newButton: {
    backgroundColor: "white",
    border: "1px solid #FF0092",
    borderRadius: 4,
    color: "black",
    width: 67,
    padding: 0,
    height: 26,
    boxShadow: "none",
    position: "absolute",
    left: 30,
    top: -6,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#FF0092",
      boxShadow: "none",
      color: "white"
    }
  },
  addIcon: {
    fontSize: 16,
    position: "relative",
    bottom: 1,
    marginRight: 4,
  }
})
)

export { BookingAttendeesStyles };