import { Button, CircularProgress, Dialog, Switch, TextField, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import React, { ReactElement, useContext, useState } from 'react';

import { AppContext, AppContextType } from '../../../../context/AppContext';
import PopupHeader from '../../../PopupHeader/PopupHeader';

import styles from './EditSupportMessagePopup.module.css';
import { EditSupportMessagePopupStyles } from './EditSupportMessagePopupStyles';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function EditSupportMessagePopup(props: Props): ReactElement {

  const { selectedBooking, setSelectedBooking } : AppContextType = useContext(AppContext);

  const [ supportMessage, setSupportMessage ] = useState(
    selectedBooking.supportMessage !== undefined ? selectedBooking.supportMessage : ""
  );
  const [ enableSupport, toggleEnableSupport ] = useState(
    selectedBooking.enableSupport !== undefined ? selectedBooking.enableSupport : false
  )
  const [ error, toggleError ] = useState(false); 
  const [ formHasBeenSubmitted, toggleFormHasBeenSubmitted ] = useState(false);
  const [ requiredError, toggleRequiredError ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  //Change handler
  const handleSupportMessageChange = (e) => {
    toggleRequiredError(false);

    setSupportMessage(e.target.value);
  }

  //Submit handler
  const handleSupportMessageSubmit = async () => {

    toggleError(false);
    toggleFormHasBeenSubmitted(true);
    toggleRequiredError(false);
    
    //Only set required error if enableSupport is true
    if (!supportMessage.trim().length && enableSupport) {
      toggleRequiredError(true);
      return;
    }

    toggleLoadingSpinner(true);

    let selectedBookingClone = selectedBooking;
    
    selectedBooking["enableSupport"] = enableSupport;
    selectedBooking["supportMessage"] = supportMessage;

    try {
      await axios.put("/booking", selectedBookingClone);

      toggleLoadingSpinner(false);
      setSelectedBooking(selectedBookingClone);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
      
  }

  //Cancel handler
  const handleCancelClick = () => {
    props.handlePopupClose();
  }

  //Enable support toggle
  const handleEnableSupportToggle = () => {
    toggleEnableSupport(!enableSupport);
  }
  
  const classes = EditSupportMessagePopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <FormControlLabel
          control={<Switch color="secondary" className={classes.enableSupportSwitch} checked={enableSupport} onChange={handleEnableSupportToggle} name="HTML" />}
          label="Support Active"
          classes={{
            root: classes.enableSupportToggle,
            label: classes.enableSupportLabel
          }}
        />  

        <Typography className={classes.editErrorMessageHeader}>
         Support Message
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred updating the support message for this booking
          </Typography>
        }

        <TextField
          className={classes.supportMessageTextField}
          label=""
          variant="outlined"
          multiline
          rows={12}
          error={formHasBeenSubmitted && enableSupport && requiredError}
          value={supportMessage}
          onChange={handleSupportMessageChange}
          InputProps={{
            className: classes.errorMessageInput
          }}
        />

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }
        
        <Button onClick={handleCancelClick} className={classes.cancelButton} variant="contained" color="primary">
          CANCEL
        </Button>
        <Button disabled={loadingSpinner} onClick={handleSupportMessageSubmit} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
