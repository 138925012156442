import React, { ReactElement, useState, useContext } from 'react';
import { Typography, Button, Dialog, TextField, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import { EditBookingGracePeriodPopupStyles } from './EditBookingGraceTimePopupStyles';
import { AppContext, AppContextType } from '../../../../context/AppContext';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function EditBookingGraceTimePopup(props: Props): ReactElement {

  const { 
    selectedBooking, 
    setSelectedBooking, 
    selectedSpaceID 
  } : AppContextType = useContext(AppContext);

  const [ graceTime, setGraceTime ] = useState(selectedBooking.gracePeriodMinutes);
  const [ error, toggleError ] = useState(false);
  const [ graceTimeError, toggleGraceTimeError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const handleCancelButtonClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();

  }

  const handleSaveButtonClick = async () => {

    if (graceTime > 60) {
      toggleGraceTimeError(true);
      return;
    }

    toggleGraceTimeError(false);

    toggleLoadingSpinner(true);

    let selectedBookingClone = selectedBooking;

    selectedBookingClone.gracePeriodMinutes = graceTime;

    try {
      await axios.put('/booking', selectedBookingClone);

      toggleLoadingSpinner(false);
      setSelectedBooking(selectedBookingClone);
      props.handlePopupClose();
    }
    catch (error) {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
 
  }

  const handleGraceTimeChange = (e) => {
    setGraceTime(e.target.value);
  }
  
  //Material ui classes
  const classes = EditBookingGracePeriodPopupStyles();
  

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <Typography variant="h1" className={classes.graceTimeHeader}>
          Grace Time
        </Typography>

        <Typography variant="body1" className={classes.graceTimeText}>
          Sets the number of minutes guests can linger post event
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            Error updating the selected booking
          </Typography>
        }

        {graceTimeError &&
          <Typography variant="body1" className={classes.error}>
            Grace period minutes cannot exceed 60 minutes
          </Typography>
        }

        <TextField 
          className={classes.graceTimeInput}
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: { 
                min: 0
            }
          }}
          onChange={handleGraceTimeChange}
          value={graceTime}
         /*  defaultValue={props.capacity} */
        />

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }

        <Button 
          className={classes.cancelButton} 
          variant="contained" 
          color="primary"
          onClick={handleCancelButtonClick}
        >
          CANCEL
        </Button>
        <Button 
          className={classes.saveButton} 
          variant="contained" 
          color="primary"
          onClick={handleSaveButtonClick}
          disabled={loadingSpinner}
        >
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
