import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { orderBy } from 'natural-orderby';

import UploadImagePopup from '../UploadPopups/UploadImagePopup/UploadImagePopup';
import { AppContext, AppContextType } from '../../../context/AppContext';
import ManageAvatarColorsPopup from '../ManageAvatarColorsPopup/ManageAvatarColorsPopup';
import PreviewAssetPopup from '../PreviewAssetPopup/PreviewAssetPopup';
import InRoomSkinnableItem from '../InRoomSkinnableItem/InRoomSkinnableItem';
import UploadVideoPopup from '../UploadPopups/UploadVideoPopup/UploadVideoPopup';
import { BrandingInRoomStyles } from './BrandingInRoomStyles';
import UploadImageOrVideoPopup from '../UploadPopups/UploadImageOrVideoPopup/UploadImageOrVideoPopup';
import UploadAudioPopup from '../UploadPopups/UploadAudioPopup/UploadAudioPopup';
import EditColorPopup from '../UploadPopups/EditColorPopup/EditColorPopup';
import PreviewSingleColorPopup from '../PreviewSingleColorPopup/PreviewSingleColorPopup';
import EditNumberPopup from '../UploadPopups/EditNumberPopup/EditNumberPopup';

interface Props {
  
}

interface PreviewObject {
  title: string,
  property: string,
  value: string,
  type: string,
  division: string
}

export default function BrandingInRoom(props: Props): ReactElement {

  const { 
    selectedSpace,
    isSuperAdmin
  } : AppContextType = useContext(AppContext);

  const [ skinnableFields, setSkinnableFields ] = useState([]);

  const [ preview, togglePreview ] = useState(false);
  const [ previewObject, setPreviewObject ] = useState({
    title: "",
    property: "",
    value: "",
    type: "",
    division: ""
  } as PreviewObject);

  const [ uploadProperty, setUploadProperty ] = useState("");
  const [ uploadType, setUploadType ] = useState("");
  const [ uploadTitle, setUploadTitle ] = useState("");
  const [ uploadDivision, setUploadDivision ] = useState("");
  const [ uploadDimensions, setUploadDimensions ] = useState({});
  const [ uploadAssetFamily, setUploadAssetFamily ] = useState("");
  const [ upload, toggleUpload ] = useState(false);


  useEffect(() => {

    let avatarSkinArrayOfObjects = [];

    //Assemble objects for each item, add to array
    for (const skinItem in selectedSpace.avatarSkin) {

      //Skip property if it is "thumbnails" or "textureMap"
      if (skinItem === "thumbnails" 
      || skinItem === "textureMap")
      {
        continue;
      }

      let skinItemEntry;

      //If skin item has dimensions, add that to the object
      if (selectedSpace.avatarSkin[skinItem].dimensions) {
        skinItemEntry = {
          property: skinItem,
          label: selectedSpace.avatarSkin[skinItem].label,
          type: selectedSpace.avatarSkin[skinItem].type,
          value: selectedSpace.avatarSkin[skinItem].value,
          dimensions: selectedSpace.avatarSkin[skinItem].dimensions,
          division: "avatarSkin"
        }

      } else {
        skinItemEntry = {
          property: skinItem,
          label: selectedSpace.avatarSkin[skinItem].label,
          type: selectedSpace.avatarSkin[skinItem].type,
          value: selectedSpace.avatarSkin[skinItem].value,
          division: "avatarSkin"
        }
      }

      //Check if asset family is undefined on skin item
      if (selectedSpace.avatarSkin[skinItem].assetFamily) {
        skinItemEntry.assetFamily = selectedSpace.avatarSkin[skinItem].assetFamily;
      }

      //Check if hidden is on the skin item
      if (selectedSpace.avatarSkin[skinItem].hidden !== undefined) {
        skinItemEntry.hidden = selectedSpace.avatarSkin[skinItem].hidden;
      }

      avatarSkinArrayOfObjects.push(skinItemEntry);

    }

    let worldSkinArrayOfObjects = [];

    //Assemble world skin objects for each item, add to world skin array
    for (const skinItem in selectedSpace.worldSkin) {

      let skinItemEntry

      if (selectedSpace.worldSkin[skinItem].dimensions) {
        skinItemEntry = {
          property: skinItem,
          label: selectedSpace.worldSkin[skinItem].label,
          type: selectedSpace.worldSkin[skinItem].type,
          value: selectedSpace.worldSkin[skinItem].value,
          dimensions: selectedSpace.worldSkin[skinItem].dimensions,
          division: "worldSkin"
        }

      } else {
        skinItemEntry = {
          property: skinItem,
          label: selectedSpace.worldSkin[skinItem].label,
          type: selectedSpace.worldSkin[skinItem].type,
          value: selectedSpace.worldSkin[skinItem].value,
          division: "worldSkin"
        }

      }

      //Check if assetFamily is undefined
      if (selectedSpace.worldSkin[skinItem].assetFamily) {
        skinItemEntry.assetFamily = selectedSpace.worldSkin[skinItem].assetFamily
      }

      //Check if hidden is undefined
      if (selectedSpace.worldSkin[skinItem].hidden !== undefined) {
        skinItemEntry.hidden = selectedSpace.worldSkin[skinItem].hidden;
      }

      worldSkinArrayOfObjects.push(skinItemEntry);
    }

    //Combine avatar/world arrays
    let skinPropertyArray = [...avatarSkinArrayOfObjects, ...worldSkinArrayOfObjects];

    //Order skinnable properties by name
    const sortedPropertyArray = orderBy(
      skinPropertyArray,
      [s => s.label],
      ['asc']
    );

    setSkinnableFields(sortedPropertyArray);
    
  }, []);

  /*Preview handlers */
  const handleShowPreviewPopup = (previewObj : PreviewObject) => {

    setPreviewObject(previewObj);

    togglePreview(true);
  }

  const handlePreviewClose = () => {

    setPreviewObject({
      title: "",
      property: "",
      value: "",
      type: "",
      division: ""
    });

    togglePreview(false);
  }

  /**/

  /* Upload handlers */
  const handleShowUploadPopup = (label: string, type: string, property: string, assetFamily: any, division: string, dimensions: {}) => {
    setUploadTitle(label);
    setUploadProperty(property);
    setUploadDivision(division);
    setUploadDimensions(dimensions);
    setUploadAssetFamily(assetFamily);
    setUploadType(type);
    toggleUpload(true);
  };

  const handleUploadClose = () => {
    toggleUpload(false);
    setUploadTitle("");
    setUploadProperty("");
    setUploadType("");
  }
  /**/

  //Handle image or video upload
  const handleImageOrVideoUpload = (imageOrVideo: string, property: string) => {
    let spaceSkinnableFields = skinnableFields;

    //Find corresponding skin object and change type on it
    spaceSkinnableFields.map(field => {
      if (field.property === property) {
        field.type = imageOrVideo;
      }
    });

    setSkinnableFields(spaceSkinnableFields);

  }

  const classes = BrandingInRoomStyles(); 

  let previewPopup;

  if (previewObject.type === "SingleColor") {
    previewPopup = (
      <PreviewSingleColorPopup 
        previewObject={previewObject}
        handlePopupClose={handlePreviewClose} 
      />
    )
  } else {
    previewPopup = (
      <PreviewAssetPopup 
        previewObject={previewObject}
        handlePopupClose={handlePreviewClose}
      />
    )
  }
  
  return (
    <React.Fragment>

        {skinnableFields.map((field, i) => {

          if (field.hidden && !isSuperAdmin) {
            return null;
          } else {
            return (
              <InRoomSkinnableItem
                type={field.type}
                value={field.value}
                label={field.label}
                division={field.division}
                property={field.property}
                dimensions={field.dimensions}
                assetFamily={field.assetFamily}
                key={i}
                handleShowUploadPopup={handleShowUploadPopup}
                handleShowPreviewPopup={handleShowPreviewPopup}
                index={i}
              />
            )
          }
         
        })}

      {upload && 
        
          {
            'Image': 
              <UploadImagePopup 
                uploadDimensions={uploadDimensions} 
                uploadDivision={uploadDivision} 
                uploadProperty={uploadProperty} 
                uploadTitle={uploadTitle} 
                uploadType={uploadType} 
                handlePopupClose={handleUploadClose} 
              />,
            'ColorPairList': 
              <ManageAvatarColorsPopup 
                handlePopupClose={handleUploadClose} 
              />,
            'Video': 
              <UploadVideoPopup 
                uploadDimensions={uploadDimensions} 
                uploadDivision={uploadDivision} 
                uploadProperty={uploadProperty} 
                uploadTitle={uploadTitle} 
                uploadType={uploadType} 
                handlePopupClose={handleUploadClose} 
              />,
            'ImageOrVideo': 
              <UploadImageOrVideoPopup 
                uploadDimensions={uploadDimensions} 
                uploadType={uploadType} 
                uploadTitle={uploadTitle} 
                uploadProperty={uploadProperty} 
                uploadDivision={uploadDivision} 
                handlePopupClose={handleUploadClose} 
                handleImageOrVideoUpload={handleImageOrVideoUpload}
              />,
              "Audio":
              <UploadAudioPopup
                  uploadDimensions={uploadDimensions}
                  uploadType={uploadType}
                  uploadTitle={uploadTitle}
                  uploadProperty={uploadProperty}
                  uploadDivision={uploadDivision}
                  handlePopupClose={handleUploadClose}
                />,
              "SingleColor":
              <EditColorPopup
                uploadType={uploadType}
                uploadTitle={uploadTitle}
                uploadProperty={uploadProperty}
                uploadDivision={uploadDivision}
                handlePopupClose={handleUploadClose}
              />,
              "SingleNumber":
              <EditNumberPopup
                uploadType={uploadType}
                uploadTitle={uploadTitle}
                uploadProperty={uploadProperty}
                uploadDivision={uploadDivision}
                handlePopupClose={handleUploadClose}
              />
          }[(uploadAssetFamily !== undefined) ? uploadAssetFamily : uploadType]
          
      }

      {preview &&
        previewPopup
      }
      
    </React.Fragment>
  )
}
