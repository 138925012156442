import { makeStyles, createStyles } from '@material-ui/core/styles';

const EmailSkinnableItemStyles = makeStyles((theme: any) => createStyles({
  itemHeader: {
    position: "relative",
    fontSize: 14,
    left: 30,
    top: 14,
  },
  uploadButton: {
    position: "absolute",
    top: 12,
    right: 30,
    padding: 4,
    color: "black",
  },
  viewIcon: {
    position: "absolute",
    left: 242,
    top: 16,
    color: "black",
    "&:hover": {
      cursor: "pointer"
    }
  },
  webAssetIcon: {
    fontSize: 42,
    "&:hover": {
      cursor: "pointer"
    }
  },
  webAssetIconButton: {
    position: "absolute",
    color: "black",
    padding: 5,
    zIndex: 999,
    left: 605,
    top: 8,
  },
})
)

export { EmailSkinnableItemStyles };