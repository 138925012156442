import React, { ReactElement, useContext, useState, useEffect } from 'react';
import TrashIcon from '@material-ui/icons/DeleteOutlined';
import { reorder } from 'react-reorder';
import { Dialog, Typography, TextField, Divider, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import SlideReorder from '../SlideReorder/SlideReorder';
import { AppContext, AppContextType } from '../../../context/AppContext';
import DeletePresentationPopup from '../DeletePresentationPopup/DeletePresentationPopup';

import styles from './EditPresentationPopup.module.css';
import { EditPresentationPopupStyles } from './EditPresentationPopupStyles';
import PopupHeader from '../../PopupHeader/PopupHeader';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function EditPresentationPopup(props: Props): ReactElement {

  const { 
    selectedPresentation, 
    selectedSpace, 
    setSelectedSpace, 
    selectedSpaceID 
  } : AppContextType = useContext(AppContext);

  const [ presentationName, setPresentationName ] = useState(selectedPresentation.name);
  const [ deletePresentation, toggleDeletePresentation ] = useState(false);
  const [ slides, setSlides ] = useState(selectedPresentation.slides !== undefined ? selectedPresentation.slides : []); 
  const [ selectedSlides, setSelectedSlides ] = useState([]);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ error, toggleError ] = useState(false);

  const handleCancelClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();
  }

  const handleSaveClick = async () => {

    toggleError(false);

    toggleLoadingSpinner(true);

    try {
      await axios.put("/presentation", {
        spaceID: selectedSpaceID,
        name: presentationName,
        presentationID: selectedPresentation.presentationID,
        slides: slides
      });

      let selectedSpaceClone = selectedSpace;

      selectedSpaceClone.presentations[selectedPresentation.presentationID].presentationName=presentationName;

      setSelectedSpace(selectedSpaceClone);
      
      toggleLoadingSpinner(false);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
      console.log(error);
    }

  }

  const handlePresentationNameChange = (e) => {
    setPresentationName(e.target.value);
  }

  /* Reorder slides */
  const handleSlideReorder = (previousIndex, nextIndex) => {
    setSlides(reorder(slides, previousIndex, nextIndex));
  }

  //Remove selected slides on reorder
  useEffect(() => {
    setSelectedSlides([]);
  }, [slides])
  
  /**/

  /* Select slides */
  const handleSlideSelect = (slide: string) => {
    setSelectedSlides(selectedSlides => [...selectedSlides, slide]);
  }
  
  const handleSlideRemove = (slide: string) => {
    setSelectedSlides(selectedSlides.filter(slideURL => slideURL !== slide))
  }
  
  /**/

  /* Delete presentation */
  const handlePresentationDeleteClick = () => {

    toggleDeletePresentation(true);
    
  }

  const handleDeletePresentationClose = () => {
    toggleDeletePresentation(false);
  }

  const handleDeletePresentation = () => {

    let selectedSpaceClone = selectedSpace;

    delete selectedSpaceClone.presentations[selectedPresentation.presentationID];

    setSelectedSpace(selectedSpaceClone);
    
    toggleDeletePresentation(false);
    props.handlePopupClose();
    
  }

  /**/
  
  //Delete selected
  const handleDeleteSelectedClick = () => {
    
    setSlides(slides => slides.filter(slide => selectedSlides.indexOf(slide) < 0));
   
  };

  //Delete all
  const handleDeleteAllClick = () => {
    setSlides([]);
  }
  

  const classes = EditPresentationPopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper}}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />
        
        <Typography variant="h1" className={classes.editPresentationHeader}>
          Edit Presentation
        </Typography>
        
        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred editing this presentation
          </Typography>
        }

        <TextField
          variant="outlined"
          value={presentationName}
          onChange={handlePresentationNameChange}
          className={classes.presentationNameInput}
          helperText="Required"
        />

        <div className={styles.buttonHolder}>
          <Button 
            className={classes.deleteSelectedButton} 
            onClick={handleDeleteSelectedClick} 
            variant="text" 
            classes={{
              label: classes.buttonLabel
            }}
          >
            <TrashIcon className={classes.trashIconButton} />
            SELECTED
          </Button>
          <Button 
            className={classes.deleteAllSlidesButton} 
            onClick={handleDeleteAllClick} 
            variant="text" 
            color="primary"
            style={{
              color: "red"
            }}
            classes={{
              label: classes.buttonLabel
            }}
          >
            <TrashIcon className={classes.trashIconButton} />
            ALL SLIDES
          </Button>
        </div>

        <Typography variant="h2" className={classes.slidesHeader}>
          Slides
        </Typography>
        <Typography variant="h3" className={classes.dragHeader}>
          Drag to reorder
        </Typography>

        <Divider className={classes.divider} />

        <SlideReorder 
          selectedSlides={selectedSlides}
          slides={slides} 
          handleSlideReorder={handleSlideReorder}
          handleSlideSelect={handleSlideSelect} 
          handleSlideRemove={handleSlideRemove}
        />

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button 
          onClick={handleCancelClick} 
          className={classes.cancelButton} 
          variant="contained"
          color="primary"
        >
          CANCEL
        </Button>

        <Button 
          disabled={loadingSpinner} 
          onClick={handleSaveClick} 
          variant="contained" 
          className={classes.saveButton} 
        >
          SAVE
        </Button>

        {deletePresentation &&
          <DeletePresentationPopup presentationName={selectedPresentation.name} handleDeletePresentation={handleDeletePresentation} open={deletePresentation} handlePopupClose={handleDeletePresentationClose} />
        }
      </div>
    </Dialog>
  )
}
