import { CircularProgress, Paper, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from './LoadingSpinner.module.css';
import { LoadingSpinnerStyles } from './LoadingSpinnerStyles';

interface Props {
}

export default function LoadingSpinner(props: Props): ReactElement {

  const classes = LoadingSpinnerStyles();
  
  return (
    <div className={styles.loadingSpinnerHolder}>

      <Paper elevation={0} className={classes.loadingWrapper}>
        
        <div className={styles.loadingContentHolder}>
          <div className={styles.showboatLogoHolder}>
            <img
              alt="Showboat logo"
              src="assets/images/ShowboatLogo.svg"
              className={styles.showboatLogo}
            />
          </div>
          
          <CircularProgress className={classes.loadingSpinner} />

          <Typography variant="body1" className={classes.loadingText}>
            Loading
          </Typography>
        </div>

      </Paper>
      
    </div>
  )
}
