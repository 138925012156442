import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const LoadingSpinnerStyles = makeStyles((theme: any) => createStyles({
  loadingWrapper: {
    backgroundColor: "#EFEFEF",
    width: 417,
    minHeight: 477,
    height: "auto",
    borderRadius: 10,
    position: "absolute",
    margin: "auto",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
  loadingSpinner: {
    position: "absolute",
    color: "#FF0092",
    left: 0,
    right: 0,
    bottom: 77,
    margin: "auto",
  },
  loadingText: {
    color: "#3B3B3B",
    position: "relative",
    textAlign: "center",
    top: 260,
  }
})
)

export { LoadingSpinnerStyles };