import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const LoginStyles = makeStyles((theme: any) => createStyles({
  loginContentHolder: {
    backgroundColor: "#EFEFEF",
    width: 417,
    minHeight: 477,
    height: "auto",
    borderRadius: 10,
    position: "absolute",
    margin: "auto",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
})
)

export { LoginStyles };