import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingEmailStyles = makeStyles((theme: any) => createStyles({
  emailHeaderHolder: {
    position: "absolute",
    width: "100%",
    top: 48,
    height: 50,
    backgroundColor: "#E5E5E5"
  },
  nameHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 30,
    top: 26,
    fontFamily: "Helvetica"
  },
  systemHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 250,
    top: 26,
    fontFamily: "Helvetica"
  },
  htmlHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 337,
    top: 26,
    fontFamily: "Helvetica"
  },
 
  newButton: {
    backgroundColor: "white",
    border: "1px solid #FF0092",
    borderRadius: 4,
    color: "black",
    padding: 0,
    width: 67,
    height: 26,
    boxShadow: "none",
    position: "absolute",
    left: 30,
    top: -6,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#FF0092",
      boxShadow: "none",
      color: "white"
    }
  },
  addIcon: {
    fontSize: 16,
    position: "relative",
    bottom: 1,
    marginRight: 4,
  }
})
)

export { BookingEmailStyles };