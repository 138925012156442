
export const intakeStore = {
  intakeFields: [
    {name: "First Name", visible: true, mandatory: true},
    {name: "Last Name", visible: true, mandatory: false},
    {name: "Company", visible: true, mandatory: false},
    {name: "Email", visible: true, mandatory: false},
    {name: "Phone", visible: true, mandatory: false},
    {name: "Address", visible: true, mandatory: false}
  ],
}