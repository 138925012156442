import { Button, IconButton, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import UploadIcon from '@material-ui/icons/'
import ViewIcon from '@material-ui/icons/Visibility';
import WebAssetIcon from '@material-ui/icons/WebAsset';

import styles from './InRoomSkinnableItem.module.css';
import { InRoomSkinnableItemStyles } from './InRoomSkinnableItemStyles';

interface Props {
  type: string,
  value: string,
  label: string,
  property: string,
  division: string,
  assetFamily: string,
  dimensions: {},
  handleShowUploadPopup: (label: string, type: string, property: string, assetFamily: any, division: string, dimensions: {}) => any,
  handleShowPreviewPopup: (previewObj : PreviewObject) => any,
  index: number
}

interface PreviewObject {
  title: string,
  property: string,
  value: string,
  type: string,
  division: string
}

export default function InRoomSkinnableItem(props: Props): ReactElement {
  
  const classes = InRoomSkinnableItemStyles();

  //Upload/edit click
  const handleButtonClick = () => {

    props.handleShowUploadPopup(props.label, props.type, props.property, props.assetFamily, props.division, props.dimensions);
  };

  //Preview click
  const handlePreviewClick = () => {

    let previewObj : PreviewObject = {
      title: props.label,
      property: props.property,
      value: props.value,
      type: props.type,
      division: props.division
    }

    props.handleShowPreviewPopup(previewObj);
  }

  //Select correct version of button
  let button;


  if (props.type === "ColorPairList" || props.type === "SingleColor" || props.type === "SingleNumber") {
    button = (
      <IconButton 
        onClick={handleButtonClick} 
        className={`${classes.button} ${classes.editButton}`} 
      >
        <EditIcon 
          style={{
            fontSize: "20px"
          }}
        />
      </IconButton>
    )
  } else if (props.type === "Image" || props.type === "Video" || props.type === "Audio") {
    button = (
      <IconButton 
        onClick={handleButtonClick} 
        className={classes.button} 
      >
        <img className={styles.uploadIcon} alt="upload icon" src="assets/images/UploadIcon.svg" />
      </IconButton>
    )
  }

  return (

    <div 
      className={styles.itemHolder}
      style={{ backgroundColor: (props.index + 1) % 2 === 0
        ? "#FBFBFB"
        : "white"
      }}
    >
        <Typography variant="body1" className={classes.itemHeader}>
          {props.label}
        </Typography>

          <div className={styles.iconHolder}>

            {props.type !== "SingleNumber" &&
              <IconButton className={classes.webAssetButton} onClick={handlePreviewClick}>
                <img 
                  src="assets/images/PreviewIcon.svg" 
                  alt="Preview icon" 
                />
              </IconButton>
            }


            {button}

          </div>
    </div>
      
  )
}
