import React, { ReactElement, useEffect, useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AWS  from 'aws-sdk';
import VisibleIcon from '@material-ui/icons/Visibility';

import { ForgotPasswordStyles } from './ForgotPasswordStyles';
import styles from './ForgotPassword.module.css';
import { Button, CircularProgress, InputAdornment, TextField, Typography } from '@material-ui/core';

const passwordValidator = require('password-validator');

interface Props {
  handleBackButtonClick: () => any,
  handleChangePasswordComplete: () => any,
}

export default function ForgotPassword(props: Props): ReactElement {

  var testPool = {
    UserPoolId: 'us-east-1_N15Q0NLkm',
    ClientId: '2332rbhi35f5016dglri2mojo'
  };

  var testUserPool = new CognitoUserPool(testPool);

  const [ verificationFormHasBeenSubmitted, toggleVerificationFormHasBeenSubmitted ] = useState(false);

  const [ forgotPasswordEmailAddressOrUsername , setForgotPasswordEmailAddressOrUsername ] = useState("");
  const [ emailAddressRequiredError, toggleEmailAddressRequiredError ] = useState(false);
  const [ forgotPasswordLoadingSpinner, toggleForgotPasswordLoadingSpinner ] = useState(false);
  const [ forgotPasswordError, toggleForgotPasswordError ] = useState(false);
  const [ limitExceededError, toggleLimitExceededError ] = useState(false);

  const [ verificationCode, toggleVerificationCode ] = useState(false); 
  const [ verificationCodeValue, setVerificationCodeValue ] = useState("");
  const [ verificationError, toggleVerificationError ] = useState(true);
  const [ verificationRequiredError, toggleVerificationRequiredError ] = useState(true);

  const [ passwordErrorMessageStore, setPasswordErrorMessageStore ] = useState([]);

  const [ newPassword, setNewPassword ] = useState("");
  const [ confirmNewPassword, setConfirmNewPassword ] = useState("");

  const [ showPassword, toggleShowPassword ] = useState(false);

  const [ passwordRequiredError, togglePasswordRequiredError ] = useState(false);
  const [ confirmPasswordRequiredError, toggleConfirmPasswordRequiredError ] = useState(false);

  const [ expiredCodeError, toggleExpiredCodeError ] = useState(false);

  const [ confirmPasswordLoadingSpinner, toggleConfirmPasswordLoadingSpinner ] = useState(false);

  const [ cognitoUserState, setCognitoUserState ] = useState(new CognitoUser({
    Username: "",
    Pool: testUserPool
  }));

  useEffect(() => {

    //Add enter button event listeners for forgot password form
    let submitButtonForgotPassword = document.getElementById("submitButtonForgotPassword");
    
    let forgotPasswordInput = document.getElementById("forgotPasswordInput");

    forgotPasswordInput.addEventListener("keyup", function(event) {
      if (event.key === "Enter") {
        submitButtonForgotPassword.click();
      }
    });

    return function cleanup () {
      forgotPasswordInput.removeEventListener("keyup", function(event) {
        if (event.key === "Enter") {
          submitButtonForgotPassword.click();
        }
      })
    }
    
  }, []);

  useEffect(() => {

    //Only add listeners if showing verification form
    if (verificationCode) {

      //Add verification code enter listener once it mounts
      let submitButtonVerification = document.getElementById("submitButtonConfirm");

      let verificationCodeInput = document.getElementById("verificationCodeInput");
      let passwordInput = document.getElementById("passwordInput");
      let passwordConfirmInput = document.getElementById("passwordConfirmInput");

      verificationCodeInput.addEventListener("keyup", function(event) {
        if (event.key === "Enter") {
          submitButtonVerification.click();
        }
      })
      
      passwordInput.addEventListener("keyup", function(event) {
        if (event.key === "Enter") {
          submitButtonVerification.click();
        }
      })

      passwordConfirmInput.addEventListener("keyup", function(event) {
        if (event.key === "Enter") {
          submitButtonVerification.click();
        }
      })

    } else {
      //Re-add forgot password event listeners
      let submitButtonForgotPassword = document.getElementById("submitButtonForgotPassword");
    
      let forgotPasswordInput = document.getElementById("forgotPasswordInput");

      forgotPasswordInput.addEventListener("keyup", function(event) {
        if (event.key === "Enter") {
          submitButtonForgotPassword.click();
        }
      });
    }

    return function cleanup() {
      if (verificationCode) {
        
      }
    }

  }, [verificationCode])

  var passwordSchema = new passwordValidator();

  passwordSchema.is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().digits(1);
  
  const handleForgotPasswordEmailAddressChange = (e) => {

    toggleEmailAddressRequiredError(false);
    
    setForgotPasswordEmailAddressOrUsername(e.target.value);
    
  }

  const handleForgotPasswordEmailAddressSubmit = () => {

    //Remove error message
    toggleForgotPasswordError(false);
    toggleLimitExceededError(false);

    //Check for required email address/username
    if (!forgotPasswordEmailAddressOrUsername.trim().length) {
      toggleEmailAddressRequiredError(true);
      return;
    }

    toggleEmailAddressRequiredError(false);
    
    toggleForgotPasswordLoadingSpinner(true);

    let userPoolData = {
      UserPoolId: 'us-east-1_N15Q0NLkm',
      ClientId: '2332rbhi35f5016dglri2mojo'
    };
    
    let userPool = new CognitoUserPool(userPoolData);

    //Create user object
    let cognitoUser = new CognitoUser({
      Username: forgotPasswordEmailAddressOrUsername,
      Pool: userPool
    });

    setCognitoUserState(cognitoUser);

    //Call forgotPassword method on the user
    cognitoUser.forgotPassword({
      //Successful send of email
      onSuccess: function(result) {

      toggleForgotPasswordLoadingSpinner(false);

        //Show code input form 
        toggleVerificationCode(true);

      },
      //Fail to find username/email address, OR fail to send
      onFailure: function (result) {

        toggleForgotPasswordLoadingSpinner(false);

        //Check if error is due to limit exceeded
        if (result.name === "LimitExceededException") {
          //Show limit exceeded error message
          toggleLimitExceededError(true);
        } else {
          //Show default error message
          toggleForgotPasswordError(true);
        }

      }
    })
  }

  const handleBackButtonClick = () => {
    props.handleBackButtonClick();
  }

  //Verification code
  const handleVerificationCodeChange = (e) => {
    toggleVerificationRequiredError(false);

    setVerificationCodeValue(e.target.value);
  }

  const handleVerificationCodeSubmit = () => {

    toggleConfirmPasswordLoadingSpinner(true);
    toggleVerificationError(false);
    toggleExpiredCodeError(false);
    toggleVerificationFormHasBeenSubmitted(true);

    let verificationRequiredErrorLocal = false;
    let newPasswordRequiredErrorLocal = false;
    let confirmPasswordRequiredErrorLocal = false;

    //Check that required fields are there
    if (!verificationCodeValue.trim().length) {
      toggleVerificationRequiredError(true);
      verificationRequiredErrorLocal = true;
    }

    if (!newPassword.trim().length) {
      newPasswordRequiredErrorLocal = true;
      togglePasswordRequiredError(true);
    }

    if (!confirmNewPassword.trim().length) {
      confirmPasswordRequiredErrorLocal = true;
      toggleConfirmPasswordRequiredError(true);
    }

    //If any fields are empty, exit out of function
    if (verificationRequiredErrorLocal || newPasswordRequiredErrorLocal || 
      confirmPasswordRequiredErrorLocal) {
        return;
    }

    //Otherwise, toggle all required errors to false
    toggleVerificationRequiredError(false);
    togglePasswordRequiredError(false);
    toggleConfirmPasswordRequiredError(false);
    
    //Clear password error message store
    setPasswordErrorMessageStore([]);

    //Validate password
    if (newPassword !== confirmNewPassword) {
      setPasswordErrorMessageStore(passwordErrorMessageStore => 
        [...passwordErrorMessageStore, "Passwords must match"]
      )
      toggleConfirmPasswordLoadingSpinner(false);
      return;
    }

    let passwordErrors = passwordSchema.validate(newPassword, { list: true });

    //If there are errors present, set error store with custom messages
    if (passwordErrors.length) {

      passwordErrors.forEach(error => {
        if (error === "min") {
          setPasswordErrorMessageStore(passwordErrorMessageStore => [...passwordErrorMessageStore, "Password must be at least 8 characters"]);
        }
        if (error === "uppercase") {
          setPasswordErrorMessageStore(passwordErrorMessageStore => [...passwordErrorMessageStore, "Password must contain at least 1 uppercase letter"]);
        }
        if (error === "lowercase") {
          setPasswordErrorMessageStore(passwordErrorMessageStore => [...passwordErrorMessageStore, "Password must contain at least 1 lowercase letter"]);
        }
        if (error === "digits") {
          setPasswordErrorMessageStore(passwordErrorMessageStore => [...passwordErrorMessageStore, "Password must contain at least 1 number"]);
        }
      })

      toggleConfirmPasswordLoadingSpinner(false);
      return;
    } else {
      //Call confirm password endpoint on CognitoUser with verification code
      cognitoUserState.confirmPassword(verificationCodeValue, newPassword, {
        onSuccess: function() {
          toggleConfirmPasswordLoadingSpinner(false);
          
          //Successful change, redirect to sign-in form
          props.handleChangePasswordComplete();
        },
        onFailure: function(result) {
          toggleConfirmPasswordLoadingSpinner(false);

          //Check if error is sent back due to code expiring
          if (result.name === "ExpiredCodeException") {
            toggleExpiredCodeError(true);
          } else {

            //Otherwise just show general error
            toggleVerificationError(true);
          }
          
        }
      })
    }


    
  }

  //New password handlers
  const handleNewPasswordChange = (e) => {
    togglePasswordRequiredError(false);
    setNewPassword(e.target.value);
  }
  const handleNewPasswordMatchChange = (e) => {
    toggleConfirmPasswordRequiredError(false);
    setConfirmNewPassword(e.target.value);
  }

  //Password visibility toggle
  const handleVisibilityClick = () => {
    toggleShowPassword(!showPassword);
  }

  //Verify back button
  const handleVerifyBackButtonClick = () => {
    //Clear inputs and errors
    setVerificationCodeValue("");
    toggleVerificationError(false);
    toggleExpiredCodeError(false);
    toggleVerificationRequiredError(false);
    setPasswordErrorMessageStore([]);
    setNewPassword("");
    setConfirmNewPassword("");
    toggleShowPassword(false);
    toggleConfirmPasswordLoadingSpinner(false);

    //Hide verification code form
    toggleVerificationCode(false);
  }
  
  const classes = ForgotPasswordStyles();
  
  if (verificationCode) {
    return (
      <div className={`${styles.forgotPasswordHolder} ${styles.forgotPasswordHolderVerification}`}>
        <Typography classes={{ root: classes.signInHeader }} variant="h1">
          Verify
        </Typography>

        <Typography classes={{ root: classes.forgotPasswordSubheader }} variant="h2">
          Please enter the verification sent to your registered email address below, and enter your new password.
        </Typography>

        <div className={styles.errorHolderPassword}>
          {passwordErrorMessageStore.map(error => {
            return (
              <Typography key={error} classes={{root: classes.errorNewPassword}} variant="h1">
                {error}
              </Typography>
            )
          })}
        </div>

        {expiredCodeError &&
          <Typography variant="body1" className={classes.confirmPasswordError}>
            This verification code has expired. 
          </Typography>
        }

        {verificationError &&
          <Typography variant="body1" className={classes.confirmPasswordError}>
            An error occurred. Please ensure your verification code is correct.
          </Typography>
        }

        <TextField 
          variant="outlined"
          placeholder="Verification code"
          fullWidth
          id="verificationCodeInput"
          classes={{ root: classes.verificationCodeInput }}
          onChange={handleVerificationCodeChange}
          value={verificationCodeValue}
          error={verificationFormHasBeenSubmitted && verificationRequiredError}
        >
        </TextField>

        <TextField 
          variant="outlined"
          placeholder="Password"
          fullWidth
          id="passwordInput"
          error={ verificationFormHasBeenSubmitted && passwordRequiredError }
          type={showPassword ? "text" : "password"}
          classes={{ root: classes.newPasswordInput}}
          onChange={handleNewPasswordChange}
          value={newPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibleIcon className={classes.visibleIcon} onClick={handleVisibilityClick} />
              </InputAdornment>
            ),
          }}
        >
        </TextField>
  
        <TextField 
          variant="outlined"
          placeholder="Confirm Password"
          fullWidth
          id="passwordConfirmInput"
          error={verificationFormHasBeenSubmitted && confirmPasswordRequiredError }
          type={showPassword ? "text" : "password"}
          classes={{ root: classes.newPasswordConfirmInput}}
          onChange={handleNewPasswordMatchChange}
          value={confirmNewPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibleIcon className={classes.visibleIcon} onClick={handleVisibilityClick} />
              </InputAdornment>
            ),
          }}
        >
        </TextField>

        <Button 
          variant="contained" 
          color="primary" 
          id="confirmBackButton"
          disabled={confirmPasswordLoadingSpinner}
          classes={{ root: `${classes.verifyBackButton}`}}
          onClick={handleVerifyBackButtonClick}
        >
          BACK
        </Button>

        <Button 
          variant="contained" 
          color="primary" 
          id="submitButtonConfirm"
          disabled={confirmPasswordLoadingSpinner}
          classes={{ root: `${classes.emailAddressSubmitButton} ${classes.submitButtonVerify}`}}
          onClick={handleVerificationCodeSubmit}
        >
          SUBMIT
        </Button>

        {confirmPasswordLoadingSpinner &&
          <CircularProgress className={classes.confirmPasswordloadingSpinner} />
        }

      </div>
    )
  } else {

    return (
      <div className={styles.forgotPasswordHolder}>
        <Typography classes={{ root: classes.signInHeader }} variant="h1">
          Forgot Password
        </Typography>

        <Typography classes={{ root: classes.forgotPasswordSubheader }} variant="h2">
          Please enter your registered email address or username below.
        </Typography>

        {forgotPasswordError &&
          <Typography variant="body1" className={classes.forgotPasswordError}>
            An error occurred sending your verification code. Please try again.
          </Typography>
        }

        {limitExceededError &&
          <Typography variant="body1" className={classes.forgotPasswordError}>
            Maximum verification attempts exceeded. Please wait a few minutes and try again.
          </Typography>
        }

        <TextField 
          variant="outlined"
          placeholder="Email/Username"
          fullWidth
          id="forgotPasswordInput"
          classes={{ root: classes.emailAddressInput}}
          onChange={handleForgotPasswordEmailAddressChange}
          value={forgotPasswordEmailAddressOrUsername}
          error={emailAddressRequiredError}
        >
        </TextField>

        <Button 
          variant="contained" 
          color="primary" 
          id="enterButton"
          disabled={forgotPasswordLoadingSpinner}
          classes={{ root: `${classes.emailAddressBackButton}`}}
          onClick={handleBackButtonClick}
        >
          BACK
        </Button>

        <Button 
          variant="contained" 
          color="primary" 
          id="submitButtonForgotPassword"
          disabled={forgotPasswordLoadingSpinner}
          classes={{ root: classes.emailAddressSubmitButton}}
          onClick={handleForgotPasswordEmailAddressSubmit}
        >
          SUBMIT
        </Button>

        {forgotPasswordLoadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }
      </div>
    )
  }
}
