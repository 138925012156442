import { Button, CircularProgress, Dialog, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useContext, useState } from 'react';

import { AppContext, AppContextType } from '../../../context/AppContext';
import PopupHeader from '../../PopupHeader/PopupHeader';

import { EditCalendarNamePopupStyles } from './EditCalendarNamePopupStyles';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function EditCalendarNamePopup(props: Props): ReactElement {

  const { 
    selectedCalendar, 
    setSelectedCalendar, 
    selectedSpaceID 
  } : AppContextType = useContext(AppContext);
  
  const [ calendarName, setCalendarName ] = useState(selectedCalendar.calendarName);
  const [ error, toggleError ] = useState(false);
  const [ isLoading, toggleIsLoading ] = useState(false);
  const [ calendarNameRequired, toggleCalendarNameRequired ] = useState(false);

  const handleCalendarNameChange = (e) => {
    setCalendarName(e.target.value);
  }

  const handleCancelButtonClick = () => {
    props.handlePopupClose();
  }

  const handleSaveButtonClick = async () => {

    //Check if there is a name value
    if (!calendarName.trim().length) {
      toggleCalendarNameRequired(true);
      return;
    }

    toggleCalendarNameRequired(false);
    toggleIsLoading(true);
    toggleError(false);

    let selectedCalendarClone = selectedCalendar;

    selectedCalendarClone.calendarName = calendarName;
    
    //Assemble new calendar object
    let updatedCalendar = {
      spaceID: selectedSpaceID,
      calendarID: selectedCalendar.calendarID,
      calendarName: calendarName,
      bookings: selectedCalendar.bookings
    }
    
    try {
      await axios.put("/calendar", JSON.stringify(updatedCalendar));

      toggleIsLoading(false);
      setSelectedCalendar(selectedCalendarClone);
      props.handlePopupClose();

    } catch {
      toggleIsLoading(false);
      toggleError(true);
    }
    
  }
  
  const classes = EditCalendarNamePopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    > 
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={isLoading}
        />

        <Typography className={classes.editNameHeader}>
          Edit Calendar Name
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred updating the name of this calendar
          </Typography>
        }

        <TextField
          className={classes.calendarNameTextField}
          label=""
          helperText="Required"
          variant="outlined"
          value={calendarName}
          onChange={handleCalendarNameChange}
          defaultValue={selectedCalendar.calendarName}
          error={calendarNameRequired}
          InputProps={{
            className: classes.calendarNameInput
          }}
        />

        {isLoading &&
          <CircularProgress className={classes.loadingSpinner } />
        }
        
        <Button onClick={handleCancelButtonClick} className={classes.cancelButton} variant="contained" color="primary">
          CANCEL
        </Button>
        <Button disabled={isLoading} onClick={handleSaveButtonClick} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
