import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useContext, useState } from 'react';

import { AppContext, AppContextType } from '../../../context/AppContext';
import PopupHeaderDelete from '../../PopupHeaderDelete/PopupHeaderDelete';

import { DeleteCalendarPopupStyles } from './DeleteCalendarPopupStyles';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function DeleteCalendarPopup(props: Props): ReactElement {

  const { 
    selectedCalendar, 
    selectedSpaceID, 
    setSelectedCalendar 
  } : AppContextType = useContext(AppContext);

  const [ error, toggleError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  
  const handleNoButtonClick = () => {
    if (loadingSpinner) {
      return;
    }

    props.handlePopupClose();
  }

  const handleYesButtonClick = async () => {

    toggleError(false);
    toggleLoadingSpinner(true);

    try {
      await axios.delete("/calendar", {
        params: {
          spaceID: selectedSpaceID,
          calendarID: selectedCalendar.calendarID,
        }
      });

      toggleLoadingSpinner(false);
      setSelectedCalendar(null);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
   
  }
  
  const classes = DeleteCalendarPopupStyles();
  
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={props.open}
    >
      <div className={classes.popup}>
        <PopupHeaderDelete
          closeFunction={props.handlePopupClose}
          headerText="Delete Calendar?"
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.deleteCalendarHeader}>
          There is no undo for calendar delete.
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred deleting this calendar
          </Typography>
        }

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }

        <Button 
          className={classes.noButton} 
          variant="contained" 
          color="primary"
          onClick={handleNoButtonClick}
        >
          CANCEL
        </Button>

        <Button 
          className={classes.yesButton} 
          onClick={handleYesButtonClick}
          disabled={loadingSpinner}
        >
          CONFIRM
        </Button>
      </div>
    </Dialog>
  )
}
