import { makeStyles, createStyles } from '@material-ui/core/styles';

const SlideReorderStyles = makeStyles((theme: any) => createStyles({
  slideNumber: {
    position: "absolute",
    top: 0,
    left: 0,
    fontSize: "0.75rem",
  },
  noSlidesText: {
    textAlign: "center",
    position: "relative",
    top: 40,
  }
})
)

export { SlideReorderStyles };