import { makeStyles, createStyles } from '@material-ui/core/styles';

const SendEmailPresentersStyles = makeStyles((theme: any) => createStyles({
  nameHeader: {
    fontWeight: 600,
    fontSize: ".875rem",
    position: "absolute",
    left: 30,
    top: 9,
    fontFamily: "Helvetica"
  },
  brandHeader: {
    fontWeight: 600,
    fontSize: ".875rem",
    position: "absolute",
    left: 315,
    top: 9,
    fontFamily: "Helvetica"
  },
  sentPreviousHeader: {
    fontWeight: 600,
    fontSize: ".875rem",
    position: "absolute",
    left: 402,
    top: 9,
    fontFamily: "Helvetica"
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    top: 22,
    left: "calc(50% - 20px)"
  },
  checkbox: {
    position: "relative",
    left: 57,
  },
  attendeeSearch: {
    position: "absolute",
    width: 262,
    left: 59,
    top: 45,
    height: 39,
    zIndex: 9999
  },
  searchInput: {

  },
  error: {
    color: "red",
    position: "relative",
    textAlign: "center",
    top: 50
  },
  noPresentersError: {
    color: "black",
    position: "relative",
    textAlign: "center",
    top: 28
  }
})
)

export { SendEmailPresentersStyles };