import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingPresentersStyles = makeStyles((theme: any) => createStyles({
  presentersHeaderHolder: {
    position: "absolute",
    width: "100%",
    top: 48,
    height: 50,
    backgroundColor: "#E5E5E5"
  },
  nameHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 30,
    top: 26,
    fontFamily: "Helvetica"
  },
  brandHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 586,
    top: 26,
    fontFamily: "Helvetica"
  },
  companyHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 219,
    top: 26,
    fontFamily: "Helvetica"
  },
  emailHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 367,
    top: 26,
    fontFamily: "Helvetica"
  },
  presentationHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 250,
    top: 26,
    fontFamily: "Helvetica"
  },
  addIcon: {
    fontSize: 16,
    position: "relative",
    bottom: 1,
    marginRight: 4,
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    left: "calc(50% - 20px)",
    top: 60,
  },
  error: {
    fontSize: 14,
    color: "red",
    position: "relative",
    top: 50,
    textAlign: "center"
  },
  noPresentersError: {
    fontSize: 14,
    position: "relative",
    top: 50,
    textAlign: "center"
  },
  newButton: {
    backgroundColor: "white",
    border: "1px solid #FF0092",
    borderRadius: 4,
    color: "black",
    padding: 0,
    width: 67,
    height: 26,
    boxShadow: "none",
    position: "absolute",
    left: 30,
    top: -6,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#FF0092",
      boxShadow: "none",
      color: "white"
    }
  }
})
)

export { BookingPresentersStyles };