import { makeStyles, createStyles } from '@material-ui/core/styles';

const DeleteBookingPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 361,
    height: 238,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 238,
    position: "absolute",
  },
  deleteBookingHeader: {
    fontWeight: 400,
    fontSize: 16,
    position: "absolute",
    top: 83,
    left: 30
  },
  loadingSpinner: {
    position: "absolute",
    color: "grey",
    right: 75,
    bottom :83,
    width: "25px !important",
    height: "25px !important",
  },
  noButton: {
    width: 95,
    height: 44,
    position: "absolute",
    right: 163,
    bottom: 30,
  },
  yesButton: {
    width: 114,
    height: 44,
    right: 30,
    bottom: 30,
    position: "absolute",
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "green"
    }
  },
  error: {
    color: "red",
    position: "absolute",
    left: 30,
    top: 105
  }
})
)

export { DeleteBookingPopupStyles };