import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const AdminMenuStyles = makeStyles((theme: any) => createStyles({
  menuButton: {
    
  },
  menuButtonUsage: {
    width: 145,
    right: 25,
  },
  spacePickerButton: {
    position: "absolute",
    top: 15,
    left: 15
  }
})
)

export { AdminMenuStyles };