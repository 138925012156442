import React, { ReactElement, useContext, useState } from 'react';
import { Dialog, Typography, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../context/AppContext';

import { DeletePresentationPopupStyles } from './DeletePresentationPopupStyles';
import PopupHeaderDelete from '../../PopupHeaderDelete/PopupHeaderDelete';

interface Props {
  open: boolean,
  presentationName: string,
  handlePopupClose: () => any,
  handleDeletePresentation: (deletedPresentation) => any
}

export default function DeletePresentationPopup(props: Props): ReactElement {

  const { 
    selectedPresentation, 
    selectedSpaceID 
  } : AppContextType = useContext(AppContext);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [error, toggleError] = useState(false);

  const handleNoButtonClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    toggleError(false);
    props.handlePopupClose();
  }

  const handleYesButtonClick = async () => {

    toggleLoadingSpinner(true);


    try {
      await axios.delete("/presentation", {
        params: {
          spaceID: selectedSpaceID,
          presentationID: selectedPresentation.presentationID
        }
      });

      toggleLoadingSpinner(false);
      props.handleDeletePresentation(selectedPresentation);

    }
    catch {
      toggleError(true);
      toggleLoadingSpinner(false);
    }
   
  }
  
  const classes = DeletePresentationPopupStyles();
  
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper}}
      open={props.open}
    >
      <div className={classes.popup}>

        <PopupHeaderDelete
          closeFunction={props.handlePopupClose}
          headerText="Delete Presentation?"
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.deletePresentationHeader}>
          There is no undo for presentation delete.
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred deleting this presentation
          </Typography>
        }

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button 
          onClick={handleNoButtonClick} 
          variant="contained" 
          color="primary" 
          className={classes.noButton}
        >
          CANCEL
        </Button>
        <Button 
          disabled={loadingSpinner} 
          onClick={handleYesButtonClick} 
          variant="contained" 
          className={classes.yesButton}
        >
          CONFIRM
        </Button>

      </div>
    </Dialog>
  )
}
