import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarItemStyles = makeStyles((theme: any) => createStyles({
  calendarName: {
    fontSize: 14,
    position: "relative",
    top: 13,
    left: 30
  },
  editButton: {
    display: "inline-block",
    padding: 6,
    position: "absolute",
    right: 30,
    top: 8,
    color: "black",
    
  },
  editIcon: {
    width: 20,
    height: 20,
  },
  loadingSpinner: {
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    bottom: 14,
    right: 80,

  }
})
)

export { CalendarItemStyles };