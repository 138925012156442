import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { Typography, Button, IconButton, Snackbar } from '@material-ui/core';
import axios from 'axios';
import OpenIcon from '@material-ui/icons/OpenInNewOutlined';
import LinkIcon from '@material-ui/icons/LinkOutlined';

import ManagePresenterPopup from '../ManagePresenterPopup/ManagePresenterPopup';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import styles from './BookingPresenter.module.css';
import { BookingPresenterStyles } from './BookingPresenterStyles';
import AttendeeEditPopup from '../../BookingAttendees/AttendeeEditPopup/AttendeeEditPopup';

interface Props {
  presenter: any,
  handleEditPresenter: (newPresenter) => any,
}

export default function BookingPresenter(props: Props): ReactElement {

  const { 
    selectedRegistration, 
    setSelectedRegistration, 
    setSelectedPresenter,
    selectedSpace 
  } : AppContextType = useContext(AppContext);

  const [ hasError, toggleHasError ] = useState(false);
  const [ presentationDeleted, togglePresentationDeleted ] = useState(false);
  const [ initialEmailAddress, setInitialEmailAddress ] = useState(props.presenter.registrationData.emailAddress); 
  const [ managePresenter, toggleManagePresenter ] = useState(false);
  const [ showEditPopup, toggleShowEditPopup ] = useState(false);

  const [ copyToClipboard, toggleCopyToClipboard ] = useState(false);

  useEffect(() => {

    //Show (deleted) notifier if presentation is undefined AND presenter is NOT explicity given no presentation
    if (selectedSpace.presentations[props.presenter.presentation.presentationID] === undefined
      && props.presenter.presentation.presentationID !== "none"      
    ) {
      togglePresentationDeleted(true);
    };

  }, [])

  const handleManagePresenter = () => {
    setInitialEmailAddress(props.presenter.registrationData.emailAddress);
    setSelectedPresenter(props.presenter);
    setSelectedRegistration(props.presenter);
    toggleShowEditPopup(true);
  };
  

  /*Presenter edit*/
 /*  const handleEditPresenter = async (newPresenter) => {

    try {
      let response = await axios.put("/presenter", newPresenter);

      setSelectedPresenter(null);
      toggleHasError(false);
      togglePresentationDeleted(false);
      props.handleEditPresenter(response.data.Attributes);
      toggleManagePresenter(false);

    }
    catch (error) {
      toggleHasError(true);
      console.log(error);
    }
   
  } */
  /**/

  const handlePopupClose = (cancelled: boolean) => {

    if (cancelled) {

      //Reset email address
      let selectedRegistrationClone = selectedRegistration;

      selectedRegistrationClone.registrationData.emailAddress = initialEmailAddress;

      setSelectedRegistration(selectedRegistrationClone);
    }

    setSelectedRegistration(null);
    toggleShowEditPopup(false);
    props.handleEditPresenter(selectedRegistration);
  }

  const handleClearError = () => {
    toggleHasError(false);
  }

  const handlePresenterLinkCopy = () => {
    let dummy = document.createElement('textarea');

    document.body.appendChild(dummy);

    dummy.value = `https://go.showboat.live/${props.presenter.loginCode}`;
    dummy.select();

    document.execCommand('copy');
    document.body.removeChild(dummy);

    toggleCopyToClipboard(true);
  }

  const handleCopyToClipboardClose = () => {
    toggleCopyToClipboard(false);
  }
  

  //Material ui classes  
  const classes = BookingPresenterStyles();
  
  return (
    <React.Fragment>
      <div className={styles.presenterHolder}>
        <Typography variant="body1" className={classes.presenterName}>
          {props.presenter.registrationData.lastName.trim().length
            ? `${props.presenter.registrationData.lastName}, ${props.presenter.registrationData.firstName}`
            : `${props.presenter.registrationData.firstName}`
          }
        </Typography>

        <Typography variant="body1" className={classes.presenterCompany}>
          {props.presenter.registrationData.company}
        </Typography>

        <Typography variant="body1" className={classes.presenterEmail}>
          {props.presenter.registrationData.emailAddress}
        </Typography>

        {props.presenter.brand &&
          <div className={styles.brandDisplay}></div>
        }
        
        <div className={styles.iconHolder}>

          <div>
            <IconButton
              className={classes.linkIconButton}
              onClick={handlePresenterLinkCopy}
            >
              <LinkIcon 
                style={{
                  fontSize: "20px"
                }}
              />
            </IconButton>
          </div>
          
          <div>
            <IconButton
              className={classes.manageButton}
              onClick={handleManagePresenter}
            >
              <OpenIcon 
                style={{
                  fontSize: "20px"
                }}
              />
            </IconButton>
          </div>
          
        </div>
        

      </div>

      
      {selectedRegistration !== null &&
        <AttendeeEditPopup 
          handlePopupClose={handlePopupClose} 
          open={showEditPopup} 
          presenter={true}
        />
      }

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={classes.copySnackbar}
        autoHideDuration={1500}
        open={copyToClipboard}
        onClose={handleCopyToClipboardClose}
        message="Presenter Link Copied to Clipboard"
      />
      
    </React.Fragment>
  )
}
