import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingItemsDisplayStyles = makeStyles((theme: any) => createStyles({
  nameHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 30,
    top: -2,
    fontFamily: "Helvetica"
  },
  startHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 230,
    top: -2,
    fontFamily: "Helvetica"
  },
  endHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    right: 303,
    top: -2,
    fontFamily: "Helvetica"
  },
  registrationCountHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    right: 74,
    display: "inline-block",
    width: 100,
    top: -2,
    fontFamily: "Helvetica"
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    zIndex: 999,
    left: "calc(50% - 20px)",
    top: 40,
  },
  error: {
    fontSize: 14,
    color: "red",
    position: "relative",
    top: 50,
    textAlign: "center"
  },
  noBookingsError: {
    fontSize: 14,
    position: "relative",
    top: 50,
    textAlign: "center"
  }
})
)

export { BookingItemsDisplayStyles };