import axios from 'axios';

const jwt = require("jsonwebtoken");

export class JWTHelper {

  public static createJWT = (spaceID, bookingID) => {

    let payload = {
      spaceID,
      bookingID,
    }

    let jwtToken = jwt.sign(payload, process.env.REACT_APP_JWT_SECRET, {
      algorithm: "HS256",
      expiresIn: process.env.REACT_APP_JWT_EXPIRATION
    })

    //Assign JWT as default header so it is passed with every request
    axios.defaults.headers.common['producerauth'] = `Basic ${jwtToken}`;
    
    localStorage.setItem("producerJWT", jwtToken);

    return jwtToken;
  }

  public static storeJWT = (jwt) => {
    axios.defaults.headers.common['producerauth'] = `Basic ${jwt}`;

    localStorage.setItem("producerJWT", jwt);
  }
}