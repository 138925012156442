import { makeStyles, createStyles } from '@material-ui/core/styles';

const SpacePickerFormStyles = makeStyles((theme: any) => createStyles({
  spacePickerHeader: {
    fontSize: 20,
    color: "black",
    display: "block"
  },
  projectSelectHeader: {
    fontSize: 20,
    color: "black",
    display: "inline-block",
    position: "absolute",
    top: 80,
    left: 50,
  },
  projectSelectHolder: {
    minWidth: 260,
    maxWidth: 260,
    position: "relative",
    float: "left",
    top: 100,
    left: 50,
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    top: 210,
    right: 330,
  },
  spaceSelectHeader: {
    fontSize: 20,
    color: "black",
    display: "inline-block",
    position: "absolute",
    top: 80,
    right: 255,
  },
  spaceSelectHolder: {
    minWidth: 260,
    maxWidth: 260,
    position: "relative",
    float: "right",
    top: 100,
    right: 50,
  },
  projectMenu: {
    top: 150,
    textAlign: "left",
    paddingLeft: 15,
  },
  spaceMenu: {
    top: 150,
    textAlign: "left",
    paddingLeft: 15,
  },
  spacePickerNextButton: {
    height: 64,
    width: 169,
    position: "relative",
    top: 275,
  },
  error: {
    fontSize: "1rem",
    position: "relative",
    top: 25,
    color: "red"
  },
  signOutButton: {
    position: "absolute",
    top: 15,
    right: 15
  },
  menuItem: {
    paddingLeft: 20,
  },
  menuItemSelected: {
    paddingLeft: 20,
  },
  adminButton: {
    top: 10,
    left: 10,
    position: "absolute"
  }
})
)

export { SpacePickerFormStyles };