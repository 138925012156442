import { Dialog, Typography, IconButton } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import styles from './DetailsPopup.module.css';
import { DetailsPopupStyles } from './DetailsPopupStyles';


interface Props {
  open: boolean,
  firstName: string,
  lastName: string,
  data: any,
  systemCheckID: string,
  comment: string,
  handlePopupClose: () => any,
}

export default function DetailsPopup(props: Props): ReactElement {

  const classes = DetailsPopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper}}  
    >
      <div className={classes.popup}>

        <IconButton 
          className={classes.closeButton}
          onClick={props.handlePopupClose}
        >
          <CloseIcon />
        </IconButton>
        
        <Typography variant="body1" className={classes.systemCheckID}>
          <b>SystemCheck ID: </b>{props.systemCheckID}
        </Typography>

        <Typography variant="body1" className={classes.name}>
          <b>Name: </b>{props.firstName} {props.lastName}
        </Typography>

        <div className={styles.itemHolderLeft}>

          <Typography variant="body1" className={classes.itemText}>
            <b>Browser:</b> {props.data.browserName} {props.data.browserVersion}
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Operating System:</b> {props.data.operatingSystem} {props.data.operatingSystemVersion}
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Can Reach AWS: </b> 
            <span style={{ color: props.data.canReachAWS ? "green" : "red"}}>
              {props.data.canReachAWS ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Can Reach Game Server: </b> 
            <span style={{ color: props.data.canReachGameServer ? "green" : "red"}}>
              {props.data.canReachGameServer ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Can Reach Liveswitch: </b> 
            <span style={{ color: props.data.canReachLiveswitch ? "green" : "red"}}>
              {props.data.canReachLiveswitch ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Can Use Websockets: </b> 
            <span style={{ color: props.data.canUseWebsockets ? "green" : "red"}}>
              {props.data.canUseWebsockets ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Are Websockets Blocked?: </b> 
            <span style={{ color: props.data.areWebsocketsBlocked ? "red" : "green"}}>
              {props.data.areWebsocketsBlocked ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Are Web Workers Supported?: </b> 
            <span style={{ color: props.data.areWebWorkersSupported ? "green" : "red"}}>
              {props.data.areWebWorkersSupported ? "Yes" : "No"}
            </span>
          </Typography>
        </div>

        <div className={styles.itemHolderRight}>
          <Typography variant="body1" className={classes.itemText}>
            <b>Has Microphone: </b> 
            <span style={{ color: props.data.hasMic ? "green" : "red"}}>
              {props.data.hasMic ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Has Camera: </b> 
            <span style={{ color: props.data.hasCamera ? "green" : "red"}}>
              {props.data.hasCamera ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Granted Camera Permissions: </b> 
            <span style={{ color: props.data.hasCameraPermissions ? "green" : "red"}}>
              {props.data.hasCameraPermissions ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Granted Microphone Permissions: </b> 
            <span style={{ color: props.data.hasMicPermissions ? "green" : "red"}}>
              {props.data.hasMicPermissions ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Can Screenshare: </b> 
            <span style={{ color: props.data.canScreenshare ? "green" : "red"}}>
              {props.data.canScreenshare ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>Can Video Stream: </b> 
            <span style={{ color: props.data.canVideoStream ? "green" : "red"}}>
              {props.data.canVideoStream ? "Yes" : "No"}
            </span>
          </Typography>

          <Typography variant="body1" className={classes.itemText}>
            <b>WebRTC Supported?: </b> 
            <span style={{ color: props.data.isWebRTCSupported ? "green" : "red"}}>
              {props.data.isWebRTCSupported ? "Yes" : "No"}
            </span>
          </Typography>

        </div>

        <Typography variant="body1" className={classes.comment}>
          <b>Comment: </b> {props.comment}
        </Typography>
      </div>
    </Dialog>
  )
}
