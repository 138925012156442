import React, { ReactElement, useContext, useState } from 'react';
import axios from 'axios';
import { Typography, FormControlLabel, Switch, Dialog, CircularProgress } from '@material-ui/core';

import AttendeeForm from '../AttendeeForm/AttendeeForm';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import styles from './AddAttendeePopup.module.css';
import { AddAttendeePopupStyles } from './AddAttendeePopupStyles';
import PopupHeader from '../../../PopupHeader/PopupHeader';

interface Props {
  open: boolean,
  handleAttendeeAdd: (newAttendee: any) => any,
  handlePopupClose: () => any,
  isPresenter
}

export default function AddAttendeePopup(props: Props): ReactElement {

  const {
    selectedBooking
  } : AppContextType = useContext(AppContext);

  const [ brand, toggleBrand ] = useState(false);
  const [ allowEarlyAccess, toggleAllowEarlyAccess ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ error, toggleError ] = useState(false);

  //Add attendee submit handler
  const handleAddAttendeeSubmit = async (newAttendeeObject : SHOWBOAT.Registration) => {

    toggleLoadingSpinner(true);

    //Add brand and allowEarlyAccess properties to passed attendee object
    if (brand || props.isPresenter) {
      newAttendeeObject.brand = true;
    } else {
      newAttendeeObject.brand = false;
    };

    if (allowEarlyAccess) {
      newAttendeeObject.allowEarlyAccess = true;
    } else {
      newAttendeeObject.allowEarlyAccess = false;
    }
    
    toggleError(false);
    
    try {
      let response = await axios.post(`${props.isPresenter ? "/presenter" : "/registration"}`, newAttendeeObject);
      
      toggleLoadingSpinner(false);
      props.handleAttendeeAdd(response.data);
    }
    catch (error) {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
  }

  //Brand toggle handler
  const handleBrandChange = () => {
    if (brand) {
      toggleBrand(false);
    } else {
      toggleBrand(true);
    }
  }

  //Early access toggle handler
  const handleAllowEarlyAccessChange = () => {
    if (allowEarlyAccess) {
      toggleAllowEarlyAccess(false);
    } else {
      toggleAllowEarlyAccess(true);
    }
  }

  //Close popup
  const handleAddAttendeeClose = () => {
    toggleError(false);
    props.handlePopupClose();
  }
  
  //material ui classes
  const classes = AddAttendeePopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: 
        selectedBooking.intakeFields[5].type === "international" ? `${classes.dialogPaper} ${classes.dialogPaperInternational}` : classes.dialogPaper 
      }}
    >
      <div className={selectedBooking.intakeFields[5].type === "international" ? `${classes.popup} ${classes.popupInternational}` : classes.popup}>
        
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.addAttendeeHeader}>
          Add {props.isPresenter ? "Presenter" : "Attendee"}
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred creating {props.isPresenter ? "a presenter" : "an attendee"} for this booking
          </Typography>
        }

        <div className={styles.attendeeFormHolder}>
          <AttendeeForm 
            isAddingAttendee={true}
            isLoading={loadingSpinner}
            handlePopupClose={handleAddAttendeeClose} 
            handleAttendeeSubmit={handleAddAttendeeSubmit} 
            emailError={false}
          />
        </div>

        {!props.isPresenter &&
          <div className={styles.brandToggleHolder}>
            <FormControlLabel
              control={<Switch onChange={handleBrandChange} value={brand} color="secondary" className={classes.brandToggle} />}
              label="Brand"
              classes={{
                label: classes.toggleLabel
              }}
            />
          </div>
        }

        <div className={styles.allowEarlyAccessToggleHolder}>

          <FormControlLabel
            control={<Switch checked={allowEarlyAccess} onChange={handleAllowEarlyAccessChange} color="secondary" className={classes.allowEarlyAccessToggle} />}
            label="Allow Early Access"
            classes={{
              label: classes.toggleLabel
            }}
          />
          
        </div>

        {loadingSpinner &&
          <CircularProgress className={selectedBooking.intakeFields[5].type === "international" ? `${classes.loadingSpinner} ${classes.loadingSpinnerInternational}` : classes.loadingSpinner } />
        }

      </div>
    </Dialog>
    
  )
}
