import { Paper } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { ProjectsAdminEditStyles } from './ProjectsAdminEditStyles'

interface Props {
  
}

export default function ProjectsAdminEdit(props: Props): ReactElement {

  const classes = ProjectsAdminEditStyles();
  
  return (
    <Paper className={classes.projectAdminWrapper}>
      
    </Paper>
  )
}
