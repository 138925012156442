const DefaultAttendeeLink = `
<~~~WELCOME HEADER~~~>Here's Your Invite!<~~~END WELCOME HEADER~~~>

Hi {~~~FIRSTNAME~~~},

Below is a link to join us in the Showboat virtual event platform. We are excited to see you!

<~~~ADD TO CALENDAR LINK~~~>

<~~~EVENT DETAILS~~~>
`

export default DefaultAttendeeLink;