import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const ProducerAdminEditStyles = makeStyles((theme: any) => createStyles({
  producerAdminWrapper: {
    position: "absolute",
    display: "inline-block",
    width: 714,
    height: 750,
    top: -30,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  createProducerHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
  producerDivider: {
    width: "90%",
    position: "relative",
    top: 25,
    display: "inline-block"
  },
  producerSelectHolder: {
    position: "absolute",
    left: 0,
    width: 300,
    top: 40
  },
  producerMenu: {
    top: 150,
    textAlign: "left",
    paddingLeft: 15,
    maxHeight: 200,
  },
  producerNameHeader: {
    position: "relative",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
  },
  producerMenuPaper: {
    maxHeight: 300,
  },
  menuItem: {
    paddingLeft: 20,
  },
  menuItemSelected: {
    paddingLeft: 20,
  },
  assignProjectsHeader: {
    position: "relative",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
    top: 150,
    right: 135,
  },
  listHolder: {
    width: 270,
    height: 300,
    overflow: 'auto',
  },
  button: {

  },
  saveButton: {
    position: "absolute",
    bottom: 30,
    right: 30,
  },
  loadingSpinner: {
    position: "absolute",
    width: "25px !important",
    height: "25px !important",
    bottom: 35,
    right: 120,
  },
  success: {
    fontSize: 18,
    fontWeight: 400,
    display: "inline-block",
    position: "absolute",
    top: 150,
    left: 0,
    right: 0,
    center: 0,
    margin: "auto"
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    position: "relative",
    bottom: 10,
  },
  producerLoadingSpinner: {
    position: "absolute",
    top: 45,
    left: 325,
  }
})
)

export { ProducerAdminEditStyles };