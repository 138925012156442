import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeInformationPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper:{ 
    width: 800,
    height: 387,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down('sm')]: {
      width: 720,
    }
  },
  popup: {
    position: "absolute",
    width: "100%",
    height: 387,
  },
  closeIconButton: {
    position: "absolute",
    top: 3,
    color: "white",
    padding: 4,
    right: 5,
  },
  attendeeInformationHeader: {
    fontSize: "1.5rem",
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 58,
  },
  attendeeURL: {
    fontSize: "1.125rem",
    position: "absolute",
    fontWeight: 400,
    right: 42,
    top: 58,
  },
  copyButton: {
    position: "relative",
    color: "black",
    padding: 4,
    left: 5,
  },
  informationHeader: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    position: "absolute",
    left: 0,
    bottom: 10,
  },
  informationText: {
    fontSize: "0.875rem",
    position: "absolute",
    left: 104.5,
    bottom: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
    width: 260,
  },
  copySnackbar: {
    width: 360,
  }
})
)

export { AttendeeInformationPopupStyles };