import React, {useState, useContext, useEffect, useRef} from 'react';
import {BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Login from './Pages/Login/Login';
import Main from './Pages/Main/Main';
import SpacePicker from './Pages/SpacePicker/SpacePicker';
import SpaceAdmin from './Pages/SpaceAdmin/SpaceAdmin';
import ProducerAdminPage from './Pages/ProducerAdminPage/ProducerAdminPage';
import axios from 'axios';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { AppContext } from './context/AppContext';
import { AuthContext } from './context/AuthContext';
import ProjectsAdmin from './Pages/ProjectsAdmin/ProjectsAdmin';
import AdminMenu from './Pages/AdminMenu/AdminMenu';
import BookingsAdmin from './Pages/BookingsAdmin/BookingsAdmin';
import UsageAdmin from './Pages/UsageAdmin/UsageAdmin';
import SystemCheckAdmin from './Pages/SystemCheckAdmin/SystemCheckAdmin';
import CacheAdmin from './Pages/CacheAdmin/CacheAdmin';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import LoadingSpinner from './Pages/LoadingSpinner/LoadingSpinner';
import { JWTHelper } from './utilities/JWTHelper';

export default function App(props) {

  const history = useHistory();
  
  const {
    producerID,
    setProducerID,
    setSelectedSpace,
    setSelectedSpaceID,
    setSelectedProject,
    setSelectedBooking,
    setSelectedBookingID,
  } = useContext(AppContext);

  const {
    setIdToken
  } = useContext(AuthContext);

  let urlPath = window.location.pathname;
  
  const [ spaceLink, toggleSpaceLink ] = useState(urlPath.split("/").includes("space"));
  const [bookingLink, toggleBookingLink] = useState(urlPath.split("/").includes("booking"));
  const [ render, toggleRender ] = useState(false);
  const [ isLoadingContent, toggleIsLoadingContent ] = useState(false);
  const [ error, toggleError ] = useState(false);
  const [ isAuthenticated, toggleIsAuthenticated ] = useState(false);

  useEffect(() => {

    let urlPathArray = urlPath.split("/");

    //Check for "/space" in the URL
    if (urlPathArray.includes("space")) {
      sessionStorage.setItem("spaceLink", true);
      sessionStorage.setItem("linkSpaceID", urlPathArray[2]);
      toggleSpaceLink(true);
    }

    //Check for "/booking" in the URL
    if (urlPathArray.includes("booking")) {
      sessionStorage.setItem("bookingLink", true);
      sessionStorage.setItem("linkBookingID", urlPathArray[2]);
      toggleBookingLink(true);
    }

    let isProducerAuthenticated = checkAuthentication();

    toggleIsAuthenticated(checkAuthentication);

    if (urlPath.split("/").includes("error")) {
      toggleError(true);
      toggleRender(true);
      return;
    }

    //If we are authenticated and not coming from space or booking link, navigate to home
  /*   if (isProducerAuthenticated && !spaceOrBookingLinkCheck() && 
      !urlPath.split("/").includes("error") &&
      !urlPath.split("/").includes("admin-menu")
    ) {
      console.log("Navigating to home");
      console.log("A");
      window.history.pushState(null, "foo", "/home")
      toggleRender(true);
      return;
    } */

    //If we are authenticated and coming from space or booking link, 
    //show general loader while content is fetched
    if (isProducerAuthenticated && spaceOrBookingLinkCheck() && !urlPath.split("/").includes("error")) {

      JWTHelper.createJWT(urlPathArray[2], "");

      //Show loader while content is fetched
      toggleIsLoadingContent(true);

      //Ensure sessionStorage variables are set
      setSessionStorageVariables(spaceLink);

      const getBookingOrSpaceInUseEffect = async () => {
        await getBookingOrSpaceObject(urlPath.split("/").includes("booking"), localStorage.getItem("producerID"));
      }
      getBookingOrSpaceInUseEffect();

    }
    toggleRender(true);

    return function cleanup() {
      
    }


  }, [isAuthenticated]);

  const getBookingOrSpaceObject = async (isBooking, producerIDParam) => {
    let params;

    //Assemble parameters object
    if (isBooking) {
      params = {
        bookingID: urlPath.split("/")[2],
        producerID: producerIDParam
      }
    } else {
      params = {
        spaceID: urlPath.split("/")[2],
        producerID: producerIDParam
      }
    };

    try {
      let bookingOrSpaceResponse = await axios.get(isBooking ? "/booking" : "/space", {
        params: params
      });

      //If a JWT was sent back, store it
      if (bookingOrSpaceResponse.data.jwt) {
        JWTHelper.storeJWT(bookingOrSpaceResponse.data.jwt);
      }

      //If space request was made, set the space as selected
      if (!isBooking) {
        setSelectedSpace(bookingOrSpaceResponse.data.space);
        setSelectedSpaceID(bookingOrSpaceResponse.data.space.spaceID);

        //Legacy check for project
        if (bookingOrSpaceResponse.data.space.project) {
          setSelectedProject(bookingOrSpaceResponse.data.space.project);
        } else {
          await getProject(bookingOrSpaceResponse.data.space.projectID);
        }
      } else {
        //A booking was gotten, so get space first
        let spaceID = bookingOrSpaceResponse.data.booking.spaceID;

        await getSpace(spaceID);

        setSelectedBooking(bookingOrSpaceResponse.data.booking);
        setSelectedBookingID(bookingOrSpaceResponse.data.booking.bookingID);
      }


      window.history.pushState(
        null,
        "foo",
        isBooking
          ? `/booking/${bookingOrSpaceResponse.data.booking.bookingID}`
          : `/space/${bookingOrSpaceResponse.data.space.spaceID}`
      );

      toggleIsLoadingContent(false);

    }
    catch (err) {
      toggleError(true);
      //If 401 is returned, we are unauthorized. So, show sign in form
      //Otherwise, show invalid link page
      if ((err.response) && (err.response.status !== 401)) {
        window.history.pushState(
          null,
          "foo",
          "/error"
        );
      } else {
        //Show sign-in form
        window.history.pushState(
          null,
          "foo",
          "/error"
        );
      }

      toggleIsLoadingContent(false);

    }
  };

  const spaceOrBookingLinkCheck = () => {
    return (urlPath.split("/").includes("booking") || urlPath.split("/").includes("space"));
  }

  //Check for authentication
  const checkAuthentication = () => {
    if (localStorage.getItem("producerID") !== null) {
      setProducerID(localStorage.getItem("producerID"))
    } else {
      return false;
    }

    if (localStorage.getItem("idToken") !== null) {
      setIdToken(localStorage.getItem("idToken"));
      axios.defaults.headers.common['Authorization'] = localStorage.getItem("idToken");
    } else {
      return false;
    }

    return true;
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#868686"
      },
      secondary: {
        main: "#FF0092"
      }
    },
    breakpoints: {
      values: {
        lg: 1281,
        md: 960,
      }
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': 'thin',
          },
          '*::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          }
        }
      },
      
    }
  })

  const setSessionStorageVariables = (fromSpaceLink) => {
    if (fromSpaceLink) {
      sessionStorage.setItem("spaceLink", "true");
    } else {
      sessionStorage.setItem("bookingLink", "true")
    }
  }

  const handleSpaceLinkEnter = () => {
    toggleSpaceLink(false);
  }

  const handleBookingLinkEnter = () => {
    toggleBookingLink(false);
  }

  const handleAuthenticate = () => {
    toggleIsAuthenticated(true);
  }

  const getProject = async (projectID) => {
    try {
      let projectResponse = await axios.get("/project", {
        params: {
          projectID: projectID
        }
      });

      setSelectedProject(projectResponse.data);
    }
    catch (err) {
      //TODO: TODO: TODO:
      console.log("ERR")
    }
  }

  const getSpace = async (spaceID) => {
    try {
      let response = await axios.get("/space", {
        params: {
          spaceID: spaceID,
          producerID: producerID
        }
      })

      let responseObj = response.data;

      //Legacy check for project
      if (responseObj.space.project) {
        setSelectedProject(responseObj.space.project);
      } else {
        await getProject(responseObj.space.projectID);
      }

      setSelectedSpace(responseObj.space);
      setSelectedSpaceID(responseObj.space.spaceID);
    }
    catch (err) {
      console.log("err");

    }
  }

  const handleErrorLinkClick = () => {
    toggleError(false);
  }

  if (render) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <Router>
            <div className="app" id="app">

              {isAuthenticated
              ? 
              /* Show authenticated set of routes */
              (
                <React.Fragment>
                  {isLoadingContent 
                  ?
                    <LoadingSpinner /> 
                  :
                    <React.Fragment>
                      {error 
                        ?
                          <ErrorPage 
                            errorText="Unable to find a page using the link provided."
                            handleLinkClick={handleErrorLinkClick}
                          />
                        :
                        <Switch>
                          <Route exact path={["/space/:spaceId", "/booking/:bookingId"]}>
                            <Main
                              spacePicker={false}
                              spaceLink={urlPath.split("/").includes("space")}
                              bookingLink={urlPath.split("/").includes("booking")}
                            />
                          </Route>
                          <Route exact path="/home">
                            <Main
                              spacePicker={true}
                            />
                          </Route>
                          <Route exact path="/space-admin">
                            <SpaceAdmin />
                          </Route>
                          <Route exact path="/producer-admin">
                            <ProducerAdminPage />
                          </Route>
                          <Route exact path="/bookings-admin">
                            <BookingsAdmin />
                          </Route>
                          <Route exact path="/usage-admin">
                            <UsageAdmin />
                          </Route>
                          <Route exact path="/projects-admin">
                            <ProjectsAdmin />
                          </Route>
                          <Route exact path="/admin-menu">
                            <AdminMenu />
                          </Route>
                          <Route exact path="/cache-admin">
                            <CacheAdmin />
                          </Route>
                          <Route exact path="/system-check-admin">
                            <SystemCheckAdmin />
                          </Route>
                          <Route exact path="/">
                            <Login
                              fromBookingLink={false}
                              fromSpaceLink={false}
                            />
                          </Route>
                          <Route path="*">
                            <ErrorPage
                              errorText="Unable to find a page using the link provided."
                            />
                          </Route>
                          <Route exact path="/error">
                            <ErrorPage
                              errorText="Unable to find a page using the link provided."
                            />
                          </Route>
                        </Switch>
                    }
                    </React.Fragment>
                  }
                  
                </React.Fragment>

              )
              :
              (
                /*  We are unauthenticated, so show login form */
                <Switch>
                  <Route exact path={["/", "/space/:spaceId", "/booking/:bookingId"]}>
                    <Login
                      fromSpaceLink={spaceLink}
                      fromBookingLink={bookingLink}
                      handleAuthenticate={handleAuthenticate}
                    />
                  </Route>
                  <Route path="*">
                    <ErrorPage
                      errorText="Unable to find a page using the link provided."
                    />
                  </Route>
                  <Route exact path="/error">
                    <ErrorPage
                      errorText="Unable to find a page using the link provided."
                    />
                  </Route>
                </Switch>
              )}
              
            </div>
          </Router>
        </ThemeProvider>
      </MuiPickersUtilsProvider>

    );

  } else {
    return null;
  }

  
  
}


