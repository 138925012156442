import React, { ReactElement, useContext } from 'react';
import Reorder from 'react-reorder';
import { Typography } from '@material-ui/core';

import { AppContext, AppContextType } from '../../../context/AppContext';

import styles from './SlideReorder.module.css';
import { SlideReorderStyles } from './SlideReorderStyles';

interface Props {
  handleSlideSelect: (slideURL: string) => any,
  handleSlideRemove: (slideURL: string) => any,
  handleSlideReorder: (previousIndex, nextIndex) => any,
  selectedSlides: string[],
  slides: string[]
}

export default function SlideReorder(props: Props): ReactElement {

  const { 
    selectedPresentation 
  } : AppContextType = useContext(AppContext);

  //Reorder slide handler
  const handleReorder = (e, previousIndex, nextIndex, fromId, toId) => {
    props.handleSlideReorder(previousIndex, nextIndex);
  };

  //If slide is selected, remove from selected. Otherwise, add to selected array
  const handleSlideClick = (slide : string) => {
    if (props.selectedSlides.includes(slide)) {
      props.handleSlideRemove(slide);
    } else {
      props.handleSlideSelect(slide)
    }
  };

  const classes = SlideReorderStyles();

  return (
    <div className={styles.slideHolder}>

      {props.slides.length === 0 && 
        <Typography className={classes.noSlidesText} variant="body1">
          No slides found for this presentation
        </Typography>
      }
      <Reorder
        reorderId="slide-list"
        reorderGroup="reorder-group"
        onReorder={handleReorder}
        autoScroll={true}
      >
        {props.slides.map((slide, i) => (
          <div 
            key={i} 
            onClick={() => handleSlideClick(slide)}
            className={
              props.selectedSlides.includes(slide)
              ? `${styles.slideItem} ${styles.slideItemSelected}`
              : styles.slideItem
            }
          >
            
            <Typography variant="body1" className={classes.slideNumber}>
              {i + 1}
            </Typography>

            <img className={styles.slideImage} src={selectedPresentation.thumbnailRoot + slide} alt="presentation slide" />

          </div>
        ))}
      </Reorder>
    </div>
  )
}
