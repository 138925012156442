import { Button, CircularProgress, Divider, FormControlLabel, Switch, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useState, useContext, useEffect, useRef } from 'react';
import { AppContext, AppContextType } from '../../context/AppContext';
import AddCalendarPopup from './AddCalendarPopup/AddCalendarPopup';
import AddIcon from '@material-ui/icons/Add';

import CalendarEdit from './CalendarEdit/CalendarEdit';
import CalendarItem from './CalendarItem/CalendarItem';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

import styles from './CalendarWindow.module.css';
import { CalendarWindowStyles } from './CalendarWindowStyles';
import { orderBy } from 'natural-orderby';
import CalendarItemsDisplay from './CalendarItemsDisplay/CalendarItemsDisplay';

interface Props {
  
}

export default function CalendarWindow(props: Props): ReactElement {
  
  const { 
    selectedCalendar, 
    setSelectedCalendar,
  } : AppContextType = useContext(AppContext);

  const [ addAndEdit, toggleAddAndEdit ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  useEffect(() => {

    //Show loading spinner on load
    toggleLoadingSpinner(true);

  }, []);

  //Add and edit
  const handleAddAndEditClick = () => {
    toggleAddAndEdit(true);
  }

  //Add and edit close
  const handleAddAndEditClose = () => {
    toggleAddAndEdit(false);
  }

  //Cancel 
  const handleCancelClick = () => {
    toggleAddAndEdit(false);
  }

  //Edit back
  const handleCalendarEditBackClick = () => {
    toggleLoadingSpinner(true);
    setSelectedCalendar(null);
  }
  
  const classes = CalendarWindowStyles();

  if (selectedCalendar !== null) {
    return (
      <CalendarEdit handleBackButtonClick={handleCalendarEditBackClick} />
    )
  } else {
    return (
      <React.Fragment>
        <Typography variant="h1" classes={{ root: classes.spaceHeader }}>
          Calendar
        </Typography>
  
        <Button
          color="primary"
          variant="contained"
          classes={{ root: classes.addAndEditButton }}
          onClick={handleAddAndEditClick}
        >
          <AddIcon className={classes.addIcon}></AddIcon>
          NEW
      </Button>
  
        <Typography variant="h2" classes={{ root: classes.calendarNameHeader }}>
          Name
        </Typography>

        <CalendarItemsDisplay />
  
        {addAndEdit &&
          <AddCalendarPopup 
            open={true} 
            handlePopupClose={handleAddAndEditClose}
            handleCancelClick={handleCancelClick}
          />
        }
  
      </React.Fragment>
    )
  }
  
  
}
