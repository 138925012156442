import { makeStyles, createStyles } from '@material-ui/core/styles';

const AddAttendeePopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 1003,
    height: 644,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down('sm')]: {
      width: 700
    }
  },
  dialogPaperInternational: {
    height: 794,
  },
  popup: {
    width: "100%",
    height: 644,
    position: "absolute"
  },
  popupInternational: {
    height: 794
  },  
  addAttendeeHeader: {
    fontSize: "1.5rem",
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 58
  },
  cancelButton: {
    width: 95,
    height: 44,
    position: "absolute",
    bottom: 55,
    right: 225
  },
  saveButton: {
    width: 95,
    height: 44,
    position: "absolute",
    bottom: 55,
    right: 100,
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  brandToggle: {
    position: "relative",
    left: 105,
  },
  loadingSpinner: {
    width: "20px !important",
    height: "20px !important",
    right: 62,
    bottom: 84,
    color: "grey",
    position: "absolute",
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      right: 54,
      bottom: 82
    }
  },
  loadingSpinnerInternational: {
    bottom: 110,
  },
  error: {
    color: "red",
    position: "absolute",
    right: 575,
    top: 86,
    [theme.breakpoints.down('sm')]: {
      left: 30,
      top: 88,
      right: 0
    }
  },
  allowEarlyAccessToggle: {
    position: "relative",
    left: 200
  },
  toggleLabel: {
    fontSize: 16
  }
})
)

export { AddAttendeePopupStyles };