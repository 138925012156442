import { Card, Paper, Typography } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';

import styles from './AvatarColorPreview.module.css';
import { AvatarColorPreviewStyles } from './AvatarColorPreviewStyles';

interface Props {
  colors: any,
}

export default function AvatarColorPreview(props: Props): ReactElement {

  const [ colorOptions, setColorOptions ] = useState(props.colors);
  const [ currentSelection, setCurrentSelection ] = useState(props.colors[0])

  const handleColorSelectionChange = (i) => {
    setCurrentSelection(colorOptions[i]);
  }

  const classes = AvatarColorPreviewStyles();

  //Set up color for the avatar preview 
  let borderStyles = {
    fill: currentSelection.primary,
  }

  let faceStyles = {
    backgroundColor: currentSelection.secondary,
  }
  
  return (
    <div className={styles.avatarPreviewHolder}>

      <div className={styles.avatarHolder}>
        
        <svg className={styles.avatarFaceBorder} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170" style={borderStyles}>
            <defs>
            </defs>
            <title>avatarHead-overlay</title>
            <g id="Layer_1" data-name="Layer 1">
              <path className="cls-1" d="M158.85,0H9.24A9.24,9.24,0,0,0,0,9.24V160a10,10,0,0,0,10,10H160a10,10,0,0,0,10-10V11.15A11.15,11.15,0,0,0,158.85,0ZM161,159a2,2,0,0,1-2,2H11a2,2,0,0,1-2-2V11a2,2,0,0,1,2-2H43.09c1.93,5.6,6.1,9.24,10.73,9.24h62.36c4.63,0,8.8-3.64,10.73-9.24H159a2,2,0,0,1,2,2Z"/>
            </g>
        </svg>

        <img 
          alt="avatar face" 
          src="assets/images/face-01.svg" 
          style={faceStyles} 
          className={styles.avatarFace} 
        />
        

      </div>

      <div className={styles.selectionHolder}>
        <Typography variant="h2" className={classes.optionsHeader}>
          Color Options
        </Typography>

        <form className={styles.colorForm}>
          {colorOptions.map((color, i) => {
            
            if (i === colorOptions.indexOf(currentSelection)) {

              if (color.primary === "") {
                return null;
              } else {
                return (
                  <Paper key={i} style={{ backgroundColor: color.primary }} classes={{ root: classes.colorInputPaperSelected }}>
                    <input
                    type="button"
                    onClick={() => handleColorSelectionChange(i)}
                    onFocus={() => handleColorSelectionChange(i)}
                    className={styles.colorInput}
                    >
                    </input>
      
                    <Card elevation={0} className={classes.selectedIcon}>
                      <CheckIcon classes={{ root: classes.checkIcon }} />
                    </Card>
                  </Paper>
                )
              }
              
            } else {

              if (color.primary === "") {
                return null;
              } else {
                return (
                  <Paper key={i} style={{ backgroundColor: color.primary }} classes={{ root: classes.colorInputPaper }}>
                    <input
                    type="button"
                    onClick={() => handleColorSelectionChange(i)}
                    onFocus={() => handleColorSelectionChange(i)}
                    className={styles.colorInput}
                    >
                    </input>
                  </Paper>
                )
              }
            }

          })}
        </form>
      </div>
    </div>
  )
}
