import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarItemsDisplayStyles = makeStyles((theme: any) => createStyles({
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    top: 80,
    marginLeft: "calc(50% - 20px)"
  },
  error: {
    fontSize: 14,
    color: 'red',
    position: "absolute",
    top: 75,
    marginLeft: "calc(50% - 159px)",
    fontWeight: 400,
  },
  noCalendarsError: {
    fontSize: 14,
    position: "absolute",
    top: 75,
    marginLeft: "calc(50% - 109px)",
    fontWeight: 400,
  }
}))

export { CalendarItemsDisplayStyles };