import { makeStyles, createStyles } from '@material-ui/core/styles';

const AvatarColorItemStyles = makeStyles((theme: any) => createStyles({
  avatarColorHolder: {
    width: 187,
    height: 155,
    display: "inline-block",
    position: "relative",
    overflow: "visible",
    marginRight: 32,
    [theme.breakpoints.down('sm')]: {
      width: 155,
    }
  },
  editIcon: {
    fontSize: 16,
    position: 'relative',
    left: 2,
    bottom: 0.5
  },
  bodyColorHeader: {
    fontSize: 18,
    position: "absolute",
    top: 13,
    right: 60,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      right: 42
    }
  },
  bodyColor: {
    fontSize: 18,
    position: "absolute",
    top: 40,
    left: 87.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      left: 75.5,
    }
  },
  faceColorHeader: {
    fontSize: 18,
    position: "absolute",
    top: 80,
    right: 60,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      right: 42
    }
  },
  faceColor: {
    fontSize: 18,
    position: "absolute",
    top: 106,
    left: 87.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      left: 75.5,
    }
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    fontSize: 20,
    color: "grey",
    "&:hover": {
      cursor: "pointer"
    }
  },
  addIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    fontSize: 20,
    color: "grey",
    "&:hover": {
      cursor: "pointer"
    }
  }
})
)

export { AvatarColorItemStyles };