import { Typography, Button } from '@material-ui/core';
import React, { ReactElement, useState, useEffect } from 'react';
import moment from 'moment';

import styles from './SystemCheckAdminItem.module.css';
import { SystemCheckAdminItemStyles } from './SystemCheckAdminItemStyles';
import DetailsPopup from '../DetailsPopup/DetailsPopup';

interface Props {
  firstName: string,
  lastName: string,
  data: any,
  timestamp: string,
  comment: string,
  systemCheckID: string
}

export default function SystemCheckAdminItem(props: Props): ReactElement {

  const [ showDetailsPopup, toggleShowDetailsPopup ] = useState(false); 

  //Details click
  const handleDetailsButtonClick = () => {
    toggleShowDetailsPopup(true);
  }

  //Close details popup
  const handleDetailsPopupClose = () => {
    toggleShowDetailsPopup(false);
  }
  
  const classes = SystemCheckAdminItemStyles();
  
  return (

    <React.Fragment>

      <div className={styles.systemCheckItemHolder}>

        <Typography variant="body1" className={`${classes.systemCheckItemHeader} ${classes.name}`}>
          {props.firstName} {props.lastName}
        </Typography>

        <Typography variant="body1" className={`${classes.systemCheckItemHeader} ${classes.date}`}>
          {moment(props.timestamp).format("L")}        
        </Typography>

        <Typography variant="body1" className={`${classes.systemCheckItemHeader} ${classes.time}`}>
          {moment.tz(props.timestamp, "America/Chicago").format("LT z")}        
        </Typography>
        
        <Typography variant="body1" className={`${classes.systemCheckItemHeader} ${classes.systemCheckID}`}>
          {props.systemCheckID}        
        </Typography>

        <Button 
          className={classes.detailsButton} 
          onClick={handleDetailsButtonClick}
          variant="contained"
          color="primary"
        >
          Details
        </Button>
        
      </div>

      {showDetailsPopup &&
        <DetailsPopup 
          open={true} 
          data={props.data} 
          firstName={props.firstName}
          lastName={props.lastName}
          comment={props.comment}
          systemCheckID={props.systemCheckID}
          handlePopupClose={handleDetailsPopupClose}
        />
      }

    </React.Fragment>

  )
}
