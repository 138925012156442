import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';

import { AttendeeItemStyles } from './AttendeeItemStyles';
import styles from './AttendeeItem.module.css';
import { Typography } from '@material-ui/core';

interface Props {
  start,
  end,
  firstName,
  lastName,
  attendeeID,
  timezone,
  handleAttendeeSelect,
}

export default function AttendeeItem(props: Props): ReactElement {

  const [ isSelected, toggleIsSelected ] = useState(false);
  
  const classes = AttendeeItemStyles();
  
  //Select attendee
  const handleAttendeeSelect = () => {

    //Change local state to change styles
    toggleIsSelected(!isSelected);

    props.handleAttendeeSelect(props.attendeeID);
    
  }
  
  return (
    <div 
      onClick={handleAttendeeSelect} 
      className={isSelected 
        ? `${styles.attendeeItemHolder} ${styles.attendeeItemHolderSelected}`
        : styles.attendeeItemHolder
      }
    >

      <Typography style={{ left: 15 }} variant="body1" className={classes.attendeeItemText}>
        {props.firstName} {props.lastName}
      </Typography>

      <Typography style={{ left: 200, width: 100 , top: 4,}} variant="body1" className={classes.attendeeItemText}>
        {`${moment.tz(props.start, props.timezone).format("MM/DD/YYYY")} ${moment.tz(props.start, props.timezone).format("h:mm A z")}`}
      </Typography>

      <Typography style={{ left: 350, width: 100, top: 4, }} variant="body1" className={classes.attendeeItemText}>

        {props.end !== "" 
        ? `${moment.tz(props.end, props.timezone).format("MM/DD/YYYY")} ${moment.tz(props.end, props.timezone).format("h:mm A z")}`
        : ""}
        
      </Typography>

      
    </div>
  )
}
