import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingCurrentAttendeesStyles = makeStyles((theme: any) => createStyles({
  currentAttendeesHeaderHolder: {
    position: "absolute",
    width: "100%",
    top: 45,
    height: 50,
    backgroundColor: "#E5E5E5"
  },
  nameHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    left: 30,
    top: 26,
    fontFamily: "Helvetica"
  },
  brandHeader: { 
    position: "absolute",
    top: 26,
    left: 231,
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "Helvetica"
  },
  presenterHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    top: 26,
    left: 308,
    fontFamily: "Helvetica"
  },
  inRoomHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    top: 26,
    left: 405,
    fontFamily: "Helvetica"
  },
  timeHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    top: 26,
    right: 157,
    fontFamily: "Helvetica"
  },
  attendeeSearch: {
    position: "absolute",
    left: 30,
    top: 24,
    display: "inline-block",
  },
  input: {
    height: 44,
  },
  error: {
    fontSize: 14,
    position: "relative",
    top: 50,
    textAlign: "center"
  },
  loadingSpinner: {
    position: "absolute",
    color: "grey",
    zIndex: 9999,
    left: "calc(50% - 20px)",
    top: 60
  },
  inputLabel: {
    bottom: 5,
  },
  downloadIcon: {
    color: "black",
    position: "relative",
    top: 1,
    "&:hover": {
      cursor: "pointer"
    }
  },
  downloadIconDisabled: {
    color: "grey",
    "&:hover": {
      cursor: "auto"
    }
  },
  refreshIcon: {
    color: "black",
    "&:hover": {
      cursor: "pointer"
    }
  },
  downloadSnackbar: {
    width: 360
  },
  attendeesCount: {
    fontSize: 14,
    position: "absolute",
    top: 35,
    right: 192
  },
  attendeesCountCount: {
    position: "relative",
    display: "inline-block",
    fontSize: 14,
    top: 6,
  }
})
)

export { BookingCurrentAttendeesStyles };