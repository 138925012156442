import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';
import dotenv from 'dotenv';
import AppContextProvider from './context/AppContext';
import EmailContextProvider from './context/EmailContext';
import AuthContextProvider from './context/AuthContext';

//Start up dotenv
dotenv.config();


if (process.env.REACT_APP_API_STAGE === "dev" 
|| window.location.href.includes("showboatproducerdev")
) {
  axios.defaults.baseURL = `https://zeazen0t8i.execute-api.us-east-1.amazonaws.com/dev`;
} else {
  axios.defaults.baseURL = `https://zeazen0t8i.execute-api.us-east-1.amazonaws.com/prod`;
}


ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <AuthContextProvider>
        <EmailContextProvider>
          <App />
        </EmailContextProvider>
      </AuthContextProvider>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


