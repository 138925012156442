import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const ProjectsAdminCreateStyles = makeStyles((theme: any) => createStyles({
  projectAdminWrapper: {
    position: "absolute",
    display: "inline-block",
    width: 714,
    height: 375,
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  createProjectHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
  projectsDivider: {
    width: "90%",
    position: "relative",
    top: 25,
    display: "inline-block"
  },
  projectNameHeader: {
    position: "relative",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
  },
  projectTextField: {
    position: "absolute",
    left: 0,
    top: 40,
    width: 300,
  },
  submitButton: {
    position: "absolute",
    right: 0,
    bottom: 60,
  },
  loadingSpinner: {
    width: "25px !important",
    height: "25px !important",
    position: "absolute",
    bottom: 65,
    right: 100,
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    position: "relative",
    bottom: 10,
  },
  success: {
    fontSize: 16,
    position :"relative",
    display: "inline-block"
  }
})
)

export { ProjectsAdminCreateStyles };