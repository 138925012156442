import React, { ReactElement, useCallback, useState, useContext, useEffect } from 'react';
import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import AWS from 'aws-sdk';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import FailIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import styles from './UploadVideoPopup.module.css';
import { UploadVideoPopupStyles } from './UploadVideoPopupStyles';

interface Props {
  handlePopupClose: () => any,
  uploadType: string,
  uploadTitle: string,
  uploadProperty: string,
  uploadDivision: string,
  uploadDimensions?: any,
}

export default function UploadVideoPopup(props: Props): ReactElement {
  
  const { 
    selectedSpaceID, 
    selectedSpace, 
    setSelectedSpace 
  } : AppContextType = useContext(AppContext);

  const [ correctDimensions, toggleCorrectDimensions ] = useState(false);
  const [ dimensionsError, toggleDimensionsError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ fileError, toggleFileError ] = useState(false);

  const [ error, toggleError ] = useState(false);

  /*File drop code */
  const onDrop = useCallback(acceptedFiles => {

    //Make sure we have dimensions to check before checing them
    if (props.uploadDimensions === undefined) {
      return; 
    } else {
      var reader = new FileReader();

      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function (e) {
        var video = document.createElement("video");
  
        video.setAttribute("src", e.target.result as string)
  
        video.onload = function() {
          var height = video.height;
          var width = video.width;
  
          if (height === props.uploadDimensions.height && width === props.uploadDimensions.width) {
  
            toggleCorrectDimensions(true);
            toggleDimensionsError(false);
  
          } else {
  
            toggleCorrectDimensions(false);
            toggleDimensionsError(true);
  
          }
        }
      }
    
    }


  }, [])


  //Configure dropzone
  const {acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: "video/mp4",
    maxSize: 10000000,
    multiple: false,
    onDrop: onDrop
  });

  //Accepted files
  const files = acceptedFiles.map(file => (
    <li key={file.name} className={styles.acceptedItem}>
      {file.name} - {file.size} bytes
    </li>
  ));
  
  //Rejected files
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li className={styles.rejectionItem} key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const classes = UploadVideoPopupStyles();
  
  const handleCancelClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    toggleError(false);
    props.handlePopupClose();
  }

  const handleSaveClick = () => {
    toggleLoadingSpinner(true);

    if (!acceptedFiles.length) {
      return alert("Please choose a file to upload first.");
    }

    AWS.config.update({
      accessKeyId: "AKIA6EJ7JKCAQG4SURI4",
      secretAccessKey: "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",
      region: "us-east-1",
      signatureVersion: "v4",
    })

    //Get signed urls from s3
    let filesToSend = [];

    acceptedFiles.forEach(file => {
      filesToSend.push({
        name: file.name,
        type: file.type
      })
    });

    axios.post("/assets/upload-request", {
      files: filesToSend,
      folderName: props.uploadProperty
    })
    .then(function(response) {
      let preSignedURLResponse = response.data;

      let baseUrl = "https://go.showboat.live/assets/app/";

      //Upload the file to s3
      axios.put(preSignedURLResponse[0].url, acceptedFiles[0])
      .then(response => {
        
        //Update ui skin with uploaded item's url
        if (props.uploadDivision === "uiSkin") {
          
          let selectedSpaceClone = selectedSpace;

          let uploadProperty = props.uploadProperty;

          selectedSpaceClone.uiSkin[uploadProperty].value = baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted;
          
          axios.put("/skin/ui", {
            spaceID: selectedSpaceID,
            property: props.uploadProperty,
            value: baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted,
          })
          .then(response => {

            toggleLoadingSpinner(false);
            console.log(response);
            props.handlePopupClose();
            setSelectedSpace(selectedSpaceClone);

          })
          .catch(err => {

            toggleLoadingSpinner(false);
            console.log(err);

          })
        }
        //Update world skin
        else if (props.uploadDivision === "worldSkin") {
          let selectedSpaceClone = selectedSpace;

          let uploadProperty = props.uploadProperty;

          selectedSpaceClone.worldSkin[uploadProperty].value = baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted;
          
          axios.put("/skin/world", {
            spaceID: selectedSpaceID,
            property: props.uploadProperty,
            value: baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted,
          })
          .then(response => {

            toggleLoadingSpinner(false);
            console.log(response);
            props.handlePopupClose();
            setSelectedSpace(selectedSpaceClone);

          })
          .catch(err => {

            toggleLoadingSpinner(false);
            console.log(err);

          })
        } 

        else if (props.uploadDivision === "avatarSkin") {

          let selectedSpaceClone = selectedSpace;

          let uploadProperty = props.uploadProperty;

          selectedSpaceClone.avatarSkin[uploadProperty].value = baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted;
          
          axios.put("/skin/avatar", {
            spaceID: selectedSpaceID,
            property: props.uploadProperty,
            value: baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted,
          })
          .then(response => {

            toggleLoadingSpinner(false);
            console.log(response);
            props.handlePopupClose();
            setSelectedSpace(selectedSpaceClone);

          })
          .catch(err => {

            toggleLoadingSpinner(false);
            console.log(err);

          })
        }
        //Update email skin
        else if (props.uploadDivision === "emailSkin") {
          let selectedSpaceClone = selectedSpace;

          let uploadProperty = props.uploadProperty;

          selectedSpaceClone.emailSkin[uploadProperty].value = baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted;
          
          axios.put("/skin/email", {
            spaceID: selectedSpaceID,
            property: props.uploadProperty,
            value: baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted,
          })
          .then(response => {

            toggleLoadingSpinner(false);
            console.log(response);
            props.handlePopupClose();
            setSelectedSpace(selectedSpaceClone);

          })
          .catch(err => {

            toggleLoadingSpinner(false);
            console.log(err);

          })
        }

      })
      .catch(err => {
        console.log(err);
        toggleLoadingSpinner(false);
      })

    })
    .catch(function(err) {
      toggleError(true);
      toggleLoadingSpinner(false);
      console.log("ERROR", err);
    })
  }

  useEffect(() => {
    if (fileRejections && fileRejections[0] && fileRejections[0].errors) {
      if (fileRejections[0].errors[0]) {
        toggleFileError(true);
      } else {
        toggleFileError(false);
      }
    }

    if (acceptedFiles[0]) {
      toggleFileError(false);
    }

  }, [fileRejections, acceptedFiles])
  
  return (
    <Dialog
      open={true}
      classes={{ paper: classes.dialogPaper}}
    >
      <div className={classes.popup}>

        <Typography variant="h1" className={classes.uploadHeader}>
          {props.uploadTitle}
        </Typography>

        {props.uploadDimensions !== undefined && 
          <Typography variant="body1" className={classes.uploadSizeHeader}>
            MP4: {props.uploadDimensions.width}px x {props.uploadDimensions.height}px
          </Typography>
        }

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred uploading the video
          </Typography>
        }

        <div 
          className={styles.fileUploadHolder}
          style={{
            marginBottom: fileRejections.length
              ? "160px"
              : files.length
                ? "139px"
                : "0px"
          }}
        >
          <div {...getRootProps({className: `${styles.dropzone}`})}>
            <input type="file" {...getInputProps()} id="fileUpload" />
            <Typography variant="body1" className={classes.dropzoneText}>
              Drag 'n' drop a file here, or click to select a file
            </Typography>
          </div>

          <div
            className={styles.fileResultHolder}

          >
            {(files.length !== 0) &&
              <div
                className={styles.fileSuccessHolder}
              >
                <Typography variant="body1" className={classes.fileName}>
                  {
                    acceptedFiles[0]
                      ? (acceptedFiles[0] as any).name
                      : ""
                  }
                </Typography>

                <CheckIcon className={styles.checkIcon} />
              </div>
            }

            {(fileRejectionItems.length !== 0) &&
              <div className={styles.fileFailureHolder}>
                <Typography variant="body1" className={classes.fileName}>
                  {
                    fileRejections[0]
                      ?
                      (fileRejections[0] as any).file.name
                      : ""
                  }
                </Typography>

                <Typography variant="body1" className={classes.fileError}>
                  File must be an MP4 video
                </Typography>

                <FailIcon className={styles.failIcon} />
              </div>
            }
          </div>
          
        </div>

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button onClick={handleCancelClick} className={classes.cancelButton} variant="text">
          CANCEL
        </Button>
        <Button disabled={!correctDimensions || loadingSpinner || fileError} onClick={handleSaveClick} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
