import React, { ReactElement, useContext, useState } from 'react';
import { Typography, Button, Dialog, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';

import { AttendeeDeletePopupStyles } from './AttendeeDeletePopupStyles';
import styles from './AttendeeDeletePopup.module.css';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
  handleDeleteCancel: () => any,
}

export default function AttendeeDeletePopup(props: Props): ReactElement {
  
  const {
    selectedRegistration
  } : AppContextType = useContext(AppContext);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ error, toggleError ] = useState(false);

  //Cancel delete registration
  const handleNoClick = () => {

    if (loadingSpinner) return;
    
    props.handleDeleteCancel();
  }

  //Confirm delete registration
  const handleYesClick = async () => {

    toggleLoadingSpinner(true);
    toggleError(false);

    try {
      await axios.delete('/registration', {
        params: {
          registrationID: selectedRegistration.registrationID
        }
      })

      toggleLoadingSpinner(false);
      props.handlePopupClose();
    }
    catch (error) {
      toggleError(true);
      toggleLoadingSpinner(false);
    }
   
  }

  //Material UI classes
  const classes = AttendeeDeletePopupStyles();

  return (

    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper}}
    >
      <div className={classes.popup}>

        <div className={styles.popupHeader}>
          <Typography variant="body1" className={classes.deleteText}>
            Delete Attendee?
          </Typography>
        </div>
        
        <Typography variant="body1" className={classes.deleteTextConfirm}>
          There is no undo for Attendee delete.
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred deleting this attendee
          </Typography>
        }

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }

        <Button 
          variant="contained" 
          color="primary" 
          className={classes.noButton}
          onClick={handleNoClick}
        >
          CANCEL
        </Button>

        <Button 
          variant="contained" 
          className={classes.yesButton}
          onClick={handleYesClick}
          disabled={loadingSpinner}
        >
          CONFIRM
        </Button>
      </div>
    </Dialog>
    
  )
}
