import { makeStyles, createStyles } from '@material-ui/core/styles';

const EditBookingStartTimePopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 532,
    height: 536,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  dialogPaperTimezoneOpen: {
    height: 700,
  },
  popup: {
    width: "100%",
    height: 536,
    position: "absolute",
  },
  popupTimezoneOpen: {
    height: 700,
  }, 
  editTimeHeader: {
    fontSize: 24,
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 40,
  },
  editTimeText: {
    fontSize: 16,
    position: "absolute",
    left: 30,
    top: 70,
  },
  cancelButton: {
    right: 141,
    bottom: 35,
    height: 44,
    width: 95,
    position: "absolute"
  },
  errorText: {
    color: "red",
    fontSize: 14,
    position: "absolute",
    top: 387,
    left: 30,
  },
  dateError: {
    color: "red",
    fontSize: 14,
    position: "absolute",
    top: 100,
    left: 30,
  },
  saveButton: {
    right: 30,
    bottom: 35,
    height: 44,
    width: 95,
    position: "absolute",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  datePicker: {
    top: 139,
    left: 30,
    position: 'absolute',
    width: 472
  },
  timePicker: {
    top: 230,
    left: 30,
    position: 'absolute',
    width: 472
  },
  timezoneLabel: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.87)",
    position: "absolute",
    top: 320,
    left: 35,
  },
  loadingSpinner: {
    position: "absolute",
    color: "grey",
    bottom: 88,
    right: 58
  }
})
)

export { EditBookingStartTimePopupStyles };