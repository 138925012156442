import { makeStyles, createStyles } from '@material-ui/core/styles';

const RunDisplayStyles = makeStyles((theme: any) => createStyles({
  runHeader: {
    fontSize: 16,
    position: "absolute",
    left: 30,
    top: 20,
    fontWeight: 400,
  },
  runStartHeader: {
    fontSize: 14,
    position: "absolute",
    left: 30,
    marginRight: 5,
    bottom: 16,
    fontWeight: "bold",
  },
  startTime: {
    fontSize: 14,
    position: "absolute",
    left: 0,
    top: 0,
  },
  startDate: {
    fontSize: 14,
    position: "absolute",
    top: 0,
    left: 95,
  },
  runEndHeader: {
    fontSize: 14,
    position: "absolute",
    marginRight: 5,
    left: 30,
    fontWeight: "bold",
    bottom: 16,
  },
  endTime: {
    fontSize: 14,
    position: "absolute",
    left: 0,
    top: 0,
  },
  endDate: {
    fontSize: 14,
    position: "absolute",
    top: 0,
    left: 95,
  },
  editIconButton: {
    position: "absolute",
    top: -4,
    right: -27,
    color: "black",
    padding: 3,
    "&:hover": {
      cursor: "pointer"
    }
  },
  editIcon: {
    fontSize: 20
  },
  runDivider: {
    position: "absolute",
    bottom: 0,
    right: 8,
    width: "89.1%"
  },
  runDividerRight: {
    right: 15,
    width: "87%"
  },
  doorsOpenEarlyToggle: {
    position: "absolute",
    right: 30,
    top: 10,
  },
  doorsOpenEarlyLabel: {
    fontSize: 14,
    fontWeight: "bold",
  },
  doorsLoadingSpinner: {
    width: "25px !important",
    height: "25px !important",
    color: "grey",
    position: "absolute",
    right: 208,
    top: 17,
  }
})
)

export { RunDisplayStyles };