import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const ProducerAdminStyles = makeStyles((theme: any) => createStyles({
  producerAdminWrapper: {
    position: "absolute",
    display: "inline-block",
    width: 714,
    height: 875,
    top: 30,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  createProducerHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
  producerDivider: {
    width: "90%",
    position: "relative",
    top: 25,
    display: "inline-block"
  },
  producerFormHeader: {
    position: "relative",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
  },
  producerTextField: {
    position: "absolute",
    left: 0,
    top: 40,
    width: 300,
  },
  listHolder: {
    width: 270,
    height: 300,
    overflow: 'auto',
  },
  button: {

  },
  submitButton: {
    position: "absolute",
    left: 35,
    bottom: 30,
  },
  loadingSpinner: {
    width: "25px !important",
    height: "25px !important",
    position: "absolute",
    bottom: 35,
    left: 140,
  },
  success: {
    fontSize: 18,
    fontWeight: 400,
    display: "inline-block",
    position: "absolute",
    top: 150,
    left: 0,
    right: 0,
    center: 0,
    margin: "auto"
  },
  projectsLoadingSpinner: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto"
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    position: "relative",
    bottom: 10,
  }
})
)

export { ProducerAdminStyles };