import React, { ReactElement, useContext, useState } from 'react';
import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';

import styles from './ConfirmAttendeeLinkRegenerate.module.css';
import { ConfirmAttendeeLinkRegenerateStyles } from './ConfirmAttendeeLinkRegenerateStyles';

interface Props {
  open: boolean,
  isPresenter: boolean,
  handlePopupClose: (wasRegenerated: boolean) => any,
}

export default function ConfirmAttendeeLinkRegenerate(props: Props): ReactElement {

  const {
    selectedRegistration,
    setSelectedRegistration,
    selectedPresenter,
    setSelectedPresenter
  } : AppContextType = useContext(AppContext);
  
  const [ error, toggleError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  
  //Cancel
  const handleCancelClick = () => {

    if (loadingSpinner) return;
    
    props.handlePopupClose(false);
  }

  //Regenerate attendee
  const handleRegenerateAttendeeLink = async () => {

    toggleError(false);
    toggleLoadingSpinner(true);

    try {
      let response = await axios.put("/registration/login/regenerate", {
        registrationID: selectedRegistration.registrationID
      });

      let selectedRegistrationClone = selectedRegistration;
      selectedRegistrationClone.loginCode = response.data;
      setSelectedRegistration(selectedRegistrationClone);
      toggleLoadingSpinner(false);
      props.handlePopupClose(true);
    }
    catch (error) {
      console.log("ERROR", error)
      toggleLoadingSpinner(false);
      toggleError(true);
    }

  }

  //Regenerate presenter
  const handleRegeneratePresenterLink = async () => {

    toggleError(false);
    toggleLoadingSpinner(true);

    try {
      let response = await  axios.put("/presenter/login/regenerate", {
        registrationID: selectedPresenter.registrationID
      });

      let selectedPresenterClone = selectedPresenter;
      selectedPresenterClone.loginCode = response.data;
      setSelectedPresenter(selectedPresenterClone);
      toggleLoadingSpinner(false);
      props.handlePopupClose(true);
    }
    catch (error) {
      console.log("ERROR", error);
      toggleLoadingSpinner(false);
      toggleError(true);
    }
   
  }
  
  const classes = ConfirmAttendeeLinkRegenerateStyles();
  
  return (
    <Dialog
      open={props.open} 
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <div className={styles.popupHeader}>
          <Typography className={classes.linkRegenerateHeader}>
            Confirm Link Regeneration
          </Typography>
        </div>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred regenerating this link
          </Typography>
        }

        <Typography variant="body1" className={classes.linkRegenerateSubtext}>
          Regenerating this link will cause the current link to stop working.
        </Typography>
        
        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button 
          variant="contained" 
          color="primary" 
          className={classes.saveButton}
          disabled={loadingSpinner}
          onClick={props.isPresenter ? handleRegeneratePresenterLink : handleRegenerateAttendeeLink}
        >
          CONFIRM
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          className={classes.cancelButton}
          onClick={handleCancelClick}
        >
          CANCEL
        </Button>
      </div>
    </Dialog>
  )
}
