import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresentationsWindowStyles = makeStyles((theme: any) => createStyles({
  addIcon: {
    position: "relative",
    bottom: 1,
    right: 2,
    marginRight: 4,
    width: 16,
    height: 16,
  },
  addAndEditButton: {
    left: 175,
    top: 26,
    fontSize: 14,
    backgroundColor: "white",
    border: "1px solid #FF0092",
    borderRadius: 4,
    color: "black",
    padding: 0,
    width: 67,
    height: 26,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FF0092",
      boxShadow: "none",
      color: "white"
    }
  },
  presentationsHeader: {
    fontSize: 20,
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 75,
  }
})
)

export { PresentationsWindowStyles };