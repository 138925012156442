import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresentationItemStyles = makeStyles((theme: any) => createStyles({
  presentationName: {
    fontSize: 14,
    fontWeight: 400,
    position: "relative",
    left: 30,
    top: 16,
  },
  editButton: {
    position: "absolute",
    right: 27,
    top: 9,
    color: "black",
    padding: 4,
  },
  uploadIconButton: {
    position: "absolute",
    right: 74,
    color: "black",
    padding: 4,
    top: 9,
  },
  uploadIcon: {
    fontSize: 20,
  },
  deleteIconButton: {
    position: "absolute",
    color: "red",
    padding: 4,
    right: 118,
    top: 9,
  },
  deleteIcon: {
    fontSize: 20,
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    bottom: 8,
    right: 175,
    width: "30px !important",
    height: "30px !important"
  },
})
)

export { PresentationItemStyles };