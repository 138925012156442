import { makeStyles, createStyles } from '@material-ui/core/styles';

const PreviewAssetPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    height: 580,
    width: 779,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      width: 730,
      height: 550
    }
  },
  dialogPaperAudio: {
    height: 500,
    width: 500,
  },
  dialogPaperAvatar: {
    height: 406,
    width: 752
  },
  dialogPaperHeaderImage: {
    height: 520,
  },
  popup: {
    height: 580,
    width: "100%",
    position: "absolute",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      height: 550,
    }
  },
  popupAudio: {
    height: 500,
  },
  popupAvatar: {
    height: 406,
  },
  popupHeaderImage: {
    height: 520
  },
  previewHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
    textAlign: "left",
    fontWeight: 400,
  },
  closeButton: {
    width: 93,
    height: 44,
    position: "absolute",
    bottom: 30,
    right: 30,
  }
})
)

export { PreviewAssetPopupStyles };