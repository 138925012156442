import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Tabs, Tab } from '@material-ui/core';
import AWS from 'aws-sdk';
import axios from 'axios';

import PresentationsWindow from '../PresentationsWindow/PresentationsWindow';
import BookingsWindow from '../BookingsWindow/BookingsWindow';
import CalendarWindow from '../CalendarWindow/CalendarWindow';
import BrandingWindow from '../BrandingWindow/BrandingWindow';
import { AppContext, AppContextType } from '../../context/AppContext';

import { MainWindowStyles } from './MainWindowStyles';

interface Props {
  gettingSpace: boolean
}

export default function MainWindow(props: Props): ReactElement {

  const history = useHistory();
  
  const [ currentTab, setCurrentTab ] = useState(0);

  const { 
    setSelectedBooking, 
    setSelectedBookingUsage, 
    setSelectedBookingID,
    onMainBookingWindow,
    selectedBooking,
    bookingHasCalendar,
    setSelectedCalendar,
    onMessagesWindow,
    setSpaceCalendars,
    selectedSpaceID,
  } : AppContextType = useContext(AppContext);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {

    //Check for booking link on first load
    if (sessionStorage.getItem("bookingLink") !== "true") {
      setSelectedBooking(null);
      setSelectedBookingUsage(null);
      setSelectedBookingID(null);
    }


    //Get space calendars on mount (needed for bookings tab)
    const getAllCalendars = async () => {
      try {
        let response = await axios.get("/calendars", {
          params: {
            spaceID: selectedSpaceID
          },
          cancelToken: source.token,
        });

        setSpaceCalendars(response.data);
      }
      catch (error) {

        if (axios.isCancel(error)) {
          return;
        }
      }
    }
    getAllCalendars();

  }, []);

  useEffect(() => {
    //When selected space changes, reset back to bookings tab
    setCurrentTab(0);
  }, [selectedSpaceID])

  const handleTabChange = (newTab: number) => {

    if (newTab === currentTab) return;

    history.push(`/space/${selectedSpaceID}`);

    //Reset selected booking and calendar
    setSelectedBooking(null);
    setSelectedCalendar(null);

    setCurrentTab(newTab);
  };

  //Material UI styles
  const classes = MainWindowStyles();

  return (
    <Paper 
      elevation={0} 
      classes={{ 
        root: classes.mainWindowWrapper
      }}
    >
      <Tabs 
        value={currentTab}
        indicatorColor="primary"
        textColor="primary" 
        classes={{ 
          root: classes.mainWindowTabs,
          indicator: classes.mainWindowTabsIndicator 
        }}
      >
        <Tab
          classes={{
            root: classes.mainWindowTab,
            selected: classes.mainWindowTabSelected
          }}
          onClick={() => handleTabChange(0)}
          label="Bookings"
        />
        <Tab 
          classes={{
            root: classes.mainWindowTab,
            selected: classes.mainWindowTabSelected
          }} 
          onClick={() => handleTabChange(1)} 
          label="Calendar" 
        />
        <Tab 
          disabled={props.gettingSpace} 
          classes={{
            root: classes.mainWindowTab,
            selected: classes.mainWindowTabSelected
          }}  
          onClick={() => handleTabChange(2)} 
          label="Branding" 
        />
        <Tab 
          disabled={props.gettingSpace} 
          classes={{
            root: `${classes.mainWindowTab} ${classes.mainWindowTabPresentations}`,
            selected: `${classes.mainWindowTabSelected} ${classes.mainWindowTabPresentations}`
          }} 
          onClick={() => handleTabChange(3)} 
          label="Presentations" 
        />
      </Tabs>

      {currentTab === 0 &&
        <BookingsWindow />
      }

      {currentTab === 1 &&
        <CalendarWindow />
      }

      {currentTab === 2 &&
        <BrandingWindow />
      }

      {currentTab === 3 &&
        <PresentationsWindow />
      }
    </Paper>
  )
}
