import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingPresenterStyles = makeStyles((theme: any) => createStyles({
  presenterName: {
    display: "inline-block",
    fontSize: 14,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 175,
    position: "absolute",
    left: 30,
    top: 14,
  },
  presenterCompany: {
    width: 140,
    display: "inline-block",
    fontSize: 14,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "absolute",
    top: 14,
    left: 219,
  },
  presenterEmail: {
    width: 205,
    display: "inline-block",
    fontSize: 14,
    left: 367,
    position: "relative",
    wordWrap: "break-word",
    top: 13,
  },
  manageButton: {
    color: "black",
    padding: 4,
  },
  linkIconButton: {
    color: "black",
    padding: 4,
  },
  copySnackbar: {
    width: 360,
  }
})
)

export { BookingPresenterStyles };