import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarAssignPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 505,
    height: 411,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  popup: {
    width: "100%",
    height: 411,
    position: "absolute",
  },
  chooseCalendarHeader: {
    fontSize: 24,
    position: "absolute",
    top: 49,
    left: 30,
    fontWeight: 400,
  },
  calendarHolder: {
    width: 443,
    height: 191,
    overflowX: "hidden",
    overflowY: "auto",
    position: "absolute",
    top: 104,
    left: 30,
  },
  cancelButton: {
    position: "absolute",
    right: 143,
    bottom: 30,
    padding: 0,
    width: 95,
    height: 44,
  },
  saveButton: {
    position: "absolute",
    right: 30,
    bottom: 30,
    padding: 0,
    width: 95,
    height: 44,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  calendarName: {
    fontSize: 16,
    position: "absolute",
    left: 17,
    top: 12,
  },
  checkIcon: {
    color: "black",
    position: "absolute",
    right: 20,
    top: 12,
  },
  loadingSpinner: {
    position: "absolute",
    left: 210,
    top: 66,
    color: "grey"
  },
  saveLoadingSpinner: {
    position: "absolute",
    right: 65,
    bottom: 82,
    color: "grey",
    width: "25px !important",
    height: "25px !important"
  },
  error: {
    color: "red",
    display: "inline-block",
    position: "relative",
    top: 74,
    left: 32,
    fontSize: 14,
  }
})
)

export { CalendarAssignPopupStyles };