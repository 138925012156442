import { makeStyles, createStyles } from '@material-ui/core/styles';

const SpacePickerStyles = makeStyles((theme: any) => createStyles({
  pickerLabel: {
    fontSize: 14,
    color: "white",
    position: "absolute",
    left: 12,
    top: 16,
    fontFamily: "Roboto, sans-serif",
    "&:hover": {
      cursor: "pointer"
    }
  },
  arrowDown: {
    position: "absolute",
    color: "white",
    right: 0,
    top: 12,
  },
  projectName: {
    fontSize: 14,
    fontFamily: "Helvetica",
    position: "absolute",
    left: 15,
    bottom: 10,
    color: "white",
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: -50,
    top: 10,
    width: "25px !important",
    height: "25px !important"
  },
  rightIcon: {
    color: "white",
    position: "absolute",
    right: 10,
    top: 5,
  }
})
)

export { SpacePickerStyles };