import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Dialog, Typography, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import { EmailContext, EmailContextType } from '../../../../../context/EmailContext';
import { AppContext, AppContextType } from '../../../../../context/AppContext';

import { ConfirmSendPopupStyles } from './ConfirmSendPopupStyles';
import styles from './ConfirmSendPopup.module.css';
import PopupHeader from '../../../../PopupHeader/PopupHeader';


interface Props {
  open: boolean,
  handlePopupClose: () => any,
  handleConfirmSendComplete: () => any
}

export default function ConfirmSendPopup(props: Props): ReactElement {

  const { 
    selectedAttendees, 
    selectedPresenters, 
    selectedEmailTemplate 
  } : EmailContextType = useContext(EmailContext);
  
  const { 
    selectedBooking, 
    selectedSpace 
  } : AppContextType = useContext(AppContext);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ error, toggleError ] = useState(false);

  const handleCancelButtonClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    toggleError(false);
    props.handlePopupClose();
  }

  const handleSendButtonClick = async () => {

    toggleLoadingSpinner(true);

    let toSend = [...selectedAttendees, ...selectedPresenters];

    try {
      await axios.post("/email-parse-request", {
        bookingID: selectedBooking.bookingID,
        spaceID: selectedSpace.spaceID,
        emailTemplateID: selectedEmailTemplate.emailTemplateID,
        toSend
      });

      toggleLoadingSpinner(false);
      toggleError(false);
      props.handleConfirmSendComplete();
    }
    catch (err) {
      toggleLoadingSpinner(false);
      toggleError(true);
      console.log(err);
    }
    
  }
  
  const classes = ConfirmSendPopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <div className={styles.contentWrapper}>


          <Typography variant="h1" className={classes.confirmHeader}>
            Confirm Sending Email To: {selectedAttendees.length + selectedPresenters.length}
          </Typography>

          {error &&
            <Typography variant="body1" className={classes.error}>
              An error occurred sending this email to the selected registrations
            </Typography>
          }

          <Typography variant="body1" className={classes.confirmAttendees}>
            Attendees: {selectedAttendees.length}
          </Typography>

          <Typography variant="body1" className={classes.confirmPresenters}>
            Presenters: {selectedPresenters.length}
          </Typography>
        </div>

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button onClick={handleCancelButtonClick} className={classes.cancelButton} variant="text">
          CANCEL
        </Button>
        <Button disabled={loadingSpinner} onClick={handleSendButtonClick} className={classes.sendButton} variant="contained" color="primary">
          SEND
        </Button>
      </div>
    </Dialog>
  )
}
