import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const ProducerAdminPageStyles = makeStyles((theme: any) => createStyles({
  createButton: {
    position: "absolute",
    top: 10,
    right: 85,
    zIndex: 9999,

  },
  editButton :{
    position: "absolute",
    top: 10,
    right: 10
  },
  adminMenuButton: {
    position: "absolute",
    top: 15,
    left: 15,
  }
})
)

export { ProducerAdminPageStyles };