import { makeStyles, createStyles } from '@material-ui/core/styles';

const MainWindowStyles = makeStyles((theme: any) => createStyles({
  mainWindowWrapper: {
    position: "relative",
    left: 300,
    top: 90,
    display: "inline-block",
    width: 714,
    height: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    marginBottom: 100,
    [theme.breakpoints.down('md')]: {
      left: 275,
    },
    [theme.breakpoints.down('sm')]: {
      left: "calc(50% - 357px)",
      top: 197,
      marginBottom: 270,
    }
  },
  mainWindowWrapperBooking: {
    height: 791
  },
  mainWindowWrapperBookingCalendarSelected: {
    height: 831,
  },
  mainWindowWrapperMessages: {
    height: 870,
  },
  mainWindowTabs: {
    backgroundColor: "#dcdcdc"
  },
  mainWindowTab: {
    width: 132,
    fontSize: 14
  },
  mainWindowTabSelected: {
    backgroundColor: "#ececec",
    color: "black !important",
  },
  mainWindowTabsIndicator: {
    backgroundColor: "#e73690"
  },
  mainWindowTabPresentations: {
    width: 160,
  }
})
)

export {MainWindowStyles};