import { makeStyles, createStyles } from '@material-ui/core/styles';

const DeletePresenterPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 361,
    height: 238,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 238,
    position: "absolute"
  },
  deleteText: {
    fontSize: 20,
    position: "relative",
    color: "white",
    left: 30,
    top: 23
  },
  error: {
    color: "red",
    position: "relative",
    top: 40,
    left: 63,
  },
  noButton: {
    width: 95,
    height: 44,
    position: "absolute",
    bottom: 30,
    right: 163,
  },
  yesButton: {
    width: 114,
    height: 44,
    position: "absolute",
    bottom: 30,
    right: 30,
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "green"
    }
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 65,
    bottom: 80
  },
  deleteTextConfirm: {
    fontSize: 16,
    position: "absolute",
    top: 83,
    left: 30,
  }
})
)

export { DeletePresenterPopupStyles };