import { makeStyles, createStyles } from '@material-ui/core/styles';

const AddBookingPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 462,
    height: 298,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 298,
    position: "absolute",
  },
  bookingNameInput: {
    width: 403,
    position: "absolute",
    left: 30,
    top: 120
  },
  createBookingHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
    fontWeight: 400,
  },
  cancelButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 141,
    bottom: 30,
  },
  saveButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 30,
    bottom: 30,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  error: {
    color: "red",
    position: "relative",
    top: 60,
    left: 32
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 57,
    bottom: 77
  }
})
)

export { AddBookingPopupStyles };