import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingErrorMessagesStyles = makeStyles((theme: any) => createStyles({
  errorOptionHeader: {
    fontSize: 16,
    position: "relative",
    top: 10,
    left: 27,
  },
  errorOptionHeaderRegistration: {
    top: 20
  },
  supportHeader: {
    fontWeight: 400,
    fontSize: 20,
    position: "relative",
    left: 27
  },
  editIconButton: {
    color: "black",
    position: "relative",
    left: 255,
    bottom: 17.5,
    padding: 4,
  },
  editIcon: {
    fontSize: 20,
  },
  editIconRegistration: {

  },
  editIconButtonRegistration: {
    left: 285,
  },
  editIconButtonRegistrationEmail: {
    top: -7,
    left: 335,
  },
  webPageErrorMessagesHeader: {
    fontSize: 20,
    fontWeight: 400,
    position: "relative",
    left: 27,
  },
  errorMessagesDivider: {
    position: "absolute",
    width: 655,
    top: 83,
    marginLeft: "calc(50% - 326.5px)",
  },
  errorMessagesDivider2: {
    top: 302,
  },
  errorMessagesDivider3: {
    top: 420,
  },
  supportMessageOption: {
    fontSize: 16,
    position: "relative",
    top: 10,
    left: 27,
  },
  editIconButtonSupport: {
    left: 173,
  },
  
})
)

export { BookingErrorMessagesStyles };