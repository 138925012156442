import React, { ReactElement, useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Dialog, Typography, Button, CircularProgress, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import AWS from 'aws-sdk';
import FailIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { AppContext, AppContextType } from '../../../../context/AppContext';
import { AuthContext, AuthContextType } from '../../../../context/AuthContext';

import styles from './UploadImageOrVideoPopup.module.css';
import { UploadImageOrVideoPopupStyles } from './UploadImageOrVideoPopupStyles';
import PopupHeader from '../../../PopupHeader/PopupHeader';

interface Props {
  handlePopupClose: () => any,
  uploadType: string,
  uploadTitle: string,
  uploadProperty: string,
  uploadDivision: string,
  uploadDimensions?: any,
  handleImageOrVideoUpload: (imageOrVideo: string, property: string) => any,
};

var bucketName= "showboat-assets-temp";

export default function UploadImageOrVideoPopup(props: Props): ReactElement {

  const { 
    selectedSpaceID, 
    selectedSpace, 
    setSelectedSpace 
  } : AppContextType = useContext(AppContext);

  const { idToken } : AuthContextType = useContext(AuthContext);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ correctDimensions, toggleCorrectDimensions ] = useState(false);
  const [ dimensionsError, toggleDimensionsError ] = useState(false);
  const [ error, toggleError ] = useState(false);

  const [ fileTooLarge, toggleFileTooLarge ] = useState(false);

  const [ imageOrVideoState, setImageOrVideoState ] = useState(props.uploadType);

  const [ fileTypeError, toggleFileTypeError ] = useState(false);

  const imageOrVideo = useRef(props.uploadType);

  /*File drop code */
  const onDrop = useCallback(acceptedFiles => {
    toggleDimensionsError(false);

    //Make sure we have accepted files
    if (!acceptedFiles.length) {
      return;
    }

    //Make sure there are dimensions to check before checking
    if (props.uploadDimensions === undefined) {
      return; 
    } else {

      //Check if image is correct size
      if (imageOrVideo.current === "Image") {
        var reader = new FileReader();
        
        reader.readAsDataURL(acceptedFiles[0]);

        reader.onload = function (e) {

          var image = new Image();
    
          (image as HTMLImageElement).src = e.target.result as string;
    
          image.onload = function() {
            var height = image.height;
            var width = image.width;
    
            if (height === props.uploadDimensions.height && width === props.uploadDimensions.width) {

              toggleCorrectDimensions(true);
              toggleDimensionsError(false);
    
            } else {

              toggleCorrectDimensions(false);
              toggleDimensionsError(true);
              toggleFileTypeError(false);
    
            }
          }
        }
      } 
      //Check if video is correct size
      else {
        var reader = new FileReader();

        reader.readAsDataURL(acceptedFiles[0]);

        reader.onload = function (e) {

          var video = document.createElement("video");

          (video as HTMLVideoElement).src = e.target.result as string;

          video.addEventListener("loadedmetadata", function(e) {

            var height = video.height;
            var width = video.width;

    
            if (height === props.uploadDimensions.height && width === props.uploadDimensions.width) {
    
              toggleCorrectDimensions(true);
              toggleDimensionsError(false);
    
            } else {
    
              toggleCorrectDimensions(true);
              toggleDimensionsError(false);
    
            }
          });

          (video as HTMLVideoElement).load();
          
        }
      }
    }
  }, []);

  let fileTypesToAccept;

  if (imageOrVideo.current === "Image") {
    fileTypesToAccept = "image/png"
  } else {
    fileTypesToAccept = "video/mp4";
  }

  //Configure dropzone
  const {acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: fileTypesToAccept,
    onDrop: onDrop,
    multiple: false,
    maxSize: 10000000
  });

  //Assemble accepted files list
  const files = acceptedFiles.map(file => (
    <li key={file.name} className={styles.acceptedItem}>
      {file.name} - {file.size} bytes
    </li>
  ));

  //Assemble rejected file list
  const fileRejectionItems = fileRejections.map(({ file, errors }) => {

    if (errors[0].code === "file-too-large") {
      return (
        <li className={styles.rejectedItem} key={file.name}>
          {file.name} - {file.size /1000} MB
          <ul>
            <li key={"e"}>Video file size cannot exceed 10 MB</li>
          </ul>
        </li>
      )
    } else {
      return (
        <li className={styles.rejectedItem} key={file.name}>
          {file.name} - {file.size} bytes
          <ul>
            {errors.map(e => (
              <li key={e.code}>{e.message}</li>
            ))}
          </ul>
        </li>
      )
    }
    
  });
  /**/

  const handleCancelClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    toggleError(false);
    toggleCorrectDimensions(false);
    toggleDimensionsError(false);
    props.handlePopupClose();
  };

  const handleSaveClick = () => {
    
    toggleLoadingSpinner(true);

    if (!acceptedFiles.length) {
      toggleLoadingSpinner(false);
      return alert("Please choose a file to upload first.");
    }

    AWS.config.update({
      accessKeyId: "AKIA6EJ7JKCAQG4SURI4",
      secretAccessKey: "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",
      region: "us-east-1",
      signatureVersion: "v4",
    })

    //GET SIGNED URLS FROM S3
    let filesToSend = [];

    acceptedFiles.forEach(file => {
      filesToSend.push({
        name: file.name,
        type: file.type
      })
    });

    axios.post("/assets/upload-request", {
      files: filesToSend,
      folderName: props.uploadProperty
    })
    .then(function(response) {

      let preSignedURLResponse = response.data;

      let baseUrl = "https://go.showboat.live/assets/app/";

      //remove authorization header to allow for upload to pre-signed URL
      delete axios.defaults.headers.common["Authorization"];

      //UPLOAD THE FILE TO S3
      axios.put(preSignedURLResponse[0].url, acceptedFiles[0])
      .then(response => {

        //add back authorization header
        axios.defaults.headers.common["Authorization"] = idToken;
        
        //UPDATE UI SKIN WITH UPLOADED ITEM'S URL
        if (props.uploadDivision === "uiSkin") {
          
          let selectedSpaceClone = selectedSpace;

          let uploadProperty = props.uploadProperty;

          //Change value and type on space
          selectedSpaceClone.uiSkin[uploadProperty].value = baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted;
          selectedSpaceClone.uiSkin[uploadProperty].type = imageOrVideo.current;

          //HANDLE SPECIAL CASE FOR LANDING PAGE ASSET
          axios.put("/skin/ui", {
            spaceID: selectedSpaceID,
            property: props.uploadProperty,
            value: baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted,
            type: imageOrVideo.current
          })
          .then(response => {

            toggleLoadingSpinner(false);
            props.handlePopupClose();
            setSelectedSpace(selectedSpaceClone);

          })
          .catch(err => {

            toggleLoadingSpinner(false);
            console.log(err);

          })
        }
        //UPDATE WORLD SKIN
        else if (props.uploadDivision === "worldSkin") {
          let selectedSpaceClone = selectedSpace;

          let uploadProperty = props.uploadProperty;

          //Change value and type on space object
          selectedSpaceClone.worldSkin[uploadProperty].value = baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted;
          selectedSpaceClone.worldSkin[uploadProperty].type = imageOrVideo.current;

          axios.put("/skin/world", {
            spaceID: selectedSpaceID,
            property: props.uploadProperty,
            value: baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted,
            type: imageOrVideo.current
          })
          .then(response => {

            toggleLoadingSpinner(false);
            console.log(response);
            props.handlePopupClose();
            setSelectedSpace(selectedSpaceClone);

          })
          .catch(err => {

            toggleLoadingSpinner(false);
            console.log(err);

          })
        } 

        else if (props.uploadDivision === "avatarSkin") {

          let selectedSpaceClone = selectedSpace;

          let uploadProperty = props.uploadProperty;

          //Change value and type on space object
          selectedSpaceClone.avatarSkin[uploadProperty].value = baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted;
          selectedSpaceClone.avatarSkin[uploadProperty].type = imageOrVideo.current;
          
          axios.put("/skin/avatar", {
            spaceID: selectedSpaceID,
            property: props.uploadProperty,
            value: baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted,
            type: imageOrVideo.current
          })
          .then(response => {

            toggleLoadingSpinner(false);
            console.log(response);
            props.handlePopupClose();
            setSelectedSpace(selectedSpaceClone);

          })
          .catch(err => {

            toggleLoadingSpinner(false);
            console.log(err);

          })
        }
        //UPDATE EMAIL SKIN
        else if (props.uploadDivision === "emailSkin") {
          let selectedSpaceClone = selectedSpace;

          let uploadProperty = props.uploadProperty;

          //Change value and type on space object
          selectedSpaceClone.emailSkin[uploadProperty].value = baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted;
          selectedSpaceClone.emailSkin[uploadProperty].type = imageOrVideo.current;

          axios.put("/skin/email", {
            spaceID: selectedSpaceID,
            property: props.uploadProperty,
            value: baseUrl + `${props.uploadProperty}/` + preSignedURLResponse[0].fileID + "." + preSignedURLResponse[0].fileTypeFormatted,
            type: imageOrVideo.current
          })
          .then(response => {

            toggleLoadingSpinner(false);
            console.log(response);
            props.handlePopupClose();
            setSelectedSpace(selectedSpaceClone);

          })
          .catch(err => {

            toggleLoadingSpinner(false);
            console.log(err);

          })
        }

      })
      .catch(err => {
        console.log(err);
        toggleLoadingSpinner(false);
      })

    })
    .catch(function(err) {
      toggleLoadingSpinner(false);
      console.log("ERROR", err);
    })

    //Call props function to change type on skin object that was changed
    props.handleImageOrVideoUpload(imageOrVideo.current, props.uploadProperty);
  };

  /* IMAGE/VIDEO TOGGLE */
  const handleImageOrVideoChange = (e) => {

    //TODO: TODO: TODO:
    // - clear files on type change?

    toggleDimensionsError(false);
    toggleCorrectDimensions(false);

    while (acceptedFiles.length) {
      acceptedFiles.pop();
    };

    while (fileRejections.length) {
      fileRejections.pop();
    }

    imageOrVideo.current = e.target.value;
    setImageOrVideoState(e.target.value);

  }
  /**/

  const classes = UploadImageOrVideoPopupStyles();

  useEffect(() => {

    //Check if we have a file size error
    if (fileRejections && fileRejections[0] && fileRejections[0].errors) {
      console.log(fileRejections[0].errors[0])
      if (fileRejections[0].errors[0].code === "file-too-large") {
        toggleFileTooLarge(true);
      } else {
        toggleFileTooLarge(false);
      }

      if (fileRejections[0].errors[0].code === "file-invalid-type") {
        toggleFileTypeError(true);
      } else {
        toggleFileTypeError(false);
      }
    } else {
      toggleFileTooLarge(false);
      toggleFileTypeError(false);
    }
  }, [fileRejections]);

  return (
    <Dialog
      open={true}
      classes={{ paper: classes.dialogPaper}}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.uploadHeader}>
          {props.uploadTitle}
        </Typography>

        <FormControl component="fieldset" className={classes.imageOrVideoToggle}>
          <FormLabel component="legend">Asset Type</FormLabel>
          <RadioGroup 
            aria-label="assetType" 
            name="assetType" 
            value={imageOrVideoState} 
            onChange={handleImageOrVideoChange}
            className={classes.radioGroup}
          >
            <FormControlLabel value="Image" control={<Radio />} label="Image" />
            <FormControlLabel className={classes.videoToggle} value="Video" control={<Radio />} label="Video" />
          </RadioGroup>
        </FormControl>
        
        {imageOrVideo.current === "Image" 
        ?
          props.uploadDimensions !== undefined && 
            <Typography variant="body1" className={classes.uploadSizeHeader}>
              PNG: {props.uploadDimensions.width}px x {props.uploadDimensions.height}px
            </Typography>
        
        : 
          <Typography variant="body1" className={classes.uploadSizeHeader}>
            MP4: 10MB Max
          </Typography>
        }
        

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred uploading the {imageOrVideo.current.toLowerCase()}
          </Typography>
        }

        <div 
          className={styles.fileUploadHolder}
          style={{
            marginBottom: fileRejections.length || dimensionsError
              ? "160px"
              : files.length
                ? "139px"
                : "0px"
          }}
        >
          <div {...getRootProps({className: `${styles.dropzone}`})}>
            <input type="file" {...getInputProps()} id="fileUpload" />
            <Typography variant="body1" className={classes.dropzoneText}>
              Drag 'n' drop a file here, or click to select a file
            </Typography>
          </div>
          <div
            className={styles.fileResultHolder}

          >
            {(files.length !== 0 && !dimensionsError) &&
              <div
                className={styles.fileSuccessHolder}
              >
                <Typography variant="body1" className={classes.fileName}>
                  {
                    acceptedFiles[0]
                      ? (acceptedFiles[0] as any).name
                      : ""
                  }
                </Typography>

                <CheckIcon className={styles.checkIcon} />
              </div>
            }

            {(fileRejectionItems.length !== 0 || dimensionsError) &&
              <div className={styles.fileFailureHolder}>
                <Typography variant="body1" className={classes.fileName}>
                  {
                    dimensionsError
                      ?
                      acceptedFiles[0]
                        ? (acceptedFiles[0] as any).name
                        : ""
                      :
                      fileRejections[0]
                        ?
                        (fileRejections[0] as any).file.name
                        : ""
                  }
                </Typography>

                {dimensionsError &&
                  <Typography variant="body1" className={classes.fileError}>
                    Dimensions of image must be {props.uploadDimensions.width}px x {props.uploadDimensions.height}px
                  </Typography>
                }

                {fileTooLarge &&
                  <Typography variant="body1" className={classes.fileError}>
                    Video may not exceed 10MB
                  </Typography>
                }

                {fileTypeError &&
                  <Typography variant="body1" className={classes.fileError}>
                    {imageOrVideo.current === "Image" ? "Image must be PNG format" : "Video must be MP4 format"}
                    </Typography>
                }

                <FailIcon className={styles.failIcon} />
              </div>
            }
          </div>
        </div>

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button onClick={handleCancelClick} className={classes.cancelButton} variant="text">
          CANCEL
        </Button>
        <Button disabled={!correctDimensions || loadingSpinner || fileTypeError || fileTooLarge} onClick={handleSaveClick} className={classes.saveButton} variant="contained" color="primary">
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
