import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const BookingsAdminStyles = makeStyles((theme: any) => createStyles({
  bookingsAdminWrapper: {
    position: "absolute",
    display: "inline-block",
    width: 1214,
    height: 675,
    top: 40,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  bookingsHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
  bookingsDivider: {
    width: "90%",
    position: "relative",
    top: 25,
    display: "inline-block"
  },
  adminMenuButton: {
    position: "absolute",
    top: 15,
    left: 15,
    zIndex: 900,
  },
  chooseDateHeader: {
    fontSize: 20,
    position: "relative",
    float: "left",
    bottom: 40,
    fontWeight: 400,
  },
  calendarHolder: {
    width: "80%",
    position: "absolute",
    bottom: -90,
    height: 450
  },
  calendar: {
    width: 450,
  },
  leftArrow: {
    left: 10,
  },
  rightArrow: {
    right: 5,
  },
  bookingsViewHeader: {
    fontSize: 20,
    position: "relative",
    float: "left",
    fontWeight: 400
  },
  bookingNameHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    bottom: 5,
    left: 20,
  },
  bookingSpaceHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    bottom: 5,
    left: 240,
  },
  bookingStartHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    bottom: 5,
    right: 220,
  },
  bookingEndHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    bottom: 5,
    right: 120,
  },
  registrationCountHeader: {
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute",
    bottom: 0,
    left: 430,
    textAlign: "left",
    display: "inline-block",
    width: 100,
  },
  bookingsHeaderHolder: {
    position: "absolute",
    width: "100%",
    height: 50,
    backgroundColor: "#E5E5E5"
  },
  bookingsLoadingSpinner: {
    position: "absolute",
    left: 0,
    top: -150,
    right: 0,
    bottom: 0,
    margin: "auto"
  }
})
)

export { BookingsAdminStyles };