import { makeStyles, createStyles } from '@material-ui/core/styles';

const EmailItemStyles = makeStyles((theme: any) => createStyles({
  emailName: {
    fontSize: 14,
    position: "absolute",
    left: 30,
    top: 13,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
    width: 205,
  },
  sendButton: {
    padding: 4,
    color: "black"
  },
  manageButton: {
    padding: 4,
    color: "black",
  },
  loadingSpinner: {
    position: "absolute",
    color: "grey",
    width: "20px !important",
    height: "20px !important",
    right: 112,
    top: 13
  }
})
)

export { EmailItemStyles };