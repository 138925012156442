import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeEditPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 1003,
    height: 644,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down('sm')]: {
      width: 700,
      height: 700,
    }
  },
  dialogPaperInternational: {
    height: 800,
    [theme.breakpoints.down('sm')]: {
      height: 870,
    }
  },
  popup: {
    width: "100%",
    height: 644,
    position: "absolute",
    [theme.breakpoints.down('sm')]: {
      height: 700,
    }
  },
  popupInternational: {
    [theme.breakpoints.down('sm')]: {
      height: 870,
    }
  },
  editAttendeeHeader: {
    fontSize: "1.5rem",
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 58
  },
  editAttendeeLink: {
    fontSize: 18,
    position: "relative",
    fontWeight: 400,
  },
  copyLinkButton: {
    padding: 4,
    position: "relative",
    bottom: 6,
    color: "black",
    zIndex:9999
  },
  resendInviteButton: {
    padding: 4,
    color: "black",
    position: "relative",
    bottom: 6,
  },
  regenerateButton: {
    padding: 4,
    color: "black",
    position: "relative",
    bottom: 6,
  },
  trashIcon: {
  },
  trashIconButton: {
    padding: 4,
    color: "black",
    position: "relative",
    bottom: 6,
  },
  brandToggle: {
    position: "relative",
    left: 105,
  },
  loadingSpinner: {
    width: "20px !important",
    height: "20px !important",
    right: 71,
    bottom: 84,
    color: "grey",
    position: "absolute",
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      right: 57,
      bottom: 93
    }
  },
  loadingSpinnerInternational: {
    bottom: -30,
  },
  copySnackbar: {
    width: 360,
  },
  regenerateSnackbar: {
    width: 360,
  },
  error: {
    color: "red",
    position: "absolute",
    right: 500,
    top: 37,
    [theme.breakpoints.down('sm')]: {
      left: 31,
      top: 82,
      right: 0
    }
  },
  emailError: {
    color: "red",
    position: "absolute",
    right: 125,
    top: 130,
    [theme.breakpoints.down('sm')]: {
      top: 175,
      right: 115
    }
  },
  regenerateLoadingSpinner: {
    width: "20px !important",
    height: "20px !important",
    color: "grey",
    position: "absolute",
    right: 115,
    bottom: 35,
  },
  resendInviteLoadingSpinner: {
    width: "20px !important",
    height: "20px !important",
    color: "grey",
    position: "absolute",
    right: 275,
    bottom: 35,
  },
  allowEarlyAccessToggle: {
    position: "relative",
    left: 200
  },
  closeIconButton: {
    position: "absolute",
    top: 3,
    color: "white",
    padding: 4,
    right: 5,
  },
  tooltip: {
    opacity: 1,
    fontSize: 12,
    padding: "8px 12px",
    backgroundColor: "#757575 !important"
  },
  tooltipPopper: {
    opacity: 1,
  },
  toggleLabel: {
    fontSize: 14,
  }
})
)

export { AttendeeEditPopupStyles };