import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const SpaceAdminStyles = makeStyles((theme: any) => createStyles({
  spaceAdminWrapper: {
    position: "absolute",
    display: "inline-block",
    width: 714,
    height: 875,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    border: "1px solid #dedede",
    borderRadius: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  createSpaceHeader: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    top: 20,
  },
  spaceDivider: {
    width: "90%",
    position: "relative",
    top: 25,
    display: "inline-block"
  },
  spaceNameHeader: {
    position: "relative",
    fontSize: 18,
    float: "left",
    fontWeight: 400,
  },
  spaceNameTextField: {
    position: "absolute",
    left: 0,
    top: 40,
    width: 300,
  },
  projectSelectHolder: {
    position: "absolute",
    left: 0,
    width: 300,
    top: 40,
  },
  projectMenu: {
    top: 150,
    textAlign: "left",
    paddingLeft: 15,
    maxHeight: 200,
  },
  menuItem: {
    paddingLeft: 20,
  },
  menuItemSelected: {
    paddingLeft: 20,
  },
  submitButton: {
    position: "absolute",
    right: 30,
    bottom: 30,
  },
  loadingSpinner: {
    width: "25px !important",
    height: "25px !important",
    position: "absolute",
    bottom: 35,
    right: 130,
  },
  success: {
    fontSize: 18,
    fontWeight: 400,
    display: "inline-block",
    position: "absolute",
    top: 150,
    left: 0,
    right: 0,
    center: 0,
    margin: "auto"
  },
  startDatePicker: {
    position: "relative",
  },
  endDatePicker: {
    position: "relative"
  },
  timezoneHeader: {
    fontSize: 18,
    position: "relative",
    textAlign: "left"
  },
  projectMenuPaper: {
    maxHeight: 300,
  },
  adminMenuButton: {
    position: "absolute",
    left: 15,
    top: 15,
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    position: "relative",
    bottom: 10,
    left: 160
  },
  worldLoadingSpinner: {
    color: "grey",
    position: "absolute",
    top: 48,
    left: 325
  }
})
)

export { SpaceAdminStyles };