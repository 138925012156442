import React, { ReactElement, useState, useContext, useEffect, useRef } from 'react';
import { Typography, Button, Divider, Snackbar, Switch, CircularProgress, IconButton } from '@material-ui/core';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import RunDisplay from './RunDisplay/RunDisplay';
import MiscellaneousDisplay from './MiscellaneousDisplay/MiscellaneousDisplay';
import { AppContext, AppContextType } from '../../../context/AppContext';
import ConfirmLinkRegeneratePopup from './ConfirmLinkRegeneratePopup/ConfirmLinkRegeneratePopup';
import CalendarAssignPopup from './CalendarAssignPopup/CalendarAssignPopup';

import styles from './BookingOverview.module.css';
import { BookingOverviewStyles } from './BookingOverviewStyles';

interface Props {
  
}

export default function BookingOverview(props: Props): ReactElement {

  const {
    selectedBooking,
    setSelectedBooking,
    toggleOnMainBookingWindow,
    toggleBookingHasCalendar,
    isSuperAdmin,
    spaceCalendars
  } : AppContextType = useContext(AppContext);

  const [ copySignUpToClipboard, toggleSignUpCopyToClipboard] = useState(false);
  const [ copyIntakeToClipboard, toggleIntakeCopyToClipboard] = useState(false);
  
  const [ regenerateSignUp, toggleRegenerateSignUp ] = useState(false);
  const [ regenerateIntake, toggleRegenerateIntake ] = useState(false);
  const [ confirmLinkRegeneratePopup, toggleConfirmLinkRegeneratePopup ] = useState(false);

  const [ isRegeneratingPublicCode, toggleIsRegeneratingPublicCode ] = useState(false);
  
  const [ signUpRegenerating, toggleSignUpRegenerating ] = useState(false);
  const [ intakeRegenerating, toggleIntakeRegenerating ] = useState(false);

  const [ calendarEntryLoadingSpinner, toggleCalendarEntryLoadingSpinner ] = useState(false);

  const [ calendarEntry, toggleCalendarEntry ] = useState(
    (selectedBooking.calendarID !== undefined && selectedBooking.calendarID !== "")
    ? true
    : false
  );

  const [ calendarAssign, toggleCalendarAssign ] = useState(false);

  const [ stateCounter, setStateCounter ] = useState(0);

  const [privateCloud, togglePrivateCloud] = useState(selectedBooking.privateCloud !== undefined
    ? selectedBooking.privateCloud
    : false
  );
  const [ privateCloudLoadingSpinner, togglePrivateCloudLoadingSpinner ] = useState(false);

  const [bookingStartTime, setBookingStartTime] = useState(selectedBooking.start.time);

  const firstRender = useRef(true);
  
  //Toggle context variable to increase height when on main booking window
  useEffect(() => {

    toggleOnMainBookingWindow(true);

    //Check if selected booking has calendar to increase height further
    if (selectedBooking.calendarID !== "" && selectedBooking.calendarID !== undefined) {
      toggleBookingHasCalendar(true);
    } else {
      toggleBookingHasCalendar(false);
    }


    return function cleanup() {
      toggleOnMainBookingWindow(false);
    }
  }, [])

  //Show calendar assign popup upon toggling "Calendar Entry" on
  useEffect(() => {

    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

  }, [calendarEntry]);

  useEffect(() => {

    //Double check for calendar entry toggle
    if (selectedBooking.calendarID == "" || selectedBooking.calendarID === undefined) {
      toggleCalendarEntry(false);
    };

  }, [selectedBooking]);

  //typeof type : "signup" or "intake" 
  const handleCopyToClipboardClick = (type : string) => {
    let dummy = document.createElement('textarea');

    document.body.appendChild(dummy);

    dummy.value = `${type === "signup" ? document.getElementById("signUpLink").innerText : document.getElementById("intakeLink").innerText}`;
    dummy.select();

    document.execCommand('copy');
    document.body.removeChild(dummy);

    type === "signup" ? toggleSignUpCopyToClipboard(true) : toggleIntakeCopyToClipboard(true);
  }


  /* Regenerate sign up handlers */
  const handleRegenerateSignUpClick = () => {

    toggleIsRegeneratingPublicCode(false);

    toggleConfirmLinkRegeneratePopup(true);
    
  }
  const handleSignUpLinkRegenerated = () => {

    toggleRegenerateSignUp(true);
  }
  /**/

  /*Regenerate intake handlers */
  const handleRegerateIntakeClick = () => {

    toggleIsRegeneratingPublicCode(true);

    toggleConfirmLinkRegeneratePopup(true);

  }

  const handlePublicLinkRegenerated = () => {

    toggleRegenerateIntake(true);
  }

  const handleConfirmLinkRegeneratePopupClose = () => {
    toggleConfirmLinkRegeneratePopup(false);
  }
  /**/

  /* Calendar Entry Toggle */
  const handleCalendarEntryToggle = async () => {

    //Show assign popup if toggling on calendar entry
    if (!calendarEntry) {
      toggleCalendarAssign(true);
    } 
    //Edit booking to empty string for calendar otherwise
    else {
      
      toggleCalendarEntryLoadingSpinner(true);

      toggleBookingHasCalendar(false);

      let selectedBookingClone = selectedBooking;

      selectedBookingClone.calendarID = "";

      try {
        await axios.put("/booking", JSON.stringify(selectedBookingClone))

        setSelectedBooking(selectedBookingClone);
        toggleCalendarEntryLoadingSpinner(false);
        toggleCalendarEntry(false);
      }
      catch {
        toggleCalendarEntryLoadingSpinner(false);
        alert("An error occurred.")
      }
    
    }
  }
  /**/

  const handleCalendarAssignClose = (calendarID : string) => {

    setStateCounter(stateCounter => stateCounter + 1);
    
    //Check if there is still no calendar ID assigned, if so, toggle calendar entry to false
    if (calendarID === "" || calendarID === undefined) {
      toggleBookingHasCalendar(false);
      toggleCalendarEntry(false);
    } else {
      //Otherwise, toggle calendar entry to true
      toggleBookingHasCalendar(true);
      toggleCalendarEntry(true);
    }

    //Close popup
    toggleCalendarAssign(false);
  }

  //Edit assigned calendar
  const handleEditCalendarNameClick = () => {
    toggleCalendarAssign(true);
  }

  const handleStartTimeChange = (startTime) => {
    setBookingStartTime(startTime);
  }

  const classes = BookingOverviewStyles();

  //Find matching calendar name if calendarID !== ""
  let bookingCalendarName;

  if (selectedBooking.calendarID !== undefined && selectedBooking.calendarID !== "") {
    for (let i = 0; i < spaceCalendars.length; i++) {
      if (spaceCalendars[i].calendarID === selectedBooking.calendarID) {
        bookingCalendarName = spaceCalendars[i].calendarName;
        break;
      }
    }
  }
  
  return (
    <div className={styles.bookingOverviewWrapper}>

      <Typography variant="h2" className={classes.linksHeader}>
        General Access Links
      </Typography>

      <div className={styles.signUpUrlHolder}>
        <Typography variant="body1" classes={{ root: classes.signUpUrlHeader}}>
          Sign-up URL:
        </Typography>
        <Typography variant="body1" id="signUpLink" classes={{ root: classes.signUpUrl}}>
          https://registration.showboat.live/{selectedBooking.signUpLoginCode}
        </Typography>

        <div className={styles.signUpButtonHolder}>
          <IconButton 
            onClick={() => handleCopyToClipboardClick("signup")} 
            disabled={signUpRegenerating} 
            color="primary" 
            classes={{ root: classes.copyButton}}
          >
            <img className={styles.copyImage} alt="Copy icon" src="assets/images/CopyIcon.svg" />
          </IconButton>

          <IconButton
            disabled={signUpRegenerating} 
            onClick={handleRegenerateSignUpClick} 
            color="primary" 
            classes={{ root: classes.regenerateButton}}
          >
            <RefreshIcon 
              style={{ color: "black", fontSize: "20px" }}
            ></RefreshIcon>
          </IconButton>
        </div>
      </div>

      <Divider
        className={classes.dividerSignUp}
      >
      </Divider>


      <div className={styles.intakeUrlHolder}>
        <Typography variant="body1" classes={{ root: classes.intakeUrlHeader}}>
          Intake URL:
        </Typography>
        <Typography id="intakeLink" variant="body1" classes={{ root: classes.intakeUrl}}>
          https://go.showboat.live/{selectedBooking.publicAppLoginCode}
        </Typography>

        <div className={styles.intakeButtonHolder}>
          <IconButton 
            onClick={() => handleCopyToClipboardClick("intake")} 
            disabled={intakeRegenerating} 
            color="primary" 
            classes={{ root: classes.copyButton}}
          >
            <img className={styles.copyImage} alt="Copy icon" src="assets/images/CopyIcon.svg" />
          </IconButton>

          <IconButton 
            color="primary" 
            disabled={intakeRegenerating} 
            onClick={handleRegerateIntakeClick} 
            classes={{ root: classes.regenerateButton}}
          >
            <RefreshIcon style={{ color: "black", fontSize: "20px" }}></RefreshIcon>
          </IconButton>
        </div>
      </div>

      <Divider
        className={classes.dividerIntake}
      >
      </Divider>
      

      <div className={styles.runHolder}>
        <RunDisplay handleStartTimeChange={handleStartTimeChange} />
      </div>

      <div className={styles.limitHolder}>
        <MiscellaneousDisplay 
          privateCloud={privateCloud} 
          selectedBookingStartTime={bookingStartTime}
        />
      </div>

      {/* LINK REGENERATE ERROR MESSAGE POPUP */}
      {confirmLinkRegeneratePopup &&
        <ConfirmLinkRegeneratePopup
          isPublicCode={isRegeneratingPublicCode}
          open={confirmLinkRegeneratePopup}
          handlePopupClose={handleConfirmLinkRegeneratePopupClose}
          handleSignUpLinkRegenerated={handleSignUpLinkRegenerated}
          handlePublicLinkRegenerated={handlePublicLinkRegenerated}
        />
      }
      
       {/*  SIGN UP COPY TO CLIPBOARD SNACKBAR */}
       <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }} 
        autoHideDuration={1500}
        className={classes.snackbar}
        open={copySignUpToClipboard}
        onClose={() => toggleSignUpCopyToClipboard(false)}
        message="Sign-up URL Copied to Clipboard"
      />

      {/* INTAKE COPY TO CLIPBOARD SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }} 
        autoHideDuration={1500}
        className={classes.snackbar}
        open={copyIntakeToClipboard}
        onClose={() => toggleIntakeCopyToClipboard(false)}
        message="Intake URL Copied to Clipboard"
      />

      {/* SIGN UP REGENERATED SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }} 
        autoHideDuration={1500}
        open={regenerateSignUp}
        className={classes.snackbar}
        onClose={() => toggleRegenerateSignUp(false)}
        message="Sign-up URL Regenerated"
      />

      {/* INTAKE REGENERATED SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }} 
        autoHideDuration={1500}
        className={classes.snackbar}
        open={regenerateIntake}
        onClose={() => toggleRegenerateIntake(false)}
        message="Intake URL Regenerated"
      />

      {calendarAssign &&
        <CalendarAssignPopup 
          open={true} 
          handlePopupClose={handleCalendarAssignClose}
        />
      }


    </div>
    
  )
}
