import { makeStyles, createStyles } from '@material-ui/core/styles';

const AvatarColorPreviewStyles = makeStyles((theme: any) => createStyles({
  optionsHeader: {
    position: "relative",
    fontSize: 24,
    fontWeight: 400,
    top: 35,
    right: 118,
    [theme.breakpoints.down('sm')]: {

    }
  },
  selectedIcon: {
    position: "relative",
    bottom: 120,
    backgroundColor: theme.palette.text.primary,
    borderRadius: "50%",
    display: "inline-block",
    height: 25,
    width: 25,
  
  },
  checkIcon: {
    color: "white",
    position: "relative",
    top: 2.5,
    fontSize: 16,
    strokeWidth: 2,
  },
  colorInputPaperSelected: {
    width: 104,
    height: 104,
    marginRight: 15,
    marginBottom: 15,
    "&:hover": {
      cursor: "pointer",
    },

  },
  colorInputPaper: {
    width: 104,
    height: 104,
    position: "relative",
    display: "inline-block",
    marginBottom: 15,
    marginRight: 15,
    "&:hover": {
      cursor: "pointer",
    },

  },
})
)

export { AvatarColorPreviewStyles };