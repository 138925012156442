import { makeStyles, createStyles } from '@material-ui/core/styles';

const WebTemplateSkinnableItemStyles = makeStyles((theme: any) => createStyles({
  webThemeHeader: {
    position: "absolute",
    fontSize: 14,
    top: 6,
    left: 30, 
  },
  themeFormHolder: {
    top: 28,
    left: 46,
  },
  highlightColorHeader: {
    position: "absolute",
    fontSize: 14,
    top: 19,
    left: 100, 
  },
  highlightColor: {
    position: "absolute",
    fontSize: 14,
    top: 43,
    left: 100,
  },
  editIcon: {
    fontSize: 16,
    position: 'relative',
    left: 2,
    bottom: 0.5
  },
  itemHeader: {
    position: "relative",
    fontSize: 14,
    left: 30,
    top: 14,
  },
  button: {
    position: "absolute",
    right: 23,
    top: 13,
    width: 105,
    height: 28
  },
  uploadButton: {
    position: "absolute",
    top: 12,
    right: 30,
    padding: 4
  },
  viewIcon: {
    position: "absolute",
    left: 242,
    top: 19,
    color: "black",
    "&:hover": {
      cursor: "pointer"
    }
  },
  webAssetIcon: {
    fontSize: 42,
    "&:hover": {
      cursor: "pointer"
    }
  },
  webAssetIconButton: {
    position: "absolute",
    zIndex: 999,
    left: 605,
    top: 8,
    color: "black",
    padding: 5,
  },
  loadingSpinner: {
    height: "25px !important",
    width: "25px !important",
    color: "grey",
    position: "absolute",
    top: 53,
    left: 200
  },
  highlightColorLoadingSpinner: {
    color: "grey",
    position: "absolute",
    left: 275,
    top: 38,
    width: "30px !important",
    height: "30px !important",
  },
  radioLabel: {
    fontSize: "14px !important",
  },
  radioLabelLight: {
    position: "relative",
    bottom: 5,
  },
})
)

export { WebTemplateSkinnableItemStyles };