import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import React, { ReactElement, useContext, useState } from 'react';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';
import { EmailContext, EmailContextType } from '../../../../context/EmailContext';

import { DeleteEmailTemplatePopupStyles } from './DeleteEmailTemplatePopupStyles';
import PopupHeaderDelete from '../../../PopupHeaderDelete/PopupHeaderDelete';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
  handleEmailTemplateDelete: (emailTemplateID: string) => any,
}

export default function DeleteEmailTemplatePopup(props: Props): ReactElement {

  const { 
    selectedEmailTemplate 
  } : EmailContextType = useContext(EmailContext);
  
  const { 
    selectedBooking, 
    setSelectedBooking 
  } : AppContextType = useContext(AppContext);

  const [ error, toggleError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
   
  const handleNoClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();
  }

  const handleYesClick = async () => {

    toggleLoadingSpinner(true);
    toggleError(false);

    try {
      await axios.delete("/email-template", {
        params: {
          emailTemplateID: selectedEmailTemplate.emailTemplateID,
          bookingID: selectedBooking.bookingID
        }
      });

      toggleLoadingSpinner(false);
     
      props.handleEmailTemplateDelete(selectedEmailTemplate.emailTemplateID);

    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }

  }
  
  const classes = DeleteEmailTemplatePopupStyles();
  
  return (
    <Dialog
      open={true}
      classes={{ paper: classes.dialogPaper}}
    >
      <div className={classes.popup}>

        <PopupHeaderDelete
          disableBoolean={loadingSpinner}
          closeFunction={props.handlePopupClose}
          headerText={`Delete ${selectedEmailTemplate.emailTemplateName}?`}
        />

        <Typography variant="h1" className={classes.deleteEmailTemplateHeader}>
          There is no undo for email template delete.
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred deleting this email template
          </Typography>
        }

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }

        <Button 
          variant="contained" 
          color="primary" 
          className={classes.noButton}
          onClick={handleNoClick}
        >
          CANCEL
        </Button>

        <Button 
          variant="text" 
          className={classes.yesButton}
          onClick={handleYesClick}
          disabled={loadingSpinner}
        >
          CONFIRM
        </Button>
      </div>
    </Dialog>
  )
}
