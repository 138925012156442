import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useHistory } from 'react-router-dom';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, Calendar, DatePicker } from "@material-ui/pickers";

import { AuthContext, AuthContextType } from '../../context/AuthContext';

import { BookingsAdminStyles } from './BookingsAdminStyles';
import styles from './BookingsAdmin.module.css';
import { Button, CircularProgress, Divider, Paper, Typography } from '@material-ui/core';
import BookingsAdminItem from './BookingsAdminItem/BookingsAdminItem';

interface Props {
  
}

export default function BookingsAdmin(props: Props): ReactElement {

  const history = useHistory();
  
  const { idToken, setIdToken } : AuthContextType = useContext(AuthContext);
  
  const [ authenticated, setAuthenticated ] = useState(false);

  const [ bookings, setBookings ] = useState([]);

  const [ selectedDate, setSelectedDate ] = useState(null);

  const [ dateBookings, setDateBookings ] = useState([]);

  const [ bookingsLoadingSpinner, toggleBookingsLoadingSpinner ] = useState(false);
  
  useEffect(() => {

    //Initial authentication check
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {

      //Redirect to sign-in if no producerID in sessionStorage   
      alert("You do not have privileges to access this page.");
      window.location.replace("/");

    } else {

      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: 'us-east-1_N15Q0NLkm',
        ClientId: '2332rbhi35f5016dglri2mojo'
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession(async (err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {

            toggleBookingsLoadingSpinner(true);

            axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage

            try {

              let roleResponse = await axios.get("/producer/role", {
                params: {
                  producerID: localStorage.getItem("producerID")
                }
              });

              //Get the role on the producer object
              if (roleResponse.data.role === 0) {

                setAuthenticated(true);

                //Get all spaces using admin endpoint
                try {

                  let response = await axios.get("/spaces/all/admin");

                  let spacesResponse = response.data;

                  let bookingsArray = [];
                  
                  //For each space in response, add booking mini objects to booking array
                  spacesResponse.forEach(space => {

                    let spaceBookings = space.bookings;

                    for (const booking in spaceBookings) {

                      let bookingObj = spaceBookings[booking];
                      bookingObj.spaceName = space.spaceName;

                      bookingsArray.push(bookingObj);
                    }

                  });

                  //Set state with bookings array (contains all bookings on Showboat)
                  setBookings(bookingsArray);

                  //Get initial bookings list for current date
                  setBookingsForDay(new Date(), bookingsArray);

                  toggleBookingsLoadingSpinner(false);

                }
                catch (err) {
                  console.log("ERROR", err);
                  toggleBookingsLoadingSpinner(false);
                }

              } else {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              };
            }
            catch {
              alert("You do not have privileges to access this page.");
              window.location.replace("/");
            }
           
          }
        })
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);

  const setBookingsForDay = (date, initialBookings?) => {

  

    let bookingArr = initialBookings ? initialBookings : bookings;

    let dateBookingsArr = [];

    bookingArr.forEach(booking => {

      //Loop through bookings array, finding items that fall within the bounds of selected date

      //First convert selected date to booking we are looping over's timezone
      let selectedDateInBookingTimezone = moment.tz(date, booking.start.timezone);

      //Get start and end of selected date (in this booking's timezone), convert to ms
      let selectedDateStart = moment(selectedDateInBookingTimezone).set("hour", 0).set("minutes", 1).set("seconds", 0).valueOf();
      let selectedDateEnd = moment(selectedDateInBookingTimezone).set("hour", 23).set("minutes", 59).set("seconds", 0).valueOf();

      //Convert booking's start and end times to ms
      let bookingStartTimeValue = moment(booking.start.time).valueOf();
      let bookingEndTimeValue = moment(booking.end.time).valueOf();

      //Check if any part of booking falls within bounds of selected date 
      if ((bookingStartTimeValue <= selectedDateEnd) && (selectedDateStart <= bookingEndTimeValue)) {
        dateBookingsArr.push(booking);
      }
    })

    console.log("BOOKINGS FOR DAY", dateBookingsArr);

    setDateBookings(dateBookingsArr);
  }

  //Date change
  const handleDateChange = (date : any) => {

    //Set date bookings for this selected date
    setBookingsForDay(date);
    
    setSelectedDate(date);
    
  }

  //Month change
  const handleMonthChange = (date: any) => {

  }
    
  const classes = BookingsAdminStyles();
  
  if (authenticated) {
    return (
      
      <React.Fragment>
        <Button 
          className={classes.adminMenuButton} 
          variant="contained" 
          color="primary"
          onClick={() => {history.push("/admin-menu")}}
          >
          ADMIN MENU
        </Button>
        <div className={styles.bookingsAdminWrapper}>

          
          <Paper className={classes.bookingsAdminWrapper}>
            <Typography variant="h1" className={classes.bookingsHeader}>
              Bookings Overview
            </Typography>


            <Divider className={classes.bookingsDivider}></Divider>

            <div className={styles.bookingsOverviewHolder}>

              <div className={styles.calendarHolder}>
                <Typography variant="h2" className={classes.chooseDateHeader}>
                  Select Date
                </Typography>

                <Paper className={classes.calendarHolder}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        disabled={bookingsLoadingSpinner}
                        defaultValue={null}
                        value={selectedDate}
                        className={classes.calendar}
                        onChange={handleDateChange}
                        disablePast={bookingsLoadingSpinner}
                        disableFuture={bookingsLoadingSpinner}
                        TextFieldComponent={() => null}
                        open={true}
                        variant="static"
                        onMonthChange={handleMonthChange}
                        leftArrowButtonProps={{
                          classes: {
                            root: classes.leftArrow
                          }
                        }}
                        rightArrowButtonProps={{
                          classes: {
                            root: classes.rightArrow
                          }
                        }}
                      >
                      </DatePicker>
                  </MuiPickersUtilsProvider>
                    
                </Paper>
              </div>

              <div className={styles.bookingsHolder}>
                <Typography className={classes.bookingsViewHeader} variant="h2">
                  Bookings on DATEDATEDATE
                </Typography>

                <Paper square className={classes.bookingsHeaderHolder}>
                    <Typography variant="body1" className={classes.bookingNameHeader}>
                      Name
                    </Typography>

                    <Typography variant="body1" className={classes.bookingSpaceHeader}>
                      Space Name
                    </Typography>

                    <Typography variant="body1" className={classes.bookingStartHeader}>
                      Start
                    </Typography>

                    <Typography variant="body1" className={classes.bookingEndHeader}>
                      End
                    </Typography>

                </Paper>

                <div className={styles.bookingsItemHolder}>

                  {bookingsLoadingSpinner &&
                    <CircularProgress className={classes.bookingsLoadingSpinner}/>
                  }

                  {dateBookings.map(booking => {
                    return (
                      <BookingsAdminItem 
                        name={booking.bookingName}
                        start={booking.start}
                        end={booking.end}
                        spaceName={booking.spaceName}
                        key={booking.bookingID}
                      />
                    )
                  })}

                </div>
              </div>
              
            </div>

          </Paper>

        </div>
      </React.Fragment>

    )

  } else {
    return (
      <div>
      </div>
    )
  }
 
}
