import React, { ReactElement, useContext, useEffect, useState } from 'react';

//TODO: TODO: TODO:
//Set correct types for selectedEmailTemplate and setSelectedEmailTemplate

export interface EmailContextType {
  selectedAttendees : SHOWBOAT.Registration[],
  setSelectedAttendees : any,
  selectedPresenters : SHOWBOAT.Registration[],
  setSelectedPresenters : any,
  selectedEmailTemplate : any,
  setSelectedEmailTemplate : (selectedEmailTemplate : any) => void
}

export const EmailContext = React.createContext(null);

export default function EmailContextProvider(props): ReactElement {

  const [ selectedEmailTemplate, setSelectedEmailTemplate ] = useState("");

  const [ selectedAttendees, setSelectedAttendees ] = useState([]);

  const [ selectedPresenters, setSelectedPresenters ] = useState([]);

  const EmailContextValue : EmailContextType = {
    selectedAttendees,
    setSelectedAttendees,
    selectedPresenters,
    setSelectedPresenters,
    selectedEmailTemplate,
    setSelectedEmailTemplate
  }

  return (
    <EmailContext.Provider
      value={EmailContextValue}
    >
      {props.children}
    </EmailContext.Provider>
  )
}
