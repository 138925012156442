import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const MainStyles = makeStyles((theme: any) => createStyles({
  previewRoomButton: {
    marginRight: 14
  },
  homeButton: {
    position: "absolute",
    right: 115,
    zIndex: 9998,
  },
  signOutButton: {
    position: "absolute",
    right: 0,
    zIndex: 9999,
    color: "white",
    fontSize: 16,
  },
  mainHeader: {
    fontSize: 24,
    position: "absolute",
    top: 50,
    left: 0,
    fontWeight: 400,
  },
  mainClientHeader: {
    fontSize: 20,
    color: "white",
    position: "absolute",
    top: 15,
    left: 75,
    fontWeight: 400,
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      left: 65
    }
  },
  navHolder: {
    backgroundColor: "black",
    position: "absolute",
    display: "inline-block",
    top: 0,
    width: "100%",
    height: 80,
    zIndex: 110,
  },
  mainSpaceHeader: {
    fontSize: 16,
    marginBottom: 3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      position: "absolute",
      top: -54,
      left: "45.5%",
      transform: "translate(-50%, -50%)"
    }
  },
  mainSpaceHeaderBottom: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      top: -22,
    }
  },
  mainOpenDate: {
    fontSize: 15,
    color: "grey",
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  mainCloseDate: {
    fontSize: 15,
    marginBottom: 10,
    color: "grey",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  mainUsageHeader: {
    fontSize: 15,
    marginBottom: 10,
    color: "grey",
    width: 250,
    bottom: 1,
    position: "relative",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  mainUsageHours: {
    fontSize: 15,
    marginBottom: 10,
    color: "grey",
    display: "inline-block",
    position: "relative",
    bottom: 37,
    left: 60,
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  usageHours: {
    fontSize: 15,
    marginBottom: 10,
    width: 150,
    position: "relative",
    left: 70,
    bottom: 37,
    display: "inline-block",
    color: "grey",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  usageHoursLoading: {
    left: 90,
  }, 
  usageLoadingSpinner: {
    color: "grey",
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    left: 61,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: "23.5%",
    }
  },
  refreshIcon: {
    color: "grey",
    position: "relative",
    top: -2,
    left: 20,
    padding: 6,
    "&:hover": {
      cursor: "pointer"
    },
    [theme.breakpoints.down('md')]: {
      left: 6,
    }
  },
  refreshButtonIcon: {
    fontSize: 22
  },
  spaceID: {
    position: "absolute",
    fontSize: 14,
    color: "grey",
    top: 193,
    [theme.breakpoints.down('sm')]: {
      top: -50,
      right: 80,
      width: 200,
    }
  },
  spaceCopyButton: {
    top: 20,
    padding: 4,
    left: -4,
    color: "black",
    [theme.breakpoints.down('sm')]: {
      top: -20,
    }
  },
  welcomeHolder: {
    backgroundColor: "#00B6FF",
    width: 990,
    height: 450,
    position: "relative",
    left: "calc(50% - 495px)",
    top: 90,
    [theme.breakpoints.down('sm')]: {
      width: 830,
      left: "calc(50% - 415px)",
    }
  },
  welcomeHeader: {
    fontSize: 44,
    position: "absolute",
    fontWeight: 300,
    color: "white",
    display: "inline-block",
    width: 600,
    left: 137,
    top: 257,
    [theme.breakpoints.down('sm')]: {
      left: 68,
    }
  },
  releaseVersion: {
    display: "inline-block",
    color: "white",
    position: "relative",
    left: 40,
    top: 7,
    fontSize: 16,
  },
  spaceIcon: {
    color: "black",
    position: "relative",
    marginRight: 10,
    top: 4,
  },
  adminButton: {
    position: "absolute",
    top: 85,
    left: 5,
    zIndex: 111,
  }
})
)

export { MainStyles };