import { makeStyles, createStyles } from '@material-ui/core/styles';

const ConfirmSendPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    height: 317,
    width: 506,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: 506,
    height: 317,
    backgroundColor: "white",
    border: "none",
    outline: "none",
    position: "absolute"
  },
  error: {
    color: "red",
    position: "relative",
    left: 40,
    top: 45,
    width: 450
  },
  confirmHeader: {
    fontWeight: 400,
    fontSize: "1.5rem",
    position: "relative",
    top: 40,
    left: 40,
  },
  confirmAttendees: {
    fontSize: "1.25rem",
    position: "relative",
    display: "inline-block",
    top: 60,
    left: 39,
  },
  confirmPresenters: {
    fontSize: "1.25rem",
    position: "relative",
    top: 70,
    left: 39,
  },
  cancelButton: {
    width: 95,
    height: 44,
    position: "absolute",
    bottom: 26,
    right: 143,
  },
  sendButton: {
    width: 95,
    height: 44,
    position: "absolute",
    bottom: 26,
    right: 32,
    backgroundColor: "green",
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    bottom: 85,
    right: 60,
  }
})
)

export { ConfirmSendPopupStyles }