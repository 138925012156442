import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, IconButton, Typography } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import TrashIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CopyIcon from '@material-ui/icons/Link';

import BookingOverview from '../BookingOverview/BookingOverview';
import BookingAttendees from '../BookingAttendees/BookingAttendees';
import BookingPresenters from '../BookingPresenters/BookingPresenters';
import BookingEmail from '../BookingEmail/BookingEmail';
import EditBookingNamePopup from './EditBookingNamePopup/EditBookingNamePopup';
import DeleteBookingPopup from './DeleteBookingPopup/DeleteBookingPopup';
import BookingRegistration from '../BookingRegistration/BookingRegistration';
import { AppContext, AppContextType } from '../../../context/AppContext';
import BookingErrorMessages from '../BookingErrorMessages/BookingErrorMessages';
import BookingCurrentAttendees from '../BookingCurrentAttendees/BookingCurrentAttendees';
import EditDuplicateBookingTimePopup from './EditDuplicateBookingTimePopup/EditDuplicateBookingTimePopup';

import styles from './BookingEdit.module.css';
import { BookingEditStyles } from './BookingEditStyles';

interface Props {
  itemToEdit: any,
  handleDoneButtonClick: () => any,
  addAndEdit: boolean
}

export default function BookingEdit(props: Props): ReactElement {

  const history = useHistory();
  
  const [ selectedTab, setSelectedTab ] = useState("overview");
  const [ editBookingName, toggleEditBookingName ] = useState(false);
  const [ bookingDelete, toggleBookingDelete] = useState(false);
  const [ isLoadingDelete, toggleIsLoadingDelete] = useState(false);
  const [ doneClick, toggleDoneClick ] = useState(false);

  const [ duplicateBooking, toggleDuplicateBooking ] = useState(false);

  const [ bookingDeleteError, toggleBookingDeleteError ] = useState(false);

  const [ live, toggleLive ] = useState(false);
  const [ showAttendees, toggleShowAttendees ] = useState(false);

  const [ isSuperAdmin, toggleIsSuperAdmin ] = useState(false);

  const { 
    selectedBooking, 
    selectedSpaceID, 
    setSelectedBooking,
    setSelectedBookingID,
    setSelectedBookingUsage,
    toggleWasDoneClicked,
    producerID
  } : AppContextType  = useContext(AppContext);

  useEffect(() => {
    if (process.env.REACT_APP_API_STAGE === "dev") {
      console.log("Selected booking change", selectedBooking);
    }
  }, [selectedBooking])

  useEffect(() => {

    //Check role to see if we need to display bookingID
    const getProducerRole = async () => {
      try {
        let response = await axios.get("/producer/role", {
          params: {
            producerID: producerID
          }
        });

        if (response.data.role === 0) {
          toggleIsSuperAdmin(true);
        } else {
          toggleIsSuperAdmin(false);
        }
      }
      catch {
        toggleIsSuperAdmin(false);
      }
    }
    getProducerRole();

    //Check if the booking we are editing is live
    let bookingStartUTC = moment.utc(selectedBooking.start.time).valueOf();
    let bookingEndUTC = moment.utc(selectedBooking.end.time).valueOf();
    let currentUTC = Date.now();

    if ((bookingStartUTC < currentUTC) && (currentUTC < bookingEndUTC)) {
      toggleLive(true);
    } else {
      toggleLive(false);
    };

    //Check if attendees tab should be shown (only after event accessTime has been reached)
    let beforeTimeComparison = selectedBooking.accessTime ? new Date(selectedBooking.accessTime).getTime() : bookingStartUTC;
    
    if (beforeTimeComparison < currentUTC) {
      toggleShowAttendees(true);
    } else {
      toggleShowAttendees(false);
    }

    
  }, []);

  const handleTabSelect = (e) => {
    setSelectedTab(e.target.id);
  }

  /* EDIT BOOKING NAME POPUP */
  const handleEditBookingNameClick = () => {
    toggleEditBookingName(true);
  }

  const handleBookingNameClose = () => {
    toggleEditBookingName(false);
  }
  /**/

  /* BOOKING DELETE POPUP */
  const handleDeleteBookingClick = () => {
    
    toggleBookingDelete(true);
  }

  const handleBookingDeleteClose = () => {
    
    toggleBookingDelete(false);
  }

  const handleBookingDelete = async (bookingID: string) => {

    toggleBookingDeleteError(false);

    toggleIsLoadingDelete(true);

    try {
      await axios.delete('/booking', { 
        params: {
          spaceID: selectedSpaceID,
          bookingID: bookingID
        } 
      });

      toggleIsLoadingDelete(false);
      setSelectedBooking(null);
      setSelectedBookingID(null);
      setSelectedBookingUsage(null);
    }
    catch (error) {
      toggleIsLoadingDelete(false);
      toggleBookingDeleteError(true);
    }

  }
  /**/

  const handleDoneButtonClick = () => {
    
    //Put selected space back into URL bar
    history.push(`/space/${selectedSpaceID}`);

    //Trigger done button logic in AppContext
    toggleWasDoneClicked(true);
  }

  /* Duplicate Booking */
  const handleDuplicateBookingClick = () => {
    toggleDuplicateBooking(true);
  }

  const handleDuplicatePopupClose = () => {
    toggleDuplicateBooking(false);
  }

  /**/

  //Copy bookingID 
  const handleCopyBookingIDClick = () => {
    let dummy = document.createElement('textarea');

    document.body.appendChild(dummy);

    dummy.value = props.itemToEdit.bookingID;
    dummy.select();

    document.execCommand('copy');
    document.body.removeChild(dummy);

  }
  
  const handleOverviewTabSelect = () => {
    setSelectedTab("overview");
  }
  
  //Material UI classes
  const classes = BookingEditStyles();
  
  return (
    <div className={styles.bookingEditWrapper}>
      <div className={styles.bookingEditHeader}>

        <Typography className={classes.eventNameHeader} variant="h2">
          Event Name
        </Typography>
        <Typography className={classes.eventName} variant="body1">
          {selectedBooking.name}
        </Typography>

        {isSuperAdmin &&
          <React.Fragment>

            <Typography variant="body1" className={classes.bookingID}>
              Booking ID: {props.itemToEdit.bookingID}
            </Typography>

            <IconButton
              className={classes.copyBookingIDButton}
              onClick={handleCopyBookingIDClick}
            >
              <CopyIcon style={{ fontSize: "20px" }} />
            </IconButton>
            
          </React.Fragment>
        }

        <div className={styles.inputIconHolder}>
          <EditIcon 
            className={classes.editIcon} 
            onClick={handleEditBookingNameClick}
          />

          <img 
            className={styles.duplicateIcon}
            alt="duplicate icon" 
            onClick={handleDuplicateBookingClick}
            src="assets/images/DuplicateIcon.png"
          />

          <TrashIcon 
            className={classes.trashIcon} 
            onClick={handleDeleteBookingClick}
          />

          {live &&
          <div className={styles.liveDisplay}>
            <Typography variant="body1" className={classes.liveHeader}>
              LIVE
            </Typography>
          </div>
          }

        </div>

        <Button 
          variant="text" 
          color="primary" 
          classes={{ root: classes.doneButton, label: classes.doneButtonLabel}}
          onClick={handleDoneButtonClick}
        >
          <ArrowBackIcon className={classes.arrowBackIcon}></ArrowBackIcon>
          BACK
        </Button>
      </div>

      <div className={styles.bookingEditNav}>
        
        <div className={styles.optionWrapper}>
          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedOverview}`}
            style={{
              backgroundColor: selectedTab === "overview" ? "#FF0092" : "#DCDCDC"
            }}
          ></span>
            <Typography 
              id="overview" 
              onClick={handleTabSelect} 
              variant="body1" 
              classes={
                { 
                  root: selectedTab==='overview' ? `${classes.bookingNavOption} ${classes.bookingNavOptionSelected}` : classes.bookingNavOption 
                }
              }
              style={{
                left: "8px",
                color: selectedTab==="overview" ? "white" : "black"
              }}
            >
              Overview
            </Typography>
        </div>
        
        <div className={styles.optionWrapper}>
          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedAttendees}`}
            style={{
              backgroundColor: selectedTab === "currentAttendees" ? "#FF0092" : "#DCDCDC"
            }}
          ></span>

          {showAttendees &&
              <Typography
                id="currentAttendees"
                onClick={handleTabSelect}
                variant="body1"
                classes={
                  { 
                    root: selectedTab==='currentAttendees' ? `${classes.bookingNavOption} ${classes.bookingNavOptionSelected}` : classes.bookingNavOption 
                  }
                }
                style={{
                  left: "5px",
                  color: selectedTab === "currentAttendees" ? "white" : "black"
                }}
              >
                Attendees
              </Typography>
          }
        </div>
        
        <div className={styles.optionWrapper}>
          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedGuestList}`}
            style={{
              backgroundColor: selectedTab === "guestList" ? "#FF0092" : "#DCDCDC"
            }}
          ></span>
          <Typography 
            id="guestList" 
            onClick={handleTabSelect} 
            variant="body1" 
            classes={
              { 
                root: selectedTab==='guestList' ? `${classes.bookingNavOption} ${classes.bookingNavOptionSelected}` : classes.bookingNavOption 
              }
            }
            style={{
              left: "3px",
              color: selectedTab === "guestList" ? "white" : "black"
            }}
          >
            Guest List
          </Typography>
        </div>
        
        <div className={styles.optionWrapper}>
          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedRegistration}`}
            style={{
              backgroundColor: selectedTab === "registration" ? "#FF0092" : "#DCDCDC"
            }}
          ></span>
          <Typography 
            id="registration" 
            onClick={handleTabSelect} 
            variant="body1" 
            classes={
              { 
                root: selectedTab==='registration' ? `${classes.bookingNavOption} ${classes.bookingNavOptionSelected}` : classes.bookingNavOption 
              }
            }
            style={{
              left: "2px",
              color: selectedTab === "registration" ? "white" : "black"
            }}
          >
            Registration
          </Typography>
        </div>
        
        <div className={styles.optionWrapper}>
          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedPresenters}`}
            style={{
              backgroundColor: selectedTab === "presenters" ? "#FF0092" : "#DCDCDC"
            }}
          ></span>
          <Typography 
            id="presenters" 
            onClick={handleTabSelect} 
            variant="body1" 
            classes={
              { 
                root: selectedTab==='presenters' ? `${classes.bookingNavOption} ${classes.bookingNavOptionSelected}` : classes.bookingNavOption 
              }
            }
            style={{
              left: "-1px",
              color: selectedTab === "presenters" ? "white" : "black"
            }}
          >
            Presenters
          </Typography>
        </div>
        
        <div className={styles.optionWrapper}>
          <span 
            className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedEmail}`}
            style={{
              backgroundColor: selectedTab === "email" ? "#FF0092" : "#DCDCDC"
            }}
          ></span>
          <Typography 
            id="email" 
            onClick={handleTabSelect} 
            variant="body1" 
            classes={
              { 
                root: selectedTab==='email' ? `${classes.bookingNavOption} ${classes.bookingNavOptionSelected}` : classes.bookingNavOption 
              }
            }
            style={{
              left: "-1px",
              color: selectedTab === "email" ? "white" : "black"
            }}
          >
            Email
          </Typography>
        </div>
        
        <div className={styles.optionWrapper}>
            <span 
              className={`${styles.optionHolderSelected} ${styles.optionHolderSelectedError}`}
              style={{
                backgroundColor: selectedTab === "errorMessages" ? "#FF0092" : "#DCDCDC"
              }}
            ></span>
            <Typography 
              id="errorMessages" 
              onClick={handleTabSelect} 
              variant="body1" 
              classes={
                { 
                  root: selectedTab==='errorMessages' ? `${classes.bookingNavOption} ${classes.bookingNavOptionSelected}` : classes.bookingNavOption 
                }
              }
              style={{
                left: -2,
                color: selectedTab === "errorMessages" ? "white" : "black"
              }}
            >
              Messages
            </Typography>
        </div>
        
      </div>

      <div className={styles.bookingEditMain}>
        {
          {
            'overview': <BookingOverview />,
            'guestList': <BookingAttendees />,
            'currentAttendees': <BookingCurrentAttendees />,
            'registration': <BookingRegistration doneClick={doneClick} currentTab={selectedTab} handleOverviewTabSelect={handleOverviewTabSelect}/>,
            'email': <BookingEmail />,
            'presenters': <BookingPresenters />,
            'errorMessages': <BookingErrorMessages />
          }[selectedTab]
        }
      </div>

      {editBookingName &&
        <EditBookingNamePopup bookingName={selectedBooking.name} open={editBookingName} handlePopupClose={handleBookingNameClose} />
      }

      <DeleteBookingPopup 
        bookingID={selectedBooking.bookingID} 
        open={bookingDelete} 
        isLoading={isLoadingDelete}
        bookingDeleteError={bookingDeleteError}
        bookingName={selectedBooking.name} 
        handlePopupClose={handleBookingDeleteClose} 
        handleBookingDelete={handleBookingDelete} 
      />

      {duplicateBooking &&
        <EditDuplicateBookingTimePopup 
          open={true}        
          handlePopupClose={handleDuplicatePopupClose}
        />
      }

    </div>
  )
}
