import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Dialog, Paper, Typography, Tab, Tabs, Button } from '@material-ui/core';

import SendEmailAttendees from './SendEmailAttendees/SendEmailAttendees';
import SendEmailPresenters from './SendEmailPresenters/SendEmailPresenters';
import ConfirmSendPopup from './ConfirmSendPopup/ConfirmSendPopup';
import { EmailContext, EmailContextType } from '../../../../context/EmailContext';

import { SendEmailPopupStyles } from './SendEmailPopupStyles';
import PopupHeader from '../../../PopupHeader/PopupHeader';

interface Props {
  handlePopupClose: () => any,
  templateName: string,
  templateID: string
}

export default function SendEmailPopup(props: Props): ReactElement {

  const { selectedEmailTemplate } : EmailContextType = useContext(EmailContext);

  const [ currentTab, setCurrentTab ] = useState(0);

  const [ confirmSend, toggleConfirmSend ] = useState(false);

  //Switch to "Presenters" tab if we are sending the Presenter Invite Link email
  useEffect(() => {
    if (selectedEmailTemplate.emailTemplateName === "Presenter Invite Link") {
      setCurrentTab(1)
    };
  }, [])

  const handleTabChange = (tab: number) => {
    setCurrentTab(tab);    
  }

  const handleCancelButtonClick = () => {
    props.handlePopupClose();
  }

  /*Confirm send*/
  const handleSendButtonClick = () => {
    toggleConfirmSend(true);
  }

  const handleConfirmSendCancel = () => {
    toggleConfirmSend(false);
  }
  
  const handleConfirmSendComplete = () => {
    toggleConfirmSend(false);
    props.handlePopupClose();
  }
  /**/

  //MaterialUI classes
  const classes = SendEmailPopupStyles();

  return (
    <Dialog
      open={true}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={false}
        />
        <Paper className={classes.sendEmailHeaderHolder}>
          <Typography variant="h1" className={classes.sendEmailHeader}>
            Send Email
          </Typography>
          <Typography variant="h2" className={classes.sendEmailName}>
            {props.templateName}
          </Typography>

          <Button onClick={handleCancelButtonClick} className={classes.cancelButton} variant="text">
            CANCEL
          </Button>
          <Button onClick={handleSendButtonClick} className={classes.sendButton} variant="contained" color="primary">
            SEND
          </Button>

          <Tabs 
            value={currentTab}
            indicatorColor="primary"
            textColor="primary" 
            classes={{ root: classes.sendEmailTabs }}
          >
            <Tab disabled={props.templateName==="Presenter Invite Link"} onClick={() => handleTabChange(0)} label="Attendees" />
            <Tab onClick={() => handleTabChange(1)} label="Presenters" />
        </Tabs>

        </Paper>

        <SendEmailAttendees currentTab={currentTab}/>
        <SendEmailPresenters currentTab={currentTab}/>

      </div>
      
      {confirmSend &&
        <ConfirmSendPopup 
          open={confirmSend} 
          handlePopupClose={handleConfirmSendCancel} 
          handleConfirmSendComplete={handleConfirmSendComplete}
        />
      }
      
    </Dialog>
    
  )
}
