import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarWindowStyles = makeStyles((theme: any) => createStyles({
  spaceHeader: {
    fontSize: 20,
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 75,
  },
  spaceDivider: {
    width: "100%",
    position: "absolute",
    top: 129,
  },
  calendarURLHeader: {
    fontSize: 16,
    position: "absolute",
    left: 30,
  },
  calendarURL: {
    fontSize: 16,
    position: "absolute",
    left: 173.5
  },
  copyButton: {
    position: "absolute",
    right: 156,
    top: 0,
    display: "inline-block",
    width: 62,
    height: 28,
    padding: 0,
  },
  regenerateButton: {
    position: "absolute",
    top: 0,
    right: 24,
    display: "inline-block",
    width: 123,
    height: 28,
    padding: 0,
  },
  calendarLiveToggle: {
    position: "absolute",
    left: 165.5,
  },
  addAndEditButton: {
    left: 139,
    padding: 0,
    top: 26,
    backgroundColor: "white",
    border: "1px solid #FF0092",
    borderRadius: 4,
    color: "black",
    width: 67,
    height: 26,
    fontSize: 14,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FF0092",
      boxShadow: "none",
      color: "white"
    }
  },
  calendarNameHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    top: 138,
    left: 30,
    fontFamily: "Helvetica",
  },
  addIcon: {
    position: "relative",
    bottom: 1,
    right: 2,
    marginRight: 4,
    width: 16,
    height: 16
  }
 
  
})
)

export { CalendarWindowStyles };