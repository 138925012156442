import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const DetailsPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 816,
    height: 756,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down('sm')]: {
      width: 700,
    }
  },
  popup: {
    height: 756,
    width: "100%",
    position: "absolute",
  },
  itemText: {
    fontSize: 16,
    position: "relative",
    fontFamily: "Roboto, sans-serf"
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 100
  },
  systemCheckID: {
    fontSize: 16,
    position: "absolute",
    top: 50,
    left: 50,
  },
  name: {
    fontSize: 16,
    position: "absolute",
    top: 25,
    left: 50,
  },
  comment: {
    fontSize: 14,
    position: "absolute",
    left: 50,
    top: 610,
    display: "inline-block",
    width: "90%"
  }
})
)

export { DetailsPopupStyles };