import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarEditStyles = makeStyles((theme: any) => createStyles({
  calendarEditHeader: {
    fontSize: 16,
    position: "absolute",
    top: 27,
    left: 24,
    fontWeight: 400,
  },
  backButton: {
    position: "absolute",
    top: 17,
    right: 25,
    color: "black",
    padding: 4,
  },
  calendarNameHeader: {
    fontSize: 10,
    position: "absolute",
    left: 27,
    top: 65,
    color: "#888888"
  },
  calendarName: {
    fontSize: 20,
    position: "relative",
    display: "inline-block",
    left: 27,
    top: 80,
    maxWidth: 350,
    minWidth: 100,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 400,
  },
  editIcon: {
    fontSize: 20,
    "&:hover": {
      cursor: "pointer"
    }
  },
  trashIcon: {
    fontSize: 20,
    position: "relative",
    left: 15,
    "&:hover": {
      cursor: "pointer"
    }
  },
  divider: {
    position: "absolute",
    width: "100%",
    top: 121
  },
  calendarURLHeader: {
    position: "absolute",
    left: 27,
    fontSize: 16,
    top: 5,
  },
  calendarURL: {
    fontSize: 14,
    position: "absolute",
    width: 340,
    display: "inline-block",
    wordWrap: "break-word",
    left: 140,
    top: 3,
  },
  copyButton: {
    display: "inline-block",
    padding: 0,
    position: "absolute",
    right: 85,
  },
  regenerateButton: {
    display: "inline-block",
    padding: 6,
    position: "absolute",
    right: 27,
    bottom: 2,
    color: "black"
  },
  copySnackbar: { 
    width: 360,
  },
  regenerateSnackbar: {
    width: 360,
  },
  loadingSpinner: {
    width: "20px !important",
    height: "20px !important",
    color: "grey",
    position: "absolute",
    top: -23,
    right: 33,
  },
  refreshIcon: {
    width: 20,
    height: 20,
  }
})
)

export { CalendarEditStyles };