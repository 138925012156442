import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Typography, TextField, Button, InputAdornment, CircularProgress, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AWS, { CognitoIdentityServiceProvider }  from 'aws-sdk';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import VisibleIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import Cookies from "js-cookie";

import { AuthContext, AuthContextType } from '../../context/AuthContext';
import { AppContext, AppContextType } from '../../context/AppContext';
import ForgotPassword from './ForgotPassword/ForgotPassword';

import styles from './SignInForm.module.css';
import { SignInFormStyles } from './SignInFormStyles';
import { JWTHelper } from '../../utilities/JWTHelper';

const passwordValidator = require('password-validator');
const jwt = require("jsonwebtoken");

var cogntioIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

interface Props {
  fromBookingLink: boolean,
  fromSpaceLink: boolean,
  handleAuthenticate: () => any,
}

export default function SignInForm(props : Props): ReactElement {

  const history = useHistory();
  
  const { 
    setIdToken, 
    setRefreshToken,
  } : AuthContextType = useContext(AuthContext);

  const { 
    producerID,
    setProducerID,
    setProducerProjects,
    setProducerSpaces,
    setSelectedSpace,
    setSelectedSpaceID,
    setSelectedBooking,
    setSelectedBookingID,
    setSelectedProject
  } : AppContextType = useContext(AppContext);

  var testPool = {
    UserPoolId: 'us-east-1_N15Q0NLkm',
    ClientId: '2332rbhi35f5016dglri2mojo'
  };

  var testUserPool = new CognitoUserPool(testPool);

  const [ username, setUserName ] = useState("");
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ showPassword, toggleShowPassword ] = useState(false);
  const [ newPasswordRequired, toggleNewPasswordRequired ] = useState(false);

  const [ forgotPassword, toggleForgotPassword ] = useState(false);

  const [ newPasswordValue, setNewPasswordValue ] = useState("");
  const [ newPasswordMatch, setNewPasswordMatch ] = useState("");

  const [ loginErrorMessageStore, setLoginErrorMessageStore ] = useState([]);
  const [ newPasswordErrorMessageStore, setNewPasswordErrorMessageStore ] = useState([]);
  const [ firstNameRequiredError, toggleFirstNameRequiredError ] = useState(false);
  const [ lastNameRequiredError, toggleLastNameRequiredError ] = useState(false);
  const [ passwordRequiredError, togglePasswordRequiredError ] = useState(false);
  const [ confirmPasswordRequiredError, toggleConfirmPasswordRequiredError ] = useState(false);

  const [ passwordHasBeenChanged, togglePasswordHasBeenChanged ] = useState(false);
  
  const [ formHasBeenSubmitted, toggleFormHasBeenSubmitted ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ usernameError, toggleUsernameError ] = useState(false);
  const [ passwordError, togglePasswordError ] = useState(false);

  const [ urlPathArray, setURLPathArray ] = useState(window.location.pathname.split("/"))

  const [ cognitoUser, setCognitoUser ] = useState(new CognitoUser({
    Username: "",
    Pool: testUserPool
  }));

  const [ cognitoUserAttributes, setCognitoUserAttributes] = useState(null);

  var passwordSchema = new passwordValidator();

  passwordSchema.is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().digits(1);


  const setProducerCredentialsOnSecondVisit = () => {
    //Check if we are coming from a link
    if (props.fromSpaceLink || props.fromBookingLink) {
      JWTHelper.createJWT(urlPathArray[2], "");
    }

    //Check for presence of producerID in localStorage
    if (localStorage.getItem("producerID") !== null) {
      setProducerID(localStorage.getItem("producerID"));
    }
  }

  const removeLinkItemsFromStorage = () => {
    sessionStorage.removeItem("spaceLink");
    sessionStorage.removeItem("linkSpaceID");
    sessionStorage.removeItem("bookingLink");
    sessionStorage.removeItem("linkBookingID");
  }
  
  useEffect(() => {

    //Check for presence of JWT in sessionStorage
    let idToken;

    if (localStorage.getItem("idToken") !== null) {
      idToken = localStorage.getItem("idToken");
    } else {
      //Show sign in form
      
    }

    //Check if we are coming from a space or booking link
    if (props.fromSpaceLink || props.fromBookingLink) {

      setProducerCredentialsOnSecondVisit();
      
      if (idToken) {
        //Make request to check if our credentials are valid
        axios.defaults.headers.common["Authorization"] = idToken;
        setIdToken(idToken);


        return;
      } else {
        //Show sign-in form with "You must sign in"

      }
    } else {

      //Ensure all session storage items are cleared relating to booking/space links
      removeLinkItemsFromStorage();
    }

    return function cleanup() {
      let usernameInput = document.getElementById("usernameInput");
      let passwordInput = document.getElementById("passwordInput");
      let enterButton = document.getElementById("enterButton");

      if (usernameInput) {
        usernameInput.removeEventListener("keyup", function(event) {
          if (event.key === "Enter") {
            enterButton.click();
          }
        });
      }

      if (passwordInput) {
        passwordInput.removeEventListener("keyup", function(event) {
          if (event.key === "Enter") {
            enterButton.click();
          }
        });
      }
      
    }

  }, []);

  useEffect(() => {

    if (!forgotPassword) {
      let usernameInput = document.getElementById("usernameInput");
      let passwordInput = document.getElementById("passwordInput");
      let enterButton = document.getElementById("enterButton");

      usernameInput.addEventListener("keyup", function (event) {
        if (event.key === "Enter") {
          enterButton.click();
        }
      });
      
      passwordInput.addEventListener("keyup", function (event) {
        if (event.key === "Enter") {
          enterButton.click();
        }
      });
      
    }

    return function cleanup() {
      
    }
  }, [forgotPassword]);

  

  

  const handleUsernameChange = (e) => {
    toggleUsernameError(false);
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    togglePasswordError(false);
    setPassword(e.target.value);
  };

  /* New password set */

  const handleNewPasswordChange = (e) => {
    togglePasswordRequiredError(false);
    setNewPasswordValue(e.target.value);
  };

  const handleNewPasswordMatchChange = (e) => {
    toggleConfirmPasswordRequiredError(false);
    setNewPasswordMatch(e.target.value);
  }

  const handleFirstNameChange = (e) => {
    toggleFirstNameRequiredError(false);
    setFirstName(e.target.value);
  }

  const handleLastNameChange = (e) => {
    toggleLastNameRequiredError(false);
    setLastName(e.target.value);
  }

  const handleSaveNewPasswordClick = () => {

    toggleFormHasBeenSubmitted(true);

    let firstNameRequiredError = false;
    let lastNameRequiredError = false;
    let passwordRequiredError = false;
    let confirmPasswordRequiredError = false;

    if (!firstName.trim().length) {
      firstNameRequiredError = true;
      toggleFirstNameRequiredError(true);
    }
    if (!lastName.trim().length) {
      lastNameRequiredError = true;
      toggleLastNameRequiredError(true);
    }
    if (!newPasswordValue.trim().length) {
      passwordRequiredError = true;
      togglePasswordRequiredError(true);
    }
    if (!newPasswordMatch.trim().length) {
      confirmPasswordRequiredError = true;
      toggleConfirmPasswordRequiredError(true);
    }

    if (firstNameRequiredError || lastNameRequiredError || passwordRequiredError || confirmPasswordRequiredError) {
      return;
    }

    toggleFirstNameRequiredError(false);
    toggleLastNameRequiredError(false);
    togglePasswordRequiredError(false);
    toggleConfirmPasswordRequiredError(false);

    setNewPasswordErrorMessageStore([]);

    toggleLoadingSpinner(true);

    //Validate
    if (newPasswordValue !== newPasswordMatch) {
      setNewPasswordErrorMessageStore(newPasswordErrorMessageStore=> [...newPasswordErrorMessageStore, "Passwords must match"]);
      toggleLoadingSpinner(false);
      return;
    };

    let passwordErrors = passwordSchema.validate(newPasswordValue, { list: true });

    if (passwordErrors.length) {

      passwordErrors.forEach(error => {
        if (error === "min") {
          setNewPasswordErrorMessageStore(newPasswordErrorMessageStore => [...newPasswordErrorMessageStore, "Password must be at least 8 characters"]);
        } 
        if (error === "uppercase") {
          setNewPasswordErrorMessageStore(newPasswordErrorMessageStore => [...newPasswordErrorMessageStore, "Password must contain at least 1 uppercase letter"]);
        }
        if (error === "lowercase") {
          setNewPasswordErrorMessageStore(newPasswordErrorMessageStore => [...newPasswordErrorMessageStore, "Password must contain at least 1 lowercase letter"]);
        }
        if (error === "digits") {
          setNewPasswordErrorMessageStore(newPasswordErrorMessageStore => [...newPasswordErrorMessageStore, "Password must contain at least 1 number"]);
        }
      });

      toggleLoadingSpinner(false);
      return;

    } else {

      
      let cognitoUserAttributesClone = cognitoUserAttributes;

      cognitoUser.completeNewPasswordChallenge(newPasswordValue, cognitoUserAttributesClone, {
        onSuccess: function(result) {

          handleLoginSuccess(result);
        },
        onFailure: function(err) {
          alert(err.message);
        }
      });
    }

  }

  /**/
  
  const handleLoginButtonClick = () => {

    //Remove passwordHasBeenChanged message 
    togglePasswordHasBeenChanged(false);

    setLoginErrorMessageStore([]);
    toggleLoadingSpinner(true);

    if (username === "" && password !== "") {
      toggleUsernameError(true);
      toggleLoadingSpinner(false);
      return;
    } else if (username === "" && password === "") {
      toggleUsernameError(true);
      togglePasswordError(true);
      toggleLoadingSpinner(false);
      return;
    } else if (username !== "" && password === "") {
      togglePasswordError(true);
      toggleLoadingSpinner(false);
      return;
    }

    var authenticationData = {
      Username: username,
      Password: password,
    };

    var authenticationDetails = new AuthenticationDetails(
      authenticationData
  );

    var poolData = {
      UserPoolId: 'us-east-1_N15Q0NLkm',
      ClientId: '2332rbhi35f5016dglri2mojo'
    };

    var userPool = new CognitoUserPool(poolData);

    var userData = {
      Username: username,
      Pool: userPool
    };
    
    var cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {

        handleLoginSuccess(result);
      },
      newPasswordRequired: function(userAttr) {

        delete userAttr.email_verified;

        userAttr.name = username;

        toggleLoadingSpinner(false);

        setCognitoUser(cognitoUser);

        setCognitoUserAttributes(userAttr);

        toggleNewPasswordRequired(true);
      },
      onFailure: function(err) {
        console.log("FAILURE", err);
        setLoginErrorMessageStore(loginErrorMessageStore => [...loginErrorMessageStore, err.message]);
        
        toggleLoadingSpinner(false);

        //Re-add event listeners
        let usernameInput = document.getElementById("usernameInput");
        let passwordInput = document.getElementById("passwordInput");
        let enterButton = document.getElementById("enterButton");

        usernameInput.addEventListener("keyup", function (event) {
          if (event.key === "Enter") {
            enterButton.click();
          }
        });

        passwordInput.addEventListener("keyup", function (event) {
          if (event.key === "Enter") {
            enterButton.click();
          }
        });

      },
    })

  };

  const handleGetProjects = async (producerID) => {
    return new Promise(async function(resolve, reject) {
      try {


        let response = await axios.get("/projects-and-spaces", {
          params: {
            producerID: producerID
          }
        });

        console.log("RESPONSE", response);

        //Set projects in context
        setProducerProjects(response.data.projects);
        setProducerSpaces(response.data.spaces);
        
        resolve(true);
      }
      catch (err) {
        console.log("ERROR ERROR", err);
        resolve(false);

        alert("Error getting projects and spaces");

        history.push("/")
      }
    })
    
  }
  
  const handleLoginSuccess = async (result) => {

    //Set default axios header
    axios.defaults.headers.common["Authorization"] = result.getIdToken().getJwtToken();

    let jwtToken = result.getIdToken().getJwtToken();

    //Set token values in context
    setIdToken(jwtToken);
    setRefreshToken(result.getRefreshToken().getToken());

    //Set ID token in localStorage
    localStorage.setItem("idToken", jwtToken);

    localStorage.setItem("awsIDToken", jwtToken);
    localStorage.setItem("awsAccessToken", result.getAccessToken().getJwtToken());

    //Set producer id in context and session storage
    setProducerID(result.accessToken.payload.sub);
    localStorage.setItem("producerID", result.accessToken.payload.sub);

    AWS.config.region="us-east-1";

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:3b0dd9ac-0e3a-4181-8be4-2dba044f5c88',
      IdentityId: result.getIdToken(),
      Logins: {
        // Change the key below according to the specific region your user pool is in.
        'cognito-idp.us-east-1.amazonaws.com/us-east-1_N15Q0NLkm': result
            .getIdToken()
            .getJwtToken(),
      },
    });

    (AWS.config.credentials as AWS.CognitoIdentityCredentials).refresh(async error => {
      if (error) {
        console.error("ERRORERRORERROR", error);
        alert("Error in authentication")
      } else {

        //Get projects and spaces
        await handleGetProjects(result.accessToken.payload.sub);

        toggleLoadingSpinner(false);
        if (!props.fromBookingLink && !props.fromSpaceLink) {
          history.push("/home");
        }

        if (props.handleAuthenticate) {
          props.handleAuthenticate();
        }
      }
    });

  }

  const handleVisibilityClick = () => {
    toggleShowPassword(!showPassword);
  }

  //Forgot password
  const handleForgotPasswordClick = () => {
    //Clear inputs
    //Clear inputs
    setCognitoUser(new CognitoUser({
      Username: "",
      Pool: testUserPool
    }));
    setUserName("");
    setPassword("");

    //Clear errors
    toggleUsernameError(false);
    togglePasswordError(false);
    setLoginErrorMessageStore([]);


    toggleForgotPassword(true);
  }

  const handleForgotPasswordBackButtonClick = () => {

    toggleForgotPassword(false);

  }

  const handleChangePasswordComplete = () => {
    togglePasswordHasBeenChanged(true);

    //Hide forgot password component
    toggleForgotPassword(false);
  }

  //Material UI classes
  const classes = SignInFormStyles();

  if (newPasswordRequired) {
    return (
      <div className={styles.signInFormHolderNewPassword}>
        <Typography classes={{ root: `${classes.signInHeader} ${classes.signInHeaderNewPassword}` }} variant="h1">
          Welcome! Please set your account details:
        </Typography>

        <div className={styles.errorHolderPassword}>
          {newPasswordErrorMessageStore.map(error => {
            return (
              <Typography key={error} classes={{root: classes.errorNewPassword}} variant="h1">
                {error}
              </Typography>
            )
          })}
        </div>

        <TextField 
          variant="outlined"
          placeholder="First Name"
          helperText="Required"
          error={formHasBeenSubmitted && firstNameRequiredError}
          fullWidth
          classes={{ root: classes.firstNameInput}}
          onChange={handleFirstNameChange}
          value={firstName}
        >
        </TextField>
        <TextField 
          variant="outlined"
          placeholder="Last Name"
          error={formHasBeenSubmitted && lastNameRequiredError}
          helperText="Required"
          fullWidth
          classes={{ root: classes.lastNameInput}}
          onChange={handleLastNameChange}
          value={lastName}
        >
        </TextField>
  
        <TextField 
          variant="outlined"
          placeholder="Password"
          helperText="Required"
          fullWidth
          error={ formHasBeenSubmitted && passwordRequiredError }
          type={showPassword ? "text" : "password"}
          classes={{ root: classes.newPasswordInput}}
          onChange={handleNewPasswordChange}
          value={newPasswordValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibleIcon className={classes.visibleIcon} onClick={handleVisibilityClick} />
              </InputAdornment>
            ),
          }}
        >
        </TextField>
  
        <TextField 
          variant="outlined"
          placeholder="Confirm Password"
          helperText="Required"
          fullWidth
          error={formHasBeenSubmitted && confirmPasswordRequiredError }
          type={showPassword ? "text" : "password"}
          classes={{ root: classes.newPasswordConfirmInput}}
          onChange={handleNewPasswordMatchChange}
          value={newPasswordMatch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibleIcon className={classes.visibleIcon} onClick={handleVisibilityClick} />
              </InputAdornment>
            ),
          }}
        >
        </TextField>

        {loadingSpinner &&
          <React.Fragment>
            <CircularProgress className={classes.loadingSpinnerPassword} />
            
          </React.Fragment>
        }
  
        <Button 
          variant="contained" 
          color="primary" 
          classes={{ root: classes.saveButton}}
          onClick={handleSaveNewPasswordClick}
          disabled={loadingSpinner}
        >
          SAVE
        </Button>
      </div>
    )
  } else if (forgotPassword) {
    return (
      <ForgotPassword 
        handleBackButtonClick={handleForgotPasswordBackButtonClick} 
        handleChangePasswordComplete={handleChangePasswordComplete}
      />
    )
  } else {
    return (
      <div className={styles.signInFormHolder}>

        {loadingSpinner &&
          <React.Fragment>
            <CircularProgress className={classes.loadingSpinner} />
            <Typography variant="body1" className={classes.loadingText}>
              Loading
            </Typography>
          </React.Fragment>
        }
        
        {!loadingSpinner &&
          <React.Fragment>
            { passwordHasBeenChanged &&
            <Typography variant="body1" className={classes.passwordHasBeenChangedMessage}>
              Your password has been successfully changed.
            </Typography>
            }

            <div className={styles.errorHolder}>
              {loginErrorMessageStore.map(error => {
                return (
                  <Typography key={error} classes={{ root: classes.error }} variant="h1">
                    {error}
                  </Typography>
                )
              })}
            </div>

            {((props.fromSpaceLink || props.fromBookingLink) && !loginErrorMessageStore.length) &&
              <Typography 
                variant="body1" 
                className={classes.passwordHasBeenChangedMessage}
              >
                Please log in to see this page
              </Typography>
            }

            <TextField
              variant="outlined"
              placeholder="Username"
              fullWidth
              id="usernameInput"
              classes={{ root: classes.usernameInput }}
              onChange={handleUsernameChange}
              value={username}
              error={usernameError}
            >
            </TextField>

            <TextField
              variant="outlined"
              placeholder="Password"
              fullWidth
              id="passwordInput"
              type={showPassword ? "text" : "password"}
              classes={{ root: classes.passwordInput }}
              onChange={handlePasswordChange}
              value={password}
              error={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <VisibleIcon className={classes.visibleIcon} onClick={handleVisibilityClick} />
                  </InputAdornment>
                ),
              }}
            >
            </TextField>

            {loadingSpinner &&
              <CircularProgress className={classes.loadingSpinner} />
            }

            <Link onClick={handleForgotPasswordClick} className={classes.forgotPasswordLink}>
              Forgot Password?
            </Link>

            <Button
              variant="contained"
              color="primary"
              id="enterButton"
              disabled={loadingSpinner}
              classes={{ root: classes.loginButton, label: classes.loginButtonlabel }}
              onClick={handleLoginButtonClick}
            >
              LOGIN
            </Button>
          </React.Fragment>
        }
        
      </div>
    )
  }
 
}
