import { makeStyles, createStyles } from '@material-ui/core/styles';

const EditColorPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 475,
    height: 300,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    height: 300,
    width: "100%",
    position: "absolute",
  },
  editHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
    fontWeight: 400,
  },
  editIcon: {
    fontSize: 16,
    position: 'relative',
    left: 2,
    bottom: 0.5
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 59,
    bottom: 84,
  },
  cancelButton: {
    position: "absolute",
    right: 141,
    bottom: 30,
    width: 95,
    height: 44,
  },
  saveButton: {
    position: "absolute",
    right: 30,
    bottom: 30,
    width: 95,
    height: 44,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "#00B6FF"
    }
  },
  colorHeader: {
    position: "absolute",
    fontSize: 14,
    top: 35,
    left: 81,
  },
  colorLabel: {
    position: "absolute",
    fontSize: 14,
    top: 57,
    left: 81,
  },
  error: {
    color: "red",
    position: "absolute",
    left: 30,
    top: 89,
  }
})
)

export { EditColorPopupStyles };