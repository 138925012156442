import React, { ReactElement, useState, useContext } from 'react'
import { Typography, Button, Dialog, TextField, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';

import { EditBookingCapacityStyles } from './EditBookingCapacityPopupStyles'
import PopupHeader from '../../../PopupHeader/PopupHeader';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function EditBookingCapacityPopup(props: Props): ReactElement {

  const { 
    selectedBooking, 
    setSelectedBooking, 
    selectedSpaceID,
  } : AppContextType = useContext(AppContext);

  const [ capacity, setCapacity ] = useState(selectedBooking.capacity); 
  const [ capacityError, toggleCapacityError ] = useState(false);
  const [ error, toggleError ] = useState(false);
  const [ registrationCountError, toggleRegistrationCountError ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false)

  const handleCancelButtonClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    toggleError(false);
    toggleCapacityError(false);
    toggleRegistrationCountError(false);
    props.handlePopupClose();
  }

  const handleSaveButtonClick = async () => {

    toggleLoadingSpinner(true);

    //Check that capacity is not above 500
    if (capacity > 500) {
      toggleLoadingSpinner(false);
      toggleCapacityError(true);
      return;
    }

    toggleCapacityError(false);

    //Check that capacity value is not below current registration count for the booking
    if (selectedBooking.registrationCount !== undefined && capacity < selectedBooking.registrationCount) {
      toggleLoadingSpinner(false);
      toggleRegistrationCountError(true);
      return;
    }

    toggleRegistrationCountError(false);

    //Tack on new capacity to selectedBookingClone
    let selectedBookingClone = selectedBooking;

    selectedBookingClone.capacity = capacity;

    try {
      let response = await axios.put('/booking', selectedBookingClone);

      toggleLoadingSpinner(false);
      props.handlePopupClose();
      setSelectedBooking(selectedBookingClone);
    } catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
    
  }

  const handleCapacityChange = (e) => {
    setCapacity(e.target.value);
  }
  

  //material UI classes
  const classes = EditBookingCapacityStyles();
  
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={props.open}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.eventCapacityHeader}>
          Event Capacity
        </Typography>

        <Typography variant="body1" className={classes.eventCapacityText}>
          This will limit the number of people in the event
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            Error updating the selected booking
          </Typography>
        }

        {registrationCountError &&
          <Typography variant="body1" className={classes.error}>
            Event capacity may not be set below the current registration count for this event
          </Typography>
        }


        {capacityError &&
          <Typography variant="body1" className={classes.error}>
            Event capacity setting cannot exceed 500 attendees
          </Typography>
        }

        <TextField 
          className={classes.capacityInput}
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: { 
                min: 0
            }
          }}
          onChange={handleCapacityChange}
          value={capacity}
          defaultValue={capacity}
        />

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner } />
        }

        <Button 
          className={classes.cancelButton} 
          variant="contained" 
          color="primary"
          onClick={handleCancelButtonClick}
        >
          CANCEL
        </Button>

        <Button 
          className={classes.saveButton} 
          variant="contained" 
          color="primary"
          onClick={handleSaveButtonClick}
          disabled={loadingSpinner}
        >
          SAVE
        </Button>
      </div>
    </Dialog>
  )
}
