import React, { ReactElement, useContext, useEffect, useState, useRef } from 'react';
import { Button, Typography, Paper, CircularProgress, IconButton } from '@material-ui/core';
import axios from 'axios';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import FolderIcon from '@material-ui/icons/FolderOpen';
import DoorIcon from '@material-ui/icons/MeetingRoomOutlined';
import CopyIcon from '@material-ui/icons/Link';

import { AppContext, AppContextType } from '../../context/AppContext';
import MainWindow from '../../components/MainWindow/MainWindow';
import { AuthContext, AuthContextType } from '../../context/AuthContext';
import SpacePicker from '../../components/SpacePicker/SpacePicker';

import styles from './Main.module.css';
import { MainStyles } from './MainStyles';
import { testSpace } from '../../utilities/TestSpace';
import { AuthHelper } from '../../utilities/AuthHelper';
import ReactDOM from 'react-dom';
import Login from '../Login/Login';

interface Props {
  spacePicker: boolean,
  spaceLink: boolean,
  bookingLink: boolean
}

export default function Main(props: Props): ReactElement {

  const history = useHistory();

  const { 
    setSelectedBooking, 
    setSelectedBookingUsage,
    setSelectedBookingID,
    setSelectedCalendar,
    selectedProject,
    producerID,
    isSuperAdmin,
    toggleIsSuperAdmin,
    spaceLink,
    toggleSpaceLink,
    bookingLink,
    toggleBookingLink,
    setProducerID,
    selectedSpaceID
  } : AppContextType = useContext(AppContext);

  const { setIdToken } : AuthContextType = useContext(AuthContext);

  const { 
    selectedSpace, 
    setSelectedSpace,
    setSelectedSpaceID
  } : AppContextType = useContext(AppContext);

  const [ spaceChosen, toggleSpaceChosen ] = useState(false);
  
  const [ gettingSpace, toggleGettingSpace ] = useState(false);

  const [ spaceUsage, setSpaceUsage ] = useState(null);

  const [ usageLoadingSpinner, toggleUsageLoadingSpinner ] = useState(false);

  const [ spaceID, setSpaceID ] = useState("");

  const usageLoadingSpinnerRef = useRef(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getSpace = async (spaceID) => {
    try {
      let response = await axios.get("/space", {
        params: {
          spaceID: spaceID,
          producerID: producerID
        }
      })

      setSelectedSpace(response.data.space);
      setSelectedSpaceID(response.data.space.spaceID);
    }
    catch (err) {
      console.log("err");
      
    }
  }

  const evaluateURL = () => {
    //Ensure both sessionStorage items are cleared
    sessionStorage.removeItem("linkSpaceID");
    sessionStorage.removeItem("spaceLink");
  }

  useEffect(() => {

    //Disable back 
    window.addEventListener("popstate", function() {
      history.go(1);
    })

    if (sessionStorage.getItem("spaceLink") == null 
      && sessionStorage.getItem("bookingLink") == null
    ) {
      history.push("/home");
    }
    
    //If we are coming from the /space-picker route, clear selected space
    if (props.spacePicker) {
      setSelectedSpace({});
      sessionStorage.removeItem("selectedSpaceID");
    } 

    //apply min-height class to app
    document.getElementById("app").classList.add("appMain");
    
    //Refresh credentials on page refresh
    var poolData = {
      UserPoolId: 'us-east-1_N15Q0NLkm',
      ClientId: '2332rbhi35f5016dglri2mojo'
    };

    const userPool = new CognitoUserPool(poolData);

    let currentUser = userPool.getCurrentUser();

    if (currentUser) {
      currentUser.getSession(async (err, session) => {
        if (err) {
          alert("Your session has expired. Please sign in again.");
          window.location.replace("/");
        } else {
  
          setIdToken(session.getIdToken().getJwtToken());
  
          axios.defaults.headers.common["Authorization"] = session.getIdToken().getJwtToken();

          evaluateURL();
          
          axios.get("/producer/role", {
            params: {
              producerID: producerID
            }
          })
          .then(function (response) {
            //Show admin button if we are a super admin
            if (response.data.role === 0) {
              toggleIsSuperAdmin(true);
            }
          })
          .catch(function (error) {

          })
          
          //Set refresh token interval (10 minutes)
          setInterval(function() {
            var poolData = {
              UserPoolId: 'us-east-1_N15Q0NLkm',
              ClientId: '2332rbhi35f5016dglri2mojo'
            };
  
            const userPool = new CognitoUserPool(poolData);
        
            let currentUser = userPool.getCurrentUser();
  
            currentUser.getSession((err, session) => {
              if (err) {
                alert("Your session has expired. Please sign in again.");
                window.location.replace("/");
              } else {
                setIdToken(session.getIdToken().getJwtToken());
              }
            })

            
          }, 600000)
          
        }
      });
    } else {
      alert("Your session has expired. Please sign in again.");
      window.location.replace("/");
    };

    return function cleanup() {
      source.cancel("Component was unmounted")

      window.removeEventListener("popstate", function () {
        history.go(1);
      })
    }

  }, []);


  useEffect(() => {
    //Re-get usage when selected space changes
    if (Object.keys(selectedSpace).length !== 0) {
      handleRefreshUserHoursClick();
    }
  }, [selectedSpace])

  //SIGN OUT
  const handleSignOutButtonClick = () => {

    //reset selected booking and calendar
    setSelectedBooking(null);
    setSelectedBookingUsage(null);
    setSelectedBookingID(null);
    setSelectedCalendar(null);

    AuthHelper.signOutUser();
    setProducerID("");

    history.push("/");
  }

  //REFRESH HOURS
  const handleRefreshUserHoursClick = () => {

    if (usageLoadingSpinnerRef.current) {
      return;
    }
    
    setSpaceUsage(null);
    usageLoadingSpinnerRef.current = true;
    toggleUsageLoadingSpinner(true);

    //get space usage
    axios.get("/space/usage", {
      params: {
        spaceID: selectedSpace.spaceID
      },
      cancelToken: source.token,
    })
    .then(function(response) {
      toggleUsageLoadingSpinner(false);
      usageLoadingSpinnerRef.current = false;
      setSpaceUsage(response.data.usageSeconds)
    })
    .catch(function(err) {

      if (axios.isCancel(err)) {
        return;
      }

      toggleUsageLoadingSpinner(false);
      usageLoadingSpinnerRef.current = false;
      setSpaceUsage(0);
    })
  }

  //Copy spaceID
  const handleSpaceIDCopy = () => {
    let dummy = document.createElement('textarea');

    document.body.appendChild(dummy);

    dummy.value = spaceID;
    dummy.select();

    document.execCommand('copy');
    document.body.removeChild(dummy);

  }

  //material UI classes
  const classes = MainStyles();

  return (
    <React.Fragment>
      <Paper square className={classes.navHolder}>

        <img 
          className={styles.showboatLogoHeader}
          alt="Showboat logo"
          src="assets/images/sb-logo.svg"
        />

        <div className={styles.navHeaderHolder}>
          <SpacePicker />
        </div>
        <div className={styles.buttonHolder}>
          <Typography variant="body1" className={classes.releaseVersion}>
            Beta 0.15a
          </Typography>
          <Button 
            onClick={handleSignOutButtonClick} 
            variant="text" 
            color="primary" 
            classes={{ root: classes.signOutButton }}
          >
            SIGN OUT
          </Button>
        </div>
      </Paper>

      {/*  Show admin menu button if super admin */}
      {isSuperAdmin &&
        <Button
          variant="contained"
          color="primary"
          className={classes.adminButton}
          onClick={() => { history.push("/admin-menu") }}
        >
          Admin Menu
        </Button>
      }

      {(Object.keys(selectedSpace).length !== 0 
      || sessionStorage.getItem("selectedSpaceID") !== null
      )
      
      ? 
        <div className={styles.mainWrapper}>

          <div className={styles.spaceInfoHolder}>
            <Typography variant="body1" classes={{ root: classes.mainSpaceHeader }}>
              <FolderIcon className={classes.spaceIcon}></FolderIcon>
              {selectedProject ? selectedProject.projectName : ""}
            </Typography>
            <Typography variant="body1" classes={{ root: `${classes.mainSpaceHeader} ${classes.mainSpaceHeaderBottom}` }}>
              <DoorIcon className={classes.spaceIcon}></DoorIcon>
              {selectedSpace.spaceName}
            </Typography>
            <div className={styles.spaceInfoItem}>
              <Typography variant="body1" classes={{ root: classes.mainOpenDate }}>
                Opens: {selectedSpace.startDate !== undefined && moment.tz(selectedSpace.startDate.date, selectedSpace.startDate.timezone).format("L z")}
              </Typography>
            </div>
            <div className={styles.spaceInfoItem}>
              <Typography variant="body1" classes={{ root: classes.mainCloseDate }}>
                Closes: {selectedSpace.endDate !== undefined && moment.tz(selectedSpace.endDate.date, selectedSpace.endDate.timezone).format("L z")}
              </Typography>
            </div>
            <div className={styles.spaceInfoItem}>
              <Typography variant="body1" classes={{ root: classes.mainUsageHeader }}>
                Usage:
            </Typography>
              <Typography variant="body1" classes={{ root: classes.mainUsageHours }}>
                {spaceUsage !== null ? (spaceUsage / 3600).toFixed(2) : " "}
              </Typography>
              <Typography variant="body1" classes={{ root: usageLoadingSpinner ? `${classes.usageHours} ${classes.usageHoursLoading}` : classes.usageHours }}>
                /{selectedSpace.maximumUserHours} hours

              <IconButton className={classes.refreshIcon} onClick={handleRefreshUserHoursClick}>
                  <RefreshIcon className={classes.refreshButtonIcon} />
                </IconButton>
              </Typography>

              {usageLoadingSpinner &&
                <CircularProgress className={classes.usageLoadingSpinner} />
              }
            </div>

            {(isSuperAdmin && (Object.keys(selectedSpace).length !== 0)) &&
              <React.Fragment>

                <Typography variant="body1" className={classes.spaceID}>
                  Space ID: {selectedSpace.spaceID}
                </Typography>

                <IconButton
                  className={classes.spaceCopyButton}
                  onClick={handleSpaceIDCopy}
                >
                  <CopyIcon style={{ fontSize: "20px" }} />
                </IconButton>

              </React.Fragment>

            }

          </div>

          <MainWindow gettingSpace={gettingSpace} />
        </div>

      :
        <Paper className={classes.welcomeHolder}>
          
          <img alt="Showboat logo" src="assets/images/ShowboatLogoWelcome.svg" className={styles.welcomeLogo} />
          
          <Typography variant="h1" className={classes.welcomeHeader}>
            Welcome to the Showboat Producer Portal
          </Typography>
          
        </Paper>
      }

      
    </React.Fragment>
  )
}
