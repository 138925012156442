import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const SystemCheckAdminItemStyles = makeStyles((theme: any) => createStyles({
  systemCheckItemHeader: {
    fontSize: 14,
    position: "absolute",
    display: "inline-block",
    bottom: 5,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 205,
    textAlign: "left"
  },
  name: {
    left: 20,
    width: 160
  },
  date: {
    wordWrap: "break-word",
    left: 185,
    bottom: 20,
  },
  time: {
    wordWrap: "break-word",
    left: 185,
    bottom: 0,
  },
  systemCheckID: {
    top: 3,
    wordWrap: "break-word",
    width: 160,
    whiteSpace: "initial",
    overflow: "initial",
    left: 325,
  },
  detailsButton: {
    position: "absolute",
    right: 0,
    bottom: 7,
    display: "inline-block",
    padding: 0,
    height: 30,
    width: 90
  },
  
})
)

export { SystemCheckAdminItemStyles };