import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { ReactElement, useState } from 'react';
import ProducerAdminCreate from './ProducerAdminCreate/ProducerAdminCreate';
import ProducerAdminEdit from './ProducerAdminEdit/ProducerAdminEdit';
import { ProducerAdminPageStyles } from './ProducerAdminPageStyles';

interface Props {
  
}

export default function ProducerAdminPage(props: Props): ReactElement {

  const history = useHistory();
  
  const [ currentView, setCurrentView ] = useState("create");

  const classes = ProducerAdminPageStyles();
  
  return (
    <React.Fragment>

      <Button 
        className={classes.adminMenuButton} 
        variant="contained" 
        color="primary"
        onClick={() => {history.push("/admin-menu")}}
      >
        ADMIN MENU
      </Button>


      <Button onClick={() => setCurrentView("create")} variant="contained" color="primary" className={classes.createButton}>
        CREATE
      </Button>
      <Button onClick={() => setCurrentView("edit")} variant="contained" color="primary" className={classes.editButton}>
        EDIT
      </Button>

      {currentView === "create"
      ? (
        <ProducerAdminCreate />
      )
      : (
        <ProducerAdminEdit />
      )}
      
    </React.Fragment>
  )
}
