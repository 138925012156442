import { Divider, IconButton, Typography } from '@material-ui/core';
import React, { ReactElement, useState, useEffect, useContext } from 'react';
import EditIcon from '@material-ui/icons/Edit';

import ErrorMessageEditPopup from './ErrorMessageEditPopup/ErrorMessageEditPopup';
import EditSupportMessagePopup from './EditSupportMessagePopup/EditSupportMessagePopup';
import HangUpURLEditPopup from './HangUpURLEditPopup/HangUpURLEditPopup';
import { AppContext, AppContextType } from '../../../context/AppContext';
import RegistrationEmailEdit from './RegistrationEmailEdit/RegistrationEmailEdit';

import styles from './BookingErrorMessages.module.css';
import { BookingErrorMessagesStyles } from './BookingErrorMessagesStyles';

interface Props {
  
}

export default function BookingErrorMessages(props: Props): ReactElement {

  const { toggleOnMessagesWindow } : AppContextType = useContext(AppContext);
  
  const [ errorMessageType, setErrorMessageType ] = useState("");
  const [ editErrorMessage, toggleEditErrorMessage ] = useState(false);

  const [ editHangUpURL, toggleEditHangUpURL ] = useState(false);
  
  const [ editSuppportMessage, toggleEditSupportMessage ] = useState(false);

  const [ editRegistrationEmailAddress, toggleEditRegistrationEmailAddress] = useState(false);

  useEffect(() => {

    //Toggle context variable to increase height when in messages tab
    toggleOnMessagesWindow(true);

    return function cleanup() {
      //Decrease height when leaving messages tab
      toggleOnMessagesWindow(false);
    }

  }, [])
  
  //Support message
  const handleEditSupportMessage = () => {
    toggleEditSupportMessage(true);
  }

  const handleEditSupportMessageClose = () => {
    toggleEditSupportMessage(false);
  }
  
  //Error messages
  const handleEditEventErrorMessage = () => {
    setErrorMessageType("Event");
    toggleEditErrorMessage(true);
  }

  const handleEditSignUpErrorMessage = () => {
    setErrorMessageType("Sign Up")
    toggleEditErrorMessage(true);
  }

  const handleEditAttendeeErrorMessage = () => {
    setErrorMessageType("Attendee");
    toggleEditErrorMessage(true);
  }

  const handleEditPresenterErrorMessage = () => {
    setErrorMessageType("Presenter");
    toggleEditErrorMessage(true);
  }

  const handleEditErrorMessageClose = () => {
    setErrorMessageType("");
    toggleEditErrorMessage(false);
  }

  //Hang up URL
  const handleEditHangUpURLClick = () => {
    toggleEditHangUpURL(true);
  }

  const handleHangUpURLPopupClose = () => {
    toggleEditHangUpURL(false);
  }

  //Registration Notification Email Address
  const handleEditRegistrationEmailAddress = () => {
    toggleEditRegistrationEmailAddress(true);
  }

  const handleEditRegistrationEmailAddressClose = () => {
    toggleEditRegistrationEmailAddress(false);
  }
  
  const classes = BookingErrorMessagesStyles();
  
  return (
    <React.Fragment>

        <div className={styles.supportHolder}>
          <Typography variant="h2" className={classes.supportHeader}>
            Support
          </Typography>
          <div className={styles.supportOptionHolder}>
            <Typography variant="body1" className={classes.supportMessageOption}>
              Support Message
            </Typography>

            <IconButton className={`${classes.editIconButton} ${classes.editIconButtonSupport}`} onClick={handleEditSupportMessage}>
                <EditIcon className={`${classes.editIcon} ${classes.editIconRegistration}`} />
            </IconButton>
          </div>
        </div>

        <Divider className={classes.errorMessagesDivider}></Divider>

        <div className={styles.errorMessageHolder}>

          <Typography variant="h2" className={classes.webPageErrorMessagesHeader}>
            Web Page Error Messages
          </Typography>
          
          <div className={styles.errorOptionHolder}>

            <div className={styles.errorOption}>
              <Typography variant="body1" className={classes.errorOptionHeader}>
                Event Link Error Message
              </Typography>
              <IconButton className={classes.editIconButton} onClick={handleEditEventErrorMessage}>
                <EditIcon className={classes.editIcon} />
              </IconButton>
            </div>
            <div className={styles.errorOption}>
              <Typography variant="body1" className={classes.errorOptionHeader}>
                Registration Link Error Message
              </Typography>
              <IconButton className={`${classes.editIconButton} ${classes.editIconButtonRegistration}`} onClick={handleEditSignUpErrorMessage}>
                <EditIcon className={`${classes.editIcon} ${classes.editIconRegistration}`} />
              </IconButton>
            </div>
            <div className={styles.errorOption}>
              <Typography variant="body1" className={classes.errorOptionHeader}>
                Attendee Link Error Message
              </Typography>
              <IconButton className={classes.editIconButton} onClick={handleEditAttendeeErrorMessage}>
                <EditIcon className={classes.editIcon} />
              </IconButton>
            </div>
            <div className={styles.errorOption}>
              <Typography variant="body1" className={classes.errorOptionHeader}>
                Presenter Link Error Message
              </Typography>
              <IconButton className={classes.editIconButton} onClick={handleEditPresenterErrorMessage}>
                <EditIcon className={classes.editIcon} />
              </IconButton>
            </div>
          </div>
        </div>

        <Divider className={`${classes.errorMessagesDivider} ${classes.errorMessagesDivider2}`}></Divider>

        <div className={styles.hangUpURLHolder}>

          <Typography variant="h2" className={classes.webPageErrorMessagesHeader}>
            In-App
          </Typography>

          <div className={`${styles.errorOption} ${styles.errorOptionHangup}`}>
            <Typography variant="body1" className={classes.errorOptionHeader}>
              Leave Meeting Destination URL
            </Typography>
            
            <IconButton className={`${classes.editIconButton} ${classes.editIconButtonRegistration}`} onClick={handleEditHangUpURLClick}>
              <EditIcon className={`${classes.editIcon} ${classes.editIconRegistration}`} />
            </IconButton>
          </div>

        </div>

        <Divider className={`${classes.errorMessagesDivider} ${classes.errorMessagesDivider3}`}></Divider>

        <div className={styles.registrationEmailHolder}>
          <Typography variant="h2" className={classes.webPageErrorMessagesHeader}>
            Registration Notifications
          </Typography>

          <div className={`${styles.errorOption} ${styles.errorOptionRegistrationNotification}`}>
            <Typography variant="body1" className={`${classes.errorOptionHeader} ${classes.errorOptionHeaderRegistration}`}>
              Registration Notification Email Address
            </Typography>

            <IconButton className={`${classes.editIconButton} ${classes.editIconButtonRegistrationEmail}`} onClick={handleEditRegistrationEmailAddress}>
              <EditIcon className={`${classes.editIcon} ${classes.editIconRegistration}`} />
            </IconButton>
          </div>

        </div>

        {editErrorMessage &&
          <ErrorMessageEditPopup
            open={editErrorMessage}
            errorMessageType={errorMessageType}
            handlePopupClose={handleEditErrorMessageClose}
          />
        }

        {editSuppportMessage &&
          <EditSupportMessagePopup 
            open={editSuppportMessage}
            handlePopupClose={handleEditSupportMessageClose}
          />
        }

        {editHangUpURL &&
          <HangUpURLEditPopup
            open={true}
            handlePopupClose={handleHangUpURLPopupClose}
          />
        }
        
        {editRegistrationEmailAddress &&
          <RegistrationEmailEdit
            open={true}
            handlePopupClose={handleEditRegistrationEmailAddressClose}
          />
        }

    </React.Fragment>

  )
}
