import { CircularProgress, Typography } from '@material-ui/core';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { orderBy } from 'natural-orderby';
import React, { ReactElement, useState, useRef, useContext, useEffect } from 'react'

import { AppContext, AppContextType } from '../../../context/AppContext';
import CalendarItem from '../CalendarItem/CalendarItem';

import styles from './CalendarItemsDisplay.module.css';
import { CalendarItemsDisplayStyles } from './CalendarItemsDisplayStyles'

interface Props {
  
}

export default function CalendarItemsDisplay(props: Props): ReactElement {
  
  const { 
    selectedSpaceID, 
    setSpaceCalendars 
  } : AppContextType = useContext(AppContext);
  
  const [ spaceCalendarsState, setSpaceCalendarsState ] = useState([]);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ noCalendarsError, toggleNoCalendarsError ] = useState(false); 
  const [ error, toggleError ] = useState(false);
  
  const firstRender = useRef(true);
  
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getAllCalendars = async () => {
    toggleError(false);
    setSpaceCalendars([]);
    setSpaceCalendarsState([]);
    toggleLoadingSpinner(true);
    try {
      let response = await axios.get("/calendars", {
        params: {
          spaceID: selectedSpaceID
        },
        cancelToken: source.token,
      })

      toggleLoadingSpinner(false);

      //Set calendars in context and state
      let spaceCalendarsResponse = response.data;

      let spaceCalendarsOrdered = orderBy(
        spaceCalendarsResponse,
        [c => (c as any).calendarName],
        ["asc"]
      );

      setSpaceCalendarsState(spaceCalendarsOrdered);
      setSpaceCalendars(spaceCalendarsOrdered as any);
    }
    catch (error) {
      toggleLoadingSpinner(false);

      if (axios.isCancel(error)) {
        return;
      }

      if (error.response && error.response.status === 404) {
        toggleNoCalendarsError(true);
      } else {
        toggleError(true);
      }
    }
  }
  
  useEffect(() => {
    toggleLoadingSpinner(true);

    toggleNoCalendarsError(false);
    
    getAllCalendars();
          
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    
    //Get calendars again when spaceID changes
    getAllCalendars();
    
  }, [selectedSpaceID])
  
  const classes = CalendarItemsDisplayStyles();
  
  return (
    <div 
      className={styles.calendarsHolder}
      style={{
        minHeight: (loadingSpinner || noCalendarsError) ? "200px" : "0px"
      }}
    >

      {loadingSpinner &&
        <CircularProgress className={classes.loadingSpinner} />
      }

      
      {(!loadingSpinner && noCalendarsError) &&
        <Typography variant="body1" className={classes.noCalendarsError}>
          No calendars found for this space.
        </Typography>
      }

      {error &&
        <Typography variant="body1" className={classes.error}>
          An error occurred getting calendars for this space.
        </Typography>
      }
      
      {!loadingSpinner &&
        spaceCalendarsState.map((calendar, i) => {
          return (
            <CalendarItem 
              key={calendar.calendarID}
              calendarName={calendar.calendarName}
              calendarID={calendar.calendarID}
              index={i}
            />
          )
        })
      }
      
      
    </div>
  )
}
