import { makeStyles, createStyles } from '@material-ui/core/styles';

const MatchingAttendeeRecordStyles = makeStyles((theme: any) => createStyles({
  recordName: {
    fontSize: 14,
    position: "relative",
    display: "inline-block",
    left: 29,
    top: 16,
    width: 190,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  recordUsage: {
    position: "absolute",
    fontSize: "0.875rem",
    top: 16,
    left: 662,
  },
  startTime: {
    fontSize: ".875rem",
    display: "inline-block",
    position: "absolute",
    top: 16,
    left: 238,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      top: 8
    }
  },
  endTime: {
    fontSize: ".875rem",
    position: "absolute",
    display: "inline-block",
    top: 16,
    left: 455,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      top: 8
    }
  },
  detailsButton: {
    padding: 4,
    position: "absolute",
    top: 8,
    right: 25,
    color: "black",
  }
})
)

export { MatchingAttendeeRecordStyles };